import { Omit } from "../../../../types/index";
import { ActionDescriptor } from "../../common";
import { ClientProject } from "./interfaces";

export const GET_CLIENT_PROJECTS = "clientProject/GETS";
export type GetClientProjectsAction = ActionDescriptor<
    typeof GET_CLIENT_PROJECTS,
    {
        accountId: number;
        clientId: number;
        includeArchived: boolean;
        sort: { field: string; direction: "ASC" | "DESC" };
    },
    ClientProject[]
>;

export const GET_ALL_PROJECTS = "project/GET_ALL";
export type GetAllProjectsAction = ActionDescriptor<
    typeof GET_ALL_PROJECTS,
    {
        accountId: number;
        includeArchived: boolean;
        sort: { field: string; direction: "ASC" | "DESC" };
    },
    ClientProject[]
>;
export const COPY_DRAPERY_ORDER = "DRAPERY_ODRDER/COPY";
export type CopyDraperyOrderAction = ActionDescriptor<
    typeof COPY_DRAPERY_ORDER,
    { accountId: number; draperyOrderId: number },
    {}
>;

export const EDIT_CLIENT_PROJECT = "clientProject/EDIT";
export type EditClientProjectAction = ActionDescriptor<
    typeof EDIT_CLIENT_PROJECT,
    Omit<ClientProject, "roomName" | "workOrderId" | "components">,
    {}
>;

export const DELETE_CLIENT_PROJECT = "clientProject/DELETE";
export declare type DeleteClientProjectAction = ActionDescriptor<
    typeof DELETE_CLIENT_PROJECT,
    {
        itemId: number;
    },
    {}
>;

export const ARCHIVE_CLIENT_PROJECT_ITEM = "clientProjectItem/ARCHIVE";
export type ArchiveClientProjectItemAction = ActionDescriptor<
    typeof ARCHIVE_CLIENT_PROJECT_ITEM,
    { accountId: number; draperyOrderId: number; archived: boolean },
    {}
>;

export interface ClientDashboardActionsMap {
    [GET_CLIENT_PROJECTS]: GetClientProjectsAction;
    [GET_ALL_PROJECTS]: GetAllProjectsAction;
    [EDIT_CLIENT_PROJECT]: EditClientProjectAction;
    [COPY_DRAPERY_ORDER]: CopyDraperyOrderAction;
    [DELETE_CLIENT_PROJECT]: DeleteClientProjectAction;
    [ARCHIVE_CLIENT_PROJECT_ITEM]: ArchiveClientProjectItemAction;
}
