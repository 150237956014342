// import { SortTableRowInfo } from "../../components/UI/table/sort_table";
import {
    AccountModule,
    ClientModule,
    DraperyModule,
    NotificationsModule,
    RoomModule,
    RootState,
    SuppliersModule,
    WorkOrderModule,
    WorkroomSettingModule,
} from "../../redux";
import {
    BreadCrumbName,
    BreadCrumbSeparator,
    CalculatorForm,
    CalculatorSectionContainer,
    CalculatorTabs,
    ClientNameSeperator,
    ContentCalculator,
    ContentCalculatorSub,
    DisableAutocompleteInput,
    ErrorsIcon,
    FooterButtonContainer,
    FooterCancelBtn,
    FooterSaveBtn,
    FooterSaveNextBtn,
    FooterSubContainer,
    HContainer,
    NoIssuesIcon,
    PageContent,
    ResetPanelConfirmation,
    SidebarAlertTitle,
    SidebarButton,
    SidebarCollapseIcon,
    SidebarContainer,
    SidebarContainerSub,
    SidebarExpandIcon,
    SidebarIssuesContainer,
    SidebarIssuesHeader,
    SidebarIssuesTitle,
    SidebarNoIssues,
    TabPanelBox,
    ValidationConfirmationCheckbox,
    ValidationConfirmationContainer,
    ValidationConfirmationElement,
    ValidationErrorElement,
    SidebarIssuesText,
} from "./styles";
import ClientHoverBox, { getParentClients } from "../clients/client_hover_box";
import DesignTab, {
    defaultBanding,
    defaultCording,
    defaultDraperyOrderDesign,
    defaultEmbellishment,
    defaultEmbellishmentsList,
    defaultFlange,
    defaultOtherEmbellishment,
    defaultTrim,
    defaultWidthEachPanelList,
    draperyLiningsList,
    duplicateDraperyOrderDesign,
    duplicateEmbellishment,
    duplicateLining,
    panelsConfiguration,
    validateDraperyOrderDesign,
    validateEmbellishmentChecked,
    validateEmbellishmentList,
    validateLiningsList,
} from "./design-tab";
import { DispatchProp, connect } from "react-redux";
import FabricsTab, {
    defaultLiningsFabric,
    defaultMainFabric,
    duplicateFabric,
    filterMainFabric,
    validateDraperyOrderFabric,
    validateDraperyOrderLiningsFabric,
    validateDraperyOrderMainFabric,
    validateDraperyOrderMainFabricConfirmations,
} from "./fabrics-tab";
import { Prompt, RouteComponentProps } from "react-router-dom";
import React, {
    ChangeEvent,
    KeyboardEvent,
    PureComponent,
    createRef,
} from "react";
import { validateMyShopItemPricing } from "../my-shop/standard-item-modal/pricing";
import SetupTab, { validateDraperyOrder } from "./setup-tab";
import SummaryTab, {
    defaultDraperyOrderSummary,
    defaultDraperyOrderSummaryComponent,
    validateDraperyItemPricingTab,
    validateRipplefoldPricing,
    validateDraperyOrderSummary,
    validateDraperyOrderSummaryComponent,
} from "./summary-tab";
import { Tab, TabList } from "react-tabs";
import { ValidationError, ValidationErrorMap } from "@quipa/api";

import { AvatarModal } from "../../modals/avatar_modal";
import Bind from "lodash-decorators/bind";
import { CategoryIds } from "../my-shop/types";
import ClientModal from "../../containers/client/client_modal";
//import DraperyPdf from "../work-order-pdf/drapery-pdf";
import ErrorMessageModal from "../../modals/drapery-order-error-modal";
import { FabricsOrLiningsItemDetailsFieldsPartial } from "../../redux/modules/my-shop/fabrics-or-linings-item-details-fields-reducer";
import {
    FormFields,
    FormFieldsPartial,
} from "../../redux/modules/my-shop/form-fields-reducer";
import LoadingModal from "../../modals/loading-modal";
import { PageHeader } from "../content/page_header";
import { PopoverScrollContext } from "@ramble/ramble-ui";
import { RealtimeActionResponse } from "redux";
import { RecommendationsOverridesModal } from "../../modals/recommendations_overrides_modal";
import { SearchSelectProps } from "../../components/UI/search_select/search_select";
import { Supplier } from "../../interfaces/supplier";
import SupplierModal from "../../containers/supplier/supplier_modal";
import { ValidationErrorInfo } from "./interfaces-common";
import { confirmation } from "../../confirmation";
import { getAll } from "../../api/item";
import { getAll as getAllCategories } from "../../api/category";
import { getAll as getAllSuppliers } from "../../api/supplier";
import { theme } from "../../theme";
import { ModalName } from "../my-shop/types";
import StandardItemModal from "../my-shop/standard-item-modal/main";
import DraperyItemModal from "../my-shop/drapery-item-modal/main";
import { MyShopItem } from "../my-shop/my-shop";
import { Category } from "../../interfaces/category";
import { WorkroomSetting } from "../../redux/modules/workroom_setting";

import { RoomModal } from "../../modals/add_room_modal";
import { FormValidationError } from "@ramble/ramble-ui";
import { validateClientBillingType } from "../clients/client_more_details";
import { SortTableRowInfo } from "../../components/UI/table/sort_table";
import { WorkOrderPdfContent } from "../../redux/modules/work_order";
import { FadeLoader } from "react-spinners";
import { ConfirmationModal } from "../../modals/confirmation_modal";
import { exportPdf } from "../../api/export-pdf";
import { Config } from "../../utils";
import { getFabricsOrLiningsRecord } from "../../api/item";
import { getSelectedName } from "../my-shop/helpers";
import {
    addDraperyOrderPrice,
    updateDraperyOrderPrice,
} from "../../api/drapery-order-price";
import moment from "moment";

export const highlightStyle = {
    backgroundColor: theme.colors.errorBackground,
    borderRadius: 6,
    paddingLeft: 8,
};
import { getAll as getAllItems } from "../../api/item";
const mergeDraperyOrderLiningFabric = ({
    draperyOrderLiningsList,
    draperyOrderAllFabrics,
}: {
    draperyOrderLiningsList: DraperyOrderDesignLining[];
    draperyOrderAllFabrics: DraperyModule.DraperyOrderFabric[];
}): DraperyOrderDesignLining[] => {
    draperyOrderLiningsList = draperyOrderLiningsList
        .sort((a, b) => a.id - b.id)
        .map((dol, index) => {
            if (!dol.active) return dol;
            // let fabric = draperyOrderAllFabrics.find(
            //     (fab) => fab.draperyOrderDesignLiningId === dol.id
            // );
            let string: string;
            let fabric = draperyOrderAllFabrics.filter((fab) => {
                let name = fab.name.split(" ");
                if (name.length > 2) {
                    string = `Other interlining ${index}`;
                } else {
                    string = fab.name.split(" ").join(" ");
                }
                return string.toLowerCase().includes(dol.name.toLowerCase());
            });
            const fabricNew = fabric.find(
                (fab) =>
                    fab.name.split(" ")[0].toLowerCase() ===
                    dol.name.toLowerCase()
            );
            const fabricName =
                fabricNew && fabricNew.name.split(" ")[0].toLowerCase();
            if (dol.active && fabricName !== dol.name.toLowerCase()) {
                dol = {
                    ...dol,
                    draperyOrderLiningsFabric: defaultLiningsFabric(
                        dol.name.toUpperCase() + " FABRIC"
                    ),
                };
            }

            if (fabricNew) {
                dol = {
                    ...dol,
                    draperyOrderLiningsFabric: {
                        ...fabricNew,
                        draperyOrderSummaryComponent:
                            defaultDraperyOrderSummaryComponent(fabricNew.name),
                    },
                };
            }

            return dol;
        });
    return draperyOrderLiningsList;
};

const addEmbellishment = (
    list: DraperyOrderDesignEmbellishment[],
    embellishment: DraperyOrderDesignEmbellishment,
    embellishmentInfo: EmbellishmentCheckInfo,
    draperyOrderFabrics: DraperyModule.DraperyOrderFabric[],
    draperyOrderSummaryComponents: DraperyModule.DraperyOrderSummaryComponent[]
) => {
    list.push(embellishment);
    embellishmentInfo.checked = true;
    const fabric = draperyOrderFabrics.find(
        (fabric) =>
            fabric.draperyOrderDesignEmbellishmentId === embellishment.id
    );
    const component = fabric
        ? draperyOrderSummaryComponents.find(
              (component) => component.draperyOrderFabricId === fabric.id
          )
        : undefined;
    embellishment.draperyOrderFabric = fabric
        ? {
              ...fabric,
              draperyOrderSummaryComponent:
                  component || defaultDraperyOrderSummaryComponent(fabric.name),
          }
        : undefined;
};

const processErrors = (
    tabIndex: number,
    errors: ValidationErrorMap,
    section?: string
): ValidationErrorInfo[] => {
    return Object.keys(errors).map((key) => {
        let camelCase = "";
        const arr = errors[key].replace(",", "").split(" ");
        for (let i = 0; i < arr.length; i++) {
            if (/[A-Z]/.test(arr[i])) {
                camelCase = arr[i];
                break;
            }
        }

        return {
            tabIndex,
            name:
                errors[key].indexOf(
                    "value must contain at least one of supplierId, supplierName"
                ) >= 0
                    ? `${section}.${camelCase}`
                    : errors[key].indexOf("must be a number") >= 0
                    ? `${section}.values.${camelCase}`
                    : key,
            message: errors[key],
        };
    });
};

const defaultDraperyOrder = (
    id?: number,
    clientId?: number,
    clientName?: string,
    quantity?: number | undefined
): DraperyModule.DraperyOrder & { clientName: string } => {
    if (!clientId && !clientName) {
        const draperyItem = localStorage.getItem("draperyItem");
        clientId =
            draperyItem && JSON.parse(draperyItem).client
                ? JSON.parse(draperyItem).client.id
                : 0;
        clientName =
            draperyItem && JSON.parse(draperyItem).client
                ? JSON.parse(draperyItem).client.displayName
                : "";
    }
    return {
        id: id || 0,
        window: "",
        related: "",
        quantity: quantity !== undefined ? quantity : 1,
        hasRelatedWorkOrders: false,
        relatedWorkOrderIds: [],
        workOrderId: undefined,
        isReturnLeftoverMaterials: false,
        clientId: clientId || 0,
        clientName: clientName || "",
        status: "New",
        editable: true,
        userConfirmation: {},
        archived: false,
        isDraft: false,
    };
};

const validateClient = (
    draperyOrder: DraperyModule.DraperyOrder
): ValidationErrorInfo[] => {
    const prefixName = "draperyOrder.";
    const tabIndex = 0;
    let errors: ValidationErrorInfo[] = [];
    if (!(draperyOrder.clientId && draperyOrder.clientId > 0) && ((!(draperyOrder.roomId && draperyOrder.roomId > 0) || !draperyOrder.roomId) && !draperyOrder.customRoom)) {
        errors.push({
            tabIndex,
            name: prefixName + "clientId",
            message: "Please select a client.",
        });
        errors = errors.concat({
            tabIndex,
            name: prefixName + "roomId",
            message: "Please select a room.",
        })
    } else if (!(draperyOrder.clientId && draperyOrder.clientId > 0)) {
        errors.push({
            tabIndex,
            name: prefixName + "clientId",
            message: "Please select a client.",
        });
    } else if ((!(draperyOrder.roomId && draperyOrder.roomId > 0) || !draperyOrder.roomId) && !draperyOrder.customRoom) {
        errors.push({
            tabIndex,
            name: prefixName + "roomId",
            message: "Please select a room.",
        });
    }
    return errors;
};

const defaultWorkOrder = (
    accountId: number,
    workOrderNum?: number
): WorkOrderModule.WorkOrder => ({
    id: 0,
    num: workOrderNum || 0,
    accountId,
});

const duplicateDraperyOrder = (
    draperyOrder: DraperyModule.DraperyOrder & { clientName: string }
): DraperyModule.DraperyOrder & { clientName: string } => {
    return {
        ...draperyOrder,
        id: 0,
        draperyOrderDesignId: undefined,
        draperyOrderSummaryId: undefined,
        workOrderId: undefined,
        isReturnLeftoverMaterials: false,
        hasRelatedWorkOrders: false,
        editable: true,
        status: "New",
        roomId: undefined,
        roomName: "",
        customRoom: "",
        window: "",
        related: "",
        quantity: draperyOrder.quantity,
        isDraft: false,
    };
};

// tslint:disable-next-line:no-empty-interface
interface CalculatorOwnProps extends RouteComponentProps {
    handleCloseModal(): void
}
export interface CalculatorProps extends CalculatorOwnProps {
    accountId: number;
    calculatorDefaults?: AccountModule.State["calculatorDefaults"];
    initialParentClientName?: string;
}

export interface EmbellishmentCheckInfo {
    checked: boolean;
    title: string;
    stateName: string;
}

export interface DraperyOrderDesignEmbellishment
    extends DraperyModule.DraperyOrderDesignEmbellishment {
    draperyOrderFabric?: DraperyOrderFabric;
    patternId?: number | null | undefined;
}

export interface DraperyOrderFabric extends DraperyModule.DraperyOrderFabric {
    supplierName?: string;
    patternId?: number | null | undefined;
    customSupplier?: string;
    draperyOrderSummaryComponent: DraperyModule.DraperyOrderSummaryComponent;
    itemId?: number;
}

export interface DraperyOrderLiningsFabric
    extends DraperyModule.DraperyOrderFabric {
    supplierName?: string;
    customSupplier?: string;
    patternId?: number | null | undefined;
    draperyOrderSummaryComponent: DraperyModule.DraperyOrderSummaryComponent;
}

export interface DraperyOrderDesignLining
    extends DraperyModule.DraperyOrderDesignLining {
    itemId?: any;
    draperyOrderLiningsFabric?: DraperyOrderLiningsFabric;
    patternId?: number | null | undefined;
}

export interface ValidationConfirmationInfo extends ValidationErrorInfo {
    checked: boolean;
    value: string;
}

export interface FabricPatternItem
    extends FormFieldsPartial,
        FabricsOrLiningsItemDetailsFieldsPartial {}

export interface CalculatorState {
    tabIndex: number;
    isSidebarHidden: boolean;
    areaScrollInfo: {
        scrollX: number;
        scrollY: number;
    };
    roomList: Array<{ key: string | number; label: string }>;
    workOrderList: WorkOrderModule.WorkOrder[];
    client?: ClientModule.Client;
    navigationConfirmed: boolean;
    isCopy: boolean;
    isChanged: boolean;
    isWorkOrderValidation: boolean;

    pathIdForModal: string;
    pathNameForModal: string;
    isAddClientModalOpen: boolean;

    isAddSupplierModalOpen: boolean;
    isAddFabricsPatternModalOpen: boolean;
    imageModalOpen: boolean;
    imageModalRelatedName: string;
    isErrorMessageOpen: boolean;
    isRecommendationsOverridesModalOpen: boolean;
    isCustomizeConstructionOpen: boolean;
    isLoadingOpen: boolean;
    fabricPatternModalCategoryId: number;
    draperyOrder: DraperyModule.DraperyOrder & { clientName: string };
    draperyOrderDesign: DraperyModule.DraperyOrderDesign;
    liningsList: DraperyOrderDesignLining[];
    isNoneLinings?: boolean;
    bandings: DraperyOrderDesignEmbellishment[];
    cordings: DraperyOrderDesignEmbellishment[];
    flanges: DraperyOrderDesignEmbellishment[];
    trims: DraperyOrderDesignEmbellishment[];
    othersEmbellishments: DraperyOrderDesignEmbellishment[];
    embellishmentsList: EmbellishmentCheckInfo[];
    isNoneEmbellishment?: boolean;
    removedEmbellishmentIds: number[];
    mainFabric: DraperyOrderFabric;
    draperyOrderSummary: DraperyModule.DraperyOrderSummary;
    draperyOrderSummaryComponents: DraperyModule.DraperyOrderSummaryComponent[];
    workOrder: WorkOrderModule.WorkOrder;
    workOrderPdfStatus: "none" | "downloading" | "error";
    workOrderPdfContent?: WorkOrderModule.WorkOrderPdfContent;
    isEditClientModalOpen: boolean;
    draperyOrderErrors?: ValidationErrorInfo[];
    draperyOrderDesignErrors?: ValidationErrorInfo[];
    draperyOrderFabricsErrors?: ValidationErrorInfo[];
    draperyOrderFabricsConfirmations?: ValidationConfirmationInfo[];
    draperyOrderSummaryErrors?: ValidationErrorInfo[];
    myShopItemPricingErrors?: ValidationErrorInfo[];
    myShopClientBillingErrors?: ValidationErrorInfo[];
    activeModal?: string | null;
    selectedItemId: number | null;
    selectedItem: MyShopItem | null;
    activeItemClassId: number | null;
    categories: Category[];
    suppliers: Supplier[];
    formFieldName?: string;
    pricingTabIndex?: number;
    highlightedSection?: string | null;
    fabricsPatternItems?: FabricPatternItem[];
    liningsPatternItems?: FabricPatternItem[];
    fabricsLinings?: DraperyOrderDesignLining[];
    draperyItemDetail: FormFields[];
    workroomSetting: WorkroomSetting | null;
    isAddRoomModalOpen: boolean;
    clientId: number | null;
    clientName: string;
    addRoomError: string;
    includeInactive: boolean;
    sort: "asc" | "desc";
    clients: SortTableRowInfo[];
    workOrderPdfContentSummery?: WorkOrderPdfContent;
    draperyOrderPriceId: number;
    workRoomSettings: any;
    widthConfiguration: Array<number | undefined>;
    orderOriginState: number;
    orderOriginStateEmb: number;
    orderOriginStateLining: number;
    isModalOpenWo: boolean;
    supplierName: string;
    supplierId: number;
    customSupplier?: string;
    isError?: boolean;
    isModalClosed?: boolean;
    isLoading?: boolean;
    showDraperyItemModal: boolean;
    isDraft: boolean;
    checkedAllowExtraWidthOne: boolean;
    checkedAllowExtraWidthTwo: boolean;
    validateClient: boolean;
    patternId: number | undefined;
    isEditFabricsPatternModalOpen: boolean;
    isEditConstructionDetailsModalOpen: boolean;
    reloadOnSaveConstructions: boolean;
    itemId?: number | string | null;
    workOrderEditMode: boolean;
    loadingUpdate: boolean;
    isUpdateChanged: boolean;
    copyState: any;
    noChanges: boolean;
    newDraperyOrderFabrics: any[];
}

export class Calculator extends PureComponent<
    CalculatorProps & DispatchProp,
    CalculatorState
> {
    public setFocusFirstFieldOnClientModal: any;
    public setFocusFirstFieldOnSupplierModal: any;
    private designTabRef = createRef<DesignTab>();
    findFirstErrorsCalled = false;

    public constructor(props: CalculatorProps & DispatchProp) {
        super(props);
        const paramState = this.props.location.state;
        this.state = {
            tabIndex: paramState && paramState.tab === "summary" ? 3 : 0,
            isSidebarHidden: true,
            areaScrollInfo: {
                scrollX: 0,
                scrollY: 0,
            },
            roomList: [],
            workOrderList: [],
            navigationConfirmed: false,
            isCopy: false,
            isChanged: false,
            isWorkOrderValidation: false,
            isEditClientModalOpen: false,
            pathIdForModal: "",
            pathNameForModal: "",
            isAddClientModalOpen: false,
            isAddSupplierModalOpen: false,
            isAddFabricsPatternModalOpen: false,
            imageModalOpen: false,
            imageModalRelatedName: "",
            isErrorMessageOpen: false,
            isRecommendationsOverridesModalOpen: false,
            isCustomizeConstructionOpen: false,
            isLoadingOpen: false,
            fabricPatternModalCategoryId: 0,
            draperyOrder: paramState
                ? defaultDraperyOrder(
                      paramState.draperyOrderId,
                      paramState.clientId,
                      paramState.clientDisplayName,
                      paramState.quantity
                  )
                : defaultDraperyOrder(),
            draperyOrderDesign: defaultDraperyOrderDesign(),
            liningsList: draperyLiningsList(),
            isNoneLinings: false,
            bandings: [defaultEmbellishment("bandings")!],
            cordings: [defaultEmbellishment("cordings")!],
            flanges: [defaultEmbellishment("flanges")!],
            trims: [defaultEmbellishment("trims")!],
            othersEmbellishments: [
                defaultEmbellishment("othersEmbellishments")!,
            ],
            embellishmentsList: defaultEmbellishmentsList(),
            fabricsLinings: [],
            isNoneEmbellishment: false,
            removedEmbellishmentIds: [],
            mainFabric: defaultMainFabric(),
            draperyOrderSummary: defaultDraperyOrderSummary(),
            draperyOrderSummaryComponents: [],
            workOrder: defaultWorkOrder(this.props.accountId),
            workOrderPdfStatus: "none",

            highlightedSection: null,
            activeModal: null,
            selectedItemId: null,
            selectedItem: null,
            activeItemClassId: null,
            categories: [],
            formFieldName: "",
            suppliers: [],
            pricingTabIndex: undefined,
            draperyItemDetail: [],
            workroomSetting: null,
            isAddRoomModalOpen: false,
            clientId: 0,
            clientName: "",
            addRoomError: "",
            includeInactive: false,
            clients: [],
            sort: "asc",
            workOrderPdfContentSummery: undefined,
            draperyOrderPriceId: 0,
            workRoomSettings: {},
            widthConfiguration: [],
            orderOriginState: -1,
            orderOriginStateEmb: -1,
            orderOriginStateLining: -1,
            isModalOpenWo: false,
            supplierName: "",
            supplierId: 0,
            isError: false,
            isModalClosed: false,
            isLoading: false,
            showDraperyItemModal: false,
            customSupplier: "",
            isDraft: false,
            checkedAllowExtraWidthOne: false,
            checkedAllowExtraWidthTwo: false,
            validateClient: false,
            patternId: 0,
            isEditFabricsPatternModalOpen: false,
            itemId: null,
            workOrderEditMode: false,
            loadingUpdate: false,
            isUpdateChanged: false,
            isEditConstructionDetailsModalOpen: false,
            reloadOnSaveConstructions: false,
            copyState: {
                draperyOrder: paramState
                    ? defaultDraperyOrder(
                        paramState.draperyOrderId,
                        paramState.clientId,
                        paramState.clientDisplayName,
                        paramState.quantity
                    )
                    : defaultDraperyOrder(),
                draperyOrderDesign: defaultDraperyOrderDesign(),
                liningsList: draperyLiningsList(),
                isNoneLinings: false,
                bandings: [defaultEmbellishment("bandings")!],
                cordings: [defaultEmbellishment("cordings")!],
                flanges: [defaultEmbellishment("flanges")!],
                trims: [defaultEmbellishment("trims")!],
                othersEmbellishments: [
                    defaultEmbellishment("othersEmbellishments")!,
                ],
                embellishmentsList: defaultEmbellishmentsList(),
                mainFabric: defaultMainFabric(),
                isNoneEmbellishment: false,
                draperyOrderSummaryComponents: [],
                draperyOrderSummary: defaultDraperyOrderSummary(),
                draperyOrderPriceId: 0,
            },
            noChanges: true,
            newDraperyOrderFabrics: [],
        };
    }

    public async componentDidMount(): Promise<void> {
        const { dispatch } = this.props;
        await this.getClient();
        await this.getRooms(this.state.draperyOrder.clientId || 0);
        // load drapery order
        let clientId;
        let clientName;
        let draperyItem = localStorage.getItem("draperyItem");
        if (draperyItem) {
            clientId =
                draperyItem && JSON.parse(draperyItem).client
                    ? JSON.parse(draperyItem).client.id
                    : 0;
            clientName =
                draperyItem && JSON.parse(draperyItem).client
                    ? JSON.parse(draperyItem).client.displayName
                    : "";
            if (clientId && clientName)
                this.props.history.push("/calculator", {
                    clientId,
                    clientDisplayName: clientName,
                });
        }
        localStorage.removeItem("draperyItem");
        if (this.state.draperyOrder.id !== 0) {
            await this.getExistingDraperyOrder();
        }

        if (!this.state.workOrder.id) {
            const workOrderNum = await this.getNextWorkOrderNum();
            this.setState((state) => ({
                ...state,
                workOrder: {
                    ...state.workOrder,
                    num: workOrderNum,
                },
                copyState: {
                    ...state.copyState,
                    workOrder: {
                        ...state.workOrder,
                        num: workOrderNum
                    }
                }
            }));
        }
        const res = await dispatch(
            WorkroomSettingModule.searchWorkroomSetting(this.props.accountId, 1)
        );

        let fabricPurchaser: any;
        let embellishmentsPurchaser: any;
        let liningsPurchaser: any;
        if (!res.error && res.payload.length > 0) {
            fabricPurchaser = res.payload[0].fabricPurchaser;
            embellishmentsPurchaser = res.payload[0].embellishmentsPurchaser;
            liningsPurchaser = res.payload[0].liningsPurchaser;
        }
        await this.getWorkOrderNumbers();
        await this.getFabricsItems({ isReload: false });
        await this.getDraperyItems({
            isReload: false,
            showDraperyItemModal: true,
        });
        await this.getLiningsItems({ isReload: false });
        await this.fetchAll();
        window.addEventListener("popstate", () => {
            let draperyItem: any = localStorage.getItem("draperyItem");
            if (draperyItem) {
                draperyItem = JSON.parse(draperyItem);
            } else {
                draperyItem = {};
            }
            draperyItem.showDraperyModal = false;
            localStorage.setItem("draperyItem", JSON.stringify(draperyItem));
        });
        this.setState((state) => ({
            ...state,
            workroomSetting: {
                ...res.payload[0],
                fabricPurchaser: fabricPurchaser,
                embellishmentsPurchaser: embellishmentsPurchaser,
                liningsPurchaser: liningsPurchaser,
                buckram: "",
                draperyPinInstaller: "",
                hardwarePurchaser: "",
                id: undefined,
                name: "",
                presetAddExcessUnit: "select unit",
                presetCutLengthJson: {
                    lining: undefined,
                    interlining: undefined,
                },
                presetDraperyFullnessJson: { value: undefined },
                presetEmbellishmentJson: {
                    trimVertical: undefined,
                    flangeVertical: undefined,
                    trimHorizontal: undefined,
                    cordingVertical: undefined,
                    flangeHorizontal: undefined,
                },
                presetHEMSJson: {
                    bottomHemFabricAllowance: undefined,
                    bottomHemFinishedSize: undefined,
                    sideHemFabricAllowance: undefined,
                    sideHemFinishedSize: undefined,
                    topHemFabricAllowance: undefined,
                    topHemFinishedSize: undefined,
                },
                presetIsYardageAddExcess: false,
                presetIsYardageAddOneExtra: false,
                presetReturnOverlapJson: {
                    leadSize: undefined,
                    returnSize: undefined,
                    overlapSize: undefined,
                },
                type: undefined,
            },
            copyState: {
                ...state.copyState,
                workroomSetting: {
                    ...res.payload[0],
                    fabricPurchaser: fabricPurchaser,
                    embellishmentsPurchaser: embellishmentsPurchaser,
                    liningsPurchaser: liningsPurchaser,
                    buckram: "",
                    draperyPinInstaller: "",
                    hardwarePurchaser: "",
                    id: undefined,
                    name: "",
                    presetAddExcessUnit: "select unit",
                    presetCutLengthJson: {
                        lining: undefined,
                        interlining: undefined,
                    },
                    presetDraperyFullnessJson: { value: undefined },
                    presetEmbellishmentJson: {
                        trimVertical: undefined,
                        flangeVertical: undefined,
                        trimHorizontal: undefined,
                        cordingVertical: undefined,
                        flangeHorizontal: undefined,
                    },
                    presetHEMSJson: {
                        bottomHemFabricAllowance: undefined,
                        bottomHemFinishedSize: undefined,
                        sideHemFabricAllowance: undefined,
                        sideHemFinishedSize: undefined,
                        topHemFabricAllowance: undefined,
                        topHemFinishedSize: undefined,
                    },
                    presetIsYardageAddExcess: false,
                    presetIsYardageAddOneExtra: false,
                    presetReturnOverlapJson: {
                        leadSize: undefined,
                        returnSize: undefined,
                        overlapSize: undefined,
                    },
                    type: undefined,
                },
            }
        }));
    }

    public componentWillUnmount(): void {
        window.removeEventListener("popstate", () => {});
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot?: any): void {
        const draperyOrderError = this.state.draperyOrderErrors || [];
        const draperyDesigneErrorr = this.state.draperyOrderDesignErrors || [];
        const draperFabricsError = this.state.draperyOrderFabricsErrors || [];
        const draperyOrderConfirmation =
            this.state.draperyOrderFabricsConfirmations || [];
        const draperyOrderSummery = this.state.draperyOrderSummaryErrors || [];
        let arrErrorr = [
            ...draperyOrderError,
            ...draperyDesigneErrorr,
            ...draperFabricsError,
            ...draperyOrderConfirmation,
            ...draperyOrderSummery,
        ];

        const { draperyOrder, bandings, cordings, draperyOrderDesign, draperyOrderPriceId, draperyOrderSummary, draperyOrderSummaryComponents, embellishmentsList, flanges, isNoneEmbellishment, isNoneLinings, liningsList, mainFabric, othersEmbellishments, trims, workOrder, workroomSetting } = this.state

        const originObjectToCompare = {
            draperyOrder,
            bandings,
            cordings,
            draperyOrderDesign,
            draperyOrderPriceId,
            draperyOrderSummary,
            draperyOrderSummaryComponents,
            embellishmentsList,
            flanges,
            isNoneEmbellishment,
            isNoneLinings,
            liningsList,
            mainFabric,
            othersEmbellishments,
            trims,
            workOrder,
            workroomSetting,
        }

        if (this.deepEqual(originObjectToCompare, this.state.copyState)) {
            this.setState(
                (state) => ({
                    ...state,
                    noChanges: true,
                }),
            );
        } else {
            this.setState(prev => {
                return {
                    ...prev,
                    noChanges: false,
                }
            })
        }

        if (this.state.isEditConstructionDetailsModalOpen) {
            getAllItems({ isActiveOnly: true }).then(res => {
                const foundDraperyItem = res.filter(i => i.name === 'Drapery')

                this.setState(prev => {
                    return {
                        ...prev,
                        selectedItemId: foundDraperyItem[0].id || null
                    }
                })
            }).catch(err => {
                console.error("Error fetching items:", err)
            })
        }

        if (this.state.reloadOnSaveConstructions) {
            if (!this.state.isEditConstructionDetailsModalOpen) {
                this.getDefaultConstruction()
            }
        }

        if (prevState.mainFabric.orderOrigin !== this.state.mainFabric.orderOrigin) {
            this.setState(prev => {
                return {
                    ...prev,
                    copyState: {
                        ...prev.copyState,
                        mainFabric: {
                            ...prev.copyState.mainFabric,
                            orderOrigin: this.state.mainFabric.orderOrigin
                        }
                    }
                }
            })
            if (
                prevState.mainFabric.orderOrigin !== -1 &&
                this.state.mainFabric.orderOrigin === 1
            ) {
                this.setState({
                    mainFabric: {
                        ...this.state.mainFabric,
                        orderOrigin: this.state.mainFabric.orderOrigin,
                    },
                });
            }
        }
        if (
            prevState.draperyOrder !== this.state.draperyOrder ||
            prevState.draperyOrderDesign !== this.state.draperyOrderDesign ||
            prevState.mainFabric !== this.state.mainFabric ||
            prevState.othersEmbellishments !==
                this.state.othersEmbellishments ||
            prevState.bandings !== this.state.bandings ||
            prevState.cordings !== this.state.cordings ||
            prevState.flanges !== this.state.flanges ||
            prevState.trims !== this.state.trims ||
            prevState.draperyOrderSummary !== this.state.draperyOrderSummary
        ) {
            if (this.state.workOrderEditMode) {
                this.setState({
                    isUpdateChanged: true,
                });
            }
        }
        if (
            prevState.draperyOrderDesign.headingType !==
            this.state.draperyOrderDesign.headingType
        ) {
            const { headingType, draperyType } = this.state.draperyOrderDesign;
            const isCustomizeConstructionopen = headingType
                ? headingType === "rodandrings"
                    ? !!draperyType
                    : true
                : false;
            this.setState({
                isCustomizeConstructionOpen: isCustomizeConstructionopen,
            });
        }
        if (this.state.draperyOrderDesign.isWeight === null){
            this.setState(prev => {
                return {
                    ...prev,
                    draperyOrderDesign: {
                        ...prev.draperyOrderDesign,
                        isWeight: false,
                    },
                    copyState: {
                        ...prev.copyState,
                        draperyOrderDesign: {
                            ...prev.draperyOrderDesign,
                            isWeight: false,
                        }
                    }
                }
            });
        }
        if (
            arrErrorr.length > 0 &&
            !this.state.isError &&
            !this.findFirstErrorsCalled &&
            !this.state.isErrorMessageOpen
        ) {
            this.setState({ isError: true }, () => {
                this.findFirstErrors();
                this.findFirstErrorsCalled = true;
            });
        }
        if (
            this.state.draperyOrderDesign.ripplefoldOptions.headerTape ===
            "other"
        ) {
            this.setState((prev) => {
                return {
                    ...prev,
                    draperyOrderDesign: {
                        ...prev.draperyOrderDesign,
                        ripplefoldOptions: {
                            ...prev.draperyOrderDesign.ripplefoldOptions,
                            headerTape: "",
                        },
                    },
                };
            });
        }
        if (
            this.state.draperyOrderDesign.ripplefoldOptions.controlLocation ===
            "other"
        ) {
            this.setState((prev) => {
                return {
                    ...prev,
                    draperyOrderDesign: {
                        ...prev.draperyOrderDesign,
                        ripplefoldOptions: {
                            ...prev.draperyOrderDesign.ripplefoldOptions,
                            controlLocation: "",
                        },
                    },
                };
            });
        }
        this.getDefaultValue();
    }

    public render(): JSX.Element {
        const { client, draperyOrder, roomList, workOrderEditMode } =
            this.state;
        // const pdfFilenamePrefix = `${
        //     this.state.client
        //         ? this.state.client.lastName ||
        //           this.state.client.company ||
        //           this.state.client.firstName ||
        //           "QUIPA"
        //         : "QUIPA"
        // } WO-`;
        const filteredRoomList = roomList.filter(
            (room) => room.key === draperyOrder.roomId
        )[0];

        return (
            <>
                <Prompt
                    message={(location: any): string | boolean => {
                        if (
                            this.state.navigationConfirmed ||
                            this.state.noChanges ||
                            !this.state.isChanged
                        ) {
                            return true;
                        } else {
                            this.customNavigation(location);
                            return false;
                        }
                    }}
                />

                <ConfirmationModal
                    uiAcceptLabel="Setup"
                    uiDeclineLabel="Cancel"
                    uiHeader="Setup Needed"
                    uiActive={this.state.showDraperyItemModal}
                    uiOnConfirm={() => {
                        let draperyItem: any =
                            localStorage.getItem("draperyItem");
                        if (draperyItem) {
                            draperyItem = JSON.parse(draperyItem);
                        } else {
                            draperyItem = {};
                        }
                        draperyItem.showDraperyModal = true;
                        draperyItem.client = this.state.client;
                        localStorage.setItem(
                            "draperyItem",
                            JSON.stringify(draperyItem)
                        );
                        this.props.history.push("/myshop");
                    }}
                    uiOnClose={() =>
                        this.setState({ showDraperyItemModal: false }, () => {
                            let draperyItem: any =
                                localStorage.getItem("draperyItem");
                            if (draperyItem) {
                                draperyItem = JSON.parse(draperyItem);
                            } else {
                                draperyItem = {};
                            }
                            draperyItem.showDraperyModal = false;
                            localStorage.setItem(
                                "draperyItem",
                                JSON.stringify(draperyItem)
                            );
                        })
                    }
                    uiOnRequestClose={() =>
                        this.setState({ showDraperyItemModal: false })
                    }
                    uiCloseOnOutsideClick={true}
                    uiCloseOnEsc={true}
                >
                    The Drapery item must be setup in My Shop prior to creating
                    a Drapery Order.
                </ConfirmationModal>

                <PageContent
                    style={{
                        position: "relative",
                        pointerEvents: this.state.isLoading
                            ? "none"
                            : undefined,
                    }}
                >
                    {/* {this.state.workOrderPdfStatus === "downloading" &&
                        this.state.workOrderPdfContent && (
                            <DraperyPdf
                                filename={
                                    pdfFilenamePrefix
                                        ? `${pdfFilenamePrefix}-${this.state.workOrderPdfContent.workOrderNum}`
                                        : `${this.state.workOrderPdfContent.workOrderNum}`
                                }
                                workOrderPdfContent={
                                    this.state.workOrderPdfContent
                                }
                                onFinished={this.handleDraperyPdfFinished}
                            />
                        )} */}

                    <FadeLoader
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(50%,-50%)",
                            zIndex: 9999,
                            display: !this.state.isLoading ? "none" : "block",
                        }}
                        color="#3B97B1"
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />

                    <HContainer uiBaseLine className="flex-wrap">
                        <PageHeader
                            classNameProps="text-xl md:text-2xl"
                            title={
                                this.state.isCopy
                                    ? `Drapery Order - Copy`
                                    : `Drapery Order`
                            }
                        />
                        {client && (
                            <ClientNameSeperator> - </ClientNameSeperator>
                        )}
                        {client &&
                            client.parent &&
                            getParentClients(client.parent).map(
                                (ancestorClient) => (
                                    <>
                                        <ClientHoverBox
                                            key={ancestorClient.id}
                                            client={ancestorClient}
                                            onClick={this.navigateToClient}
                                            type="draperyOrder"
                                        />
                                        <ClientNameSeperator>
                                            {" "}
                                            |{" "}
                                        </ClientNameSeperator>
                                    </>
                                )
                            )}
                        {client && (
                            <ClientHoverBox
                                key={client.id}
                                client={client}
                                onClick={this.navigateToClient}
                                type="draperyOrder"
                            />
                        )}
                        {client &&
                            ![0, "", null, undefined].includes(
                                draperyOrder.roomId
                            ) ?
                            roomList.length > 0 && (
                                <>
                                    <BreadCrumbSeparator>
                                        {" "}
                                        /{" "}
                                    </BreadCrumbSeparator>
                                    <BreadCrumbName>{`${
                                        filteredRoomList
                                            ? filteredRoomList.label
                                            : ""
                                    }`}</BreadCrumbName>

                                    {draperyOrder.window && (
                                        <>
                                            <BreadCrumbSeparator>
                                                {" "}
                                                /{" "}
                                            </BreadCrumbSeparator>
                                            <BreadCrumbName>
                                                {draperyOrder.window}
                                            </BreadCrumbName>
                                        </>
                                    )}
                                </>
                            ) : client && [0, "", null, undefined].includes(
                                draperyOrder.roomId
                            ) && draperyOrder.customRoom ?
                            <>
                                <BreadCrumbSeparator>
                                    {" "}
                                    /{" "}
                                </BreadCrumbSeparator>
                                <BreadCrumbName>{`${draperyOrder.customRoom
                                    ? draperyOrder.customRoom
                                    : ""
                                }`}</BreadCrumbName>

                                {draperyOrder.window && (
                                    <>
                                        <BreadCrumbSeparator>
                                            {" "}
                                            /{" "}
                                        </BreadCrumbSeparator>
                                        <BreadCrumbName>
                                            {draperyOrder.window}
                                        </BreadCrumbName>
                                    </>
                                )}
                            </> : null
                        }
                    </HContainer>
                    <CalculatorSectionContainer>
                        <CalculatorForm
                            id="draperyOrderForm"
                            onKeyPress={this.handleKeyPressForm}
                            uiPadding={false}
                        >
                            <DisableAutocompleteInput name="firstName" />
                            <ContentCalculator>
                                <ContentCalculatorSub>
                                    <PopoverScrollContext.Provider
                                        value={this.state.areaScrollInfo}
                                    >
                                        <CalculatorTabs
                                            style={{ transition: "all 0.2s" }}
                                            forceRenderTabPanel
                                            selectedIndex={this.state.tabIndex}
                                            onSelect={(tabIndex) =>
                                                this.setState({ tabIndex })
                                            }
                                        >
                                            <TabList>
                                                <Tab>Setup</Tab>
                                                <Tab>Design</Tab>
                                                <Tab>Fabrics</Tab>
                                                <Tab>Summary</Tab>
                                            </TabList>
                                            {this.renderSetupTab()}

                                            {this.renderDesignTab()}

                                            {this.renderFabricsTab()}

                                            {this.renderSummaryTab()}
                                        </CalculatorTabs>
                                    </PopoverScrollContext.Provider>

                                    {this.renderSidebar()}
                                </ContentCalculatorSub>
                                <FooterSubContainer>
                                    <FooterButtonContainer className="flex-col md:flex-row justify-between">
                                        <div>
                                            <FooterCancelBtn
                                                uiColor="primary"
                                                disabled={
                                                    this.state.loadingUpdate
                                                }
                                                uiDisabled={
                                                    this.state.loadingUpdate
                                                }
                                                type="button"
                                                onClick={() =>
                                                    workOrderEditMode
                                                        ? this.cancelEditingWorkOrder()
                                                        : this.toggleCancelModal()
                                                }
                                            >
                                                Cancel
                                            </FooterCancelBtn>
                                        </div>

                                        <div className="flex items-center">
                                            {!workOrderEditMode && (
                                                <FooterSaveBtn
                                                    type="button"
                                                    onClick={() =>
                                                        this.handleSaveAll(
                                                            false
                                                        )
                                                    }
                                                >
                                                    Save
                                                </FooterSaveBtn>
                                            )}

                                            {workOrderEditMode ? (
                                                <FooterSaveNextBtn
                                                    disabled={
                                                        this.state.loadingUpdate
                                                    }
                                                    uiDisabled={
                                                        this.state.loadingUpdate
                                                    }
                                                    uiColor="primary"
                                                    type="button"
                                                    onClick={() =>
                                                        this.state.tabIndex !==
                                                        3
                                                            ? this.handleNextTab()
                                                            : this.updateWorkOrder(
                                                                  "delete_messag"
                                                              )
                                                    }
                                                >
                                                    {this.state.tabIndex === 3
                                                        ? "Update"
                                                        : "Next"}
                                                </FooterSaveNextBtn>
                                            ) : (
                                                <FooterSaveNextBtn
                                                    uiColor="primary"
                                                    type="button"
                                                    onClick={
                                                        this.handleSaveAndNext
                                                    }
                                                >
                                                    {this.state.tabIndex === 3
                                                        ? `Save & Close`
                                                        : `Save & Next`}
                                                </FooterSaveNextBtn>
                                            )}
                                        </div>
                                    </FooterButtonContainer>
                                </FooterSubContainer>
                            </ContentCalculator>
                        </CalculatorForm>
                    </CalculatorSectionContainer>
                </PageContent>

                <ClientModal
                    isModalOpen={this.state.isAddClientModalOpen}
                    onModalClose={this.handleCloseClientModal}
                    onClientSaved={this.handleClientChanged}
                    getRooms={this.getRooms}
                    resetOnClose
                    setFocusFirstField={(fn) =>
                        (this.setFocusFirstFieldOnClientModal = fn)
                    }
                    getClient={this.getClient}
                />
                {client && client.id > 0 && (
                    <ClientModal
                        key={client.id}
                        client={client}
                        isModalOpen={this.state.isEditClientModalOpen}
                        onModalClose={this.closeEditClientModal}
                        onClientSaved={this.onClientEdited}
                        resetOnClose
                        setFocusFirstField={(fn) =>
                            (this.setFocusFirstFieldOnClientModal = fn)
                        }
                        tabIndex={1}
                        highlightedSection={this.state.highlightedSection}
                        clearHighlightSection={this.clearHighlightSection}
                        updateStateByName={this.updateStateByName}
                    />
                )}
                <RoomModal
                    draperyOrder={this.state.draperyOrder}
                    handleSearchSelectChange={this.handleSearchSelectChange}
                    handleSearchClient={this.handleSearchClient}
                    isModalOpen={this.state.isAddRoomModalOpen}
                    uiHeader="Add New Room"
                    closeModal={() =>
                        this.setState({ isAddRoomModalOpen: false })
                    }
                    addRoom={this.handleAddRoom}
                    addRoomError={this.state.addRoomError}
                />
                <SupplierModal
                    accountId={this.props.accountId}
                    isModalOpen={this.state.isAddSupplierModalOpen}
                    onModalClose={this.handleCloseSupplierModal}
                    onSave={this.handleSupplierChanged}
                    resetOnClose
                    setFocusFirstField={(fn) =>
                        (this.setFocusFirstFieldOnSupplierModal = fn)
                    }
                />

                <AvatarModal
                    uiActive={this.state.imageModalOpen}
                    uiOnRequestClose={this.handleCloseImageModal}
                    onSave={this.handleSaveImage}
                    isRect
                    ratio={
                        this.state.imageModalRelatedName.endsWith(
                            "Fabric.imageId"
                        )
                            ? 0.5
                            : undefined
                    }
                    header={
                        this.state.imageModalRelatedName.endsWith(
                            "Fabric.imageId"
                        )
                            ? "Upload Image"
                            : "Pleat Type Image"
                    }
                />
                <ErrorMessageModal
                    isModalOpen={this.state.isErrorMessageOpen}
                    onModalClose={this.toggleErrorMessage}
                />
                <RecommendationsOverridesModal
                    editMode={this.state.workOrderEditMode}
                    data={this.state}
                    active={this.state.isRecommendationsOverridesModalOpen}
                    onRequestClose={this.handleCancelRecommendationsOverrides}
                    isFunctional={this.state.draperyOrderDesign.category === 0}
                    panelType={this.state.draperyOrderDesign.panelType}
                    draperyOrderId={this.state.draperyOrder.id}
                    selectedPanels={this.state.draperyOrderDesign.category}
                    handleConfirm={this.handleConfirmRecommendationOverride}
                    draperyOrderDesign={this.state.draperyOrderDesign}
                    newDraperyOrderFabrics={this.state.newDraperyOrderFabrics}
                    workOrderEditMode={this.state.workOrderEditMode}
                    resetDraperyOrderNewFabrics={this.resetDraperyOrderNewFabrics}
                    handleSaveAll={this.saveAll}
                    resetAfterUpdatingEmbellishments={this.resetAfterUpdatingEmbellishments}
                />
                <LoadingModal
                    message="Creating work order..."
                    isModalOpen={this.state.isLoadingOpen}
                    onModalClose={this.toggleLoadingModal}
                />
            </>
        );
    }

    public renderSidebar(): JSX.Element {
        return (
            <SidebarContainer
                className={`absolute top-0 right-0 h-full lg:relative z-50 ${
                    !this.state.isSidebarHidden
                        ? "w-1/2 lg:w-1/3 max-w-xs pl-2"
                        : "w-0 max-w-0"
                }`}
                style={{ backgroundColor: "white" }}
            >
                <div className="absolute" style={{ left: -30 }}>
                    <SidebarButton onClick={this.toggleSidebar}>
                        {this.state.isSidebarHidden ? (
                            <SidebarExpandIcon />
                        ) : (
                            <SidebarCollapseIcon />
                        )}
                        {!!(
                            (this.state.draperyOrderErrors &&
                                this.state.draperyOrderErrors.length) ||
                            (this.state.draperyOrderDesignErrors &&
                                this.state.draperyOrderDesignErrors.length) ||
                            (this.state.draperyOrderFabricsErrors &&
                                this.state.draperyOrderFabricsErrors.length) ||
                            (this.state.draperyOrderFabricsConfirmations &&
                                this.state.draperyOrderFabricsConfirmations
                                    .length > 0 &&
                                (this.state.mainFabric.directionOfUse === 1
                                    ? !this.state.checkedAllowExtraWidthOne
                                    : !this.state.checkedAllowExtraWidthTwo)) ||
                            (this.state.draperyOrderSummaryErrors &&
                                this.state.draperyOrderSummaryErrors.length)
                        ) && <ErrorsIcon />}
                    </SidebarButton>
                </div>
                {!this.state.isSidebarHidden && (
                    <SidebarContainerSub>
                        {this.renderIssues()}
                    </SidebarContainerSub>
                )}
            </SidebarContainer>
        );
    }

    public renderIssues(): JSX.Element | null {
        const hasDraperyOrderIssues =
            this.state.draperyOrderErrors &&
            this.state.draperyOrderErrors.length > 0;
        const hasDraperyOrderDesignIssues =
            this.state.draperyOrderDesignErrors &&
            this.state.draperyOrderDesignErrors.length > 0;
        const hasDraperyOrderFabricsIssues =
            this.state.draperyOrderFabricsErrors &&
            this.state.draperyOrderFabricsErrors.length > 0;
        const hasDraperyOrderFabricsConfirmations =
            this.state.draperyOrderFabricsConfirmations &&
            this.state.draperyOrderFabricsConfirmations.length > 0;
        const hasDraperyOrderSummaryIssues =
            this.state.draperyOrderSummaryErrors &&
            this.state.draperyOrderSummaryErrors.length > 0;

        const hasMyShpItemPricingErrors =
            this.state.myShopItemPricingErrors &&
            this.state.myShopItemPricingErrors.length > 0;
        const hasMyShopClientBillingErrors =
            this.state.myShopClientBillingErrors &&
            this.state.myShopClientBillingErrors.length > 0;

        const ripplefoldPricingErrors = validateRipplefoldPricing(this.state.draperyItemDetail);

        const hasIssues =
            hasDraperyOrderIssues ||
            hasDraperyOrderDesignIssues ||
            hasDraperyOrderFabricsIssues ||
            hasDraperyOrderFabricsConfirmations ||
            hasDraperyOrderSummaryIssues ||
            hasMyShpItemPricingErrors ||
            hasMyShopClientBillingErrors || 
            ripplefoldPricingErrors;

        if (!hasIssues) return null;
        return (
            <SidebarIssuesContainer>
                {(hasIssues === true) && (
                    <>
                        <SidebarIssuesHeader>ISSUES</SidebarIssuesHeader>
                        <SidebarIssuesText>
                            Click the issue below to navigate to the error.
                        </SidebarIssuesText>
                    </>
                )}
                {ripplefoldPricingErrors && ripplefoldPricingErrors.length > 0 && (
                    <>
                        <SidebarIssuesTitle>
                            Pricing
                        </SidebarIssuesTitle>
                        <ul>
                            {this.renderValidationErrorElement(
                                ripplefoldPricingErrors.slice(0, 10)
                            )}
                        </ul>
                    </>
                )}
                {hasDraperyOrderIssues && (
                    <>
                        <SidebarIssuesTitle>Setup</SidebarIssuesTitle>
                        <ul>
                            {this.renderValidationErrorElement(
                                this.state.draperyOrderErrors!
                            )}
                        </ul>
                    </>
                )}
                {hasDraperyOrderDesignIssues && (
                    <>
                        <SidebarIssuesTitle>Design</SidebarIssuesTitle>
                        <ul>
                            {this.renderValidationErrorElement(
                                this.state.draperyOrderDesignErrors!.slice(
                                    0,
                                    10
                                )
                            )}
                        </ul>
                    </>
                )}
                {(hasDraperyOrderFabricsIssues ||
                    hasDraperyOrderFabricsConfirmations) && (
                    <>
                        <SidebarIssuesTitle>Fabrics</SidebarIssuesTitle>

                        {hasDraperyOrderFabricsIssues && (
                            <ul>
                                {this.renderValidationErrorElement(
                                    this.state.draperyOrderFabricsErrors!.slice(
                                        0,
                                        10
                                    )
                                )}
                            </ul>
                        )}
                        {hasDraperyOrderFabricsConfirmations && (
                            <>
                                <SidebarAlertTitle>Alert!</SidebarAlertTitle>
                                {this.renderValidationConfirmationElement(
                                    this.state.draperyOrderFabricsConfirmations!.slice(
                                        0,
                                        10
                                    )
                                )}
                            </>
                        )}
                    </>
                )}
                {hasDraperyOrderSummaryIssues && (
                    <>
                        <SidebarIssuesTitle>Summary</SidebarIssuesTitle>
                        <ul>
                            {this.renderValidationErrorElement(
                                this.state.draperyOrderSummaryErrors!.slice(
                                    0,
                                    10
                                )
                            )}
                        </ul>
                    </>
                )}
                {hasMyShpItemPricingErrors && (
                    <>
                        <SidebarIssuesTitle>
                            Pricing for Fabrics
                        </SidebarIssuesTitle>
                        <ul>
                            {this.renderValidationErrorElement(
                                this.state.myShopItemPricingErrors!.slice(0, 10)
                            )}
                        </ul>
                    </>
                )}
                {hasMyShopClientBillingErrors && (
                    <>
                        <SidebarIssuesTitle>
                            Client Billing Type
                        </SidebarIssuesTitle>
                        <ul>
                            {this.renderValidationErrorElement(
                                this.state.myShopClientBillingErrors!.slice(
                                    0,
                                    10
                                )
                            )}
                        </ul>
                    </>
                )}
                {this.state.isWorkOrderValidation && !hasIssues && (
                    <>
                        <SidebarNoIssues>
                            <NoIssuesIcon />
                            <span>All issues are resolved!</span>
                        </SidebarNoIssues>
                    </>
                )}
            </SidebarIssuesContainer>
        );
    }

    public renderValidationConfirmationElement(
        confirmations: ValidationConfirmationInfo[]
    ) {
        const { mainFabric } = this.state;
        return confirmations.map((confirmation, key) => (
            <>
                <ValidationConfirmationContainer>
                    <ValidationConfirmationCheckbox
                        id={confirmation.value}
                        name={confirmation.value}
                        checked={
                            mainFabric.directionOfUse === 1
                                ? this.state.checkedAllowExtraWidthOne
                                : this.state.checkedAllowExtraWidthTwo
                        }
                        onChange={this.handleInputChangeForBoolean}
                    />
                    <ValidationConfirmationElement
                        key={`${confirmation.value}-val-confirm-element`}
                        onClick={() =>
                            this.navigateValidation(
                                confirmation.tabIndex,
                                confirmation.name
                            )
                        }
                        className="mb-2"
                    >
                        {confirmation.message}
                    </ValidationConfirmationElement>
                </ValidationConfirmationContainer>
            </>
        ));
    }

    public renderValidationErrorElement(errors: ValidationErrorInfo[]) {
        return errors.map((error, index) => (
            <ValidationErrorElement
                key={`${error.tabIndex}-${error.name}-${index}`}
                onClick={() =>
                    this.navigateValidation(
                        error.tabIndex,
                        error.name,
                        error.itemId,
                        error.itemClassId
                    )
                }
                className="mb-2"
            >
                {error.message}
            </ValidationErrorElement>
        ));
    }

    public renderSetupTab(): JSX.Element {
        const selectedRoom = this.state.roomList.find(
            (room) => room.key === this.state.draperyOrder.roomId
        );
        const roomValue = selectedRoom ? selectedRoom.label : undefined;
        const disabled = !this.state.draperyOrder.editable;
        this.state.roomList.sort((a, b) => a.label.localeCompare(b.label));

        return (
            <TabPanelBox>
                <SetupTab
                    roomList={this.state.roomList}
                    workOrderList={this.state.workOrderList}
                    draperyOrder={this.state.draperyOrder}
                    roomValue={roomValue}
                    disabled={disabled}
                    handleOpenClientModalClick={this.handleOpenClientModalClick}
                    handleSearchSelectChange={this.handleSearchSelectChange}
                    handleSearchClient={this.handleSearchClient}
                    handleSearchRoom={this.handleSearchRoom}
                    handleSearchSelectClear={this.handleSearchSelectClear}
                    handleSelectListChange={this.handleSelectListChange}
                    handleAddRoom={this.handleAddRoom}
                    handleInputChangeForString={this.handleInputChangeForString}
                    handleInputChangeForBoolean={
                        this.handleInputChangeForBoolean
                    }
                    handleInputChangeForNumber={this.handleInputChangeForNumber}
                    openModalClick={this.addNewRoom}
                    updateStateByName={this.updateStateByName}
                    highlightedSection={this.state.highlightedSection}
                    workOrderEditMode={this.state.workOrderEditMode}
                    isCopy={this.state.isCopy}
                />
            </TabPanelBox>
        );
    }

    public renderDesignTab(): JSX.Element {
        const { dispatch } = this.props;
        return (
            /*removed "onScroll={this.onTabAreaScoll}" property and onTabAreaScroll function, 
              its slowing down ui, due to re-renders*/
            <TabPanelBox>
                <DesignTab
                    ref={this.designTabRef}
                    draperyOrderDesign={this.state.draperyOrderDesign}
                    liningsList={this.state.liningsList}
                    isNoneLinings={this.state.isNoneLinings}
                    embellishmentsList={this.state.embellishmentsList}
                    isNoneEmbellishment={this.state.isNoneEmbellishment}
                    isCustomizeConstructionOpen={
                        this.state.isCustomizeConstructionOpen
                    }
                    disabled={!this.state.draperyOrder.editable}
                    openImageModal={this.handleOpenImageModal}
                    resetPanelConf={this.handleResetPanelConf}
                    resetPleatType={this.handleResetPleatType}
                    addNewLining={this.handleAddNewLining}
                    addEmbellishment={this.handleAddEmbellishment}
                    deleteEmbellishment={this.handleDeleteEmbellishment}
                    toggleCustomizeConstruction={
                        this.handleToggleCustomizeConstruction
                    }
                    handleInputChangeForNumber={this.handleInputChangeForNumber}
                    handleInputChangeForString={this.handleInputChangeForString}
                    handleInputChangeForNumberPrice={
                        this.handleInputChangeForNumberPrice
                    }
                    handleInputChangeForBoolean={
                        this.handleInputChangeForBoolean
                    }
                    handleSelectListChange={this.handleSelectListChange}
                    updateStateByName={this.updateStateByName}
                    onShapeChanged={this.handleShapeChanged}
                    getEmbellishmentsByIndex={this.getEmbellishmentsByIndex}
                    highlightedSection={this.state.highlightedSection}
                    handleBuckramSelect={this.handleBuckramSelect}
                    handleFullnessSelect={this.handleFullnessSelect}
                    data={this.state}
                    workroomSetting={this.state.workroomSetting}
                    handleInputBlur={this.handleInputBlur}
                    draperyItemDetail={this.state.draperyItemDetail}
                    dispatch={dispatch}
                    handleChangeRipplefoldOptions={
                        this.handleChangeRipplefoldOptions
                    }
                    handleRemoveRipplefoldDetail={
                        this.handleRemoveRipplefoldDetail
                    }
                    handleRemoveDraperyDesignCategory={
                        this.handleRemoveDraperyDesignCategory
                    }
                    workOrderEditMode={this.state.workOrderEditMode}
                    openConstructionDetailsEditModal={this.openConstructionDetailsEditModal}
                    resetDraperyOrderEmbellishments={this.resetDraperyOrderEmbellishments}
                    resetLinings={this.resetLinings}
                />
            </TabPanelBox>
        );
    }

    public renderFabricsTab(): JSX.Element {
        const disabled = !this.state.draperyOrder.editable;
        const [pathName, pathIndex] = this.state.pathNameForModal.split(".");
        let index = pathIndex !== undefined ? parseInt(pathIndex) : undefined;

        const selectedFabricsItem =
            this.state.fabricsPatternItems &&
            this.state.fabricsPatternItems.length > 0
                ? this.state.fabricsPatternItems.find((item) => {
                      if (pathName === "mainFabric") {
                          return item.name === this.state.mainFabric.pattern;
                          // return item.id === this.state.mainFabric.patternId;
                      }
                      if (pathName === "bandings" && index !== undefined) {
                          const { draperyOrderFabric } =
                              this.state.bandings[index];
                          return draperyOrderFabric
                              ? item.name === draperyOrderFabric.pattern
                              : false;
                          // return draperyOrderFabric ? item.id === draperyOrderFabric.patternId : false;
                      }

                      if (pathName === "cordings" && index !== undefined) {
                          const { draperyOrderFabric } =
                              this.state.cordings[index];
                          return draperyOrderFabric
                              ? item.name === draperyOrderFabric.pattern
                              : false;
                          // return draperyOrderFabric ? item.id === draperyOrderFabric.patternId : false;
                      }

                      if (pathName === "trims" && index !== undefined) {
                          const { draperyOrderFabric } =
                              this.state.trims[index];
                          return draperyOrderFabric
                              ? item.name === draperyOrderFabric.pattern
                              : false;
                          // return draperyOrderFabric ? item.id === draperyOrderFabric.patternId : false;
                      }

                      if (pathName === "flanges" && index !== undefined) {
                          const { draperyOrderFabric } =
                              this.state.flanges[index];
                          return draperyOrderFabric
                              ? item.name === draperyOrderFabric.pattern
                              : false;
                          // return draperyOrderFabric ? item.id === draperyOrderFabric.patternId : false;
                      }

                      if (
                          pathName === "othersEmbellishments" &&
                          index !== undefined
                      ) {
                          const { draperyOrderFabric } =
                              this.state.othersEmbellishments[index];
                          return draperyOrderFabric
                              ? item.name === draperyOrderFabric.pattern
                              : false;
                          // return draperyOrderFabric ? item.id === draperyOrderFabric.patternId : false;
                      }
                      return false;
                  })
                : null;

        const selectedLiningsItem =
            this.state.liningsPatternItems &&
            this.state.liningsPatternItems.length > 0
                ? this.state.liningsPatternItems.find((item) => {
                      if (pathName === "liningsList" && index !== undefined) {
                          const { draperyOrderLiningsFabric } =
                              this.state.liningsList[index];
                          return draperyOrderLiningsFabric
                              ? item.name === draperyOrderLiningsFabric.pattern
                              : false;
                      }
                      return false;
                  })
                : null;

        return (
            <TabPanelBox>
                <FabricsTab
                    accountId={this.props.accountId}
                    bandings={this.state.bandings}
                    cordings={this.state.cordings}
                    flanges={this.state.flanges}
                    trims={this.state.trims}
                    draperyOrder={this.state.draperyOrder}
                    draperyOrderDesign={this.state.draperyOrderDesign}
                    disabledLeftover={disabled}
                    othersEmbellishments={this.state.othersEmbellishments}
                    mainFabric={this.state.mainFabric}
                    embellishmentsList={this.state.embellishmentsList}
                    liningsList={this.state.liningsList}
                    disabled={!this.state.draperyOrder.editable}
                    onSupplierSearch={this.handleSearchSupplier}
                    openSupplierModalClick={this.handleOpenSupplierModalClick}
                    onFabricsPatternSearch={this.handleSearchFabricsPattern}
                    onFabricsLiningPatternSearch={
                        this.handleSearchFabricsLiningPattern
                    }
                    openFabricsPatternModalClick={
                        this.handleOpenPatternModalClick
                    }
                    openEditFabricsPatternModalClick={
                        this.handleOpenEditPatternModal
                    }
                    openImageModal={this.handleOpenImageModal}
                    onAddSupplierByName={this.handleAddSupplierByName}
                    handleInputChangeForNumber={this.handleInputChangeForNumber}
                    handleInputChangeForString={this.handleInputChangeForString}
                    handleInputChangeForBoolean={
                        this.handleInputChangeForBoolean
                    }
                    handleSelectListChange={this.handleSelectListChange}
                    handleSearchSelectChange={this.handleSearchSelectChange}
                    handleSearchSelectClear={this.handleSearchSelectClear}
                    highlightedSection={this.state.highlightedSection}
                    fabricsPatternItems={this.state.fabricsPatternItems}
                    liningsPatternItems={this.state.liningsPatternItems}
                    workroomSetting={this.state.workroomSetting}
                    orderOriginStateEmb={this.state.orderOriginStateEmb}
                    changeTrimValue={this.changeTrimValue}
                    updateStateByName={this.updateStateByName}
                    clearHighlightSection={(e: any) => {
                        if (
                            this.state.draperyOrderFabricsConfirmations &&
                            this.state.draperyOrderFabricsConfirmations.length >
                                0
                        ) {
                            this.clearHighlightSection(e.target.name);
                        }
                    }}
                    workOrderEditMode={this.state.workOrderEditMode}
                />
                {this.state.isAddFabricsPatternModalOpen && (
                    <>
                        <StandardItemModal
                            isModalOpen={true}
                            onModalClose={this.handleCloseFabricsPatternModal}
                            categories={[]}
                            openModalClick={this.openSupplierModalaOnWo}
                            suppliers={this.state.suppliers}
                            itemId={this.state.selectedItemId}
                            activeItemClassId={this.state.activeItemClassId}
                            reloadGrid={this.handlePatternSelectAfterAdd}
                            pathIdForModal={this.state.pathIdForModal}
                            pathNameForModal={this.state.pathNameForModal}
                            fabricPatternModalCategoryId={
                                this.state.fabricPatternModalCategoryId
                            }
                            supplierName={this.state.supplierName}
                            supplierId={this.state.supplierId}
                            resetSupplier={this.resetSupplier}
                            fromCalculator={true}
                        />
                        <SupplierModal
                            accountId={this.props.accountId}
                            isModalOpen={this.state.isModalOpenWo}
                            onModalClose={this.closeSupplierModal}
                            onSave={this.handleSupplierChanged}
                            resetOnClose
                            setFocusFirstField={(fn) =>
                                (this.setFocusFirstFieldOnSupplierModal = fn)
                            }
                        />
                    </>
                )}
                {this.state.isEditFabricsPatternModalOpen &&
                    (selectedFabricsItem || selectedLiningsItem) && (
                        <>
                            <StandardItemModal
                                isModalOpen={true}
                                onModalClose={
                                    this.handleCloseEditFabricsPatternModal
                                }
                                categories={this.state.categories}
                                openModalClick={this.openSupplierModalaOnWo}
                                suppliers={this.state.suppliers}
                                itemId={
                                    selectedFabricsItem
                                        ? selectedFabricsItem.id || null
                                        : selectedLiningsItem
                                        ? selectedLiningsItem.id || null
                                        : null
                                }
                                activeItemClassId={this.state.activeItemClassId}
                                reloadGrid={this.handlePatternSelectAfterAdd}
                                pathIdForModal={this.state.pathIdForModal}
                                pathNameForModal={this.state.pathNameForModal}
                                fabricPatternModalCategoryId={
                                    this.state.fabricPatternModalCategoryId
                                }
                                supplierName={this.state.supplierName}
                                supplierId={this.state.supplierId}
                                resetSupplier={this.resetSupplier}
                                updateFabricsTab={
                                    selectedFabricsItem
                                        ? this.updateFabrics
                                        : selectedLiningsItem
                                        ? this.updateLinings
                                        : undefined
                                }
                                fromCalculator={true}
                            />
                            <SupplierModal
                                accountId={this.props.accountId}
                                isModalOpen={this.state.isModalOpenWo}
                                onModalClose={this.closeSupplierModal}
                                onSave={this.handleSupplierChanged}
                                resetOnClose
                                setFocusFirstField={(fn) =>
                                    (this.setFocusFirstFieldOnSupplierModal =
                                        fn)
                                }
                            />
                        </>
                    )}
                     {this.state.isEditConstructionDetailsModalOpen && (
                    <DraperyItemModal
                        reloadOnSave={this.reloadOnSave}
                        isModalOpen={true}
                        onModalClose={this.closeEditContructionsModal}
                        categories={this.state.categories}
                        suppliers={this.state.suppliers}
                        itemId={this.state.selectedItemId}
                        reloadGrid={this.reloadGrid}
                        isPleatModalOpen={false}
                        tabIndex={2}
                    />
                )}
            </TabPanelBox>
        );
    }

    @Bind()
    private closeEditContructionsModal(): void {
        this.setState(prev => {
            return {
                ...prev,
                isEditConstructionDetailsModalOpen: false
            }
        })
        this.props.handleCloseModal()
    }

    @Bind()
    private reloadOnSave(): void {
        this.setState(prev => {
            return {
                ...prev,
                reloadOnSaveConstructions: true,
            }
        })
    }

    @Bind()
    private async reloadGrid(): Promise<void> {
        getAllItems({ isActiveOnly: true }).then(res => {

        }).catch(err => {
            console.error("Error fetching items:", err)
        })
    }

    @Bind()
    private openConstructionDetailsEditModal(): void {
        this.setState(prev => {
            return {
                ...prev,
                isEditConstructionDetailsModalOpen: true
            }
        })
    }

    public renderSummaryTab(): JSX.Element {
        const {
            activeModal,
            selectedItem,
            categories,
            suppliers,
            selectedItemId,
            activeItemClassId,
            pricingTabIndex,
            highlightedSection,
            fabricsPatternItems,
            liningsPatternItems,
            // mainFabric,
        } = this.state;
        let index;
        if (
            selectedItem &&
            selectedItem.categoryId === 110 &&
            liningsPatternItems &&
            liningsPatternItems.length > 0
        ) {
            index = liningsPatternItems.findIndex(
                (item) => item.id === selectedItem.id
            );
        } else if (
            selectedItem &&
            selectedItem.categoryId === 101 &&
            fabricsPatternItems &&
            fabricsPatternItems.length > 0
        ) {
            index = fabricsPatternItems.findIndex(
                (item) => item.id === selectedItem.id
            );
        }

        return (
            <TabPanelBox>
                <SummaryTab
                    dispatch={this.props.dispatch}
                    accountId={this.props.accountId}
                    draperyOrderId={this.state.draperyOrder.id}
                    draperyOrderSummary={this.state.draperyOrderSummary}
                    bandings={this.state.bandings}
                    cordings={this.state.cordings}
                    flanges={this.state.flanges}
                    trims={this.state.trims}
                    othersEmbellishments={this.state.othersEmbellishments}
                    mainFabric={this.state.mainFabric}
                    embellishmentsList={this.state.embellishmentsList}
                    draperyOrderSummaryComponents={
                        this.state.draperyOrderSummaryComponents
                    }
                    draperyOrderEditable={this.state.draperyOrder.editable}
                    workOrder={this.state.workOrder}
                    workOrderStatus={this.state.workOrderPdfStatus}
                    handleDuplicate={this.handleDuplicateDraperyOrder}
                    handleCreateWorkOrder={this.handleCreateWorkOrder}
                    handleEditWorkOrder={this.handleEditWorkOrder}
                    handleDownloadWorkOrderPdf={this.handleDownloadWorkOrderPdf}
                    handleAddSummaryComponent={this.handleAddSummaryComponent}
                    handleDeleteSummaryComponent={
                        this.handleDeleteSummaryComponent
                    }
                    handleInputChangeForNumber={this.handleInputChangeForNumber}
                    handleInputChangeForString={this.handleInputChangeForString}
                    handleInputChangeForBoolean={
                        this.handleInputChangeForBoolean
                    }
                    handleSelectListChange={this.handleSelectListChange}
                    highlightedSection={this.state.highlightedSection}
                    data={this.state}
                    returnDates={this.returnDates}
                    handleChangeDates={this.handleChangeDates}
                    draperyItemDetail={this.state.draperyItemDetail}
                    workOrderPdfContentSummery={
                        this.state.workOrderPdfContentSummery
                    }
                    draperyOrderPriceId={this.state.draperyOrderPriceId}
                    draperyOrder={this.state.draperyOrder}
                    updateStateByName={this.updateStateByName}
                    editNavigate={this.editNavigate}
                    updateWorkOrder={this.updateWorkOrder}
                    workOrderEditMode={this.state.workOrderEditMode}
                    loadingUpdate={this.state.loadingUpdate}
                />
                {activeModal === ModalName.StandardItem && selectedItem && (
                    <StandardItemModal
                        validateAll={this.validateAll}
                        highlightedSection={highlightedSection}
                        itemName={selectedItem.name}
                        isModalOpen={true}
                        onModalClose={this.closeModal}
                        categories={categories}
                        suppliers={suppliers}
                        clearHighlightSection={this.clearHighlightSection}
                        highLightSection={this.highlightSection}
                        client={this.state.client}
                        // selectedItem={selectedItem}
                        itemId={selectedItemId}
                        activeItemClassId={activeItemClassId}
                        tabIndex={pricingTabIndex}
                        mainFabric={this.state.mainFabric}
                        updateStateByName={this.updateStateByName}
                        indexOfMainFabric={index}
                        categoryId={selectedItem.categoryId}
                    />
                )}
                {activeModal === ModalName.DraperyItem && selectedItem && (
                    <DraperyItemModal
                        reloadGrid={this.handlePatternSelectAfterAdd}
                        validateAll={this.validateAll}
                        isModalOpen={true}
                        onModalClose={this.closeModal}
                        categories={categories}
                        suppliers={suppliers}
                        itemId={selectedItem.id}
                        updateStateByName={this.updateStateByName}
                    />
                )}
            </TabPanelBox>
        );
    }

    @Bind()
    private closeModal() {
        this.setState({
            activeModal: null,
            activeItemClassId: null,
            selectedItemId: null,
        });
    }
    @Bind()
    private async onClientEdited(client: ClientModule.Client) {
        this.setState({
            client,
        });

        await this.loadClients();
        this.validateAll("update");
    }
    private async loadClients(): Promise<void> {
        const { accountId, dispatch } = this.props;
        const { includeInactive } = this.state;
        const res = await dispatch(
            ClientModule.getClients(accountId, this.state.sort, includeInactive)
        );
        if (!res.error) {
            const convertToSortTableRowInfo = (
                client: ClientModule.BasicClientInfo
            ) => {
                const rowInfo: SortTableRowInfo = client;
                if (client.subClients) {
                    rowInfo.subList = client.subClients.map(
                        convertToSortTableRowInfo
                    );
                }
                return rowInfo;
            };

            this.setState({
                clients: res.payload.map(convertToSortTableRowInfo),
            });
        }
    }
    @Bind()
    private closeEditClientModal() {
        this.setState({
            isEditClientModalOpen: false,
        });
    }

    @Bind()
    private async getCategories(): Promise<Category[]> {
        const catRes = await getAllCategories({ isActiveOnly: true });
        this.setState({ categories: catRes });
        return catRes;
    }

    private async getSuppliers(): Promise<Supplier[] | undefined> {
        const res: any = await getAllSuppliers();
        this.setState({ suppliers: res });

        if (!res.error) {
            return res;
        }
    }

    @Bind()
    private async fetchAll() {
        await this.getCategories();
        await this.getSuppliers();
    }

    @Bind()
    private validateClientBillingTypeTab(): boolean {
        let myShopClientBillingErrors: ValidationErrorInfo[] = [];
        let billingType: string | undefined;

        if (this.state.client && this.state.client.billToParent === true) {
            if (this.state.client.billingDetails) {
                billingType = this.state.client.billingDetails.billingType;
            }
        } else {
            if (this.state.client && this.state.client.billingDetailsDirectly) {
                billingType =
                    this.state.client.billingDetailsDirectly.billingType;
            }
        }

        myShopClientBillingErrors = validateClientBillingType(3, billingType);

        if (myShopClientBillingErrors.length > 0) {
            this.setState((state) => ({
                ...state,
                myShopClientBillingErrors,
            }));
            return false;
        } else {
            this.setState((state) => ({
                ...state,
                myShopClientBillingErrors,
            }));
            return true;
        }
    }

    @Bind()
    private validateMyShopItemPricingTab(): boolean {
        let myShopItemPricingErrors: ValidationErrorInfo[] = [];
        const { fabricsPatternItems, mainFabric, liningsPatternItems } =
            this.state;
        const liningsList = this.state.liningsList;
        const trimsList = this.state.trims;
        const flangeList = this.state.flanges;
        const bandingList = this.state.bandings;
        const cordingList = this.state.cordings;
        const otherList = this.state.othersEmbellishments;
        let selectedPatternLinings: FabricPatternItem[] = [];
        let selectedPatternFabrics: FabricPatternItem[] = [];
        let selectedPatternTrims: FabricPatternItem[] = [];
        let selectedPatternFlanges: FabricPatternItem[] = [];
        let selectedPatternBandings: FabricPatternItem[] = [];
        let selectedPatternCordings: FabricPatternItem[] = [];
        let selectedPatternOthersEmbellishments: FabricPatternItem[] = [];

        if (fabricsPatternItems && fabricsPatternItems.length > 0) {
            selectedPatternFabrics = fabricsPatternItems.filter(
                (item) => item.id === mainFabric.patternId
            );
        }
        if (liningsPatternItems && liningsPatternItems.length > 0) {
            liningsList.map((list, index) => {
                const selectedLiningPattern = liningsPatternItems.find(
                    (item) => {
                        if (
                            list.draperyOrderLiningsFabric &&
                            item.id === list.draperyOrderLiningsFabric.patternId
                        ) {
                            return true;
                        }
                        return false;
                    }
                );
                if (selectedLiningPattern) {
                    selectedPatternLinings.push(selectedLiningPattern);
                }
            });
        }
        if (fabricsPatternItems && fabricsPatternItems.length > 0) {
            trimsList.map((list) => {
                const selectedTrimPattern = fabricsPatternItems.find((item) => {
                    if (
                        list.draperyOrderFabric &&
                        item.id === list.draperyOrderFabric.patternId
                    ) {
                        return true;
                    }
                    return false;
                });
                if (selectedTrimPattern) {
                    selectedPatternTrims.push(selectedTrimPattern);
                }
            });

            flangeList.map((list) => {
                const selectedFlangePattern = fabricsPatternItems.find(
                    (item) => {
                        if (
                            list.draperyOrderFabric &&
                            item.id === list.draperyOrderFabric.patternId
                        ) {
                            return true;
                        }
                        return false;
                    }
                );
                if (selectedFlangePattern) {
                    selectedPatternFlanges.push(selectedFlangePattern);
                }
            });

            bandingList.map((list) => {
                const selectedBandingPattern = fabricsPatternItems.find(
                    (item) => {
                        if (
                            list.draperyOrderFabric &&
                            item.id === list.draperyOrderFabric.patternId
                        ) {
                            return true;
                        }
                        return false;
                    }
                );
                if (selectedBandingPattern) {
                    selectedPatternBandings.push(selectedBandingPattern);
                }
            });

            cordingList.map((list) => {
                const selectedCordingPattern = fabricsPatternItems.find(
                    (item) => {
                        if (
                            list.draperyOrderFabric &&
                            item.id === list.draperyOrderFabric.patternId
                        ) {
                            return true;
                        }
                        return false;
                    }
                );
                if (selectedCordingPattern) {
                    selectedPatternCordings.push(selectedCordingPattern);
                }
            });

            otherList.map((list) => {
                const selectedOtherPattern = fabricsPatternItems.find(
                    (item) => {
                        if (
                            list.draperyOrderFabric &&
                            item.id === list.draperyOrderFabric.patternId
                        ) {
                            return true;
                        }
                        return false;
                    }
                );
                if (selectedOtherPattern) {
                    selectedPatternOthersEmbellishments.push(
                        selectedOtherPattern
                    );
                }
            });
        }

        for (let i = 0; i < selectedPatternLinings.length; i++) {
            if (selectedPatternLinings[i] && this.state.client) {
                const liningsListItem = liningsList.find((list) => {
                    if (
                        list.draperyOrderLiningsFabric &&
                        list.draperyOrderLiningsFabric.patternId ===
                            selectedPatternLinings[i].id
                    ) {
                        return true;
                    }
                    return false;
                });
                if (
                    liningsListItem &&
                    liningsListItem.draperyOrderLiningsFabric &&
                    liningsListItem.draperyOrderLiningsFabric.orderOrigin === 1
                ) {
                    myShopItemPricingErrors = myShopItemPricingErrors.concat(
                        validateMyShopItemPricing(
                            1,
                            selectedPatternLinings[i],
                            this.state.client
                        )
                    );
                }
            }
        }

        for (let i = 0; i < selectedPatternFabrics.length; i++) {
            if (
                selectedPatternFabrics[i] &&
                this.state.client &&
                mainFabric.orderOrigin === 1
            ) {
                myShopItemPricingErrors = myShopItemPricingErrors.concat(
                    validateMyShopItemPricing(
                        1,
                        selectedPatternFabrics[i],
                        this.state.client
                    )
                );
            }
        }

        for (let i = 0; i < selectedPatternTrims.length; i++) {
            if (selectedPatternTrims[i] && this.state.client) {
                const trimListItem = trimsList.find((list) => {
                    if (
                        list.draperyOrderFabric &&
                        list.draperyOrderFabric.patternId ===
                            selectedPatternTrims[i].id
                    ) {
                        return true;
                    }
                    return false;
                });
                if (
                    trimListItem &&
                    trimListItem.draperyOrderFabric &&
                    trimListItem.draperyOrderFabric.orderOrigin === 1
                ) {
                    myShopItemPricingErrors = myShopItemPricingErrors.concat(
                        validateMyShopItemPricing(
                            1,
                            selectedPatternTrims[i],
                            this.state.client
                        )
                    );
                }
            }
        }

        for (let i = 0; i < selectedPatternFlanges.length; i++) {
            if (selectedPatternFlanges[i] && this.state.client) {
                const flangeListItem = flangeList.find((list) => {
                    if (
                        list.draperyOrderFabric &&
                        list.draperyOrderFabric.patternId ===
                            selectedPatternFlanges[i].id
                    ) {
                        return true;
                    }
                    return false;
                });
                if (
                    flangeListItem &&
                    flangeListItem.draperyOrderFabric &&
                    flangeListItem.draperyOrderFabric.orderOrigin === 1
                ) {
                    myShopItemPricingErrors = myShopItemPricingErrors.concat(
                        validateMyShopItemPricing(
                            1,
                            selectedPatternFlanges[i],
                            this.state.client
                        )
                    );
                }
            }
        }

        for (let i = 0; i < selectedPatternBandings.length; i++) {
            if (selectedPatternBandings[i] && this.state.client) {
                const bandingListItem = bandingList.find((list) => {
                    if (
                        list.draperyOrderFabric &&
                        list.draperyOrderFabric.patternId ===
                            selectedPatternBandings[i].id
                    ) {
                        return true;
                    }
                    return false;
                });
                if (
                    bandingListItem &&
                    bandingListItem.draperyOrderFabric &&
                    bandingListItem.draperyOrderFabric.orderOrigin === 1
                ) {
                    myShopItemPricingErrors = myShopItemPricingErrors.concat(
                        validateMyShopItemPricing(
                            1,
                            selectedPatternBandings[i],
                            this.state.client
                        )
                    );
                }
            }
        }
        for (let i = 0; i < selectedPatternCordings.length; i++) {
            if (selectedPatternCordings[i] && this.state.client) {
                const cordingListItem = cordingList.find((list) => {
                    if (
                        list.draperyOrderFabric &&
                        list.draperyOrderFabric.patternId ===
                            selectedPatternCordings[i].id
                    ) {
                        return true;
                    }
                    return false;
                });
                if (
                    cordingListItem &&
                    cordingListItem.draperyOrderFabric &&
                    cordingListItem.draperyOrderFabric.orderOrigin === 1
                ) {
                    myShopItemPricingErrors = myShopItemPricingErrors.concat(
                        validateMyShopItemPricing(
                            1,
                            selectedPatternCordings[i],
                            this.state.client
                        )
                    );
                }
            }
        }

        for (let i = 0; i < selectedPatternOthersEmbellishments.length; i++) {
            if (selectedPatternOthersEmbellishments[i] && this.state.client) {
                const otherListItem = otherList.find((list) => {
                    if (
                        list.draperyOrderFabric &&
                        list.draperyOrderFabric.patternId ===
                            selectedPatternOthersEmbellishments[i].id
                    ) {
                        return true;
                    }
                    return false;
                });
                if (
                    otherListItem &&
                    otherListItem.draperyOrderFabric &&
                    otherListItem.draperyOrderFabric.orderOrigin === 1
                ) {
                    myShopItemPricingErrors = myShopItemPricingErrors.concat(
                        validateMyShopItemPricing(
                            1,
                            selectedPatternOthersEmbellishments[i],
                            this.state.client
                        )
                    );
                }
            }
        }
        this.setState((state) => ({
            ...state,
            myShopItemPricingErrors,
        }));
        return !myShopItemPricingErrors.length;
    }
    private handleSaveAll = async (confirmed?: boolean): Promise<boolean> => {
        if (!this.validateAll("save")) {
            return false;
        }
        this.setState((state) => ({ ...state, isLoading: true }));

        if (!(await this.saveAll(undefined, confirmed))) {
            this.setState((state) => ({ ...state, isLoading: false }));
            return false;
        }
        this.setState((state) => ({ ...state, isLoading: false }));
        return true;
    };

    private addNewRoom = () => {
        this.setState({ isAddRoomModalOpen: true });
    };

    private handleSaveAndNext = async (): Promise<boolean> => {
        if (!(await this.handleSaveAll())) {
            return false;
        }
        const currentTabIndex = this.state.tabIndex;
        let obj: Record<string, number | boolean> = {
            tabIndex: 1,
        };

        let callbackFunc = undefined;

        switch (currentTabIndex) {
            case 1: {
                obj = {
                    tabIndex: 2,
                };
                callbackFunc = () => {
                    document.documentElement.scrollIntoView({
                        behavior: "smooth",
                    });
                };
                break;
            }
            case 2: {
                obj = {
                    tabIndex: 3,
                };
                callbackFunc = () => {
                    document.body.scrollTop = 0; // For Safari
                    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                };
                break;
            }
            case 3: {
                obj = {
                    navigationConfirmed: true,
                };
                callbackFunc = () => {
                    this.props.history.push(
                        "/clients/" + this.state.draperyOrder.clientId
                    );
                };
                break;
            }
            case 0:
            default: {
                break;
            }
        }
        this.setState(
            (state) => ({
                ...state,
                ...obj,
            }),
            callbackFunc
        );
        return true;
    };

    @Bind()
    private handleNextTab(): void {
        if (this.state.tabIndex !== 3) {
            this.setState((prev) => {
                return {
                    ...prev,
                    tabIndex: prev.tabIndex + 1,
                };
            });
        } else {
        }
    }

    @Bind()
    private editNavigate(tab: number): void {
        this.setState({
            tabIndex: tab,
            workOrderEditMode: true,
        });
    }

    private updateWorkOrder = async (val: string): Promise<void> => {
        const newDraperyOrderFabricsKeys = ['bandings', 'cordings', 'flanges', 'trims', 'mainFabric', 'liningsList', 'othersEmbellishments']
        this.setState({
            loadingUpdate: true,
        });
        // if (!(await this.saveAll(val))) {
        //     this.setState({
        //         loadingUpdate: false,
        //     });
        //     return;
        // }
        this.setState({ isWorkOrderValidation: true }, async () => {
            if (!this.validateAll("workOrder")) {
                this.toggleErrorMessage();
                this.setState({
                    loadingUpdate: false,
                });
                return;
            }
            this.addDraperyOrderNewFabrics(newDraperyOrderFabricsKeys)
            this.setState((state) => ({
                ...state,
                isRecommendationsOverridesModalOpen: true,
            }));
        });
    };

    @Bind()
    private async saveAll(val?: string, confirmed?: boolean): Promise<boolean> {
        const { tabIndex } = this.state;
        if (tabIndex >= 0 && !(await this.saveDraperyOrderDesignTab())) {
            return false;
        }
        if (tabIndex >= 0 && !(await this.saveDraperyOrderApi())) {
            return false;
        }
        if (
            tabIndex >= 2 &&
            !confirmed &&
            !(await this.saveDraperyOrderFabricsTab())
        ) {
            return false;
        } else if (confirmed && !(await this.saveDraperyOrderFabricsTab())) {
            return false;
        }
        if (tabIndex >= 2 && !(await this.saveSummaryTab())) {
            return false;
        }
        if (tabIndex >= 2 && !(await this.saveDraperyOrderApi())) {
            return false;
        }
        const { dispatch } = this.props;
        if (!val) {
            const notification: NotificationsModule.Notification = {
                id: 0,
                message: "Success!",
                subMessage: "Your progress has been saved",
                type: "info",
            };
            dispatch(NotificationsModule.addNotification(notification));
        }

        this.setChanges(false);
        return true;
    }

    @Bind()
    private async saveDraperyOrderDesignTab(): Promise<boolean> {
        if (!(await this.saveDraperyOrderDrawing())) {
            return false;
        }
        if (!(await this.saveDraperyOrderDesignApi())) {
            return false;
        }
        if (!(await this.saveLiningsList())) {
            return false;
        }
        if (!(await this.saveEmbellishmentList())) {
            return false;
        }
        if (!(await this.deleteEmbellishmentList())) {
            return false;
        }
        return true;
    }

    @Bind()
    private async saveDraperyOrderFabricsTab(): Promise<boolean> {
        const {
            mainFabric,
            embellishmentsList,
            liningsList,
            isNoneEmbellishment,
        } = this.state;
        if (
            !(await this.saveDraperyOrderFabricApi({
                name: `mainFabric`,
                fabric: mainFabric,
                draperyOrderDesignEmbellishmentId: undefined,
                draperyOrderDesignLiningId: undefined,
            }))
        ) {
            return false;
        }
        const arr = [
            ...this.state.trims,
            ...this.state.bandings,
            ...this.state.flanges,
            ...this.state.cordings,
            ...this.state.othersEmbellishments,
        ];
        if (isNoneEmbellishment) {
            arr.forEach((item) => {
                if (item.id > 0) {
                    this.deleteDraperyOrderFabricApi(item!.id);
                    this.setState({
                        bandings: [defaultEmbellishment("bandings")!],
                        cordings: [defaultEmbellishment("cordings")!],
                        flanges: [defaultEmbellishment("flanges")!],
                        trims: [defaultEmbellishment("trims")!],
                        othersEmbellishments: [
                            defaultEmbellishment("othersEmbellishments")!,
                        ],
                    });
                }
            });
        }
        if (
            liningsList[0].active === false &&
            liningsList[1].active === false
        ) {
            this.setState({
                liningsList: [
                    {
                        active: false,
                        description: "",
                        draperyOrderDesignId:
                            liningsList[0].draperyOrderDesignId,
                        id: liningsList[0].id,
                        draperyOrderLiningsFabric: undefined,
                        name: "Lining",
                    },
                    {
                        active: false,
                        description: "",
                        draperyOrderDesignId:
                            liningsList[1].draperyOrderDesignId,
                        id: liningsList[1].id,
                        draperyOrderLiningsFabric: undefined,
                        name: "Interlining",
                    },
                ],
            });
        }

        for (const [index, embellishmentInfo] of embellishmentsList.entries()) {
            if (embellishmentInfo.checked) {
                const { name, list } = this.getEmbellishmentsByIndex(index);
                for (const [subIndex, embellishment] of list.entries()) {
                    if (
                        embellishment.draperyOrderFabric &&
                        (subIndex >= 0
                            ? true
                            : embellishment.subType === 0 ||
                              embellishment.subType === 1)
                    ) {
                        if (
                            !(await this.saveDraperyOrderFabricApi({
                                name: `${name}.${subIndex}.draperyOrderFabric`,
                                fabric: embellishment.draperyOrderFabric,
                                draperyOrderDesignEmbellishmentId:
                                    embellishment.id,
                                draperyOrderDesignLiningId: undefined,
                            }))
                        ) {
                            return false;
                        }
                    }
                }
            }
        }

        for (const [index, liningInfo] of liningsList.entries()) {
            const { active, draperyOrderLiningsFabric } = liningInfo;
            if (active) {
                if (draperyOrderLiningsFabric) {
                    if (
                        !(await this.saveDraperyOrderFabricApi({
                            name: `liningsList.${index}.draperyOrderLiningsFabric`,
                            fabric: draperyOrderLiningsFabric,
                            draperyOrderDesignEmbellishmentId: undefined,
                            draperyOrderDesignLiningId: liningInfo.id,
                        }))
                    ) {
                        return false;
                    }
                }
            }
        }
        return true;
    }

    @Bind()
    private async saveSummaryTab(): Promise<boolean> {
        const {
            draperyOrderSummaryComponents,
            mainFabric,
            embellishmentsList,
        } = this.state;

        if (!(await this.saveDraperyOrderSummaryApi())) {
            return false;
        }
        if (
            !(await this.saveDraperyOrderSummaryComponentApi(
                `mainFabric.draperyOrderSummaryComponent`,
                mainFabric.draperyOrderSummaryComponent,
                mainFabric.id
            ))
        ) {
            return false;
        }

        for (const [index, embellishmentInfo] of embellishmentsList.entries()) {
            if (embellishmentInfo.checked) {
                const { name, list } = this.getEmbellishmentsByIndex(index);
                for (const [subIndex, embellishment] of list.entries()) {
                    const fabric = embellishment.draperyOrderFabric;
                    if (
                        fabric &&
                        (subIndex === 0 || subIndex === 4
                            ? true
                            : embellishment.subType === 0 ||
                              embellishment.subType === 1)
                    ) {
                        if (
                            !(await this.saveDraperyOrderSummaryComponentApi(
                                `${name}.${subIndex}.draperyOrderFabric.draperyOrderSummaryComponent`,
                                fabric.draperyOrderSummaryComponent,
                                fabric.id
                            ))
                        ) {
                            return false;
                        }
                    }
                }
            }
        }
        for (const [
            index,
            component,
        ] of draperyOrderSummaryComponents.entries()) {
            if (
                !(await this.saveDraperyOrderSummaryComponentApi(
                    `draperyOrderSummaryComponents.${index}`,
                    component
                ))
            ) {
                return false;
            }
        }
        return true;
    }

    @Bind()
    private async saveDraperyOrderDrawing(): Promise<boolean> {
        const drawingBlob = await this.drawingBlob();
        if (drawingBlob) {
            if (
                !(await this.saveDrawingImageApi(
                    new File([drawingBlob], "name")
                ))
            ) {
                return false;
            }
        } else {
            return false;
        }
        return true;
    }

    @Bind()
    private async saveLiningsList(): Promise<boolean> {
        const { liningsList } = this.state;
        for (const [index, lining] of liningsList.entries()) {
            if (!(await this.saveLiningApi(index, lining))) {
                return false;
            }
        }
        return true;
    }

    @Bind()
    private async saveEmbellishmentList(): Promise<boolean> {
        const { embellishmentsList } = this.state;
        for (const [index, embellishmentInfo] of embellishmentsList.entries()) {
            if (embellishmentInfo.checked) {
                const { list, name } = this.getEmbellishmentsByIndex(index);
                for (const [subIndex, embellishment] of list.entries()) {
                    if (
                        !(await this.saveEmbellishmentListApi(
                            name,
                            subIndex,
                            embellishment
                        ))
                    ) {
                        return false;
                    }
                }
            }
        }
        return true;
    }

    @Bind()
    private async deleteEmbellishmentList(): Promise<boolean> {
        // remove embellishemts
        for (const id of this.state.removedEmbellishmentIds) {
            if (id && !(await this.deleteEmbellishmentApi(id))) {
                return false;
            }
        }
        const arr = [
            ...this.state.trims,
            ...this.state.bandings,
            ...this.state.flanges,
            ...this.state.cordings,
            ...this.state.othersEmbellishments,
        ];
        if (this.state.isNoneEmbellishment) {
            arr.forEach((item) => {
                if (item.id > 0) {
                    this.deleteEmbellishmentApi(item!.id);
                    this.setState({
                        bandings: [defaultEmbellishment("bandings")!],
                        cordings: [defaultEmbellishment("cordings")!],
                        flanges: [defaultEmbellishment("flanges")!],
                        trims: [defaultEmbellishment("trims")!],
                        othersEmbellishments: [
                            defaultEmbellishment("othersEmbellishments")!,
                        ],
                    });
                }
            });
        }
        this.setState((state) => ({
            ...state,
            removedEmbellishmentIds: [],
        }));
        return true;
    }

    @Bind()
    private processResponseErrors(
        res: RealtimeActionResponse<string, any, any>,
        tabIndex: number,
        errorName: string,
        section?: string
    ): void {
        if (!res.error) {
            return;
        }
        if (res.payload.name === "ValidationError") {
            const errors = (res.payload as Error as ValidationError).errors;

            this.setState((state) => ({
                ...state,
                [errorName]: processErrors(tabIndex, errors, section),
            }));
        } else {
            this.setState((state) => ({
                ...state,
                [errorName]: [
                    { tabIndex, name: "", message: res.payload.message },
                ],
            }));
        }
    }

    private async saveDrawingImageApi(file: File): Promise<boolean> {
        const { dispatch, accountId } = this.props;
        const res = await dispatch(AccountModule.uploadImage(accountId, file));
        this.processResponseErrors(res, -1, "draperyOrderErrors");
        if (res.error) {
            return false;
        }
        this.updateStateByName(
            `draperyOrderDesign.drawing`,
            res.payload.url,
            true
        );
        return true;
    }

    @Bind()
    private async saveDraperyOrderApi(): Promise<boolean> {
        const { dispatch, accountId } = this.props;
        const { draperyOrder, draperyOrderPriceId } = this.state;
        // Update drapery order
        if (draperyOrder.id) {
            const res = await dispatch(
                DraperyModule.editDraperyOrder({
                    ...draperyOrder,
                    roomId: draperyOrder.roomId || undefined,
                    clientId: draperyOrder.clientId || undefined,
                    draperyOrderPriceId: draperyOrderPriceId || undefined,
                    isDraft: this.state.draperyOrder.isDraft,
                })
            );
            this.processResponseErrors(res, -1, "draperyOrderErrors");
            if (res.error) {
                return false;
            }
            return true;
        }
        // Add new drapery order
        const res = await dispatch(
            DraperyModule.addDraperyOrder({
                ...draperyOrder,
                accountId,
            })
        );
        this.processResponseErrors(res, -1, "draperyOrderErrors");
        if (res.error) {
            return false;
        }
        this.updateStateByName(`draperyOrder.id`, res.payload.id, true);
        return true;
    }

    @Bind()
    private async saveDraperyOrderDesignApi(): Promise<boolean> {
        const { dispatch, accountId } = this.props;
        const { draperyOrder, draperyOrderDesign } = this.state;
        // Update ddrapery order design

        if (draperyOrder.draperyOrderDesignId) {
            const res = await dispatch(
                DraperyModule.editDraperyOrderDesign({
                    ...draperyOrderDesign,
                    isNoneLinings: this.state.isNoneLinings,
                    isNoneEmbellishment: this.state.isNoneEmbellishment,
                })
            );
            this.processResponseErrors(res, 0, "draperyOrderDesignErrors");
            if (res.error) {
                return false;
            }
            return true;
        }
        // Add drapery order design
        const res = await dispatch(
            DraperyModule.addDraperyOrderDesign({
                ...draperyOrderDesign,
                accountId,
                isNoneLinings: this.state.isNoneLinings,
                isNoneEmbellishment: this.state.isNoneEmbellishment,
            })
        );
        this.processResponseErrors(res, 0, "draperyOrderDesignErrors");
        if (res.error) {
            return false;
        }
        this.updateStateByName(
            `draperyOrder.draperyOrderDesignId`,
            res.payload.id,
            true
        );
        this.updateStateByName(`draperyOrderDesign.id`, res.payload.id, true);
        return true;
    }

    @Bind()
    private async saveLiningApi(
        index: number,
        lining: DraperyOrderDesignLining
    ): Promise<boolean> {
        const { dispatch, accountId } = this.props;
        const { draperyOrderDesign } = this.state;
        this.updateStateByName(
            `liningsList.${index}.draperyOrderDesignId`,
            draperyOrderDesign.id,
            true
        );
        this.updateStateByName(
            `liningsList.${index}.description`,
            lining.description || "",
            true
        );
        if (!lining.active && lining.draperyOrderLiningsFabric && lining.draperyOrderLiningsFabric.draperyOrderId) {
            const id = lining.draperyOrderLiningsFabric.id

            const res = await dispatch(
                DraperyModule.deleteDraperyOrderFabric(id)
            )
            const res1 = await dispatch(
                DraperyModule.editDraperyOrderDesignLining(lining)
            )

            this.processResponseErrors(res, 0, "draperyOrderDesignErrors");

            if (res1.error) {
                return false
            }
            if (res.error) {
                return false
            }
            return true
        }
        // Update lining
        if (lining.id) {
            const res = await dispatch(
                DraperyModule.editDraperyOrderDesignLining(lining)
            );
            this.processResponseErrors(res, 0, "draperyOrderDesignErrors");
            if (res.error) {
                return false;
            }
            return true;
        }
        // Add lining
        const res = await dispatch(
            DraperyModule.addDraperyOrderDesignLining({
                ...lining,
                accountId,
                draperyOrderDesignId: draperyOrderDesign.id,
            })
        );
        this.processResponseErrors(res, 0, "draperyOrderDesignErrors");
        if (res.error) {
            return false;
        }
        this.updateStateByName(`liningsList.${index}.id`, res.payload.id, true);
        return true;
    }

    @Bind()
    private async saveEmbellishmentListApi(
        name: string,
        index: number,
        embellishment: DraperyOrderDesignEmbellishment
    ): Promise<boolean> {
        const { dispatch, accountId } = this.props;
        const { draperyOrderDesign } = this.state;
        // Set draperyOrderDesign id
        this.updateStateByName(
            `${name}.${index}.draperyOrderDesignId`,
            draperyOrderDesign.id,
            true
        );
        if (embellishment.appliedType === null) {
            this.updateStateByName(
                `${name}.${index}.appliedType`,
                undefined,
                true
            );
        }
        if (embellishment.id) {
            // Update embellishment
            const res = await dispatch(
                DraperyModule.editDraperyOrderDesignEmbellishment(embellishment)
            );
            this.processResponseErrors(res, 0, "draperyOrderDesignErrors");
            if (res.error) {
                return false;
            }
        } else {
            // Add embellishment
            const res = await dispatch(
                DraperyModule.addDraperyOrderDesingEmbellishment({
                    ...embellishment,
                    accountId,
                })
            );
            this.processResponseErrors(res, 0, "draperyOrderDesignErrors");
            if (res.error) {
                return false;
            }
            this.updateStateByName(`${name}.${index}.id`, res.payload.id, true);
        }
        return true;
    }

    @Bind()
    private async deleteEmbellishmentApi(id: number): Promise<boolean> {
        const { dispatch } = this.props;
        const res = await dispatch(
            DraperyModule.deleteDraperyOrderDesignEmbellishment(id)
        );
        this.processResponseErrors(res, 0, "draperyOrderDesignErrors");
        if (res.error) {
            return false;
        }
        return true;
    }
    @Bind()
    private async deleteDraperyOrderFabricApi(id: number): Promise<boolean> {
        const { dispatch } = this.props;
        const res = await dispatch(
            DraperyModule.deleteDraperyOrderDesignEmbellishment(id)
        );
        this.processResponseErrors(res, 0, "draperyOrderDesignErrors");
        if (res.error) {
            return false;
        }
        return true;
    }

    @Bind()
    private async saveDraperyOrderFabricApi({
        name,
        fabric,
        draperyOrderDesignEmbellishmentId,
        draperyOrderDesignLiningId,
    }: {
        name: string;
        fabric: DraperyModule.DraperyOrderFabric & { supplierName?: string } & {
            customSupplier?: string;
        };
        draperyOrderDesignEmbellishmentId?: number;
        draperyOrderDesignLiningId?: number;
    }): Promise<boolean> {
        const { dispatch, accountId } = this.props;
        const { draperyOrder } = this.state;
        const draperyOrderId = draperyOrder.id;
        this.updateStateByName(`${name}.draperyOrderId`, draperyOrderId, true);
        this.updateStateByName(
            `${name}.draperyOrderDesignEmbellishmentId`,
            draperyOrderDesignEmbellishmentId,
            true
        );
        this.updateStateByName(
            `${name}.draperyOrderDesignLiningId`,
            draperyOrderDesignLiningId,
            true
        );
        if (!fabric.supplierId) {
            fabric.supplierId = undefined;
            fabric.supplierName = undefined;
        }

        // Update fabric
        if (fabric.id) {
            const res = await dispatch(
                DraperyModule.editDraperyOrderFabric({
                    ...fabric,
                    supplierName: fabric.supplierId
                        ? undefined
                        : fabric.supplierName,
                    imageId: fabric.imageId,
                    accountId,
                })
            );
            this.processResponseErrors(
                res,
                2,
                "draperyOrderFabricsErrors",
                name
            );
            if (res.error) {
                return false;
            }
            return true;
        }
        // Add fabric
        const res = await dispatch(
            DraperyModule.addDraperyOrderFabric({
                ...fabric,
                supplierName: fabric.supplierId
                    ? undefined
                    : fabric.supplierName,
                customSupplier: fabric.customSupplier,
                accountId,
                draperyOrderId,
                draperyOrderDesignEmbellishmentId,
                patternId: fabric.itemId,
            })
        );
        this.processResponseErrors(res, 2, "draperyOrderFabricsErrors", name);
        if (res.error) {
            return false;
        }
        this.updateStateByName(`${name}.id`, res.payload.id, true);
        return true;
    }

    @Bind()
    private async saveDraperyOrderSummaryApi(): Promise<boolean> {
        const { dispatch, accountId } = this.props;
        const { draperyOrderSummary } = this.state;
        // Update drapery order summary
        if (draperyOrderSummary.id) {
            const res = await dispatch(
                DraperyModule.editDraperyOrderSummary(draperyOrderSummary)
            );
            this.processResponseErrors(res, 2, "draperyOrderSummaryErrors");
            if (res.error) {
                return false;
            }
            return true;
        }
        // Add drapery order summary
        const res = await dispatch(
            DraperyModule.addDraperyOrderSummary({
                ...draperyOrderSummary,
                accountId,
            })
        );
        this.processResponseErrors(res, 2, "draperyOrderSummaryErrors");
        if (res.error) {
            return false;
        }
        this.updateStateByName(
            `draperyOrder.draperyOrderSummaryId`,
            res.payload.id,
            true
        );
        this.updateStateByName(`draperyOrderSummary.id`, res.payload.id, true);
        return true;
    }

    @Bind()
    private async saveDraperyOrderSummaryComponentApi(
        name: string,
        component: DraperyModule.DraperyOrderSummaryComponent,
        draperyOrderFabricId?: number
    ): Promise<boolean> {
        const { dispatch, accountId } = this.props;
        const { draperyOrderSummary } = this.state;
        this.updateStateByName(
            `${name}.draperyOrderSummaryId`,
            draperyOrderSummary.id,
            true
        );
        this.updateStateByName(
            `${name}.draperyOrderFabricId`,
            draperyOrderFabricId,
            true
        );
        // Update summary component
        if (component.id) {
            const res = await dispatch(
                DraperyModule.editDraperyOrderSummaryComponent(component)
            );
            this.processResponseErrors(res, 2, "draperyOrderSummaryErrors");
            if (res.error) {
                return false;
            }
            return true;
        }
        // Add summary component
        if (component.id === 0) {
            const res = await dispatch(
                DraperyModule.addDraperyOrderSummaryComponent({
                    ...component,
                    accountId,
                })
            );
            this.processResponseErrors(res, 2, "draperyOrderSummaryErrors");
            if (res.error) {
                return false;
            }
            this.updateStateByName(`${name}.id`, res.payload.id, true);
        }
        return true;
    }

    @Bind()
    private async handleCreateWorkOrder(val: string): Promise<void> {
        // this.handleSaveAll();
        if (!(await this.saveAll(val))) {
            return;
        }
        this.setState({ isWorkOrderValidation: true }, async () => {
            if (!this.validateAll("workOrder")) {
                this.toggleErrorMessage();
                return;
            }
            // if (!(await this.saveAll())) {
            //     return;
            // }
            this.setState((state) => ({
                ...state,
                isRecommendationsOverridesModalOpen: true,
            }));
        });
    }

    @Bind()
    private validateAll(mode: "save" | "workOrder" | "update"): boolean {
        this.clearErrors();
        let noError = true;
        if (this.state.validateClient && !this.validateClient()) {
            if (mode === "save") {
                this.setState((state) => ({
                    ...state,
                    isSidebarHidden: false,
                }));
            }

            noError = false;
        }
        if (noError && this.state.isWorkOrderValidation) {
            if (mode === "workOrder") {
                if (!this.validateDraperyOrder()) {
                    noError = false;
                }
                if (!this.validateDraperyOrderDesignTab()) {
                    noError = false;
                }
                if (!this.validateDraperyOrderFabricsTab()) {
                    noError = false;
                }
                if (!this.validateClientBillingTypeTab()) {
                    noError = false;
                }
                if (!this.validateDraperyOrderFabricsConfirmation()) {
                    noError = false;
                }
                if (!this.validateSummaryTab()) {
                    noError = false;
                }
                if (
                    !this.state.myShopClientBillingErrors ||
                    (this.state.myShopClientBillingErrors &&
                        !this.state.myShopClientBillingErrors.length)
                ) {
                    if (!this.validateMyShopItemPricingTab()) {
                        noError = false;
                    }
                }
            } else {
                this.validateDraperyOrder();
                this.validateDraperyOrderDesignTab();
                this.validateDraperyOrderFabricsTab();
                this.validateDraperyOrderFabricsConfirmation();
                this.validateSummaryTab();
                this.validateClientBillingTypeTab();
                if (
                    !this.state.myShopClientBillingErrors ||
                    (this.state.myShopClientBillingErrors &&
                        !this.state.myShopClientBillingErrors.length)
                ) {
                    this.validateMyShopItemPricingTab();
                }
            }
        }
        return noError;
    }

    @Bind()
    private validateClient(): boolean {
        const { draperyOrder } = this.state;

        const draperyOrderErrors: ValidationErrorInfo[] =
            validateClient(draperyOrder);
        if (draperyOrderErrors.length > 0) {
            this.setState((state) => ({
                ...state,
                draperyOrderErrors,
            }));
            return false;
        }
        return true;
    }

    @Bind()
    private validateDraperyOrder(): boolean {
        const { draperyOrder } = this.state;

        const draperyOrderErrors: ValidationErrorInfo[] =
            validateDraperyOrder(draperyOrder);
        this.setState((state) => ({
            ...state,
            draperyOrderErrors,
        }));
        return !draperyOrderErrors.length;
    }

    @Bind()
    private validateDraperyOrderDesignTab(): boolean {
        const {
            draperyOrderDesign,
            draperyItemDetail,
            liningsList,
            isNoneLinings,
            embellishmentsList,
            isNoneEmbellishment,
            mainFabric,
        } = this.state;

        let draperyOrderDesignErrors: ValidationErrorInfo[] =
            validateDraperyOrderDesign(
                draperyOrderDesign,
                draperyItemDetail,
                embellishmentsList,
                liningsList,
                mainFabric,
                this.clearHighlightSection
            );
        draperyOrderDesignErrors = draperyOrderDesignErrors.concat(
            validateLiningsList(liningsList, isNoneLinings)
        );
        draperyOrderDesignErrors = draperyOrderDesignErrors.concat(
            validateEmbellishmentChecked(
                embellishmentsList,
                isNoneEmbellishment
            )
        );
        for (const [index, embellishmentInfo] of embellishmentsList.entries()) {
            const { name, list } = this.getEmbellishmentsByIndex(index);
            if (embellishmentInfo.checked) {
                draperyOrderDesignErrors = draperyOrderDesignErrors.concat(
                    validateEmbellishmentList(embellishmentInfo, list, name)
                );
            }
        }
        this.setState((state) => ({
            ...state,
            draperyOrderDesignErrors,
        }));
        return !draperyOrderDesignErrors.length;
    }

    @Bind()
    private validateDraperyOrderFabricsTab(): boolean {
        const {
            draperyOrderDesign,
            mainFabric,
            embellishmentsList,
            liningsList,
        } = this.state;

        let draperyOrderFabricsErrors: ValidationErrorInfo[] =
            validateDraperyOrderMainFabric(
                mainFabric,
                draperyOrderDesign,
                this.state.fabricsPatternItems,
                this.state.workroomSetting
            );
        for (const [index, embellishmentInfo] of embellishmentsList.entries()) {
            if (embellishmentInfo.checked) {
                const { name, list } = this.getEmbellishmentsByIndex(index);
                for (const [subIndex, embellishment] of list.entries()) {
                    if (
                        embellishment.draperyOrderFabric &&
                        (subIndex === 0 || subIndex === 4 || subIndex === 1
                            ? true
                            : embellishment.subType === 0 ||
                              embellishment.subType === 1)
                    ) {
                        draperyOrderFabricsErrors =
                            draperyOrderFabricsErrors.concat(
                                validateDraperyOrderFabric(
                                    name,
                                    subIndex,
                                    embellishment,
                                    embellishment.draperyOrderFabric,
                                    this.state.fabricsPatternItems,
                                    this.state.workroomSetting,
                                    list
                                )
                            );
                    }
                }
            }
        }

        for (const [index, liningInfo] of liningsList.entries()) {
            const { active, draperyOrderLiningsFabric, name } = liningInfo;
            if (active) {
                draperyOrderFabricsErrors = draperyOrderFabricsErrors.concat(
                    validateDraperyOrderLiningsFabric(
                        name,
                        index,
                        draperyOrderLiningsFabric,
                        this.state.liningsPatternItems,
                        this.state.workroomSetting
                    )
                );
            }
        }

        this.setState((state) => ({
            ...state,
            draperyOrderFabricsErrors,
        }));
        return !draperyOrderFabricsErrors.length;
    }

    @Bind()
    private validateDraperyOrderFabricsConfirmation(): boolean {
        const { draperyOrder, mainFabric, draperyOrderDesign } = this.state;

        const draperyOrderFabricsValidationConfirmations: ValidationConfirmationInfo[] =
            validateDraperyOrderMainFabricConfirmations(
                draperyOrder,
                mainFabric,
                draperyOrderDesign
            );
        this.setState((state) => ({
            ...state,
            draperyOrderFabricsConfirmations:
                draperyOrderFabricsValidationConfirmations,
        }));

        return draperyOrderFabricsValidationConfirmations.length > 0
            ? mainFabric.directionOfUse === 1
                ? this.state.checkedAllowExtraWidthOne
                : this.state.checkedAllowExtraWidthTwo
            : true;
    }

    @Bind()
    private validateSummaryTab(): boolean {
        const {
            draperyOrderSummary,
            draperyOrderSummaryComponents,
            mainFabric,
            embellishmentsList,
        } = this.state;

        let draperyOrderSummaryErrors =
            validateDraperyOrderSummary(draperyOrderSummary);
        draperyOrderSummaryErrors = draperyOrderSummaryErrors.concat(
            validateDraperyOrderSummaryComponent(
                mainFabric.draperyOrderSummaryComponent,
                `mainFabric.draperyOrderSummaryComponent.`,
                true
            )
        );
        for (const [index, embellishmentInfo] of embellishmentsList.entries()) {
            if (embellishmentInfo.checked) {
                const { name, list } = this.getEmbellishmentsByIndex(index);
                for (const [subIndex, embellishment] of list.entries()) {
                    const embellishSubType = embellishment.subType;
                    const subIndexTruthy = subIndex === 0 || subIndex === 4;
                    const subTypeTruthy =
                        embellishSubType === 0 || embellishSubType === 1;

                    if (
                        embellishment.draperyOrderFabric &&
                        (subIndexTruthy || subTypeTruthy)
                    ) {
                        draperyOrderSummaryErrors =
                            draperyOrderSummaryErrors.concat(
                                validateDraperyOrderSummaryComponent(
                                    embellishment.draperyOrderFabric
                                        .draperyOrderSummaryComponent,
                                    `${name}.${subIndex}.draperyOrderFabric.draperyOrderSummaryComponent.`,
                                    true
                                )
                            );
                    }
                }
            }
        }
        for (const [
            index,
            component,
        ] of draperyOrderSummaryComponents.entries()) {
            draperyOrderSummaryErrors = draperyOrderSummaryErrors.concat(
                validateDraperyOrderSummaryComponent(
                    component,
                    `draperyOrderSummaryComponents.${index}.`
                )
            );
        }
        draperyOrderSummaryErrors = draperyOrderSummaryErrors.concat(
            validateDraperyItemPricingTab(this.state.draperyItemDetail)
        );
        this.setState((state) => ({
            ...state,
            draperyOrderSummaryErrors,
        }));
        return !draperyOrderSummaryErrors.length;
    }

    @Bind()
    private async getExistingDraperyOrder(reset?: boolean): Promise<void> {
        const { dispatch, accountId } = this.props;
        const res = await dispatch(
            DraperyModule.getDraperyOrderAllInfo(this.state.draperyOrder.id)
        );
        this.processResponseErrors(res, -1, "draperyOrderErrors");
        if (res.error) {
            return;
        }
        const draperyOrderAllInfo: DraperyModule.DraperyOrderAllInfo =
            res.payload;
        let type = -1;
        if (draperyOrderAllInfo.draperyOrderDesign) {
            if (this.state.draperyOrderDesign.headingType === "rodandrings") {
                switch (draperyOrderAllInfo.draperyOrderDesign.draperyType) {
                    case "full-length":
                        type = 1;
                        break;
                    case "cafe-curtain":
                        type = 2;
                        break;
                }
            }

            if (this.state.draperyOrderDesign.headingType === "ripplefold") {
                type = 3;
            }
        }
        const resWork = await dispatch(
            WorkroomSettingModule.searchWorkroomSetting(accountId, type)
        );

        let draperyOrderLiningsList: DraperyOrderDesignLining[] =
            draperyLiningsList();
        // let isLiningSelected: boolean = false;
        let isEmbellishmentSelected: boolean = false;
        if (draperyOrderAllInfo.draperyOrderDesignId !== undefined) {
            const res = await dispatch(
                DraperyModule.searchDraperyOrderDesignLinings(
                    draperyOrderAllInfo.draperyOrderDesignId
                )
            );
            this.processResponseErrors(res, 0, "draperyOrderDesignErrors");
            if (res.error) {
                return;
            }
            draperyOrderLiningsList = res.payload;
            draperyOrderLiningsList = mergeDraperyOrderLiningFabric({
                draperyOrderLiningsList,
                draperyOrderAllFabrics: draperyOrderAllInfo.draperyOrderFabrics,
            });
        }
        // for (const lining of draperyOrderLiningsList) {
        //     isLiningSelected = isLiningSelected || lining.active;
        // }

        let draperyOrderSummaryComponents: DraperyModule.DraperyOrderSummaryComponent[] =
            [];
        if (draperyOrderAllInfo.draperyOrderSummaryId) {
            const res = await dispatch(
                DraperyModule.searchDraperyOrderSummaryComponents(
                    draperyOrderAllInfo.draperyOrderSummaryId
                )
            );
            this.processResponseErrors(res, 2, "draperyOrderSummaryErrors");
            if (res.error) {
                return;
            }
            draperyOrderSummaryComponents = res.payload;
        }

        const embellishmentsList: EmbellishmentCheckInfo[] =
            defaultEmbellishmentsList();
        const bandings: DraperyOrderDesignEmbellishment[] = [];
        const cordings: DraperyOrderDesignEmbellishment[] = [];
        const flanges: DraperyOrderDesignEmbellishment[] = [];
        const trims: DraperyOrderDesignEmbellishment[] = [];
        const othersEmbellishments: DraperyOrderDesignEmbellishment[] = [];
        if (draperyOrderAllInfo.draperyOrderDesignId !== undefined) {
            const res = await dispatch(
                DraperyModule.searchDraperyOrderDesignEmbellishments(
                    draperyOrderAllInfo.draperyOrderDesignId
                )
            );
            this.processResponseErrors(res, 0, "draperyOrderDesignErrors");
            if (res.error) {
                return;
            }
            const draperyOrderEmbellishmentList: DraperyOrderDesignEmbellishment[] =
                res.payload;

            for (const embellishment of draperyOrderEmbellishmentList) {
                if (embellishment.type === 1) {
                    addEmbellishment(
                        bandings,
                        embellishment,
                        embellishmentsList[0],
                        draperyOrderAllInfo.draperyOrderFabrics,
                        draperyOrderSummaryComponents
                    );
                }
                if (embellishment.type === 2) {
                    addEmbellishment(
                        cordings,
                        embellishment,
                        embellishmentsList[1],
                        draperyOrderAllInfo.draperyOrderFabrics,
                        draperyOrderSummaryComponents
                    );
                }
                if (embellishment.type === 3) {
                    addEmbellishment(
                        flanges,
                        embellishment,
                        embellishmentsList[2],
                        draperyOrderAllInfo.draperyOrderFabrics,
                        draperyOrderSummaryComponents
                    );
                }
                if (embellishment.type === 4) {
                    addEmbellishment(
                        trims,
                        embellishment,
                        embellishmentsList[3],
                        draperyOrderAllInfo.draperyOrderFabrics,
                        draperyOrderSummaryComponents
                    );
                }
                if (embellishment.type === 5) {
                    addEmbellishment(
                        othersEmbellishments,
                        embellishment,
                        embellishmentsList[4],
                        draperyOrderAllInfo.draperyOrderFabrics,
                        draperyOrderSummaryComponents
                    );
                }
            }

            if (!embellishmentsList[0].checked) {
                bandings.push(defaultBanding());
            }
            if (!embellishmentsList[1].checked) {
                cordings.push(defaultCording());
            }
            if (!embellishmentsList[2].checked) {
                flanges.push(defaultFlange());
            }
            if (!embellishmentsList[3].checked) {
                trims.push(defaultTrim());
            }
            if (!embellishmentsList[4].checked) {
                othersEmbellishments.push(defaultOtherEmbellishment());
            }
        }
        for (const embellishmentInfo of embellishmentsList) {
            isEmbellishmentSelected =
                isEmbellishmentSelected || embellishmentInfo.checked;
        }

        const mainFabric = draperyOrderAllInfo.draperyOrderFabrics.find(
            (fab) => fab.name === "MAIN FABRIC"
        );
        const component = mainFabric
            ? draperyOrderSummaryComponents.find(
                  (component) =>
                      component.draperyOrderFabricId === mainFabric.id
              )
            : undefined;

        if (
            draperyOrderAllInfo.draperyOrderSummary &&
            draperyOrderAllInfo.draperyOrderSummary.notes === null
        )
            draperyOrderAllInfo.draperyOrderSummary.notes = "";

        this.setState((state) => ({
            ...state,
            draperyOrder: {
                id: draperyOrderAllInfo.id,
                clientId: draperyOrderAllInfo.clientId,
                clientName: reset ? state.copyState.draperyOrder && state.copyState.draperyOrder.clientName : state.client ? state.client.displayName : "",
                editable:
                    draperyOrderAllInfo.editable &&
                    !draperyOrderAllInfo.archived,
                draperyOrderDesignId: draperyOrderAllInfo.draperyOrderDesignId,
                draperyOrderSummaryId:
                    draperyOrderAllInfo.draperyOrderSummaryId,
                roomId: draperyOrderAllInfo.roomId,
                customRoom: draperyOrderAllInfo.customRoom,
                workOrderId: draperyOrderAllInfo.workOrderId,
                relatedWorkOrderIds: draperyOrderAllInfo.relatedWorkOrders.map(
                    (workOrder) => workOrder.id
                ),
                window: draperyOrderAllInfo.window,
                related: draperyOrderAllInfo.related,
                quantity: draperyOrderAllInfo.quantity,
                hasRelatedWorkOrders: draperyOrderAllInfo.hasRelatedWorkOrders,
                isReturnLeftoverMaterials:
                    draperyOrderAllInfo.isReturnLeftoverMaterials,
                status: draperyOrderAllInfo.status,
                userConfirmation: draperyOrderAllInfo.userConfirmation,
                archived: draperyOrderAllInfo.archived,
                roomName: draperyOrderAllInfo.room
                    ? draperyOrderAllInfo.room.name
                    : "",
                isDraft: draperyOrderAllInfo.isDraft,
            },
            draperyOrderDesign:
                draperyOrderAllInfo.draperyOrderDesign ||
                defaultDraperyOrderDesign(),
            liningsList: draperyOrderLiningsList,
            isNoneLinings:
                draperyOrderAllInfo.draperyOrderDesign &&
                draperyOrderAllInfo.draperyOrderDesign.isNoneLinings,
            bandings,
            cordings,
            flanges,
            trims,
            othersEmbellishments,
            embellishmentsList,
            isNoneEmbellishment:
                draperyOrderAllInfo.draperyOrderDesign &&
                draperyOrderAllInfo.draperyOrderDesign.isNoneEmbellishment,
            mainFabric: mainFabric
                ? filterMainFabric(
                      mainFabric,
                      component ||
                          defaultDraperyOrderSummaryComponent(mainFabric.name)
                  )
                : defaultMainFabric(),
            draperyOrderSummary:
                draperyOrderAllInfo.draperyOrderSummary ||
                defaultDraperyOrderSummary(),
            draperyOrderSummaryComponents: draperyOrderSummaryComponents.filter(
                (component) => !component.draperyOrderFabricId
            ),
            workOrder:
                draperyOrderAllInfo.workOrder || defaultWorkOrder(accountId),
            draperyOrderPriceId: draperyOrderAllInfo.draperyOrderPriceId,
            workroomSetting: resWork.payload[0],
            copyState: {
                ...state.copyState,
                draperyOrder: {
                    customRoom: draperyOrderAllInfo.customRoom,
                    id: draperyOrderAllInfo.id,
                    clientId: draperyOrderAllInfo.clientId,
                    clientName: reset ? state.copyState.draperyOrder && state.copyState.draperyOrder.clientName : state.client ? state.client.displayName : "",
                    editable: draperyOrderAllInfo.editable && !draperyOrderAllInfo.archived,
                    draperyOrderDesignId: draperyOrderAllInfo.draperyOrderDesignId,
                    draperyOrderSummaryId:
                        draperyOrderAllInfo.draperyOrderSummaryId,
                    roomId: draperyOrderAllInfo.roomId,
                    workOrderId: draperyOrderAllInfo.workOrderId,
                    relatedWorkOrderIds: draperyOrderAllInfo.relatedWorkOrders.map(
                        (workOrder) => workOrder.id
                    ),
                    window: draperyOrderAllInfo.window,
                    related: draperyOrderAllInfo.related,
                    quantity: draperyOrderAllInfo.quantity,
                    hasRelatedWorkOrders: draperyOrderAllInfo.hasRelatedWorkOrders,
                    isReturnLeftoverMaterials:
                        draperyOrderAllInfo.isReturnLeftoverMaterials,
                    status: draperyOrderAllInfo.status,
                    userConfirmation: draperyOrderAllInfo.userConfirmation,
                    archived: draperyOrderAllInfo.archived,
                    roomName: draperyOrderAllInfo.room
                        ? draperyOrderAllInfo.room.name
                        : "",
                    isDraft: draperyOrderAllInfo.isDraft,
                },
                draperyOrderDesign:
                    draperyOrderAllInfo.draperyOrderDesign ||
                    defaultDraperyOrderDesign(),
                liningsList: draperyOrderLiningsList,
                isNoneLinings:
                    draperyOrderAllInfo.draperyOrderDesign &&
                    draperyOrderAllInfo.draperyOrderDesign.isNoneLinings,
                bandings,
                cordings,
                flanges,
                trims,
                othersEmbellishments,
                embellishmentsList,
                isNoneEmbellishment:
                    draperyOrderAllInfo.draperyOrderDesign &&
                    draperyOrderAllInfo.draperyOrderDesign.isNoneEmbellishment,
                mainFabric: mainFabric
                    ? filterMainFabric(
                        mainFabric,
                        component ||
                        defaultDraperyOrderSummaryComponent(mainFabric.name)
                    )
                    : defaultMainFabric(),
                draperyOrderSummary:
                    draperyOrderAllInfo.draperyOrderSummary ||
                    defaultDraperyOrderSummary(),
                draperyOrderSummaryComponents: draperyOrderSummaryComponents.filter(
                    (component) => !component.draperyOrderFabricId
                ),
                workOrder:
                draperyOrderAllInfo.workOrder || defaultWorkOrder(accountId),
            draperyOrderPriceId: draperyOrderAllInfo.draperyOrderPriceId,
            workroomSetting: resWork.payload[0],
        }
    }));    
    }

    @Bind()
    private async getRooms(clientId: number): Promise<void> {
        if (!clientId) {
            return;
        }

        const { dispatch } = this.props;
        const res = await dispatch(RoomModule.getRooms(clientId));
        if (res.error) {
            return;
        }
        this.setState((state) => ({
            ...state,
            roomList: res.payload.map((room) => ({
                key: room.id,
                label: room.name,
            })),
        }));
    }

    @Bind()
    private async getWorkOrderNumbers(): Promise<void> {
        const { dispatch, accountId } = this.props;

        const res = await dispatch(WorkOrderModule.searchWorkOrders(accountId));
        if (res.error) {
            return;
        }
        this.setState((state) => ({
            ...state,
            workOrderList: res.payload,
        }));
    }

    @Bind()
    private async getClient(): Promise<void> {
        const { dispatch } = this.props;
        if (this.state.draperyOrder.clientId) {
            const res = await dispatch(
                ClientModule.getClient(this.state.draperyOrder.clientId)
            );
            if (res.error) {
                return;
            }
            this.setState(
                (state) => ({
                    ...state,
                    client: res.payload,
                }),
                () => {
                    this.validateAll("update");
                }
            );
        }
    }

    @Bind()
    private async getFabricsItems({
        isReload = false,
        pathNameForModal,
        pathIdForModal,
    }: {
        isReload?: boolean;
        pathNameForModal?: string;
        pathIdForModal?: string;
    }): Promise<void> {
        const prevFabricPatternsItems = this.state.fabricsPatternItems
            ? [...this.state.fabricsPatternItems]
            : [];
        const newFabricsPatternsItems = await getAll({
            categoryId: CategoryIds.FabricsAndTrims,
            searchChildren: true,
            includeDetails: true,
        });
        this.setState({
            fabricsPatternItems: newFabricsPatternsItems,
        });

        //isReload is set to true, after a new fabric pattern item is added
        if (isReload) {
            //below helps select newly added item for the Pattern Name/# dropdown
            //and also sets defaults for that newly added pattern
            let selectedPattern = null;
            if (!prevFabricPatternsItems || !prevFabricPatternsItems.length) {
                selectedPattern = newFabricsPatternsItems[0];
            } else {
                let newFound = newFabricsPatternsItems.find(
                    (newI) =>
                        !prevFabricPatternsItems.some(
                            (prevI) => prevI.id === newI.id
                        )
                );

                if (newFound) {
                    selectedPattern = newFound;
                }
            }
            if (
                pathNameForModal &&
                pathIdForModal &&
                selectedPattern &&
                !pathNameForModal.includes("mainFabric")
            ) {
                this.updateStateByName(pathIdForModal, selectedPattern.id);
                this.updateStateByName(pathNameForModal, selectedPattern.name);
                this.setDraperyOrderFabricPatternDefaults({
                    patternId: selectedPattern.id,
                    pathId: pathNameForModal,
                });
            } else if (selectedPattern && selectedPattern.id) {
                this.updateStateByName(
                    "mainFabric.patternId",
                    selectedPattern.id
                );
                this.updateStateByName(
                    "mainFabric.pattern",
                    selectedPattern.name
                );
                this.setFabricsPatternDefaults({
                    patternId: selectedPattern.id,
                });
            }
        }
    }

    @Bind()
    private async getDraperyItems({
        isReload = false,
        showDraperyItemModal,
    }: {
        isReload?: boolean;
        pathIdForModal?: string;
        pathNameForModal?: string;
        showDraperyItemModal?: boolean;
    }): Promise<void> {
        try {
            const newDraperyItemDetail = await getAll({
                includeDetails: true,
            });

            const draperyItems = newDraperyItemDetail.filter(
                (item) => item.name === "Drapery"
            );
            if (showDraperyItemModal) this.handleShowModal(draperyItems);
            const updatedDraperyItemDetail = [
                ...this.state.draperyItemDetail,
                ...draperyItems,
            ];

            this.setState({
                draperyItemDetail: updatedDraperyItemDetail,
            });
        } catch (error) {
            console.error(error);
        }
    }

    @Bind()
    private handleShowModal(draperyItems: FormFields[]): void {
        const draperyItemDetailsKeys = [
            "pricingTypeId",
            "clientPricingAction",
            "clientPricingValue",
            "partialWidthRoundUpTypeId",
            "pleatTypesWithPricing",
            "liningAndInterliningWithPricing",
            "cafeCurtainsWidthInches",
            "cafeCurtainsWidthPricing",
            "extraLargePanelsLengthInches",
            "extraLargePanelsLengthPricing",
            "extraLargePanelsWidthInches",
            "extraLargePanelsWidthPricing",
            "draperyPinningWidthPricing",
            "specialityFabricsMarkUpPercentage",
            "embellishmentsWithPricing",
            "addOnsWithPricing",
            "salesDescriptionSettings",
            "orderingDescriptionSettings",
        ];
        if (!draperyItems[0]["isClientPricingActionOn"]) {
            const index = draperyItemDetailsKeys.findIndex(
                (key) => key === "clientPricingValue"
            );
            if (index >= 0) draperyItemDetailsKeys.splice(index, 1);
        }
        if (!draperyItems[0]["isPartialWidthRoundingOn"]) {
            const index = draperyItemDetailsKeys.findIndex(
                (key) => key === "partialWidthRoundUpTypeId"
            );
            if (index >= 0) draperyItemDetailsKeys.splice(index, 1);
        }
        for (const key of draperyItemDetailsKeys) {
            if (draperyItems[0] && draperyItems[0][key]) {
                if (Array.isArray(draperyItems[0][key])) {
                    for (let i = 0; i < draperyItems[0][key].length; i++) {
                        if (
                            draperyItems[0][key][i] &&
                            draperyItems[0][key][i].price === ""
                        ) {
                            this.setState({ showDraperyItemModal: true });
                            return;
                        }
                    }
                } else if (draperyItems[0][key] === "") {
                    this.setState({ showDraperyItemModal: true });
                    return;
                }
            } else {
                this.setState({ showDraperyItemModal: true });
                return;
            }
        }
    }

    @Bind()
    private async getLiningsItems({
        isReload = false,
        pathIdForModal,
        pathNameForModal,
    }: {
        isReload?: boolean;
        pathIdForModal?: string;
        pathNameForModal?: string;
    }): Promise<void> {
        const prevLiningPatternItems = this.state.liningsPatternItems
            ? [...this.state.liningsPatternItems]
            : [];

        const newLiningPatternItems = await getAll({
            categoryId: CategoryIds.Linings,
            searchChildren: true,
            includeDetails: true,
        });

        this.setState({
            liningsPatternItems: newLiningPatternItems,
        });

        //isReload is set to true, after a new lining pattern item is added
        if (isReload) {
            //below helps select newly added item for the Pattern Name/# dropdown
            //and also sets defaults for that newly added pattern
            let selectedPattern = null;
            if (!prevLiningPatternItems || !prevLiningPatternItems.length) {
                selectedPattern = newLiningPatternItems[0];
            } else {
                let newFound = newLiningPatternItems.find(
                    (newI) =>
                        !prevLiningPatternItems.some(
                            (prevI) => prevI.id === newI.id
                        )
                );

                if (newFound) {
                    selectedPattern = newFound;
                }
            }
            if (
                selectedPattern &&
                selectedPattern.id &&
                pathIdForModal &&
                pathNameForModal
            ) {
                this.updateStateByName(pathIdForModal, selectedPattern.id);
                this.updateStateByName(pathNameForModal, selectedPattern.name);
                this.setLiningsPatternDefaults({
                    patternId: selectedPattern.id,
                    pathId: pathIdForModal,
                });
            }
        }
    }

    @Bind()
    private async handlePatternSelectAfterAdd() {
        const { pathIdForModal, pathNameForModal } = this.state;
        const isLiningPattern =
            pathIdForModal.includes("liningsList") ||
            pathNameForModal.includes("liningsList");
        const isReload = true;
        //reload the list of items
        if (isLiningPattern) {
            await this.getLiningsItems({
                isReload,
                pathIdForModal,
                pathNameForModal,
            });
        } else {
            await this.getFabricsItems({
                isReload,
                pathIdForModal,
                pathNameForModal,
            });
            await this.getDraperyItems({
                isReload,
                showDraperyItemModal: false,
            });
        }
    }

    @Bind()
    private async getNextWorkOrderNum(): Promise<number> {
        const { dispatch, accountId } = this.props;

        const res = await dispatch(
            WorkOrderModule.getNextWorkOrderNum(accountId)
        );
        if (res.error) {
            return 0;
        } else {
            return res.payload;
        }
    }

    private async saveDraperyOrderPricing(
        workroomSettings: any,
        widthConfiguration: Array<number | undefined>
    ) {
        const { dispatch } = this.props;
        const { workOrderEditMode } = this.state;
        const panelJson = {
            pannel1Width: widthConfiguration[0],
            pannel2Width: widthConfiguration[1],
            pannel3Width: widthConfiguration[2],
            pannel4Width: widthConfiguration[3],
            pannel5Width: widthConfiguration[4],
        };
        const category = this.state.draperyOrderDesign.category;
        const arg =
            category === 0
                ? {
                      ...this.state,
                      draperyOrderDesign: {
                          ...this.state.draperyOrderDesign,
                          panelJson,
                      },
                  }
                : { ...this.state };

        if (workOrderEditMode) {
            const updateResponse = await updateDraperyOrderPrice(
                arg,
                workroomSettings,
                this.state.draperyOrderPriceId
            );

            if (!updateResponse.error) {
                await dispatch(
                    DraperyModule.editDraperyOrder({
                        ...this.state.draperyOrder,
                        workOrderId: this.state.workOrder.id,
                        draperyOrderPriceId: updateResponse.id,
                    })
                );
                this.setState((state) => ({
                    ...state,
                    draperyOrderPriceId: updateResponse.id || 0,
                    loadingUpdate: false,
                    copyState: {
                        ...state.copyState,
                        draperyOrderPriceId: updateResponse.id || 0
                    }
                }));
            }
        } else if (!this.state.draperyOrderPriceId) {
            const response = await addDraperyOrderPrice(arg, workroomSettings);
            if (!response.error) {
                await dispatch(
                    DraperyModule.editDraperyOrder({
                        ...this.state.draperyOrder,
                        workOrderId: this.state.workOrder.id,
                        draperyOrderPriceId: response.id,
                    })
                );
                this.setState((state) => ({
                    ...state,
                    draperyOrderPriceId: response.id || 0,
                    copyState: {
                        ...state.copyState,
                        draperyOrderPriceId: response.id || 0
                    }
                }));
            }
        }
    }
    @Bind()
    private async fetchEmbellishment(embelishment: any): Promise<any> {
        const cordingsList = await Promise.all(
            embelishment.map(async (emb: any) => {
                if (emb.draperyOrderFabric && emb.draperyOrderFabric.itemId) {
                    return getFabricsOrLiningsRecord({
                        recordId: emb.draperyOrderFabric.itemId,
                    });
                }
                if (
                    emb.draperyOrderLiningsFabric &&
                    emb.draperyOrderLiningsFabric.itemId
                ) {
                    return getFabricsOrLiningsRecord({
                        recordId: emb.draperyOrderLiningsFabric.itemId,
                    });
                }
            })
        );
        return cordingsList;
    }
    @Bind()
    private async fetchEmbellishmentMain(embelishment: any): Promise<any> {
        const mainFabric = await Promise.all(
            embelishment.map(async (emb: any) => {
                if (emb && emb.itemId) {
                    return getFabricsOrLiningsRecord({ recordId: emb.itemId });
                }
            })
        );

        return mainFabric;
    }

    @Bind()
    private async handleDuplicateDraperyOrder(): Promise<void> {
        const { accountId, dispatch } = this.props;
        let { draperyOrder, isChanged } = this.state;

        if (!draperyOrder.id) {
            const workOrderNum = await this.getNextWorkOrderNum();
            draperyOrder = {
                ...draperyOrder,
                id: workOrderNum,
            };
            this.setState({ draperyOrder });
        }

        const res = await dispatch(
            DraperyModule.getDraperyOrderAllInfo(draperyOrder.id)
        );
        this.processResponseErrors(res, -1, "draperyOrderErrors");
        if (res.error) {
            return;
        }
        const draperyOrderAllInfo: DraperyModule.DraperyOrderAllInfo =
            res.payload;

        let draperyOrderLiningsList: DraperyOrderDesignLining[] =
            draperyLiningsList();
        // let isLiningSelected: boolean = false;
        // let isEmbellishmentSelected: boolean = false;
        if (draperyOrderAllInfo.draperyOrderDesignId !== undefined) {
            const res = await dispatch(
                DraperyModule.searchDraperyOrderDesignLinings(
                    draperyOrderAllInfo.draperyOrderDesignId
                )
            );

            this.processResponseErrors(res, 0, "draperyOrderDesignErrors");
            if (res.error) {
                return;
            }
            draperyOrderLiningsList = res.payload;
            draperyOrderLiningsList = mergeDraperyOrderLiningFabric({
                draperyOrderLiningsList,
                draperyOrderAllFabrics: draperyOrderAllInfo.draperyOrderFabrics,
            });
        }
        // for (const lining of draperyOrderLiningsList) {
        //     isLiningSelected = isLiningSelected || lining.active;
        // }

        let draperyOrderSummaryComponents: DraperyModule.DraperyOrderSummaryComponent[] =
            [];
        if (draperyOrderAllInfo.draperyOrderSummaryId) {
            const res = await dispatch(
                DraperyModule.searchDraperyOrderSummaryComponents(
                    draperyOrderAllInfo.draperyOrderSummaryId
                )
            );
            this.processResponseErrors(res, 2, "draperyOrderSummaryErrors");
            if (res.error) {
                return;
            }
            draperyOrderSummaryComponents = res.payload;
        }

        let mainFabricObj: any = {};
        let cordingsObj: any = {};
        let bandingsObj: any = {};
        let flangesObj: any = {};
        let trimsObj: any = {};
        let liningsObj: any = {};
        let othersEmbellishmentsObj: any = {};

        mainFabricObj = await this.fetchEmbellishmentMain([
            this.state.mainFabric,
        ]);
        cordingsObj = await this.fetchEmbellishment(this.state.cordings);
        bandingsObj = await this.fetchEmbellishment(this.state.bandings);
        flangesObj = await this.fetchEmbellishment(this.state.flanges);
        trimsObj = await this.fetchEmbellishment(this.state.trims);
        liningsObj = await this.fetchEmbellishment(this.state.liningsList);
        othersEmbellishmentsObj = await this.fetchEmbellishment(
            this.state.othersEmbellishments
        );

        const embellishmentsList: EmbellishmentCheckInfo[] =
            defaultEmbellishmentsList();
        const bandings: DraperyOrderDesignEmbellishment[] = [];
        const cordings: DraperyOrderDesignEmbellishment[] = [];
        const flanges: DraperyOrderDesignEmbellishment[] = [];
        const trims: DraperyOrderDesignEmbellishment[] = [];
        const othersEmbellishments: DraperyOrderDesignEmbellishment[] = [];
        let newCording: any;
        let newBanding: any;
        let newFlange: any;
        let newTrims: any;
        let newLinings: any;
        let newOtherEmbellishment: any;
        let mainNew: any = {};
        if (draperyOrderAllInfo.draperyOrderDesignId !== undefined) {
            const res = await dispatch(
                DraperyModule.searchDraperyOrderDesignEmbellishments(
                    draperyOrderAllInfo.draperyOrderDesignId
                )
            );
            this.processResponseErrors(res, 0, "draperyOrderDesignErrors");
            if (res.error) {
                return;
            }
            const draperyOrderEmbellishmentList: DraperyOrderDesignEmbellishment[] =
                res.payload;

            for (const embellishment of draperyOrderEmbellishmentList) {
                if (embellishment.type === 1) {
                    addEmbellishment(
                        bandings,
                        embellishment,
                        embellishmentsList[0],
                        draperyOrderAllInfo.draperyOrderFabrics,
                        draperyOrderSummaryComponents
                    );
                }
                if (embellishment.type === 2) {
                    addEmbellishment(
                        cordings,
                        embellishment,
                        embellishmentsList[1],
                        draperyOrderAllInfo.draperyOrderFabrics,
                        draperyOrderSummaryComponents
                    );
                }
                if (embellishment.type === 3) {
                    addEmbellishment(
                        flanges,
                        embellishment,
                        embellishmentsList[2],
                        draperyOrderAllInfo.draperyOrderFabrics,
                        draperyOrderSummaryComponents
                    );
                }
                if (embellishment.type === 4) {
                    addEmbellishment(
                        trims,
                        embellishment,
                        embellishmentsList[3],
                        draperyOrderAllInfo.draperyOrderFabrics,
                        draperyOrderSummaryComponents
                    );
                }
                if (embellishment.type === 5) {
                    addEmbellishment(
                        othersEmbellishments,
                        embellishment,
                        embellishmentsList[4],
                        draperyOrderAllInfo.draperyOrderFabrics,
                        draperyOrderSummaryComponents
                    );
                }
            }

            let supplierName;
            if (this.state.mainFabric && this.state.mainFabric.itemId) {
                supplierName = getSelectedName({
                    list: this.state.suppliers as [],
                    findByKey: "id",
                    findValue: mainFabricObj[0].supplierId,
                    nameField: "displayName",
                });
                mainNew = {
                    ...this.state.mainFabric,
                    itemId: mainFabricObj[0].itemId,
                    supplierId: mainFabricObj[0].supplierId,
                    patternId: mainFabricObj[0].patternId,
                    supplierName,
                    pattern: mainFabricObj[0].name,
                    imageId: mainFabricObj[0].avatarId,
                    values: {
                        fabricWidth: mainFabricObj[0].fabricWidthInches,
                        verticalRepeat: mainFabricObj[0].verticalRepeatInches,
                        horizontalRepeat:
                            mainFabricObj[0].horizontalRepeatInches,
                    },
                    isDropRepeat: mainFabricObj[0].isDropRepeatOn,
                    isSpecialtyFabricHandling:
                        mainFabricObj[0].isSpecialtyFabricHandlingOn,
                    customSupplier: "",
                };
            } else {
                mainNew = {
                    ...this.state.mainFabric,
                };
            }

            newCording =
                cordings &&
                cordings.map((cording, index) => {
                    let cordingNew = {};
                    if (
                        cording.draperyOrderFabric &&
                        cording.draperyOrderFabric.itemId
                    ) {
                        supplierName = getSelectedName({
                            list: this.state.suppliers as [],
                            findByKey: "id",
                            findValue: cordingsObj[index].supplierId,
                            nameField: "displayName",
                        });
                        cordingNew = {
                            ...cording,
                            draperyOrderFabric: {
                                ...cording.draperyOrderFabric,
                                itemId: cordingsObj[index].itemId,
                                supplierId: cordingsObj[index].supplierId,
                                patternId: cordingsObj[index].patternId,
                                supplierName,
                                pattern: cordingsObj[index].name,
                                imageId: cordingsObj[index].imageId,
                                values: {
                                    fabricWidth:
                                        cordingsObj[index].fabricWidthInches,
                                    verticalRepeat:
                                        cordingsObj[index].verticalRepeatInches,
                                    horizontalRepeat:
                                        cordingsObj[index]
                                            .horizontalRepeatInches,
                                },
                                isSpecialtyFabricHandling:
                                    cordingsObj[index]
                                        .isSpecialtyFabricHandlingOn,
                                customSupplier: "",
                            },
                        };
                    } else {
                        cordingNew = {
                            ...cording,
                        };
                    }

                    return cordingNew;
                });

            newBanding =
                bandings &&
                bandings.map((banding, index) => {
                    let bandingNew = {};
                    if (
                        banding.draperyOrderFabric &&
                        banding.draperyOrderFabric.itemId
                    ) {
                        supplierName = getSelectedName({
                            list: this.state.suppliers as [],
                            findByKey: "id",
                            findValue: bandingsObj[index].supplierId,
                            nameField: "displayName",
                        });
                        bandingNew = {
                            ...banding,
                            draperyOrderFabric: {
                                ...banding.draperyOrderFabric,
                                itemId: bandingsObj[index].itemId,
                                supplierId: bandingsObj[index].supplierId,
                                patternId: bandingsObj[index].patternId,
                                supplierName,
                                pattern: bandingsObj[index].name,
                                imageId: bandingsObj[index].imageId,
                                values: {
                                    fabricWidth:
                                        bandingsObj[index].fabricWidthInches,
                                    verticalRepeat:
                                        bandingsObj[index].verticalRepeatInches,
                                    horizontalRepeat:
                                        bandingsObj[index]
                                            .horizontalRepeatInches,
                                },
                                isDropRepeat: bandingsObj[index].isDropRepeatOn,
                                isSpecialtyFabricHandling:
                                    bandingsObj[index]
                                        .isSpecialtyFabricHandlingOn,
                                customSupplier: "",
                            },
                        };
                    } else {
                        bandingNew = {
                            ...banding,
                        };
                    }
                    return bandingNew;
                });
            newFlange =
                flanges &&
                flanges.map((flange, index) => {
                    let flangesNew = {};
                    if (
                        flange.draperyOrderFabric &&
                        flange.draperyOrderFabric.itemId
                    ) {
                        supplierName = getSelectedName({
                            list: this.state.suppliers as [],
                            findByKey: "id",
                            findValue: flangesObj[index].supplierId,
                            nameField: "displayName",
                        });
                        flangesNew = {
                            ...flange,
                            draperyOrderFabric: {
                                ...flange.draperyOrderFabric,
                                itemId: flangesObj[index].itemId,
                                supplierId: flangesObj[index].supplierId,
                                patternId: flangesObj[index].patternId,
                                supplierName,
                                pattern: flangesObj[index].name,
                                imageId: flangesObj[index].imageId,
                                values: {
                                    fabricWidth:
                                        flangesObj[index].fabricWidthInches,
                                    verticalRepeat:
                                        flangesObj[index].verticalRepeatInches,
                                    horizontalRepeat:
                                        flangesObj[index]
                                            .horizontalRepeatInches,
                                },
                                isSpecialtyFabricHandling:
                                    flangesObj[index]
                                        .isSpecialtyFabricHandlingOn,
                                customSupplier: "",
                            },
                        };
                    } else {
                        flangesNew = {
                            ...flange,
                        };
                    }
                    return flangesNew;
                });
            newTrims =
                trims &&
                trims.map((trim, index) => {
                    let trimsNew = {};
                    if (
                        trim.draperyOrderFabric &&
                        trim.draperyOrderFabric.itemId
                    ) {
                        supplierName = getSelectedName({
                            list: this.state.suppliers as [],
                            findByKey: "id",
                            findValue: trimsObj[index].supplierId,
                            nameField: "displayName",
                        });
                        trimsNew = {
                            ...trim,
                            draperyOrderFabric: {
                                ...trim.draperyOrderFabric,
                                itemId: trimsObj[index].itemId,
                                supplierId: trimsObj[index].supplierId,
                                supplierName,
                                patternId: trimsObj[index].patternId,
                                pattern: trimsObj[index].name,
                                imageId: trimsObj[index].imageId,
                                values: {
                                    fabricWidth:
                                        trimsObj[index].fabricWidthInches,
                                    verticalRepeat:
                                        trimsObj[index].verticalRepeatInches,
                                    horizontalRepeat:
                                        trimsObj[index].horizontalRepeatInches,
                                },
                                isDropRepeat: trimsObj[index].isDropRepeatOn,
                                isSpecialtyFabricHandling:
                                    trimsObj[index].isSpecialtyFabricHandlingOn,
                                customSupplier: "",
                            },
                        };
                    } else {
                        trimsNew = {
                            ...trim,
                        };
                    }
                    return trimsNew;
                });
            newOtherEmbellishment =
                othersEmbellishments &&
                othersEmbellishments.map((othersEmbellishments, index) => {
                    let otherEmbellismentsNew = {};
                    if (
                        othersEmbellishments.draperyOrderFabric &&
                        othersEmbellishments.draperyOrderFabric.itemId
                    ) {
                        supplierName = getSelectedName({
                            list: this.state.suppliers as [],
                            findByKey: "id",
                            findValue:
                                othersEmbellishmentsObj[index].supplierId,
                            nameField: "displayName",
                        });
                        otherEmbellismentsNew = {
                            ...othersEmbellishments,
                            draperyOrderFabric: {
                                ...othersEmbellishments.draperyOrderFabric,
                                itemId: othersEmbellishmentsObj[index].itemId,
                                supplierId:
                                    othersEmbellishmentsObj[index].supplierId,
                                supplierName,
                                patternId:
                                    othersEmbellishmentsObj[index].patternId,
                                pattern: othersEmbellishmentsObj[index].name,
                                imageId: othersEmbellishmentsObj[index].imageId,
                                values: {
                                    fabricWidth:
                                        othersEmbellishmentsObj[index]
                                            .fabricWidthInches,
                                    verticalRepeat:
                                        othersEmbellishmentsObj[index]
                                            .verticalRepeatInches,
                                    horizontalRepeat:
                                        othersEmbellishmentsObj[index]
                                            .horizontalRepeatInches,
                                },
                                isDropRepeat:
                                    othersEmbellishmentsObj[index]
                                        .isDropRepeatOn,
                                isSpecialtyFabricHandling:
                                    othersEmbellishmentsObj[index]
                                        .isSpecialtyFabricHandlingOn,
                                customSupplier: "",
                            },
                        };
                    } else {
                        otherEmbellismentsNew = {
                            ...othersEmbellishments,
                        };
                    }
                    return otherEmbellismentsNew;
                });

            newLinings =
                this.state.liningsList &&
                this.state.liningsList.map((lining, index) => {
                    let liningsNew = {};
                    if (
                        lining.draperyOrderLiningsFabric &&
                        lining.draperyOrderLiningsFabric.itemId
                    ) {
                        supplierName = getSelectedName({
                            list: this.state.suppliers as [],
                            findByKey: "id",
                            findValue: liningsObj[index].supplierId,
                            nameField: "displayName",
                        });
                        liningsNew = {
                            ...lining,
                            draperyOrderLiningsFabric: {
                                ...lining.draperyOrderLiningsFabric,
                                itemId: liningsObj[index].itemId,
                                supplierId: liningsObj[index].supplierId,
                                supplierName,
                                patternId: liningsObj[index].patternId,
                                pattern: liningsObj[index].name,
                                imageId: liningsObj[index].imageId,
                                values: {
                                    fabricWidth:
                                        liningsObj[index].fabricWidthInches,
                                    verticalRepeat:
                                        liningsObj[index].verticalRepeatInches,
                                    horizontalRepeat:
                                        liningsObj[index].horizontalRepeat,
                                },
                                isSpecialtyFabricHandling:
                                    liningsObj[index]
                                        .isSpecialtyFabricHandlingOn,
                                customSupplier: "",
                            },
                        };
                    } else {
                        liningsNew = {
                            ...lining,
                        };
                    }
                    return liningsNew;
                });

            if (!embellishmentsList[0].checked) {
                bandings.push(defaultBanding());
                newBanding.push(defaultBanding());
            }
            if (!embellishmentsList[1].checked) {
                cordings.push(defaultCording());
                newCording.push(defaultCording());
            }

            if (!embellishmentsList[2].checked) {
                flanges.push(defaultFlange());
                newFlange.push(defaultFlange());
            }
            if (!embellishmentsList[3].checked) {
                trims.push(defaultTrim());
                newTrims.push(defaultTrim());
            }
            if (!embellishmentsList[4].checked) {
                othersEmbellishments.push(defaultOtherEmbellishment());
                newOtherEmbellishment.push(defaultOtherEmbellishment());
            }
        }

        if (isChanged) {
            const confirmed = await this.showSaveConfirm();
            if (confirmed) {
                if (!(await this.handleSaveAll())) {
                    return;
                }
            }
        }
        const workOrderNum = await this.getNextWorkOrderNum();
        this.setState((state) => ({
            ...state,
            isCopy: true,
            isChanged: true,
            draperyOrder: duplicateDraperyOrder({
                ...state.draperyOrder,
                userConfirmation: { allowExtraWideMainFabric: false },
            }),
            draperyOrderDesign: duplicateDraperyOrderDesign(
                state.draperyOrderDesign
            ),
            liningsList: newLinings.map(duplicateLining),
            bandings: newBanding.map(duplicateEmbellishment),
            cordings: newCording && newCording.map(duplicateEmbellishment),
            flanges: newFlange.map(duplicateEmbellishment),
            trims: newTrims.map(duplicateEmbellishment),
            othersEmbellishments: newOtherEmbellishment.map(
                duplicateEmbellishment
            ),
            mainFabric: duplicateFabric(mainNew),
            draperyOrderSummary: defaultDraperyOrderSummary(),
            draperyOrderSummaryComponents: [],
            workOrder: defaultWorkOrder(accountId, workOrderNum),
            isWorkOrderValidation: false,
            draperyOrderErrors: [],
            draperyOrderDesignErrors: [],
            draperyOrderFabricsErrors: [],
            draperyOrderFabricsConfirmations: [],
            draperyOrderSummaryErrors: [],
            draperyOrderPriceId: 0,
            highlightedSection: null,
            isDraft: false,
            checkedAllowExtraWidthOne: false,
            checkedAllowExtraWidthTwo: false,
        }));

        this.setState({ tabIndex: 0 });
    }

    private resetDraperyOrderNewFabrics = (): void => {
        this.setState(prev => {
            return {
                ...prev,
                newDraperyOrderFabrics: [],
                draperyOrderDesign: {
                    ...prev.draperyOrderDesign,
                    draperyOrderDesignEmbellishments: [],
                },
                copyState: {
                    ...prev.copyState,
                    draperyOrderDesign: {
                        ...prev.copyState.draperyOrderDesign,
                        draperyOrderDesignEmbellishments: [],
                    }
                }
            }
        })
    }

    private resetLinings = (): void => {
        this.setState(prev => {
            return {
                ...prev,
                draperyOrderDesign: {
                    ...prev.draperyOrderDesign,
                    draperyOrderDesignLinings: [],
                },
                newDraperyOrderFabrics: []
            }
        })
    }

    private resetAfterUpdatingEmbellishments = (): void => {
        this.setState(prev => {
            return {
                ...prev,
                newDraperyOrderFabrics: [],
                draperyOrderDesign: {
                    ...prev.draperyOrderDesign,
                    draperyOrderDesignLinings: [],
                    draperyOrderDesignEmbellishments: [],
                }
            }
        })
    }

    private resetDraperyOrderEmbellishments = (): void => {
        const newDraperyOrderFabricsKeys = ['bandings', 'cordings', 'flanges', 'trims', 'othersEmbellishments']
        newDraperyOrderFabricsKeys.forEach(k => {
            const newMappedArray = this.state[k] && this.state[k].length > 0 && this.state[k].map((item: any) => {
                return {
                    ...item,
                    draperyOrderFabric: null
                }
            })
            this.setState(prev => {
                return {
                    ...prev,
                    [k]: newMappedArray,
                    newDraperyOrderFabrics: [],
                    draperyOrderDesign: {
                        ...prev.draperyOrderDesign,
                        draperyOrderDesignEmbellishments: []
                    }
                }
            })
        })
    }

    private addDraperyOrderNewFabrics = (keys: string[]): void => {
        if (keys && keys.length > 0) {
            keys.forEach(k => {
                switch (k) {
                    case 'bandings':
                    case 'cordings':
                    case 'flanges':
                    case 'othersEmbellishments':
                    case 'trims':
                        if (this.state[k] && this.state[k].length > 0) {
                            for (const item of this.state[k]) {
                                if (item && item.draperyOrderFabric && item.draperyOrderFabric !== null) {
                                    const { draperyOrderFabric, ...rest } = item
                                    this.setState(prev => {
                                        return {
                                            ...prev,
                                            newDraperyOrderFabrics: [
                                                ...prev.newDraperyOrderFabrics,
                                                draperyOrderFabric
                                            ],
                                            draperyOrderDesign: {
                                                ...prev.draperyOrderDesign,
                                                draperyOrderDesignEmbellishments: [
                                                    ...(prev.draperyOrderDesign.draperyOrderDesignEmbellishments || []),
                                                    rest
                                                ]
                                            }
                                        }
                                    })
                                }
                            }
                        }
                        break;
                    case "mainFabric":
                        if (this.state[k]) {
                            const mainFabricPaylod = {
                                ...this.state.mainFabric,
                                values: {
                                    ...this.state.mainFabric.values,
                                    fabricWidth: this.state.mainFabric.values.fabricWidth ? Number(this.state.mainFabric.values.fabricWidth) : this.state.mainFabric.values.fabricWidth,
                                    horizontalRepeat: this.state.mainFabric.values.horizontalRepeat ? Number(this.state.mainFabric.values.horizontalRepeat) : this.state.mainFabric.values.horizontalRepeat,
                                    verticalRepeat: this.state.mainFabric.values.verticalRepeat ? Number(this.state.mainFabric.values.verticalRepeat) : this.state.mainFabric.values.verticalRepeat
                                }
                            }
                            this.setState(prev => {
                                return {
                                    ...prev,
                                    newDraperyOrderFabrics: [
                                        ...prev.newDraperyOrderFabrics,
                                        mainFabricPaylod
                                    ]
                                }
                            })
                        }
                        break;
                    case 'liningsList':
                        if (this.state[k] && this.state[k].length > 0) {
                            for (const l of this.state[k]) {
                                if (l.active && l.draperyOrderLiningsFabric) {

                                    this.setState(prev => {
                                        const { draperyOrderLiningsFabric, ...rest } = l

                                        return {
                                            ...prev,
                                            newDraperyOrderFabrics: [
                                                ...prev.newDraperyOrderFabrics,
                                                draperyOrderLiningsFabric
                                            ],
                                            draperyOrderDesign: {
                                                ...prev.draperyOrderDesign,
                                                draperyOrderDesignLinings: this.state.draperyOrderDesign.draperyOrderDesignLinings && this.state.draperyOrderDesign.draperyOrderDesignLinings.find(i => i.name === rest.name) ? [
                                                    ...(prev.draperyOrderDesign.draperyOrderDesignLinings || [])
                                                ] : [
                                                    ...(prev.draperyOrderDesign.draperyOrderDesignLinings || []),
                                                    rest
                                                ]
                                            }
                                        }
                                    })
                                }
                            }
                        }
                        break;
                    default:
                        return
                }
            })
        }
    }
    
    private handleCancelRecommendationsOverrides = () => {
        this.setState({
            loadingUpdate: false,
            isRecommendationsOverridesModalOpen: false,
        });
    };

    private handleConfirmRecommendationOverride = async (
        panelJson: DraperyModule.DraperyOrderDesignPanelJson,
        workRoomSettings: any[],
        widthConfiguration: Array<number | undefined>
    ) => {
        const { dispatch } = this.props;
        const { draperyOrder } = this.state;

        this.setState((state) => ({
            ...state,
            isRecommendationsOverridesModalOpen: false,
            draperyOrderDesign:
                state.draperyOrderDesign.category === 0
                    ? {
                          ...state.draperyOrderDesign,
                          panelJson,
                      }
                    : state.draperyOrderDesign,
        }));

        const res = await dispatch(
            WorkOrderModule.createWorkOrder(this.state.workOrder)
        );
        this.processResponseErrors(res, -1, "draperyOrderErrors");
        if (res.error) {
            return;
        }

        const workOrder = res.payload;
        const res1 = await dispatch(
            DraperyModule.editDraperyOrder({
                ...draperyOrder,
                workOrderId: workOrder.id,
                editable: false,
            })
        );
        this.processResponseErrors(res1, -1, "draperyOrderErrors");
        if (res1.error) {
            return;
        }

        if (this.state.workOrderEditMode) {
            this.setState(
                (prev) => {
                    return {
                        ...prev,
                        workRoomSettings,
                        widthConfiguration,
                        draperyOrder: {
                            ...draperyOrder,
                            workOrderId: workOrder.id,
                            editable: false,
                        },
                        workOrder,
                    };
                },
                async () => {
                    if (!(await this.saveDraperyOrderDrawing())) {
                        return;
                    }
                    if (!(await this.saveDraperyOrderDesignApi())) {
                        return;
                    }
                    await this.handleDownloadWorkOrderPdf(false);
                    const notification: NotificationsModule.Notification = {
                        id: 0,
                        message: "Success!",
                        subMessage: "Your progress has been saved",
                        type: "info",
                    };
                    dispatch(NotificationsModule.addNotification(notification));
                }
            );
        } else {
            this.setState(
                (state) => ({
                    ...state,
                    workRoomSettings,
                    widthConfiguration,
                    draperyOrder: {
                        ...draperyOrder,
                        workOrderId: workOrder.id,
                        editable: false,
                    },
                    workOrder,
                }),
                async () => {
                    if (!(await this.saveDraperyOrderDrawing())) {
                        return;
                    }
                    if (!(await this.saveDraperyOrderDesignApi())) {
                        return;
                    }
                    await this.handleDownloadWorkOrderPdf(false);
                }
            );
        }
    };

    @Bind()
    private async handleAddSummaryComponent(): Promise<void> {
        this.setState({
            draperyOrderSummaryComponents: [
                ...this.state.draperyOrderSummaryComponents,
                defaultDraperyOrderSummaryComponent(""),
            ],
        });
    }

    @Bind()
    private async handleDeleteSummaryComponent(id: number): Promise<void> {
        this.state.draperyOrderSummaryComponents.splice(id, 1);
        this.setState({
            draperyOrderSummaryComponents: [
                ...this.state.draperyOrderSummaryComponents,
            ],
        });
    }

    @Bind()
    private async handleEditWorkOrder(): Promise<void> {
        const { dispatch } = this.props;

        const res = await dispatch(
            WorkOrderModule.editWorkOrder(this.state.workOrder)
        );
        if (res.error) {
            return;
        }
    }

    @Bind()
    private async handleDownloadWorkOrderPdf(open: boolean): Promise<void> {
        const { dispatch } = this.props;
        const {
            draperyOrder,
            workOrderPdfStatus,
            workRoomSettings,
            widthConfiguration,
        } = this.state;

        if (
            draperyOrder.id &&
            // draperyOrder.workOrderId &&
            workOrderPdfStatus === "none"
        ) {
            const res = await dispatch(
                WorkOrderModule.getWorkOrderPdfContent(draperyOrder.id)
            );
            if (res.error) {
                this.processResponseErrors(res, 3, "draperyOrderSummaryErrors");
                return;
            }
            await this.getExistingDraperyOrder();
            if (draperyOrder.workOrderId) {
                this.setState(
                    (state) => ({
                        ...state,
                        isLoadingOpen: open,
                        workOrderPdfStatus: open ? "downloading" : "none",
                        workOrderPdfContent: res.payload,
                        workOrderEditMode: false,
                        loadingUpdate: false,
                        workOrderPdfContentSummery: res.payload,
                    }),
                    async () => {
                        await this.saveDraperyOrderPricing(
                            workRoomSettings,
                            widthConfiguration
                        );
                        if (open) {
                            const resPdf: any = await exportPdf(
                                res.payload,
                                Config.fileStoreUrl
                            );
                            if (resPdf.error) return;

                            const buffer = Buffer.from(resPdf.buffer);
                            const blob = new Blob([buffer], {
                                type: "application/pdf",
                            });
                            const url = window.URL.createObjectURL(blob);

                            window.open(url, "_blank");
                            this.handleDraperyPdfFinished(true);
                        }
                    }
                );
            } else {
                this.setState(
                    (state) => ({
                        ...state,
                        workOrderPdfStatus: "none",
                        workOrderPdfContent: res.payload,
                        workOrderPdfContentSummery: res.payload,
                    }),
                    async () => {
                        await this.saveDraperyOrderPricing(
                            workRoomSettings,
                            widthConfiguration
                        );
                    }
                );
            }
        }
    }

    @Bind()
    private async handleDraperyPdfFinished(success: boolean): Promise<void> {
        const { dispatch } = this.props;
        const { isLoadingOpen } = this.state;

        if (success) {
            if (!isLoadingOpen) {
                const notification: NotificationsModule.Notification = {
                    id: 0,
                    message: "Work order created successfully!",
                    type: "info",
                };
                dispatch(NotificationsModule.addNotification(notification));
            }
            this.setState({
                workOrderPdfStatus: "none",
                isLoadingOpen: false,
            });
        } else {
            if (!isLoadingOpen) {
                const notification: NotificationsModule.Notification = {
                    id: 0,
                    message: "Failed to create work order !",
                    type: "error",
                };
                dispatch(NotificationsModule.addNotification(notification));
            }
            this.setState({
                workOrderPdfStatus: "error",
                isLoadingOpen: false,
            });
        }
    }

    @Bind()
    private async getDefaultConstruction(): Promise<void> {
        let type = -1;
        if (this.state.draperyOrderDesign.headingType === "rodandrings") {
            switch (this.state.draperyOrderDesign.draperyType) {
                case "full-length":
                    type = 1;
                    break;
                case "cafe-curtain":
                    type = 2;
                    break;
            }
        }
        if (this.state.draperyOrderDesign.headingType === "ripplefold") {
            type = 3;
        }
        if (type === -1) {
            return;
        }

        const { dispatch, accountId } = this.props;
        const res = await dispatch(
            WorkroomSettingModule.searchWorkroomSetting(accountId, type)
        );
        this.processResponseErrors(res, 0, "draperyOrderDesignErrors");
        if (res.error) {
            return;
        }
        if (res.payload.length === 0) {
            this.setState((state) => ({
                ...state,
                draperyOrderDesignErrors: [
                    { tabIndex: 1, name: "", message: "Invalid drapery type" },
                ],
            }));
            return;
        }

        const workroomSetting = res.payload[0];
        const buckram = workroomSetting.buckram;
        if (workroomSetting) {
            const isWorkRoom =
                workroomSetting.draperyPinInstaller === "Workroom"
                    ? true
                    : false;
            this.setState((state) => ({
                ...state,
                reloadOnSaveConstructions: false,
                workroomSetting: workroomSetting,
                draperyOrderDesign: {
                    ...state.draperyOrderDesign,
                    customIsYardageAddOneExtra:
                        !!workroomSetting.presetIsYardageAddOneExtra,
                    customIsYardageAddExcess:
                        !!workroomSetting.presetIsYardageAddExcess,
                    customAddExcessValue: workroomSetting.presetAddExcessValue,
                    customAddExcessUnit: workroomSetting.presetAddExcessUnit,
                    customEmbellishmentJson:
                        workroomSetting.presetEmbellishmentJson || {
                            ...WorkroomSettingModule.defaultWorkroomPresetEmbellishmentJson,
                        },
                    customHEMSJson: workroomSetting.presetHEMSJson || {
                        ...WorkroomSettingModule.defaultWorkroomPresetHEMSJson,
                    },
                    customReturnOverlapJson:
                        workroomSetting.presetReturnOverlapJson || {
                            ...WorkroomSettingModule.defaultWorkroomPresetReturnOverlapJson,
                        },
                    customCutLengthJson:
                        workroomSetting.presetCutLengthJson || {
                            ...WorkroomSettingModule.defaultWorkroomPresetCutLengthJson,
                        },
                    customDraperyFullnessJson:
                        workroomSetting.presetDraperyFullnessJson || {
                            ...WorkroomSettingModule.defaultWorkroomPresetDraperyFullnessJson,
                        },
                    draperyFullness:
                        workroomSetting.presetDraperyFullnessJson &&
                        workroomSetting.presetDraperyFullnessJson.value
                            ? workroomSetting.presetDraperyFullnessJson.value
                            : 0,
                    isPinSet: isWorkRoom,
                    buckram,
                },
                // mainFabric: {
                //     ...state.mainFabric,
                //     orderOrigin:
                //         workroomSetting.fabricPurchaser === "Workroom"
                //             ? 1
                //             : workroomSetting.fabricPurchaser === "No Default"
                //             ? -1
                //             : 2,
                // },
            }));
        } else {
            this.setState((state) => ({
                ...state,
                reloadOnSaveConstructions: false,
                draperyOrderDesign: {
                    ...state.draperyOrderDesign,
                    customIsYardageAddOneExtra: false,
                    customIsYardageAddExcess: false,
                    customAddExcessValue: undefined,
                    customAddExcessUnit: undefined,
                    customEmbellishmentJson: {
                        ...WorkroomSettingModule.defaultWorkroomPresetEmbellishmentJson,
                    },
                    customHEMSJson: {
                        ...WorkroomSettingModule.defaultWorkroomPresetHEMSJson,
                    },
                    customReturnOverlapJson: {
                        ...WorkroomSettingModule.defaultWorkroomPresetReturnOverlapJson,
                    },
                    customCutLengthJson: {
                        ...WorkroomSettingModule.defaultWorkroomPresetCutLengthJson,
                    },
                    customDraperyFullnessJson: {
                        ...WorkroomSettingModule.defaultWorkroomPresetDraperyFullnessJson,
                    },
                },
            }));
        }
    }

    @Bind()
    private setChanges(isChanged: boolean): void {
        this.setState((state) => ({
            ...state,
            isChanged,
        }));
    }

    @Bind()
    private clearErrors(): void {
        this.setState((state) => ({
            ...state,
            draperyOrderErrors: [],
            draperyOrderDesignErrors: [],
            draperyOrderFabricsErrors: [],
            draperyOrderSummaryErrors: [],
        }));
    }

    @Bind()
    private getEmbellishmentsByIndex(index: number): {
        name: string;
        list: DraperyOrderDesignEmbellishment[];
    } {
        const { bandings, cordings, flanges, trims, othersEmbellishments } =
            this.state;

        if (index === 0) {
            return { name: "bandings", list: bandings };
        }
        if (index === 1) {
            return { name: "cordings", list: cordings };
        }
        if (index === 2) {
            return { name: "flanges", list: flanges };
        }
        if (index === 3) {
            return { name: "trims", list: trims };
        }
        return { name: "othersEmbellishments", list: othersEmbellishments };
    }

    @Bind()
    private updateStateByName(
        name: string,
        value:
            | string
            | number
            | boolean
            | number[]
            | undefined
            | object
            | Date
            | null,
        isSaving?: boolean
    ) {
        const nameList = name.split(".");
        this.setState(
            (state) => {
                if (
                    nameList.length === 1 &&
                    name.includes("isNoneEmbellishment")
                ) {
                    return {
                        ...state,
                        [name]: value,
                        embellishmentsList: state.embellishmentsList.map(
                            (emb) => {
                                return { ...emb, checked: false };
                            }
                        ),
                    };
                }
                if (nameList.length === 1 && name.includes("isNoneLinings")) {
                    return {
                        ...state,
                        [name]: value,
                        liningsList: state.liningsList.map((ling) => {
                            return { ...ling, active: false };
                        }),
                    };
                }
                if (
                    nameList.length === 1 &&
                    !name.includes("isNoneLinings") &&
                    !name.includes("isNoneEmbellishment")
                ) {
                    return {
                        ...state,
                        [name]: value,
                    };
                }

                if (nameList.length === 2) {
                    return {
                        ...state,
                        [nameList[0]]: {
                            ...state[nameList[0]],
                            [nameList[1]]: value,
                        },
                    };
                }

                if (nameList.length >= 3) {
                    let target = state[nameList[0]];
                    for (const name of nameList.slice(1, nameList.length - 1)) {
                        target = target[name];
                    }
                    if (
                        nameList[0] === "liningsList" &&
                        nameList[2] === "description"
                    ) {
                        if (
                            target.active === false &&
                            value !== "" &&
                            typeof value === "string" &&
                            target.description &&
                            target.description.length < value.length
                        ) {
                            target.active = true;
                        }
                    }
                    const propertyName = nameList.pop();
                    if (propertyName !== undefined && target) {
                        target[propertyName] = value;
                    }

                    if (name.includes("checked") && value) {
                        return {
                            ...state,
                            isNoneEmbellishment: false,
                        };
                    }

                    if (
                        name.includes("liningsList.") &&
                        name.includes("active") &&
                        value
                    ) {
                        return {
                            ...state,
                            isNoneLinings: false,
                        };
                    }
                    if (
                        name.includes("draperyOrder.userConfirmation.") &&
                        typeof value === "boolean"
                    ) {
                        if (this.state.mainFabric.directionOfUse === 1) {
                            return {
                                ...state,
                                checkedAllowExtraWidthOne: value,
                            };
                        } else {
                            return {
                                ...state,
                                checkedAllowExtraWidthTwo: value,
                            };
                        }
                    }
                }

                const stateNameList = state[nameList[0]];
                return {
                    ...state,
                    [nameList[0]]: Array.isArray(stateNameList)
                        ? [...stateNameList]
                        : { ...stateNameList },
                };
            },
            async () => {
                if (name === "draperyOrderDesign.draperyType") {
                    await this.getDefaultConstruction();
                }
                if (name === "draperyOrderDesign.headingType") {
                    await this.getDefaultConstruction();
                }
                this.setState((state) => ({ ...state, validateClient: true }));
                if (!isSaving) {
                    this.setChanges(true);
                    this.validateAll("update");
                    if(this.validateAll('workOrder')){
                        this.setState((state) => ({
                            ...state,
                            isSidebarHidden: true,
                        }))
                    }
                }
                if (name === "draperyOrder.isDraft") {
                    if (!this.state.workOrderEditMode) {
                        await this.saveDraperyOrderApi();
                    }                }
                const highlightedSectionArr = this.state.highlightedSection ? this.state.highlightedSection.split('.') : [];
                if((name.includes('pattern') || name.includes('patternId')) && highlightedSectionArr.length === 3 && ![null, undefined, ''].includes(this.state[highlightedSectionArr[0]][highlightedSectionArr[1]][highlightedSectionArr[2]])){
                    this.setState({
                        highlightedSection: null,
                    });
                } else if((name.includes('pattern') || name.includes('patternId') || name.includes('liningsList')) && highlightedSectionArr.length === 5 && ![null, undefined, ''].includes(this.state[highlightedSectionArr[0]][highlightedSectionArr[1]][highlightedSectionArr[2]][highlightedSectionArr[3]][highlightedSectionArr[4]])) {
                    this.setState({
                        highlightedSection: null,
                    });
                }
            }
        );
    }

    @Bind()
    private handleInputChangeForNumber(e: ChangeEvent<HTMLInputElement>): void {
        const { name, value } = e.currentTarget;

        if (value.startsWith("00")) {
            e.currentTarget.value = value.replace(/^0+/, "0");
        }
        const nValue = value ? +value : undefined;

        this.clearHighlightSection(name);
        if (name === "draperyOrderDesign.category") {
            this.state.draperyOrderDesign.rodWidth = undefined;
            if (!value) {
                this.state.draperyOrderDesign.panelJson =
                    defaultWidthEachPanelList();
            }
        } else if (name.endsWith(".orderOrigin")) {
            if (nValue === 2) {
                this.updateStateByName(
                    name.replace(
                        ".orderOrigin",
                        ".draperyOrderSummaryComponent.orderedDate"
                    ),
                    "COM"
                );
            } else if (nValue === 1) {
                this.updateStateByName(
                    name.replace(
                        ".orderOrigin",
                        ".draperyOrderSummaryComponent.orderedDate"
                    ),
                    undefined
                );
            }
        } else if (
            name === "draperyOrderDesign.rodWidth" ||
            name === "draperyOrderDesign.finishedLengthOfPanels"
        ) {
            let roundedValue;

            if (nValue !== undefined) {
                const roundedDecimal = +nValue.toFixed(3);

                roundedValue = Math.min(Math.max(roundedDecimal, 1), 999);
            } else {
                roundedValue = undefined;
            }

            this.updateStateByName(name, roundedValue);
            return;
        }

        if (
            (name === "mainFabric.values.horizontalRepeat" && !value) ||
            +value === 0
        ) {
            this.updateStateByName("mainFabric.isPleatToPattern", false);
        }
        const constructionSettingsInputs = [
            "draperyOrderDesign.customHEMSJson.topHemFinishedSize",
            "draperyOrderDesign.customHEMSJson.topHemFabricAllowance",
            "draperyOrderDesign.customHEMSJson.bottomHemFinishedSize",
            "draperyOrderDesign.customHEMSJson.bottomHemFabricAllowance",
            "draperyOrderDesign.customHEMSJson.sideHemFinishedSize",
            "draperyOrderDesign.customHEMSJson.sideHemFabricAllowance",
            "draperyOrderDesign.customCutLengthJson.lining",
            "draperyOrderDesign.customCutLengthJson.interlining",
            "draperyOrderDesign.customEmbellishmentJson.flangeVertical",
            "draperyOrderDesign.customEmbellishmentJson.flangeHorizontal",
            "draperyOrderDesign.customEmbellishmentJson.trimVertical",
            "draperyOrderDesign.customEmbellishmentJson.trimHorizontal",
            "draperyOrderDesign.customEmbellishmentJson.cordingVertical",
            "draperyOrderDesign.customEmbellishmentJson.cordingHorizontal",
            "draperyOrderDesign.customEmbellishmentJson.trimVertical",
            "draperyOrderDesign.customEmbellishmentJson.trimVertical",
            "draperyOrderDesign.customReturnOverlapJson.leadSize",
            "draperyOrderDesign.customReturnOverlapJson.returnSize",
            "draperyOrderDesign.customReturnOverlapJson.overlapSize",
        ];

        if (constructionSettingsInputs.includes(name)) {
            const regex = /^\d{0,2}(\.\d{0,2})?$/;
            const targetValue = e.currentTarget.value;

            if (!regex.test(targetValue)) {
                return;
            }

            if (
                targetValue &&
                targetValue.includes(".") &&
                targetValue.split(".")[1].length > 2
            ) {
                return;
            }
        }

        this.updateStateByName(name, nValue);
    }

    @Bind()
    private handleChangeRipplefoldOptions(
        e: ChangeEvent<HTMLInputElement>
    ): void {
        const { name, value } = e.currentTarget;

        this.clearHighlightSection(name);
        this.updateStateByName(name, value);
    }

    @Bind()
    private handleRemoveDraperyDesignCategory(value: number): void {
        if (this.state.draperyOrderDesign.category === value) {
            this.updateStateByName(`draperyOrderDesign.category`, value);
            this.setState((prev) => {
                return {
                    ...prev,
                    draperyOrderDesign: {
                        ...prev.draperyOrderDesign,
                        category: -1,
                    },
                };
            });
        }
    }

    @Bind()
    private handleRemoveRipplefoldDetail(key: string, value: string | undefined | null) {
        if (this.state.draperyOrderDesign.ripplefoldOptions[key] === value) {
            this.updateStateByName(
                `draperyOrderDesign.ripplefoldOptions.${key}`,
                value
            );
            this.setState((prev) => {
                return {
                    ...prev,
                    draperyOrderDesign: {
                        ...prev.draperyOrderDesign,
                        ripplefoldOptions: {
                            ...prev.draperyOrderDesign.ripplefoldOptions,
                            [key]: null,
                        },
                    },
                };
            });
        }
    }

    @Bind()
    private handleInputChangeForNumberPrice(
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void {
        const { name, value } = e.currentTarget;
        const clean = value.replace(/,/g, "");
        const regex = /^[+-]?[0-9]*\.?[0-9]*$/;
        this.clearHighlightSection(name);
        // Check if the input value contains a dot and if adding another dot would be invalid

        if (
            value.includes("+") ||
            value.includes("-") ||
            (value.includes(".") && value.match(/\./g)!.length > 1)
        ) {
            return;
        }
        if (
            (!value.includes(".") && value.length > 6) ||
            (value.includes(".") && value.split(".")[1].length > 2)
        )
            return;

        if (clean) {
            if (value === "." || clean === "." || clean.match(regex)) {
                if (!value.includes(".")) {
                    const formattedValue = new Intl.NumberFormat().format(
                        parseFloat(clean)
                    );
                    this.updateStateByName(name, formattedValue);
                } else {
                    this.updateStateByName(name, value);
                }
            }
        } else {
            this.updateStateByName(name, "");
        }
    }

    @Bind()
    private handleInputChangeForString(e: any, position?: string): void {
        const { name } = e.currentTarget;
        let { value } = e.currentTarget;
        if (name === "draperyOrderDesign.weightRadio") {
            this.updateStateByName("draperyOrderDesign.isWeight", true);
        }
        const isDeselectingPanel =
            this.state.draperyOrderDesign.panelType === value;
        const isDeselectingPleat =
            this.state.draperyOrderDesign.pleatType === value;
        const isDeselectingDraperyType =
            this.state.draperyOrderDesign.draperyType === value;

        const isDeselectHeadingType =
            this.state.draperyOrderDesign.headingType === value;
        
        if (name === 'draperyOrderDesign.ripplefoldOptions.headerTape' || name === 'draperyOrderDesign.ripplefoldOptions.controlLocation') {
            this.updateStateByName(name, value);
        }

        if (name === 'draperyOrder.window'){
            this.updateStateByName(name, value);
        }

        if (name === 'draperyOrderDesign.pinSetText'){
            this.updateStateByName(name, value);
        }
    
        this.clearHighlightSection(name);
        if (name === "draperyOrderDesign.panelType") {
            const currentConf = panelsConfiguration.filter(
                (el) => el.name === value
            )[0];
            this.state.draperyOrderDesign.numOfPanels =
                currentConf.numberPanels;
        } else if (name === "draperyOrderDesign.pleatType") {
            this.state.draperyOrderDesign.otherPleatImageId = undefined;
        } else if (name.endsWith(".insetSize")) {
            const nameList = name.split(".");
            const oldValue =
                this.state[nameList[0]][nameList[1]][nameList[2]][nameList[3]];

            if (!oldValue || oldValue.length < value.length) {
                this.updateStateByName(
                    name.replace(".insetSize", ".insetActive"),
                    true
                );
            }
            const decimalPlaces = value.split(".")[1];
            if (
                value.match(/[a-z]/i) ||
                (!value.includes(".") && value.length > 2) ||
                (value.includes(".") && decimalPlaces.length > 2) ||
                (!value.includes(".") &&
                    Number(oldValue) === 0 &&
                    value.length > 1) ||
                value.includes("+") ||
                value.includes("-")
            )
                return;
        } else if (name === "draperyOrderDesign.notes") {
            value = value.replace(/(\r\n|\n|\r)/gm, " ");
            value = value.length > 140 ? value.substr(0, 140) : value;
            if (value.length > 0) this.updateStateByName(name, value);
            else this.updateStateByName(name, "");
            //QUIPA-1322/Funcionality for deleting text on Windows field
        } else if (name === "draperyOrderSummary.notes") {
            value = value.replace(/(\r\n|\n|\r)/gm, " ");
            value = value.length > 150 ? value.substr(0, 150) : value;
            if (value.length > 0) this.updateStateByName(name, value);
            else this.updateStateByName(name, "");
        } else if (name === "draperyOrderDesign.draperyType") {
            this.setState({
                isCustomizeConstructionOpen: true,
            });
        }
        if (isDeselectingPanel) {
            this.updateStateByName("draperyOrderDesign.panelType", "");
        } else if (isDeselectingPleat) {
            this.updateStateByName("draperyOrderDesign.pleatType", "");
        } else if (isDeselectingDraperyType) {
            this.updateStateByName("draperyOrderDesign.draperyType", "");
            this.setState((prev) => {
                return {
                    ...prev,
                    isCustomizeConstructionOpen: false,
                };
            });
        } else if (isDeselectHeadingType) {
            this.updateStateByName("draperyOrderDesign.headingType", "");
            this.setState((prev) => {
                return {
                    ...prev,
                    isCustomizeConstructionOpen: false,
                };
            });
        } else {
            this.updateStateByName(name, value);
        }
    }

    @Bind()
    private handleChangeDates(value: Date | null, field: string): void {
        if (value) {
            const date = new Date(value);
            this.updateStateByName(field, date);
        } else {
            this.updateStateByName(field, null);
        }
    }

    @Bind()
    private returnDates(): any {
        const dates = {
            installDate: !this.state.draperyOrderSummary.installationJson
                .dateOfInstall
                ? null
                : moment(
                      this.state.draperyOrderSummary.installationJson
                          .dateOfInstall
                  ).isValid()
                ? moment(
                      this.state.draperyOrderSummary.installationJson
                          .dateOfInstall
                  ).toDate()
                : null,
            finishedDate: !this.state.draperyOrderSummary.productionJson
                .finishedDate
                ? null
                : moment(
                      this.state.draperyOrderSummary.productionJson.finishedDate
                  ).isValid()
                ? moment(
                      this.state.draperyOrderSummary.productionJson.finishedDate
                  ).toDate()
                : null,
            dueDate: !this.state.draperyOrderSummary.productionJson.dueDate
                ? null
                : moment(
                      this.state.draperyOrderSummary.productionJson.dueDate
                  ).isValid()
                ? moment(
                      this.state.draperyOrderSummary.productionJson.dueDate
                  ).toDate()
                : null,
            startDate: !this.state.draperyOrderSummary.productionJson.startDate
                ? null
                : moment(
                      this.state.draperyOrderSummary.productionJson.startDate
                  ).isValid()
                ? moment(
                      this.state.draperyOrderSummary.productionJson.startDate
                  ).toDate()
                : null,
            dateSent: !this.state.draperyOrderSummary.estimateJson.dateSent
                ? null
                : moment(
                      this.state.draperyOrderSummary.estimateJson.dateSent
                  ).isValid()
                ? moment(
                      this.state.draperyOrderSummary.estimateJson.dateSent
                  ).toDate()
                : null,
        };

        return dates;
    }

    @Bind()
    private handleInputBlur(
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) {
        let { value, name } = e.currentTarget;
        let nValue = value.includes(".")
            ? value.replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, "$1")
            : value;
        if (nValue.charAt(value.length - 1) === ".") {
            nValue = nValue.slice(0, value.length - 1);
        }
        this.updateStateByName(name, nValue);
    }

    @Bind()
    private generateDefaultFabricsLiningName({
        liningName,
    }: {
        liningName: string;
    }): string {
        const isOtherInterlining = liningName
            .toLowerCase()
            .includes("other interlining");

        const nameSet = isOtherInterlining
            ? `INTERLINING FABRIC ${this.state.liningsList.length}`
            : `${liningName.toUpperCase()} FABRIC`;

        return nameSet;
    }

    @Bind()
    private handleCheckLiningsList({
        itemIndex,
        checked,
    }: {
        itemIndex: number;
        checked: boolean;
    }): void {
        const stateLiningsList = [...this.state.liningsList];
        const selectedItem = stateLiningsList[itemIndex];
        if (checked) {
            if (
                !selectedItem.draperyOrderLiningsFabric ||
                selectedItem.draperyOrderLiningsFabric.id === 0
            ) {
                const defaultLiningsName =
                    this.generateDefaultFabricsLiningName({
                        liningName: selectedItem.name,
                    });
                selectedItem.draperyOrderLiningsFabric =
                    defaultLiningsFabric(defaultLiningsName);
            }
        } else {
            if (selectedItem.draperyOrderLiningsFabric) {
                delete selectedItem.draperyOrderLiningsFabric;
            }
        }
        this.setState({
            liningsList: stateLiningsList,
        });
    }

    @Bind()
    getDefaultValue(): void {
        const value =
            this.state.workroomSetting === null ||
            (this.state.workroomSetting &&
                this.state.workroomSetting.fabricPurchaser === "No Default" || this.state.workroomSetting && !this.state.workroomSetting.fabricPurchaser)
                ? -1
                : this.state.workroomSetting &&
                  this.state.workroomSetting.fabricPurchaser &&
                  this.state.workroomSetting.fabricPurchaser === "Workroom"
                ? 1
                : 2;
        const valueEmb =
            this.state.workroomSetting === null ||
            (this.state.workroomSetting &&
                this.state.workroomSetting.embellishmentsPurchaser ===
                    "No Default" || this.state.workroomSetting && !this.state.workroomSetting.embellishmentsPurchaser)
                ? -1
                : this.state.workroomSetting &&
                  this.state.workroomSetting.embellishmentsPurchaser &&
                  this.state.workroomSetting.embellishmentsPurchaser ===
                      "Workroom"
                ? 1
                : 2;

        const valueLining =
            this.state.workroomSetting === null ||
            (this.state.workroomSetting &&
                this.state.workroomSetting.liningsPurchaser === "No Default" || this.state.workroomSetting && !this.state.workroomSetting.liningsPurchaser)
                ? -1
                : this.state.workroomSetting &&
                  this.state.workroomSetting.liningsPurchaser &&
                  this.state.workroomSetting.liningsPurchaser === "Workroom"
                ? 1
                : 2;

        this.setState({
            orderOriginState: value,
            orderOriginStateEmb: valueEmb,
            orderOriginStateLining: valueLining,
        });
    }

    @Bind()
    private handleInputChangeForBoolean(
        e: ChangeEvent<HTMLInputElement>,
        targetArray?: string,
        targetArrayIndex?: number
    ): void {
        const { name, checked } = e.currentTarget;
        const nameList = name.split(".");
        const nameListLength = nameList.length;
        if (name === "draperyOrderDesign.isWeight") {
            if (checked) {
                this.updateStateByName(
                    "draperyOrderDesign.weightRadio",
                    "standard-corner"
                );
            } else {
                this.updateStateByName("draperyOrderDesign.weightRadio", null);
            }
        }
        if (
            name.endsWith("topEdge.active") ||
            name.endsWith("bottomEdge.active")
        ) {
            this.clearHighlightSection(
                `${targetArray}.${targetArrayIndex}.leadReturnEdge.active`
            );
        } else {
            this.clearHighlightSection(name);
        }
        if (nameListLength === 3) {
            if (name.startsWith("embellishmentsList.") && !checked) {
                this.deleteEmbellishments(+nameList[1]);
            } else if (name.startsWith("embellishmentsList.") && checked) {
                this.clearHighlightSection("isNoneEmbellishment");
            } else if (name.startsWith("liningsList.")) {
                this.handleCheckLiningsList({
                    itemIndex: +nameList[1],
                    checked,
                });
                this.clearHighlightSection("isNoneLinings");
            } else if (
                name ===
                "draperyOrder.userConfirmation.allowExtraWideMainFabric"
            ) {
                this.clearHighlightSection("mainFabric.values.fabricWidth");
            }
        }

        this.updateStateByName(name, checked);
    }

    @Bind()
    private handleSelectListChange(value: string | number, name: string): void {
        let targetValue: string | number | undefined = value;
        if (name === "draperyOrder.roomId") {
            targetValue = value === "" ? undefined : value;
        }
        if (name !== "draperyOrderDesign.panelJson.pannel1Width") {
            this.clearHighlightSection(name);
        }
        this.updateStateByName(name, targetValue);
    }

    @Bind()
    private async handleSearchSelectChange(
        id: number,
        name: string,
        pathId: string,
        pathName: string,
        itemId?: number,
        search?: string
    ) {
        this.updateStateByName(pathId, id);
        this.updateStateByName(pathName, name);
        if (document.getElementsByName(pathId)[0]) {
            this.clearHighlightSection(pathId);
        } else if (document.getElementsByName(pathName)[0]) {
            this.clearHighlightSection(pathName);
        }
        if (pathId === "draperyOrder.clientId") {
            await this.getRooms(id);
            await this.getClient();
        } else if (pathId === "mainFabric.patternId") {
            await this.setFabricsPatternDefaults({ patternId: id, itemId });
        } else if (pathId.includes("liningsList")) {
            await this.setLiningsPatternDefaults({
                patternId: id,
                pathId,
                itemId,
            });
        } else {
            await this.setDraperyOrderFabricPatternDefaults({
                patternId: id,
                pathId,
                itemId,
            });
        }
        if(pathId === "draperyOrder.roomId"){

        const { dispatch } = this.props;
        const { clientId } = this.state.draperyOrder;

        const res = await dispatch(
            RoomModule.getRooms(clientId, "asc", search)
        );
        if (res.error) {
            return [];
        }
        const rooms = res.payload.map((r: any) => ({
            id: r.id,
            displayName: r.name,
        }));
        
        if (!rooms.some((room: { id: any; displayName: any }) => room.displayName === this.state.draperyOrder.roomName)) {
            this.setState((state) => ({
                ...state,
                draperyOrder: {
                    ...state.draperyOrder,
                    customRoom: state.draperyOrder.roomName ? state.draperyOrder.roomName : state.draperyOrder.customRoom,
                },
            }), () => {
                if(this.validateAll('workOrder')){
                    this.setState((state) => ({
                        ...state,
                        isSidebarHidden: true,
                    }))
                }
            } )
        }
        
        return rooms;
        }
        // this.setState({patternId:itemId})
    }

    @Bind()
    private async setFabricsPatternDefaults({
        patternId,
        itemId,
    }: {
        patternId: number;
        itemId?: number;
    }) {
        const { fabricsPatternItems, mainFabric } = this.state;
        const { dispatch, accountId } = this.props;
        if (!patternId || !fabricsPatternItems || !fabricsPatternItems.length) {
            return;
        }
        const selectedPattern = fabricsPatternItems.find(
            (f) => f.id === patternId
        );

        if (!selectedPattern) {
            return;
        }

        const {
            isSpecialtyFabricHandlingOn,
            fabricWidthInches,
            verticalRepeatInches,
            horizontalRepeatInches,
            isDropRepeatOn,
            directionOfUseTypeId,
            isPleatToPatternOn,
            isMatchRepeatAtSeamsOn,
            supplierId,
        } = selectedPattern;

        // let orderOriginNum = -1;
        // if (
        //     this.state &&
        //     this.state.workroomSetting &&
        //     this.state.workroomSetting.fabricPurchaser === "Workroom"
        // ) {
        //     orderOriginNum = 1;
        // } else if (
        //     this.state &&
        //     this.state.workroomSetting &&
        //     this.state.workroomSetting.fabricPurchaser === "Installer"
        // ) {
        //     orderOriginNum = 2;
        // }
        this.setState((state) => {
            return {
                ...state,
                mainFabric: {
                    ...state.mainFabric,
                    isSpecialtyFabricHandling: isSpecialtyFabricHandlingOn,
                    isDropRepeat: isDropRepeatOn,
                    directionOfUse: directionOfUseTypeId,
                    isPleatToPattern: isPleatToPatternOn,
                    isMatchRepeatAtSeams: isMatchRepeatAtSeamsOn,
                    imageId: selectedPattern.avatarId || null,
                    itemId,
                },
            };
        });

        if (fabricWidthInches !== undefined) {
            this.updateStateByName(
                "mainFabric.values.fabricWidth",
                fabricWidthInches
            );
        }
        if (verticalRepeatInches !== undefined) {
            this.updateStateByName(
                "mainFabric.values.verticalRepeat",
                verticalRepeatInches
            );
        }
        if (horizontalRepeatInches !== undefined) {
            this.updateStateByName(
                "mainFabric.values.horizontalRepeat",
                horizontalRepeatInches
            );
        }

        const res = await dispatch(
            SuppliersModule.searchSuppliers(accountId, "", "asc", "asc")
        );

        if (res.error) {
            return;
        }

        const suppliers: Supplier[] = res.payload;
        if (suppliers && suppliers.length && !mainFabric.customSupplier) {
            const fabricPatternSupplier = suppliers.find(
                (s) => s.id === supplierId
            );
            if (fabricPatternSupplier) {
                const { id, displayName } = fabricPatternSupplier;
                this.updateStateByName("mainFabric.supplierId", id);
                this.updateStateByName("mainFabric.supplierName", displayName);
            }
        }
    }

    @Bind()
    private async setDraperyOrderFabricPatternDefaults({
        patternId,
        pathId,
        itemId,
    }: {
        patternId: number;
        pathId: string;
        itemId?: number;
    }) {
        let stateList: any[] = [];
        const { fabricsPatternItems } = this.state;
        // const { dispatch, accountId } = this.props;

        if (!patternId || !fabricsPatternItems || !fabricsPatternItems.length) {
            return;
        }

        const selectedPattern = fabricsPatternItems.find(
            (f) => f.id === patternId
        );

        if (!selectedPattern) {
            return;
        }
        const {
            isSpecialtyFabricHandlingOn,
            horizontalRepeatInches,
            fabricWidthInches,
            verticalRepeatInches,
            supplierId,
            isDropRepeatOn,
        } = selectedPattern;

        // let orderOriginNum = -1;

        // if (
        //     this.state &&
        //     this.state.workroomSetting &&
        //     this.state.workroomSetting.embellishmentsPurchaser === "Workroom"
        // ) {
        //     orderOriginNum = 1;
        // } else if (
        //     this.state &&
        //     this.state.workroomSetting &&
        //     this.state.workroomSetting.embellishmentsPurchaser === "Installer"
        // ) {
        //     orderOriginNum = 2;
        // }

        // const res = await dispatch(
        //     SuppliersModule.searchSuppliers(accountId, "", "asc", "asc")
        // );
        const suppliers: Supplier[] = this.state.suppliers;
        if (pathId && pathId.includes("trim")) {
            stateList = [...this.state.trims];
        }
        if (pathId && pathId.includes("cordings")) {
            stateList = [...this.state.cordings];
        }
        if (pathId && pathId.includes("bandings")) {
            stateList = [...this.state.bandings];
        }
        if (pathId && pathId.includes("flanges")) {
            stateList = [...this.state.flanges];
        }
        if (pathId && pathId.includes("othersEmbellishments")) {
            stateList = [...this.state.othersEmbellishments];
        }
        const index = +pathId.split(".")[1];

        let selectedFabric = stateList[index];
        if (!selectedFabric) {
            return;
        }
        const draperyOrderFabric: any = {
            ...selectedFabric.draperyOrderFabric,
            isSpecialtyFabricHandling: isSpecialtyFabricHandlingOn,
            isDropRepeat: isDropRepeatOn,
            imageId: selectedPattern.avatarId || null,
            values: {
                fabricWidth:
                    fabricWidthInches !== null ? fabricWidthInches : undefined,
                verticalRepeat:
                    verticalRepeatInches !== null
                        ? verticalRepeatInches
                        : undefined,
                horizontalRepeat:
                    horizontalRepeatInches !== null
                        ? horizontalRepeatInches
                        : undefined,
            },
            itemId,
        };

        if (
            suppliers &&
            suppliers.length &&
            !draperyOrderFabric.customSupplier
        ) {
            const liningPatternSupplier = suppliers.find(
                (s) => s.id === supplierId
            );
            if (liningPatternSupplier) {
                const { id, displayName } = liningPatternSupplier;
                draperyOrderFabric.supplierId = id;
                draperyOrderFabric.supplierName = displayName;
            }
        }
        stateList[index].draperyOrderFabric = draperyOrderFabric;
        if (pathId && pathId.includes("trims")) {
            this.setState({
                trims: stateList,
            });
        }
        if (pathId && pathId.includes("cordings")) {
            this.setState({
                cordings: stateList,
            });
        }
        if (pathId && pathId.includes("flanges")) {
            this.setState({
                flanges: stateList,
            });
        }
        if (pathId && pathId.includes("bandings")) {
            this.setState({
                bandings: stateList,
            });
        }
        if (pathId && pathId.includes("othersEmbellishments")) {
            this.setState({
                othersEmbellishments: stateList,
            });
        }
        this.validateAll("update");
    }

    @Bind()
    private async setLiningsPatternDefaults({
        patternId,
        pathId,
        itemId,
    }: {
        patternId: number;
        pathId: string;
        itemId?: number;
    }) {
        const { liningsPatternItems } = this.state;
        const { dispatch, accountId } = this.props;

        if (!patternId || !liningsPatternItems || !liningsPatternItems.length) {
            return;
        }

        const selectedPattern = liningsPatternItems.find(
            (f) => f.id === patternId
        );

        if (!selectedPattern) {
            return;
        }

        const { isSpecialtyFabricHandlingOn, fabricWidthInches, supplierId } =
            selectedPattern;

        const res = await dispatch(
            SuppliersModule.searchSuppliers(accountId, "", "asc", "asc")
        );
        const suppliers: Supplier[] = res.error ? [] : res.payload;

        let stateLiningsList = [...this.state.liningsList];
        const index = +pathId.split(".")[1];

        // let orderOriginNum = -1;

        // if (
        //     this.state &&
        //     this.state.workroomSetting &&
        //     this.state.workroomSetting.fabricPurchaser === "Workroom"
        // ) {
        //     orderOriginNum = 1;
        // } else if (
        //     this.state &&
        //     this.state.workroomSetting &&
        //     this.state.workroomSetting.fabricPurchaser === "Installer"
        // ) {
        //     orderOriginNum = 2;
        // }

        let selectedLiningFabric = stateLiningsList[index];
        if (!selectedLiningFabric) {
            return;
        }
        let draperyOrderLiningsFabric: any = {
            ...selectedLiningFabric.draperyOrderLiningsFabric,
            isSpecialtyFabricHandling: isSpecialtyFabricHandlingOn,
            values: {
                fabricWidth: fabricWidthInches,
            },
            itemId,
        };

        if (suppliers && suppliers.length) {
            const liningPatternSupplier = suppliers.find(
                (s) => s.id === supplierId
            );
            if (liningPatternSupplier) {
                const { id, displayName } = liningPatternSupplier;
                draperyOrderLiningsFabric.supplierId = id;
                draperyOrderLiningsFabric.supplierName = displayName;
            }
        }
        stateLiningsList[index].draperyOrderLiningsFabric =
            draperyOrderLiningsFabric;

        this.updateStateByName('liningsList', stateLiningsList)
        // this.setState({
        //     liningsList: stateLiningsList,
        // });
    
    }

    @Bind()
    private handleSearchSelectClear(
        pathId: string,
        pathName: string,
        key?: number
    ) {
        this.updateStateByName(pathId, 0);
        this.updateStateByName(pathName, "");
        if (pathId.includes("mainFabric.patternId")) {
            this.setState((state) => ({
                ...state,
                mainFabric: {
                    ...state.mainFabric,
                    isPleatToPattern: false,
                    isDropRepeat: false,
                    imageId: "",
                    isSpecialtyFabricHandling: false,
                    supplierName: "",
                    values: {
                        fabricWidth: undefined,
                        horizontalRepeat: undefined,
                        verticalRepeat: undefined,
                    },
                },
            }));
        }

        if (pathName.includes("trims")) {
            const newTrimList = this.state.trims.map(
                (trims: DraperyOrderDesignEmbellishment, idx) => {
                    if (
                        pathName.includes(
                            `trims.${idx}.draperyOrderFabric.pattern`
                        ) &&
                        trims &&
                        trims.draperyOrderFabric
                    ) {
                        return {
                            ...trims,
                            draperyOrderFabric: {
                                ...trims.draperyOrderFabric,
                                isSpecialtyFabricHandling: false,
                                isDropRepeat: false,
                                supplierName: "",
                                imageId: "",
                                values: {
                                    fabricWidth: undefined,
                                    verticalRepeat: undefined,
                                    horizontalRepeat: undefined,
                                    trimWidth: undefined,
                                },
                                pattern: "",
                            },
                        };
                    }
                    return trims;
                }
            );

            this.setState((state) => ({
                ...state,
                trims: newTrimList,
            }));
        }
        if (pathName.includes("bandings")) {
            const newTrimList = this.state.bandings.map(
                (bandings: DraperyOrderDesignEmbellishment, idx) => {
                    if (
                        pathName.includes(
                            `bandings.${idx}.draperyOrderFabric.pattern`
                        ) &&
                        bandings &&
                        bandings.draperyOrderFabric
                    ) {
                        return {
                            ...bandings,
                            draperyOrderFabric: {
                                ...bandings.draperyOrderFabric,
                                supplierName: "",
                                isSpecialtyFabricHandling: false,
                                isDropRepeat: false,
                                imageId: "",
                                // orderOrigin: -1,
                                values: {
                                    fabricWidth: undefined,
                                    verticalRepeat: undefined,
                                    horizontalRepeat: undefined,
                                },
                                pattern: "",
                            },
                        };
                    }
                    return bandings;
                }
            );

            this.setState((state) => ({
                ...state,
                bandings: newTrimList,
            }));
        }
        if (pathName.includes("cordings")) {
            const newTrimList = this.state.cordings.map(
                (cordings: DraperyOrderDesignEmbellishment, idx) => {
                    if (
                        pathName.includes(
                            `cordings.${idx}.draperyOrderFabric.pattern`
                        ) &&
                        cordings &&
                        cordings.draperyOrderFabric
                    ) {
                        return {
                            ...cordings,
                            draperyOrderFabric: {
                                ...cordings.draperyOrderFabric,
                                isSpecialtyFabricHandling: false,
                                imageId: "",
                                // orderOrigin: -1,
                                supplierName: "",
                                values: {
                                    fabricWidth: undefined,
                                    verticalRepeat: undefined,
                                    horizontalRepeat: undefined,
                                },
                                pattern: "",
                            },
                        };
                    }
                    return cordings;
                }
            );

            this.setState((state) => ({
                ...state,
                cordings: newTrimList,
            }));
        }
        if (pathName.includes("flanges")) {
            const newTrimList = this.state.flanges.map(
                (flanges: DraperyOrderDesignEmbellishment, idx) => {
                    if (
                        pathName.includes(
                            `flanges.${idx}.draperyOrderFabric.pattern`
                        ) &&
                        flanges &&
                        flanges.draperyOrderFabric
                    ) {
                        return {
                            ...flanges,
                            draperyOrderFabric: {
                                ...flanges.draperyOrderFabric,
                                isSpecialtyFabricHandling: false,
                                imageId: "",
                                // orderOrigin: -1,
                                supplierName: "",
                                values: {
                                    fabricWidth: undefined,
                                    verticalRepeat: undefined,
                                    horizontalRepeat: undefined,
                                },
                                pattern: "",
                            },
                        };
                    }
                    return flanges;
                }
            );

            this.setState((state) => ({
                ...state,
                flanges: newTrimList,
            }));
        }
        if (pathName.includes("othersEmbellishments")) {
            const newTrimList = this.state.othersEmbellishments.map(
                (
                    othersEmbellishments: DraperyOrderDesignEmbellishment,
                    idx
                ) => {
                    if (
                        pathName.includes(
                            `othersEmbellishments.${idx}.draperyOrderFabric.pattern`
                        ) &&
                        othersEmbellishments &&
                        othersEmbellishments.draperyOrderFabric
                    ) {
                        return {
                            ...othersEmbellishments,
                            draperyOrderFabric: {
                                ...othersEmbellishments.draperyOrderFabric,
                                isSpecialtyFabricHandling: false,
                                isDropRepeat: false,
                                supplierName: "",
                                imageId: "",
                                // orderOrigin: -1,
                                values: {
                                    fabricWidth: undefined,
                                    verticalRepeat: undefined,
                                    horizontalRepeat: undefined,
                                },
                                pattern: "",
                            },
                        };
                    }
                    return othersEmbellishments;
                }
            );

            this.setState((state) => ({
                ...state,
                othersEmbellishments: newTrimList,
            }));
        }

        if (pathName.includes("liningsList")) {
            const newLiningsList = this.state.liningsList.map(
                (lining: DraperyOrderDesignLining, idx) => {
                    if (
                        pathName.includes(
                            `liningsList.${idx}.draperyOrderLiningsFabric.pattern`
                        ) &&
                        lining &&
                        lining.draperyOrderLiningsFabric
                    ) {
                        return {
                            ...lining,
                            draperyOrderLiningsFabric: {
                                ...lining.draperyOrderLiningsFabric,
                                // orderOrigin: -1,
                                values: { fabricWidth: undefined },
                                isSpecialtyFabricHandling: false,
                                supplierName: "",
                                imageId: "",
                                pattern: "",
                            },
                        };
                    }
                    return lining;
                }
            );

            this.setState((state) => ({
                ...state,
                liningsList: newLiningsList,
            }));
        }

        if (pathId === "draperyOrder.clientId") {
            this.setState((state) => ({
                ...state,
                roomList: [],
                client: undefined,
                clientId: 0,
                draperyOrder: {
                    ...state.draperyOrder,
                    roomId: undefined,
                    roomName: "",
                    customRoom: "",
                },
            }), () => {
                this.validateAll("workOrder");
            });
        }
        if(pathId === "draperyOrder.roomId"){
            this.setState((state) => ({
                ...state,
                draperyOrder: {
                    ...state.draperyOrder,
                    roomId: undefined,
                    roomName: "",
                    customRoom: "",
                },
            }), () => {
                this.validateAll("workOrder");
            });
        }
    }

    @Bind()
    private handleShapeChanged(shapes: DraperyModule.Shape[]): void {
        this.setState({
            draperyOrderDesign: {
                ...this.state.draperyOrderDesign,
                drawingShapeJson: shapes,
            },
        });
    }

    @Bind()
    changeTrimValue(name: string, value: string): void {
        if (name.includes("trimWidth")) {
            const newTrimList = this.state.trims.map((trims: any, idx) => {
                if (
                    name.includes(
                        `trims.${idx}.draperyOrderFabric.values.trimWidth`
                    )
                ) {
                    return {
                        ...trims,
                        draperyOrderFabric: {
                            ...trims.draperyOrderFabric,

                            values: {
                                trimWidth: +value || undefined,
                            },
                        },
                    };
                }
                return trims;
            });
            this.setState((state) => ({
                ...state,
                trims: newTrimList,
            }));
        }
    }

    private handleResetPanelConf = async (
        val: string | number | undefined,
        name: string | undefined
    ) => {
        const { drawingShapeJson } = this.state.draperyOrderDesign;
        const { embellishmentsList } = this.state;
        let isChecked = false;
        for (const embellishment of embellishmentsList) {
            isChecked = isChecked || embellishment.checked;
        }
        let allowed = true;
        if ((drawingShapeJson && drawingShapeJson.length > 0) || isChecked) {
            allowed = await confirmation(
                <ResetPanelConfirmation>
                    Changing the panel configuration will alter the image in the
                    Drawing section below and some entries for Embellishements.
                    &nbsp; Please revisit these sections if previously
                    completed.
                    <span>
                        Are you sure you want to change the panel configuration?
                    </span>
                </ResetPanelConfirmation>,
                {
                    uiHeader: "Confirmation",
                    uiAcceptLabel: "Yes",
                    uiDeclineLabel: "No",
                }
            );
        }

        if (allowed) {
            for (const [
                index,
                embellishmentInfo,
            ] of embellishmentsList.entries()) {
                if (embellishmentInfo.checked) {
                    const { name, list } = this.getEmbellishmentsByIndex(index);

                    for (const [subIndex] of list.entries()) {
                        this.updateStateByName(
                            `${name}.${subIndex}.totalStrips`,
                            undefined
                        );
                    }
                }
            }
            this.updateStateByName("draperyOrderDesign.numOfPanels", 1);
            this.updateStateByName(
                "draperyOrderDesign.panelJson",
                defaultWidthEachPanelList()
            );
            if (val === 1) {
                this.updateStateByName("draperyOrderDesign.panelType", "");
            }
        } else {
            if (val === 1) {
                this.updateStateByName("draperyOrderDesign.panelType", name);
            } else {
                this.updateStateByName("draperyOrderDesign.panelType", val);
            }
        }
    };

    private handleResetPleatType = (): void => {
        this.updateStateByName("draperyOrderDesign.pleatType", "");
        this.updateStateByName(
            "draperyOrderDesign.otherPleatImageId",
            undefined
        );
    };

    private handleAddNewLining = () => {
        const name = "Other interlining";
        const defaultLiningsName = this.generateDefaultFabricsLiningName({
            liningName: name,
        });
        // `Interlining Fabric ${this.state.liningsList.length}`;
        const newLining: DraperyOrderDesignLining = {
            id: 0,
            name,
            active: true,
            description: "",
            draperyOrderDesignId: null,
            draperyOrderLiningsFabric: defaultLiningsFabric(
                defaultLiningsName,
                this.state.orderOriginStateLining
            ),
        };
        const updateList = [...this.state.liningsList, newLining];
        this.setState({
            liningsList: updateList,
        });
    };

    private handleAddEmbellishment = (targetArray: string) => {
        const newEmbellishment = defaultEmbellishment(targetArray);
        this.setState(
            (state) => ({
                ...state,
                [targetArray]: [...state[targetArray], newEmbellishment],
            }),
            () => {
                this.validateAll("update");
            }
        );
    };

    private deleteEmbellishments = (index: number) => {
        const { name, list } = this.getEmbellishmentsByIndex(index);

        const removedIds: number[] = [];
        for (const item of list) {
            if (item.id) {
                removedIds.push(item.id);
            }
        }

        const newEmbellishment = defaultEmbellishment(name);
        this.setState((state) => ({
            ...state,
            [name]: [newEmbellishment!],
            removedEmbellishmentIds: [
                ...state.removedEmbellishmentIds,
                ...removedIds,
            ],
        }));
    };

    private handleDeleteEmbellishment = (
        targetArray: string,
        index: number
    ): void => {
        const list: DraperyOrderDesignEmbellishment[] = this.state[targetArray];
        const removedItem: DraperyOrderDesignEmbellishment = list.splice(
            index,
            1
        )[0];

        if (list.length === 0) {
            const newEmbellishment = defaultEmbellishment(targetArray);
            list.push(newEmbellishment!);
            this.state.embellishmentsList.filter(
                (em) => em.stateName === targetArray
            )[0].checked = false;
        }

        this.setState(
            (state) => ({
                ...state,
                [targetArray]: [...list],
                removedEmbellishmentIds: [
                    ...this.state.removedEmbellishmentIds,
                    removedItem.id,
                ],
            }),
            () => {
                this.validateAll("update");
            }
        );
    };

    private handleToggleCustomizeConstruction = () => {
        this.setState(
            {
                isCustomizeConstructionOpen:
                    !this.state.isCustomizeConstructionOpen,
            },
            () => {
                const e = document.getElementById("customize-construction-div");
                if (e) {
                    e.scrollIntoView();
                }
            }
        );
    };

    private handleOpenClientModalClick = (pathId: string, pathName: string) => {
        this.setState(
            {
                isAddClientModalOpen: true,
                pathIdForModal: pathId,
                pathNameForModal: pathName,
            },
            () => {
                if (this.setFocusFirstFieldOnClientModal) {
                    this.setFocusFirstFieldOnClientModal();
                }
            }
        );
    };

    private handleOpenSupplierModalClick = (
        pathId: string,
        pathName: string
    ) => {
        this.setState(
            {
                isAddSupplierModalOpen: true,
                pathIdForModal: pathId,
                pathNameForModal: pathName,
            },
            () => {
                if (this.setFocusFirstFieldOnSupplierModal) {
                    this.setFocusFirstFieldOnSupplierModal();
                }
            }
        );
    };

    private handleOpenPatternModalClick = (
        pathId: string,
        pathName: string
    ) => {
        const fabricPatternModalCategoryId: number = pathId.includes(
            "liningsList"
        )
            ? CategoryIds.Linings
            : CategoryIds.FabricsAndTrims;
        this.setState({
            isAddFabricsPatternModalOpen: true,
            pathIdForModal: pathId,
            pathNameForModal: pathName,
            fabricPatternModalCategoryId,
        });
    };

    private handleOpenEditPatternModal = (
        pathName: string,
        itemId?: number | null
    ) => {
        this.setState({
            isEditFabricsPatternModalOpen: true,
            pathNameForModal: pathName,
            itemId,
            // pathIdForModal: pathId,
            // pathNameForModal: pathName,
            // fabricPatternModalCategoryId,
        });
    };

    private handleCloseFabricsPatternModal = () => {
        this.setState({
            isAddFabricsPatternModalOpen: false,
            pathIdForModal: "",
            pathNameForModal: "",
            fabricPatternModalCategoryId: 0,
        });
    };

    private handleCloseEditFabricsPatternModal = async () => {
        this.setState({
            isEditFabricsPatternModalOpen: false,
            pathIdForModal: "",
            pathNameForModal: "",
            fabricPatternModalCategoryId: 0,
        });
    };

    @Bind()
    private async updateLinings(payload: any): Promise<void> {
        const {
            isSpecialtyFabricHandlingOn,
            fabricWidthInches,
            verticalRepeatInches,
            horizontalRepeatInches,
            isDropRepeatOn,
            directionOfUseTypeId,
            isPleatToPatternOn,
            isMatchRepeatAtSeamsOn,
            supplierId,
            name,
            avatarId,
        } = payload;
        await this.getSuppliers();
        const { suppliers } = this.state;

        const liningPatternSupplier = suppliers.find(
            (s) => s.id === supplierId
        );
        const liningsList = [...this.state.liningsList];

        for (let i = 0; i < liningsList.length; i++) {
            const { draperyOrderLiningsFabric } = liningsList[i];
            if (
                draperyOrderLiningsFabric &&
                draperyOrderLiningsFabric.pattern === this.state.itemId
            ) {
                liningsList[i].draperyOrderLiningsFabric = {
                    ...draperyOrderLiningsFabric,
                    pattern: name,
                    isMatchRepeatAtSeams: isMatchRepeatAtSeamsOn,
                    isSpecialtyFabricHandling: isSpecialtyFabricHandlingOn,
                    isDropRepeat: isDropRepeatOn,
                    isPleatToPattern: isPleatToPatternOn,
                    directionOfUse: directionOfUseTypeId,
                    values: {
                        fabricWidth:
                            fabricWidthInches !== null
                                ? fabricWidthInches
                                : undefined,
                        verticalRepeat:
                            verticalRepeatInches !== null
                                ? verticalRepeatInches
                                : undefined,
                        horizontalRepeat:
                            horizontalRepeatInches !== null
                                ? horizontalRepeatInches
                                : undefined,
                    },
                    supplierId,
                    supplierName: liningPatternSupplier
                        ? liningPatternSupplier.displayName
                        : "",
                    imageId: avatarId,
                };
            }
        }
        this.state.liningsList.forEach((item, index) => {
            const { draperyOrderLiningsFabric } = item;
            if (
                draperyOrderLiningsFabric &&
                draperyOrderLiningsFabric.pattern === this.state.itemId
            ) {
                if (
                    supplierId &&
                    this.state.highlightedSection ===
                        `liningsList.${index}.draperyOrderLiningsFabric.supplierId`
                )
                    this.clearHighlightSection(
                        `liningsList.${index}.draperyOrderLiningsFabric.supplierId`
                    );
                if (
                    fabricWidthInches &&
                    this.state.highlightedSection ===
                        `liningsList.${index}.draperyOrderLiningsFabric.values.fabricWidth`
                )
                    this.clearHighlightSection(
                        `liningsList.${index}.draperyOrderLiningsFabric.values.fabricWidth`
                    );
                if (
                    verticalRepeatInches &&
                    this.state.highlightedSection ===
                        `liningsList.${index}.draperyOrderLiningsFabric.values.verticalRepeat`
                )
                    this.clearHighlightSection(
                        `liningsList.${index}.draperyOrderLiningsFabric.values.verticalRepeat`
                    );
                if (
                    horizontalRepeatInches &&
                    this.state.highlightedSection ===
                        `liningsList.${index}.draperyOrderLiningsFabric.values.horizontalRepeat`
                )
                    this.clearHighlightSection(
                        `liningsList.${index}.draperyOrderLiningsFabric.values.horizontalRepeat`
                    );
            }
        });
        this.setState((state) => ({
            ...state,
            liningsList,
        }));
        this.validateAll("save");
        if(this.validateAll('workOrder')){
            this.setState((state) => ({
                ...state,
                isSidebarHidden: true,
            }))
        }
    }

    @Bind()
    private async updateFabrics(payload: any): Promise<void> {
        const {
            isSpecialtyFabricHandlingOn,
            fabricWidthInches,
            verticalRepeatInches,
            horizontalRepeatInches,
            isDropRepeatOn,
            directionOfUseTypeId,
            isPleatToPatternOn,
            isMatchRepeatAtSeamsOn,
            supplierId,
            name,
            avatarId,
        } = payload;

        await this.getSuppliers();
        const { suppliers, pathNameForModal } = this.state;

        const fabricPatternSupplier = suppliers.find(
            (s) => s.id === supplierId
        );
        const embellishmentsList = [
            ...this.state.bandings,
            ...this.state.cordings,
            ...this.state.trims,
            ...this.state.flanges,
            ...this.state.othersEmbellishments,
        ].filter((item) => item.hasOwnProperty("draperyOrderFabric"));
        const updateObj: {
            mainFabric: any;
            bandings: DraperyOrderDesignEmbellishment[];
            cordings: DraperyOrderDesignEmbellishment[];
            trims: DraperyOrderDesignEmbellishment[];
            flanges: DraperyOrderDesignEmbellishment[];
            othersEmbellishments: DraperyOrderDesignEmbellishment[];
        } = {
            mainFabric: { ...this.state.mainFabric },
            bandings: [...this.state.bandings],
            cordings: [...this.state.cordings],
            trims: [...this.state.trims],
            flanges: [...this.state.flanges],
            othersEmbellishments: [...this.state.othersEmbellishments],
        };

        for (let i = 0; i < embellishmentsList.length; i++) {
            const { draperyOrderFabric } = embellishmentsList[i];
            if (
                draperyOrderFabric &&
                draperyOrderFabric.pattern === this.state.itemId
            ) {
                embellishmentsList[i].draperyOrderFabric = {
                    ...draperyOrderFabric,
                    pattern: name,
                    isMatchRepeatAtSeams: isMatchRepeatAtSeamsOn,
                    isSpecialtyFabricHandling: isSpecialtyFabricHandlingOn,
                    isDropRepeat: isDropRepeatOn,
                    isPleatToPattern: isPleatToPatternOn,
                    directionOfUse: directionOfUseTypeId,
                    values: {
                        fabricWidth:
                            fabricWidthInches !== null
                                ? fabricWidthInches
                                : undefined,
                        verticalRepeat:
                            verticalRepeatInches !== null
                                ? verticalRepeatInches
                                : undefined,
                        horizontalRepeat:
                            horizontalRepeatInches !== null
                                ? horizontalRepeatInches
                                : undefined,
                    },
                    supplierId,
                    supplierName: fabricPatternSupplier
                        ? fabricPatternSupplier.displayName
                        : "",
                    imageId: avatarId,
                };
            }
        }
        this.state.bandings.forEach((item, index) => {
            const { draperyOrderFabric } = item;
            if (
                draperyOrderFabric &&
                draperyOrderFabric.pattern === this.state.itemId
            ) {
                if (
                    supplierId &&
                    this.state.highlightedSection ===
                        `bandings.${index}.draperyOrderFabric.supplierId`
                )
                    this.clearHighlightSection(this.state.highlightedSection);
                if (
                    fabricWidthInches &&
                    this.state.highlightedSection ===
                        `bandings.${index}.draperyOrderFabric.values.fabricWidth`
                )
                    this.clearHighlightSection(this.state.highlightedSection);
                if (
                    verticalRepeatInches &&
                    this.state.highlightedSection ===
                        `bandings.${index}.draperyOrderFabric.values.verticalRepeat`
                )
                    this.clearHighlightSection(this.state.highlightedSection);
                if (
                    horizontalRepeatInches &&
                    this.state.highlightedSection ===
                        `bandings.${index}.draperyOrderFabric.values.horizontalRepeat`
                )
                    this.clearHighlightSection(this.state.highlightedSection);
            }
        });

        this.state.trims.forEach((item, index) => {
            const { draperyOrderFabric } = item;

            if (
                draperyOrderFabric &&
                draperyOrderFabric.pattern === this.state.itemId
            ) {
                if (
                    supplierId &&
                    this.state.highlightedSection ===
                        `trims.${index}.draperyOrderFabric.supplierId`
                )
                    this.clearHighlightSection(this.state.highlightedSection);
                if (
                    fabricWidthInches &&
                    this.state.highlightedSection ===
                        `trims.${index}.draperyOrderFabric.values.${
                            draperyOrderFabric.type === 7
                                ? "fabricWidth"
                                : "trimWidth"
                        }`
                )
                    this.clearHighlightSection(this.state.highlightedSection);
                if (
                    verticalRepeatInches &&
                    this.state.highlightedSection ===
                        `trims.${index}.draperyOrderFabric.values.verticalRepeat`
                )
                    this.clearHighlightSection(this.state.highlightedSection);
                if (
                    horizontalRepeatInches &&
                    this.state.highlightedSection ===
                        `trims.${index}.draperyOrderFabric.values.horizontalRepeat`
                )
                    this.clearHighlightSection(this.state.highlightedSection);
            }
        });

        this.state.cordings.forEach((item, index) => {
            const { draperyOrderFabric } = item;
            if (
                draperyOrderFabric &&
                draperyOrderFabric.pattern === this.state.itemId
            ) {
                if (
                    supplierId &&
                    this.state.highlightedSection ===
                        `cordings.${index}.draperyOrderFabric.supplierId`
                )
                    this.clearHighlightSection(this.state.highlightedSection);
                if (
                    fabricWidthInches &&
                    this.state.highlightedSection ===
                        `cordings.${index}.draperyOrderFabric.values.fabricWidth`
                )
                    this.clearHighlightSection(this.state.highlightedSection);
                if (
                    verticalRepeatInches &&
                    this.state.highlightedSection ===
                        `cordings.${index}.draperyOrderFabric.values.verticalRepeat`
                )
                    this.clearHighlightSection(this.state.highlightedSection);
                if (
                    horizontalRepeatInches &&
                    this.state.highlightedSection ===
                        `cordings.${index}.draperyOrderFabric.values.horizontalRepeat`
                )
                    this.clearHighlightSection(this.state.highlightedSection);
            }
        });

        this.state.flanges.forEach((item, index) => {
            const { draperyOrderFabric } = item;
            if (
                draperyOrderFabric &&
                draperyOrderFabric.pattern === this.state.itemId
            ) {
                if (
                    supplierId &&
                    this.state.highlightedSection ===
                        `flanges.${index}.draperyOrderFabric.supplierId`
                )
                    this.clearHighlightSection(this.state.highlightedSection);
                if (
                    fabricWidthInches &&
                    this.state.highlightedSection ===
                        `flanges.${index}.draperyOrderFabric.values.fabricWidth`
                )
                    this.clearHighlightSection(this.state.highlightedSection);
                if (
                    verticalRepeatInches &&
                    this.state.highlightedSection ===
                        `flanges.${index}.draperyOrderFabric.values.verticalRepeat`
                )
                    this.clearHighlightSection(this.state.highlightedSection);
                if (
                    horizontalRepeatInches &&
                    this.state.highlightedSection ===
                        `flanges.${index}.draperyOrderFabric.values.horizontalRepeat`
                )
                    this.clearHighlightSection(this.state.highlightedSection);
            }
        });

        this.state.othersEmbellishments.forEach((item, index) => {
            const { draperyOrderFabric } = item;
            if (
                draperyOrderFabric &&
                draperyOrderFabric.pattern === this.state.itemId
            ) {
                if (
                    supplierId &&
                    this.state.highlightedSection ===
                        `othersEmbellishments.${index}.draperyOrderFabric.supplierId`
                )
                    this.clearHighlightSection(this.state.highlightedSection);
                if (
                    fabricWidthInches &&
                    this.state.highlightedSection ===
                        `othersEmbellishments.${index}.draperyOrderFabric.values.fabricWidth`
                )
                    this.clearHighlightSection(this.state.highlightedSection);
                if (
                    verticalRepeatInches &&
                    this.state.highlightedSection ===
                        `othersEmbellishments.${index}.draperyOrderFabric.values.verticalRepeat`
                )
                    this.clearHighlightSection(this.state.highlightedSection);
                if (
                    horizontalRepeatInches &&
                    this.state.highlightedSection ===
                        `othersEmbellishments.${index}.draperyOrderFabric.values.horizontalRepeat`
                )
                    this.clearHighlightSection(this.state.highlightedSection);
            }
        });

        if (this.state.mainFabric.pattern === this.state.itemId) {
            updateObj.mainFabric = {
                ...this.state.mainFabric,
                pattern: name,
                supplierId,
                supplierName: fabricPatternSupplier
                    ? fabricPatternSupplier.displayName
                    : "",
                directionOfUse: directionOfUseTypeId,
                isPleatToPattern: isPleatToPatternOn,
                isMatchRepeatAtSeams: isMatchRepeatAtSeamsOn,
                values: {
                    fabricWidth:
                        fabricWidthInches !== null
                            ? fabricWidthInches
                            : undefined,
                    verticalRepeat:
                        verticalRepeatInches !== null
                            ? verticalRepeatInches
                            : undefined,
                    horizontalRepeat:
                        horizontalRepeatInches !== null
                            ? horizontalRepeatInches
                            : undefined,
                },
                isSpecialtyFabricHandling: isSpecialtyFabricHandlingOn,
                isDropRepeat: isDropRepeatOn,
                imageId: avatarId,
            };
            if (
                supplierId &&
                this.state.highlightedSection ===
                    `${pathNameForModal}.supplierId`
            )
                this.clearHighlightSection(this.state.highlightedSection);
            if (
                fabricWidthInches &&
                this.state.highlightedSection ===
                    `${pathNameForModal}.values.fabricWidth`
            )
                this.clearHighlightSection(this.state.highlightedSection);
            if (
                verticalRepeatInches &&
                this.state.highlightedSection ===
                    `${pathNameForModal}.values.verticalRepeat`
            )
                this.clearHighlightSection(this.state.highlightedSection);
            if (
                horizontalRepeatInches &&
                this.state.highlightedSection ===
                    `${pathNameForModal}.values.horizontalRepeat`
            )
                this.clearHighlightSection(this.state.highlightedSection);
        }

        this.setState((state) => ({
            ...state,
            ...updateObj,
        }));
        this.validateAll("save");
        if(this.validateAll('workOrder')){
            this.setState((state) => ({
                ...state,
                isSidebarHidden: true,
            }))
        }
    }

    private handleCloseClientModal = () => {
        this.setState({
            isAddClientModalOpen: false,
            pathIdForModal: "",
            pathNameForModal: "",
        });
    };

    private handleCloseSupplierModal = () => {
        this.setState({
            isAddSupplierModalOpen: false,
            pathIdForModal: "",
            pathNameForModal: "",
        });
    };

    private handleClientChanged = (client: ClientModule.Client) => {
        this.updateStateByName(this.state.pathIdForModal, client.id);
        this.updateStateByName(this.state.pathNameForModal, client.displayName);
    };

    private handleSupplierChanged = async (
        supplier: SuppliersModule.Supplier
    ) => {
        const { dispatch, accountId } = this.props;
        const res = await dispatch(
            SuppliersModule.addSupplier({
                ...supplier,
                accountId,
            })
        );
        if (res.error) {
            // if (res.payload.message.includes("DisplayName")) {
            //     throw new Error("Display name already in use");
            // } else {
            // throw new Error(res.payload.message);
            // }
            if (res.payload.name === "ValidationError") {
                const errors = (res.payload as Error as ValidationError).errors;
                if (errors.displayName) {
                    errors.displayName = errors.displayName.replace(
                        "DisplayName",
                        "Display Name"
                    );
                }
                throw FormValidationError.fromErrorDictionary(errors);
            } else {
                throw new Error(res.payload.message);
            }
        }
        const supplierId = res.payload.id;
        const supplierDisplayName = res.payload.displayName;
        this.updateStateByName(this.state.pathIdForModal, supplierId);
        this.updateStateByName(
            this.state.pathNameForModal,
            supplierDisplayName
        );
        await this.getSuppliers();
        this.setState({
            supplierName: supplierDisplayName,
            supplierId: supplierId,
        });
    };

    private handleSearchClient: SearchSelectProps["onSearch"] = async (
        search: string
    ) => {
        const { dispatch, accountId } = this.props;
        const res = await dispatch(
            ClientModule.searchClient(search, accountId)
        );
        if (res.error) {
            return [];
        } else if (res.payload.length === 0) {
            this.setState({ clientId: null });
        }
        return res.payload.map((r) => ({
            id: r.id,
            displayName:
                (r.parent ? r.parent.displayName + " | " : "") + r.displayName,
        }));
    };

    private handleSearchRoom: SearchSelectProps["onSearch"] = async (
        search: string
    ) => {
        const { dispatch } = this.props;
        const { clientId } = this.state.draperyOrder;
        this.clearHighlightSection("draperyOrder.roomId");

        const res = await dispatch(
            RoomModule.getRooms(clientId, "asc", search)
        );
        if (res.error) {
            return [];
        }
        return res.payload.map((r) => ({
            id: r.id,
            displayName: r.name,
        }));
    };

    private handleSearchSupplier: SearchSelectProps["onSearch"] = async (
        search: string
    ) => {
        const { dispatch, accountId } = this.props;
        const res = await dispatch(
            SuppliersModule.searchSuppliers(accountId, search, "asc", "asc")
        );
        if (res.error) {
            return [];
        }
        const filterRes = res.payload.filter((supp) => supp.active);
        return filterRes.map((s) => ({
            id: s.id,
            displayName: s.displayName,
            tags: s.tags,
        }));
    };

    private handleSearchPattern = ({
        search,
        list,
    }: {
        search: string;
        list: FabricPatternItem[];
    }) => {
        if (!list || !list.length) {
            return [];
        }
        if (search && search.length) {
            list = list.filter((r) => {
                const {
                    name,
                    itemNumber,
                    salesDescription,
                    orderingDescription,
                } = r;

                if (
                    (name &&
                        name.toLowerCase().includes(search.toLowerCase())) ||
                    (itemNumber && itemNumber.includes(search)) ||
                    (salesDescription &&
                        salesDescription
                            .toLowerCase()
                            .includes(search.toLowerCase())) ||
                    (orderingDescription &&
                        orderingDescription
                            .toLowerCase()
                            .includes(search.toLowerCase()))
                ) {
                    return true;
                }
            });
        }
        return list.map((l) => ({
            id: l.id!,
            displayName: l.name!,
            itemId: l.itemId!,
        }));
    };

    private handleSearchFabricsPattern: SearchSelectProps["onSearch"] = async (
        search: string
    ) => {
        let res = this.state.fabricsPatternItems;
        if (!res) return [];
        const activeItems = res.filter((item) => item.isActive === true);
        return this.handleSearchPattern({ search, list: activeItems });
    };

    private handleSearchFabricsLiningPattern: SearchSelectProps["onSearch"] =
        async (search: string) => {
            let res = this.state.liningsPatternItems;
            if (!res) return [];
            const activeItems = res.filter((item) => item.isActive === true);
            return this.handleSearchPattern({ search, list: activeItems });
        };

    private handleAddSupplierByName: SearchSelectProps["onAddByName"] = async (
        name: string
    ) => {
        // const { dispatch, accountId } = this.props;
        // const res = await dispatch(SuppliersModule.addSupplierByName(accountId, name));
        // if (res.error) {
        return false;
        // }

        // return true;
    };

    private handleAddRoom = async (value: string, reffererModal?: string) => {
        if (!this.state.draperyOrder.clientId) {
            return;
        }

        const { dispatch, accountId } = this.props;
        const res = await dispatch(
            RoomModule.addRoom({
                accountId,
                name: value,
                clientId: this.state.draperyOrder.clientId,
            })
        );

        if (res.error) {
            this.setState({ addRoomError: res.payload.message });
            return [];
        } else {
            this.setState({ addRoomError: "" });
        }
        const room: RoomModule.Room = res.payload;
        const roomList = [...this.state.roomList];
        roomList.push({
            key: room.id,
            label: room.name,
        });
        this.setState({
            roomList,
            draperyOrder: {
                ...this.state.draperyOrder,
                roomId: room.id,
                roomName: room.name,
            },
        });
    };

    private handleOpenImageModal = (
        name: string,
        option: "add" | "delete"
    ): void => {
        if (option === "add") {
            this.setState({
                imageModalOpen: true,
                imageModalRelatedName: name,
            });
        } else {
            this.updateStateByName(name, "");
        }
    };

    private handleCloseImageModal = () => {
        this.setState({
            imageModalOpen: false,
            imageModalRelatedName: "",
        });
    };

    private handleSaveImage = async (file: File) => {
        const { dispatch, accountId } = this.props;
        const res = await dispatch(AccountModule.uploadImage(accountId, file));
        if (res.error) {
            throw new Error(res.payload.message);
        }

        if (
            this.state.imageModalRelatedName ===
            "draperyOrderDesign.otherPleatImageId"
        ) {
            this.state.draperyOrderDesign.pleatType = "OTHER";
        }
        this.updateStateByName(
            this.state.imageModalRelatedName,
            res.payload.url
        );
        this.setState((state) => ({
            ...state,
            imageModalOpen: false,
            imageModalRelatedName: "",
        }));
    };

    private toggleCancelModal = async () => {
        const { history } = this.props;
        if (this.state && this.state.client && this.state.client.id) {
            history.push(
                `/clients/${
                    this.state &&
                    this.state.client &&
                    this.state.client.id === 0
                        ? ""
                        : this.state &&
                          this.state.client &&
                          this.state.client.id
                }`
            );
        } else {
            history.goBack();
        }
    };

    private toggleErrorMessage = () => {
        this.findFirstErrorsCalled = false;
        this.setState((state) => ({
            ...state,
            isSidebarHidden: false,
            isErrorMessageOpen: !state.isErrorMessageOpen,
            isError: false,
        }));
    };

    private toggleLoadingModal = () => {
        this.setState({ isLoadingOpen: !this.state.isLoadingOpen });
    };

    private handleKeyPressForm = (e: KeyboardEvent<HTMLElement>) => {
        if (
            e.which === 13 &&
            (e.target as HTMLTextAreaElement).name !==
                "draperyOrderDesign.notes" &&
            (e.target as HTMLTextAreaElement).name !==
                "othersEmbellishments.details"
        ) {
            e.preventDefault();
            return;
        }
    };

    private toggleSidebar = () => {
        this.setState({
            isSidebarHidden: !this.state.isSidebarHidden,
        });
    };

    private navigateToField = (name: string | string[]) => {
        if (Array.isArray(name)) {
            const e = ["Trade", "Retail"].some((item) => name.includes(item))
                ? document.getElementById("Trade")
                : document.getElementsByName(name[0])[0];
            if (e) {
                e.scrollIntoView({ behavior: "smooth", block: "center" });
                setTimeout(() =>
                    name.map((n) => this.highlightSection(n), 200)
                ); // Remove the highlight after 1 second
            }
        } else {
            const e = document.getElementsByName(name)[0];
            if (name === "mainFabric.values.fabricWidth") {
                if (
                    this.state.draperyOrderFabricsConfirmations &&
                    this.state.draperyOrderFabricsConfirmations.length > 0 &&
                    this.state.mainFabric.directionOfUse === 1 &&
                    !this.state.checkedAllowExtraWidthOne &&
                    e
                ) {
                    e.scrollIntoView({ behavior: "smooth", block: "center" });
                    setTimeout(() => this.highlightSection(name), 100);
                } else if (
                    this.state.draperyOrderFabricsConfirmations &&
                    this.state.draperyOrderFabricsConfirmations.length > 0 &&
                    this.state.mainFabric.directionOfUse === 2 &&
                    !this.state.checkedAllowExtraWidthTwo &&
                    e
                ) {
                    e.scrollIntoView({ behavior: "smooth", block: "center" });
                    setTimeout(() => this.highlightSection(name), 100);
                } else if (
                    this.state.draperyOrderFabricsConfirmations &&
                    this.state.draperyOrderFabricsConfirmations.length === 0 &&
                    e
                ) {
                    e.scrollIntoView({ behavior: "smooth", block: "center" });
                    setTimeout(() => this.highlightSection(name), 100);
                }
            } else if (e) {
                e.scrollIntoView({ behavior: "smooth", block: "center" });
                setTimeout(() => this.highlightSection(name), 200);
            }
        }
    };

    @Bind()
    private openItemModal(
        item: MyShopItem | null = null,
        tabIndex: number,
        name: string | string[]
    ) {
        if (item) {
            const { itemId } = item;
            this.setState(
                {
                    activeModal: ModalName.StandardItem,
                    selectedItem: item,
                    selectedItemId: itemId || null,
                    pricingTabIndex: tabIndex,
                },
                () => {
                    this.navigateToField(name);
                }
            );
        } else {
            this.setState({
                activeModal: ModalName.StandardItem,
                selectedItemId: null,
                selectedItem: {} as MyShopItem | null,
            });
            // this.setState({activeItemClassId: ItemClasses.Standard});
        }
    }

    private async openDraperyModal(
        item: MyShopItem | null,
        tabIndex: number,
        name: string | string[]
    ) {
        if (item) {
            const { itemId } = item;
            this.setState(
                {
                    activeModal: ModalName.DraperyItem,
                    selectedItem: item,
                    selectedItemId: itemId || null,
                    pricingTabIndex: tabIndex,
                },
                () => {
                    this.navigateToField(name);
                }
            );
        } else {
            this.setState({
                activeModal: ModalName.DraperyItem,
                selectedItemId: null,
                selectedItem: {} as MyShopItem | null,
            });
            // this.setState({activeItemClassId: ItemClasses.Standard});
        }
    }

    private navigateValidation = async (
        tabIndex: number,
        name: string | string[],
        itemId?: number,
        itemClassId?: number
    ) => {
        if (Array.isArray(name)) {
            if (
                ["Item Cost", "Item MSRP", "Retail", "Trade"].some((item) =>
                    name.includes(item)
                )
            ) {
                const { fabricsPatternItems, liningsPatternItems } = this.state;
                let selectedPattern;
                let itemsArr: any[] = [];
                if (fabricsPatternItems) {
                    itemsArr = [...fabricsPatternItems];
                }
                if (liningsPatternItems) {
                    itemsArr = [...itemsArr, ...liningsPatternItems];
                }
                selectedPattern = itemsArr
                    ? itemsArr.find((item) => item.id === itemId)
                    : undefined;

                if (selectedPattern) {
                    this.openItemModal(
                        selectedPattern as MyShopItem,
                        tabIndex,
                        name
                    );
                }
            } else if (name[0] === "draperyItemPricing") {
                await getAll().then((res) => {
                    const draperyItem = res.filter(
                        (item) => item.name === "Drapery"
                    );
                    this.openDraperyModal(draperyItem[0], tabIndex, name);
                });
            } else if (name[0] === "billingType") {
                this.setState(
                    {
                        tabIndex,
                        isEditClientModalOpen: true,
                    },
                    () => {
                        this.navigateToField(name);
                    }
                );
            }
        } else if (tabIndex === -1) {
            this.navigateToField(name);
        } else if (tabIndex === -2) {
            if (this.state.draperyOrderDesign.draperyType) {
                this.setState(
                    {
                        tabIndex: 1,
                        isCustomizeConstructionOpen: false,
                    },
                    () => {
                        this.navigateToField(name);
                    }
                );
            }
        } else {
            this.setState(
                {
                    tabIndex,
                },
                () => {
                    this.navigateToField(name);
                }
            );
        }
    };
    @Bind()
    private findFirstErrors() {
        if (
            this.state.draperyOrderErrors &&
            this.state.draperyOrderErrors.length > 0
        ) {
            this.navigateValidation(
                this.state.draperyOrderErrors[0].tabIndex,
                this.state.draperyOrderErrors[0].name
            );
            this.setState({ isModalClosed: false });
            return;
        }
        if (
            this.state.draperyOrderDesignErrors &&
            this.state.draperyOrderDesignErrors.length > 0
        ) {
            this.navigateValidation(
                this.state.draperyOrderDesignErrors[0].tabIndex,
                this.state.draperyOrderDesignErrors[0].name
            );
            this.setState({ isModalClosed: false });
            return;
        }
        if (
            this.state.draperyOrderFabricsErrors &&
            this.state.draperyOrderFabricsErrors.length > 0
        ) {
            this.navigateValidation(
                this.state.draperyOrderFabricsErrors[0].tabIndex,
                this.state.draperyOrderFabricsErrors[0].name
            );
            this.setState({ isModalClosed: false });
            return;
        }
        if (
            this.state.draperyOrderFabricsConfirmations &&
            this.state.draperyOrderFabricsConfirmations.length > 0
        ) {
            this.navigateValidation(
                this.state.draperyOrderFabricsConfirmations[0].tabIndex,
                this.state.draperyOrderFabricsConfirmations[0].name
            );
            this.setState({ isModalClosed: false });
            return;
        }
        if (
            this.state.draperyOrderSummaryErrors &&
            this.state.draperyOrderSummaryErrors.length > 0
        ) {
            this.navigateValidation(
                this.state.draperyOrderSummaryErrors[0].tabIndex,
                this.state.draperyOrderSummaryErrors[0].name
            );
            this.setState({ isModalClosed: false });
            return;
        }
    }

    private highlightSection = (name: string) => {
        if (this.state.highlightedSection === name) {
            return;
        }
        const element =
            document.getElementsByName(name)[0] ||
            document.getElementById(name);
        const elementType = element.getAttribute("type");
        if (elementType && ["text", "number"].includes(elementType)) {
            element.focus();
        }
        this.setState({ highlightedSection: name });
    };

    private clearHighlightSection = (name: string) => {
        if (
            name === "isNoneEmbellishment" &&
            this.state.highlightedSection &&
            this.state.highlightedSection.includes("leadReturnEdge")
        ) {
            this.setState({
                highlightedSection: null,
            });
        }
        if (this.state.highlightedSection !== name) {
            return;
        }
        this.setState({
            highlightedSection: null,
        });
    };

    private drawingBlob = async (): Promise<Blob | null> => {
        if (this.designTabRef.current) {
            return this.designTabRef.current.drawingBlob();
        } else {
            return null;
        }
    };

    private showSaveConfirm = async (): Promise<boolean> => {
        return confirmation(<div>Would you like to save your progress?</div>, {
            uiHeader: "Confirmation",
            uiAcceptLabel: "Yes",
            uiDeclineLabel: "No",
        });
    };

    private showEditWorkOrderConfirm = async (): Promise<boolean> => {
        return confirmation(<div>Would you like to cancel your edits?</div>, {
            uiHeader: "Confirmation",
            uiAcceptLabel: "No, continue editing",
            uiDeclineLabel: "Yes, cancel edits",
            workOrderEditMode: this.state.workOrderEditMode,
            cancelEditing: this.cancelEditing,
        });
    };

    private cancelEditing = async () => {
        this.setState((prev) => {
            return {
                ...prev,
                workOrderEditMode: false,
                tabIndex: 3,
                isUpdateChanged: false,
                draperyOrderDesignErrors: [],
                draperyOrderFabricsErrors: [],
                draperyOrderSummaryErrors: [],
                draperyOrderErrors: [],
            };
        });
        await this.getExistingDraperyOrder(true)
        await this.getClient()
        if (this.state.draperyOrder.clientId) {
            await this.getRooms(this.state.draperyOrder.clientId)
        }
        if (this.state.highlightedSection) {
            await this.clearHighlightSection(this.state.highlightedSection)
        }
    };

    private cancelEditingWorkOrder = async () => {
        if (this.state.workOrderEditMode && !this.state.noChanges) {
            await this.showEditWorkOrderConfirm();
        } else {
            this.setState((prev) => {
                return {
                    ...prev,
                    tabIndex: 3,
                    workOrderEditMode: false,
                    isUpdateChanged: false,
                };
            });
            await this.getExistingDraperyOrder(true)
            await this.getClient()
            if (this.state.draperyOrder.clientId) {
                await this.getRooms(this.state.draperyOrder.clientId)
            }
            if (this.state.highlightedSection) {
                await this.clearHighlightSection(this.state.highlightedSection)
            }
        }
    };

    private deepEqual(obj1: any, obj2: any): boolean {
        if (obj1 === obj2) return true;

        if (obj1 == null || obj2 == null) return false;
        
        if (Array.isArray(obj1) && Array.isArray(obj2)) {
            if (obj1.length !== obj2.length) return false;

            for (let i = 0; i < obj1.length; i++) {
                if (!this.deepEqual(obj1[i], obj2[i])) return false;
            }
            return true;
        }

        if (Array.isArray(obj1) !== Array.isArray(obj2)) return false;
        if (typeof obj1 !== 'object' || typeof obj2 !== 'object') return false;

        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) return false;

        for (let key of keys1) {
            if (!keys2.includes(key) || !this.deepEqual(obj1[key], obj2[key])) return false;
        }

        return true;
    };

    private customNavigation = async (location: Location): Promise<void> => {
        if (this.state.workOrderEditMode) {
            if (!this.state.noChanges) {
                await this.showEditWorkOrderConfirm()
            }
        } else {
            if (!this.state.noChanges) {
                const confirmed = await this.showSaveConfirm();
                if (confirmed) {
                    if (!(await this.handleSaveAll(true))) {
                        return;
                    }
                }
            }
        }
        // if (this.state.workOrderEditMode && !this.state.noChanges) {
        //     await this.showEditWorkOrderConfirm()
        // }

        // if (!this.state.workOrderEditMode && !this.state.noChanges) {
        //     const confirmed = await this.showSaveConfirm();
        //     if (confirmed) {
        //         if (!(await this.handleSaveAll(true))) {
        //             return;
        //         }
        //     }
        // }

        this.setState(
            (state) => ({
                ...state,
                navigationConfirmed: true,
            }),
            () => {
                if (location.pathname === "/calculator") {
                    this.props.history.push(location.pathname, {
                        tempKey: Math.random(),
                    });
                } else {
                    this.props.history.push(location.pathname);
                }
            }
        );
    };

    @Bind()
    openSupplierModalaOnWo() {
        this.setState({
            pathNameForModal: "mainFabric.supplierName",
            isModalOpenWo: true,
        });
    }

    @Bind()
    closeSupplierModal() {
        this.setState({
            isModalOpenWo: false,
            pathNameForModal: "mainFabric",
        });
    }

    @Bind()
    resetSupplier() {
        this.setState({
            supplierId: 0,
            supplierName: "",
        });
    }

    @Bind()
    private navigateToClient(id: number) {
        const { history } = this.props;
        history.push(`/clients/${id === 0 ? "" : id}`);
    }
    @Bind()
    private handleBuckramSelect(e: ChangeEvent<HTMLSelectElement>): void {
        const name = e.target.name;
        const value = e.target.value;

        this.clearHighlightSection(name);

        this.updateStateByName(name, value);
    }
    @Bind()
    private handleFullnessSelect(e: ChangeEvent<HTMLSelectElement>): void {
        const name = e.target.name;
        const value = e.target.value;
        e.target.style.fontStyle = "italic";
        this.clearHighlightSection(name);

        if (value !== "0") this.updateStateByName(name, value);
    }
}

/**
 * Return component properties from the redux state
 */
function mapStateToProps(
    state: RootState,
    ownProps: CalculatorOwnProps
): CalculatorProps {
    return {
        ...ownProps,
        accountId: state.account.id,
        calculatorDefaults: state.account.calculatorDefaults,
        handleCloseModal: ownProps.handleCloseModal
    };
}

export const CalculatorContainer = connect(mapStateToProps)(Calculator);
