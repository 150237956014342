import {
    AddSummaryComponentBtn,
    CalculatorFieldInput,
    CalculatorInput,
    CancelBtn,
    DeleteBtn,
    DisableAutocompleteInput,
    DraperyHeader,
    DuplicateBtn,
    EditButton,
    HContainer,
    SummaryComponentTable,
    SummaryComponentTableCell,
    SummaryComponentTableRow,
    SummaryContainer,
    SummaryContent,
    SummaryInfoBlock,
    SummaryTable,
    SummaryTableCell,
    SummaryTableCol,
    SummaryTimeline,
    WorkOrderNumber,
    CalculatorFormField,
} from "./styles";
import EditIcon from '../../assets/icons/edit.svg';
import {
    Loader,
    Select as SelectE,
} from "@ramble/ramble-ui";
import styled from "../../theme";
import { DraperyModule, WorkOrderModule } from "../../redux/modules/index";
import {
    DraperyOrderDesignEmbellishment,
    DraperyOrderFabric,
    EmbellishmentCheckInfo,
} from "./calculator";
import React, { ChangeEvent, PureComponent } from "react";

import SelectList from "../UI/select-list";
import { ValidationErrorInfo } from "./interfaces-common";
import {
    WorkroomSettingModule,
} from "../../redux/modules/index";
import { WorkOrderPdfContent } from "../../redux/modules/work_order";
import { FormFields } from "../../redux/modules/my-shop/form-fields-reducer";
import { TextArea } from "@ramble/ramble-ui";
import { getDraperyOrderPrice } from "../../api/drapery-order-price";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import '../reactDatePicker/ReactDatePicker.css'
import moment from "moment";
import RedWarning from '../../assets/icons/red_warning.svg';

const paidList = ["No", "Partial", "Yes"];
const property = ["W:", "VR:", "HR:"];

const WorkorderMeasure = styled.div`
    display: flex;
    justify-content: center;
    gap: 5px;
    font-size: 1rem;
    &.last-separator:last-of-type p span {
        display: none;
    }
`;

const WorkorderMeasureWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
`;

const WorkOrderNumberNoLink = styled.span`
    padding: 10px;
    color: rgba(27, 28, 29, 0.87);
    font-weight: bold;
`;
const DraftCheckBox = styled.div`
    display: flex;
    align-items: center;
    // accent-color: #fff;
    input[type="checkbox"] {
        margin-right: 5px;
        color: #438cac;
        border: 2px solid #3b97b1;
        height: 15px;
        width: 15px;
        background-color: transparent;
        -webkit-appearance: none;
    }
    input[type="checkbox"]:before {
        content: "";
        display: block;
        transition: transform 200ms;
    }

    input[type="checkbox"]:checked:before {
        width: 9px;
        height: 20px;
        margin: -10px 5px;
        border-bottom: 3px solid #28586f;
        border-right: 3px solid #28586f;
        transform: rotate(45deg);
    }
    span {
        color: #000;
        font-size: 14px;
        font-weight: 400;
    }
`;
const Draft = styled.span`
    color: #e74f77;
    font-size: 18px;
    font-weight: 600;
    font-style: italic;
`;
const WOContainer = styled.div`
    display: flex;
    align-items: center;
`;
const WorkOrderLink = styled.div`
    display: flex;
    gap: 7px;
`;
const TitleNotes = styled.h3`
    text-align: start;
    font-family: 'Poppins',Arial,Helvetica,sans-serif;
    font-weight: 400 !important;
    color: rgb(84, 84, 84);
    font-size:15px;
    margin-bottom:5px;
`
const Select = styled(SelectE)`
    .select {
        border-radius: 5px;
        padding: 2px 30px 2px 2px;
        white-space: pre-line;
    }
`;
export const defaultDraperyOrderSummary =
    (): DraperyModule.DraperyOrderSummary => ({
        id: 0,
        planningJson: {
            isDesign: false,
            isFabrics: false,
            isMeasurements: false,
            isHardware: false,
        },
        estimateJson: {
            num: "",
            description: "",
            dateSent: "",
        },
        invoiceJson: {
            num: "",
            description: "",
            paid: "",
        },
        componentsJson: {
            ordered: "",
            received: "",
        },
        productionJson: {},
        installationJson: {},
        notes: ''
    });

export const defaultDraperyOrderSummaryComponent = (
    item: string
): DraperyModule.DraperyOrderSummaryComponent => ({
    id: 0,
    item,
    draperyOrderSummaryId: 0,
});

// validate
export const validateDraperyOrderSummary = (
    draperyOrderSummary: DraperyModule.DraperyOrderSummary
): ValidationErrorInfo[] => {
    // const prefixName = "draperyOrderSummary.";
    // const tabIndex = 2;
    const errors: ValidationErrorInfo[] = [];
    // if (draperyOrderSummary.invoiceJson.description === undefined) { errors.push({tabIndex, name: prefixName + "invoiceJson.description", message: "Please select invoice description"}); }
    return errors;
};

export const validateDraperyOrderSummaryComponent = (
    draperyOrderSummaryComponent: DraperyModule.DraperyOrderSummaryComponent,
    prefixName: string,
    isFabric?: boolean
): ValidationErrorInfo[] => {
    const tabIndex = 3;
    const errors: ValidationErrorInfo[] = [];
    // if (draperyOrderSummaryComponent.draperyOrderFabricId === 0 && isFabric) {
    //     errors.push({
    //         tabIndex: 2,
    //         name: "",
    //         message: "Please save fabric first",
    //     });
    // }
    if (!draperyOrderSummaryComponent.item) {
        errors.push({
            tabIndex,
            name: prefixName + "item",
            message: "Please input component item",
        });
    }
    return errors;
};

export const validateDraperyItemPricingTab = (
    draperyItemDetail: FormFields[]
): ValidationErrorInfo[] => {
    let errors: ValidationErrorInfo[] = [];
    const draperyItems = draperyItemDetail.filter(
        (item) => item.name === "Drapery"
    );
    const draperyItemDetailsKeys = [
        "pricingTypeId",
        "clientPricingAction",
        "pleatTypesWithPricing",
        "liningAndInterliningWithPricing",
        "cafeCurtainsWidthInches",
        "cafeCurtainsWidthPricing",
        "extraLargePanelsLengthInches",
        "extraLargePanelsLengthPricing",
        "extraLargePanelsWidthInches",
        "extraLargePanelsWidthPricing",
        "draperyPinningWidthPricing",
        "specialityFabricsMarkUpPercentage",
        "embellishmentsWithPricing",
        "addOnsWithPricing",
        "salesDescriptionSettings",
        "orderingDescriptionSettings",
    ];
    if (!draperyItems[0]["isClientPricingActionOn"]) {
        const index = draperyItemDetailsKeys.findIndex(
            (key) => key === "clientPricingValue"
        );
        if (index >= 0) draperyItemDetailsKeys.splice(index, 1);
    }
    if (!draperyItems[0]["isPartialWidthRoundingOn"]) {
        const index = draperyItemDetailsKeys.findIndex(
            (key) => key === "partialWidthRoundUpTypeId"
        );
        if (index >= 0) draperyItemDetailsKeys.splice(index, 1);
    }

    let errorAdded = false; 

    for (const key of draperyItemDetailsKeys) {
        const value = draperyItems[0][key];

        if (!value || (typeof value === 'string' && value.trim() === "")) {
            if (!errorAdded) {
                errors.push({
                    tabIndex: 3,
                    name: ["draperyItemPricing"],
                    message: "Drapery item must be setup in My Shop prior to pricing this order.",
                    itemId: draperyItems[0].itemId,
                    itemClassId: draperyItems[0].itemClassId,
                });
                errorAdded = true;
            }
            break;
        }
        if (Array.isArray(value)) {
            for (let i = 0; i < value.length; i++) {
                if (value[i].price === "") {
                    if (!errorAdded) {
                        errors.push({
                            tabIndex: 3,
                            name: ["draperyItemPricing"],
                            message: "Drapery item must be setup in My Shop prior to pricing this order.",
                            itemId: draperyItems[0].itemId,
                            itemClassId: draperyItems[0].itemClassId,
                        });
                        errorAdded = true;
                    }
                    break; 
                }
            }
        }

        if (errorAdded) {
            break; 
        }
    }
    return errors;
};

export const validateRipplefoldPricing = (
    draperyItemDetail: FormFields[]
): ValidationErrorInfo[] => {
    let errors: ValidationErrorInfo[] = [];
    const draperyItems = draperyItemDetail.filter(
        (item) => item.name === "Drapery"
    );
    const draperyItemDetailsKeys = [
        "pleatTypesWithPricing"
    ];

    if (draperyItems.length > 0) {
    for (const key of draperyItemDetailsKeys) {
        const value = draperyItems[0][key];

        if (Array.isArray(value)) {
            const ripplefoldItem = value.find(item => item.id === "ripplefold" && item.price === "");
            if (ripplefoldItem) {
                errors.push({
                    tabIndex: 3,
                    name: ["draperyItemPricing"],
                    message: "Pricing for Ripplefold header required in My Shop > Drapery Item.",
                    itemId: ripplefoldItem.id,
                    itemClassId: ripplefoldItem.itemClassId || null,
                });
                break;
            }
            }
        }
    } 

    return errors;
};

const getEmbellishmentComps = ({
    list,
}: {
    list: DraperyOrderDesignEmbellishment[];
}) => {
    const result: DraperyModule.DraperyOrderSummaryComponent[] = [];
    if (!list || !list.length) {
        return result;
    }
    list.forEach((item) => {
        const itemDOFabric = item.draperyOrderFabric;
        if (itemDOFabric) {
            result.push(itemDOFabric.draperyOrderSummaryComponent);
        }
    });
    return result;
};

type GetComponentListParams = {
    bandings: DraperyOrderDesignEmbellishment[];
    cordings: DraperyOrderDesignEmbellishment[];
    flanges: DraperyOrderDesignEmbellishment[];
    trims: DraperyOrderDesignEmbellishment[];
    othersEmbellishments: DraperyOrderDesignEmbellishment[];
    mainFabric: DraperyOrderFabric;
    embellishmentsList: EmbellishmentCheckInfo[];
    draperyOrderSummaryComponents: DraperyModule.DraperyOrderSummaryComponent[];
};

export const getComponentList = (compListParams: GetComponentListParams) => {
    const {
        bandings,
        cordings,
        flanges,
        trims,
        othersEmbellishments,
        mainFabric,
        embellishmentsList,
        draperyOrderSummaryComponents,
    } = compListParams;
    const componentList: DraperyModule.DraperyOrderSummaryComponent[] = [];
    componentList.push(mainFabric.draperyOrderSummaryComponent);

    const [
        embell0Checked,
        embell1Checked,
        embell2Checked,
        embell3Checked,
        embell4Checked,
    ] = embellishmentsList.map((item) => item.checked);

    if (embell0Checked) {
        componentList.push(...getEmbellishmentComps({ list: bandings }));
    }
    if (embell1Checked) {
        componentList.push(...getEmbellishmentComps({ list: cordings }));
    }
    if (embell2Checked) {
        componentList.push(...getEmbellishmentComps({ list: flanges }));
    }
    if (embell3Checked) {
        componentList.push(...getEmbellishmentComps({ list: trims }));
    }
    if (embell4Checked) {
        componentList.push(
            ...getEmbellishmentComps({ list: othersEmbellishments })
        );
    }

    for (const component of draperyOrderSummaryComponents) {
        componentList.push(component);
    }
    return componentList;
};

// duplicate
export const duplicateDraperyOrderSummaryComponent = (
    component: DraperyModule.DraperyOrderSummaryComponent
): DraperyModule.DraperyOrderSummaryComponent => {
    return {
        ...component,
        id: 0,
        draperyOrderSummaryId: 0,
        draperyOrderFabricId: undefined,
    };
};

interface Props {
    draperyOrderId: number;
    draperyOrderSummary: DraperyModule.DraperyOrderSummary;

    bandings: DraperyOrderDesignEmbellishment[];
    cordings: DraperyOrderDesignEmbellishment[];
    flanges: DraperyOrderDesignEmbellishment[];
    trims: DraperyOrderDesignEmbellishment[];
    othersEmbellishments: DraperyOrderDesignEmbellishment[];
    mainFabric: DraperyOrderFabric;
    embellishmentsList: EmbellishmentCheckInfo[];
    draperyOrderSummaryComponents: DraperyModule.DraperyOrderSummaryComponent[];
    draperyOrder: DraperyModule.DraperyOrder;
    draperyOrderEditable: boolean;
    workOrder: WorkOrderModule.WorkOrder;
    draperyOrderErrors?: string[];
    draperyOrderSummaryErrors?: string[];
    workOrderStatus: "none" | "downloading" | "error";
    highlightedSection: string | null | undefined;
    data?: any;
    draperyItemDetail?: any;

    handleDuplicate(): void;
    handleCreateWorkOrder(val?: string): Promise<void>;
    handleEditWorkOrder(): Promise<void>;
    handleDownloadWorkOrderPdf(open: boolean): void;
    handleAddSummaryComponent(): void;
    handleDeleteSummaryComponent(id: number): void;
    // handleDownloadWorkOrderForSummery(): Promise<void>;
    handleInputChangeForNumber(e: ChangeEvent<HTMLInputElement>): void;
    handleInputChangeForString(
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void;
    handleInputChangeForBoolean(e: ChangeEvent<HTMLInputElement>): void;
    handleSelectListChange(value: string | number, name?: string): void;
    dispatch(arg: any): any;
    accountId: number;
    workOrderPdfContentSummery?: WorkOrderPdfContent;
    updateStateByName(name: string, value: string): void;
    draperyOrderPriceId: number;
    handleChangeDates(value: Date | null, field: string): void;
    returnDates(): any;
    editNavigate(tab: number): void;
    updateWorkOrder(val?: string): Promise<void>
    workOrderEditMode: boolean;
    loadingUpdate: boolean;
}

// interface LiningObject {
//     name: string;
//     draperyOrderLiningsFabric?: {
//         values: {
//             fabricWidth: number;
//         };
//     };
//     active: boolean;
// }

// tslint:disable-next-line:no-empty-interface

interface Price {
    basePrice: string;
    liningPrice: string;
    interliningPrice: string;
    bandingPrice: string;
    cordingPrePrice: string;
    cordingFabricPrice: string;
    flangePrePrice: string;
    flangeFabricPrice: string;
    trimPrePrice: string;
    trimFabricPrice: string;
    otherPrice: string;
    draperyPinningPrice: string;
    weightPrice: string;
    extraLongPrice: string;
    extraWidePrice: string;
    cafeCurtainPrice: string;
    calculatedSpecialityPrice: string;
    calculatedSpecialityLiningPrice: string;
    calculatedSpecialityInterliningPrice: string;
    calculatedSpecialityBandingPrice: string;
    calculatedSpecialityCordingPreMadePrice: string;
    calculatedSpecialityCordingFabricPrice: string;
    calculatedSpecialityFlangePreMadePrice: string;
    calculatedSpecialityFlangeFabricPrice: string;
    calculatedSpecialityTrimPreMadePrice: string;
    calculatedSpecialityTrimFabricPrice: string;
    calculatedSpecialityOtherPrice: string;
    totalFabricLiningPrice: string;
    totalFabricInterliningPrice: string;
    mainFabricUnitPrice: string;
    bandingUnitPrice: any;
    cordingUnitPrice: any;
    flangeUnitPrice: any;
    trimUnitPrice: any;
    liningsFabricUnitPrice: string;
    interliningsFabricUnitPrice: string;
    othersEmbellishmentsUnitPrice: any;
    mainFabricShippingPrice: string;
    bandingShippingPrice: any;
    cordingShippingPrice: any;
    trimShippingPrice: any;
    flangeShippingPrice: any;
    liningsFabricShippingPrice: string;
    interliningsFabricShippingPrice: string;
    othersEmbellishmentsShippingPrice: any;
}

interface State {
    pattern: number;
    isRecommendationsOverrides: boolean;
    calcPrice: number;
    workroomSettings: any[];
    prices: Price;
}
const defaultPrices = (): Price => ({
    basePrice: "0.00",
    liningPrice: "0.00",
    interliningPrice: "0.00",
    bandingPrice: "0.00",
    cordingPrePrice: "0.00",
    cordingFabricPrice: "0.00",
    flangePrePrice: "0.00",
    flangeFabricPrice: "0.00",
    trimPrePrice: "0.00",
    trimFabricPrice: "0.00",
    otherPrice: "0.00",
    draperyPinningPrice: "0.00",
    weightPrice: "0.00",
    extraLongPrice: "0.00",
    extraWidePrice: "0.00",
    cafeCurtainPrice: "0.00",
    calculatedSpecialityPrice: "0.00",
    calculatedSpecialityLiningPrice: "0.00",
    calculatedSpecialityInterliningPrice: "0.00",
    calculatedSpecialityBandingPrice: "0.00",
    calculatedSpecialityCordingPreMadePrice: "0.00",
    calculatedSpecialityCordingFabricPrice: "0.00",
    calculatedSpecialityFlangePreMadePrice: "0.00",
    calculatedSpecialityFlangeFabricPrice: "0.00",
    calculatedSpecialityTrimPreMadePrice: "0.00",
    calculatedSpecialityTrimFabricPrice: "0.00",
    calculatedSpecialityOtherPrice: "0.00",
    totalFabricLiningPrice: "0.00",
    totalFabricInterliningPrice: "0.00",
    mainFabricUnitPrice: "0.00",
    bandingUnitPrice: [],
    cordingUnitPrice: [],
    flangeUnitPrice: [],
    trimUnitPrice: [],
    liningsFabricUnitPrice: "0.00",
    interliningsFabricUnitPrice: "0.00",
    othersEmbellishmentsUnitPrice: [],
    mainFabricShippingPrice: "0.00",
    bandingShippingPrice: [],
    cordingShippingPrice: [],
    trimShippingPrice: [],
    flangeShippingPrice: [],
    liningsFabricShippingPrice: "0.00",
    interliningsFabricShippingPrice: "0.00",
    othersEmbellishmentsShippingPrice: [],
});

class SummaryTab extends PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            isRecommendationsOverrides: false,
            pattern: 0,
            calcPrice: 0,
            workroomSettings: [],
            prices: defaultPrices(),
        };
    }

    async componentDidMount(): Promise<void> {
        const { dispatch, accountId } = this.props;
        try {
            const res = await dispatch(
                WorkroomSettingModule.searchWorkroomSetting(accountId)
            );
            if (res && res.payload && res.payload.length > 0) {
                const workroomSettings = res.payload;
                this.setState({ workroomSettings });
            } else {
                console.error("There is no information about the settings.");
            }
        } catch (error) {
            console.error("Error executing the dispatch function:", error);
        }
        // if(this.props.draperyOrderPriceId) {
        //     try {
        //         const response = await dispatch(
        //             DraperyOrderPriceModule.getDraperyOrderPrice(this.props.draperyOrderPriceId)
        //         );
        //         console.log('response: ', response)
        //     } catch (error) {
        //         console.error("Error executing the dispatch function:", error);
        //     }
        // }
        this.props.handleDownloadWorkOrderPdf(true);
    }

    async componentWillReceiveProps(
        nextProps: Readonly<Props>,
        nextContext: any
    ): Promise<void> {
        if (nextProps.draperyOrderPriceId) {
            try {
                const response = await getDraperyOrderPrice(nextProps.draperyOrderPriceId);
                this.setState({
                    prices: {
                        ...response,
                        bandingUnitPrice:
                            response.bandingUnitPrice || [],
                        cordingUnitPrice:
                            response.cordingUnitPrice || [],
                        flangeUnitPrice: response.flangeUnitPrice || [],
                        trimUnitPrice: response.trimUnitPrice || [],
                        othersEmbellishmentsUnitPrice:
                            response.othersEmbellishmentsUnitPrice ||
                            [],
                        bandingShippingPrice:
                            response.bandingShippingPrice || [],
                        cordingShippingPrice:
                            response.cordingShippingPrice || [],
                        trimShippingPrice:
                            response.trimShippingPrice || [],
                        flangeShippingPrice:
                            response.flangeShippingPrice || [],
                        othersEmbellishmentsShippingPrice:
                            response
                                .othersEmbellishmentsShippingPrice || [],
                    },
                });
            } catch (error) {
                console.error("Error executing the dispatch function:", error);
            }
        }
    }
    public render() {
        const {
            draperyOrderSummary,
            workOrder,
            mainFabric,
            bandings,
            cordings,
            flanges,
            trims,
            othersEmbellishments,
            embellishmentsList,
            draperyOrderSummaryComponents,
            workOrderEditMode,
            loadingUpdate,
        } = this.props;

        const datesForPicker = this.props.returnDates()

        const componentList = getComponentList({
            bandings,
            cordings,
            flanges,
            trims,
            othersEmbellishments,
            mainFabric,
            embellishmentsList,
            draperyOrderSummaryComponents,
        });

        let componentChecked = true;
        for (const component of componentList) {
            if (!component.receivedDate) {
                componentChecked = false;
                break;
            }
        }

        let cancelClickHandler = () =>
            this.props.handleCreateWorkOrder("delete_messag");
        let editCreate = "Create";

        if (workOrder.id) {
            cancelClickHandler = this.props.handleEditWorkOrder;
            editCreate = "Edit";
        }

        const cancelBtn = (
            <CancelBtn
                uiColor="primary"
                type="button"
                onClick={cancelClickHandler}
                className="create-btn"
            >
                {editCreate}
            </CancelBtn>
        );

        let quantity: number = this.props.data && this.props.data.workOrderPdfContent && this.props.data.workOrderPdfContent.quantity;

        let shippingCostLining: number = 0;
        let costLining: number = 0;
        let shippingCostInterlining: number = 0;
        let costInterlining: number = 0;
        let liningYards: number = 0;
        let interliningYards: number = 0;
        let totalFabricLining: number = 0;
        let totalFabricInterlining: number = 0;
        let sumLiningInterlining: number = 0;
        if (this.props.data && this.props.data.liningsList) {
            this.props.data.liningsList.forEach((item: any) => {
                if (item.active && item.name === "Lining") {
                    shippingCostLining =
                        (item.draperyOrderLiningsFabric &&
                            item.draperyOrderLiningsFabric.amount) ||
                        0;
                    costLining =
                        (item.draperyOrderLiningsFabric &&
                            item.draperyOrderLiningsFabric.calcPrice) ||
                        0;
                    if (
                        this.props.data &&
                        this.props.data.workOrderPdfContentSummery
                    ) {
                        liningYards =
                            this.props.data.workOrderPdfContentSummery
                                .liningYDS || 0;
                    } else {
                        liningYards = 0;
                    }
                    totalFabricLining =
                        liningYards * costLining + shippingCostLining;
                } else if (item.active && item.name === "Interlining") {
                    shippingCostInterlining =
                        (item.draperyOrderLiningsFabric &&
                            item.draperyOrderLiningsFabric.amount) ||
                        0;
                    costInterlining =
                        (item.draperyOrderLiningsFabric &&
                            item.draperyOrderLiningsFabric.calcPrice) ||
                        0;
                    if (
                        this.props.data &&
                        this.props.data.workOrderPdfContentSummery
                    ) {
                        interliningYards =
                            this.props.data.workOrderPdfContentSummery
                                .interliningYDS || 0;
                    } else {
                        interliningYards = 0;
                    }
                    totalFabricInterlining =
                        quantity * interliningYards * costInterlining +
                        shippingCostInterlining;
                }
            });
        }

        const calculateTotal = () => {
            const {
                basePrice,
                liningPrice,
                interliningPrice,
                bandingPrice,
                cordingPrePrice,
                cordingFabricPrice,
                flangePrePrice,
                flangeFabricPrice,
                trimPrePrice,
                trimFabricPrice,
                otherPrice,
                draperyPinningPrice,
                weightPrice,
                cafeCurtainPrice,
                extraLongPrice,
                extraWidePrice,
                calculatedSpecialityPrice,
                calculatedSpecialityLiningPrice,
                calculatedSpecialityInterliningPrice,
                calculatedSpecialityBandingPrice,
                calculatedSpecialityFlangePreMadePrice,
                calculatedSpecialityFlangeFabricPrice,
                calculatedSpecialityCordingPreMadePrice,
                calculatedSpecialityCordingFabricPrice,
                calculatedSpecialityTrimPreMadePrice,
                calculatedSpecialityTrimFabricPrice,
                calculatedSpecialityOtherPrice,
            } = this.state.prices;

            let total = 0;

            total += parseFloat(basePrice);

            total += parseFloat(liningPrice);

            total += parseFloat(interliningPrice);

            total += parseFloat(bandingPrice);

            total += parseFloat(cordingPrePrice);

            total += parseFloat(cordingFabricPrice);

            total += parseFloat(flangePrePrice);

            total += parseFloat(flangeFabricPrice);

            total += parseFloat(trimPrePrice);

            total += parseFloat(trimFabricPrice);

            total += parseFloat(draperyPinningPrice);

            const weightPriceParsed = weightPrice !== null ? weightPrice : "0.00";
            total += parseFloat(weightPriceParsed);

            total += parseFloat(otherPrice);

            total += parseFloat(extraLongPrice);

            total += parseFloat(extraWidePrice);

            total += parseFloat(cafeCurtainPrice);

            total += parseFloat(calculatedSpecialityPrice);

            total += parseFloat(calculatedSpecialityLiningPrice);

            total += parseFloat(calculatedSpecialityInterliningPrice);

            total += parseFloat(calculatedSpecialityBandingPrice);

            total += parseFloat(calculatedSpecialityFlangePreMadePrice);

            total += parseFloat(calculatedSpecialityFlangeFabricPrice);

            total += parseFloat(calculatedSpecialityCordingPreMadePrice);

            total += parseFloat(calculatedSpecialityCordingFabricPrice);

            total += parseFloat(calculatedSpecialityTrimPreMadePrice);

            total += parseFloat(calculatedSpecialityTrimFabricPrice);

            total += parseFloat(calculatedSpecialityOtherPrice);


            const liningsList = this.props.data.liningsList;
            let liningYardageNeeded: number = 0;

            if (liningsList) {
                const liningsFabric = liningsList.find((item: any) => item.name === "Lining");
                if (liningsFabric && liningsFabric.draperyOrderLiningsFabric && liningsFabric.name === "Lining") {
                    liningYardageNeeded = liningsFabric.draperyOrderLiningsFabric.yardsNeeded || 0;
                }
            }

            const liningUnitPrice = parseFloat(
                this.state.prices.liningsFabricUnitPrice
            );
            const liningSHPrice = parseFloat(
                this.state.prices.liningsFabricShippingPrice
            );

            totalFabricLining =
                (isNaN(liningYardageNeeded) || isNaN(liningUnitPrice) || isNaN(liningSHPrice) || !quantity)
                    ? 0
                    : (liningYardageNeeded * liningUnitPrice + liningSHPrice) / quantity;

            let interliningYardageNeeded: number = 0;

            if (liningsList) {
                const interliningsFabric = liningsList.find((item: any) => item.name === "Interlining");
                if (interliningsFabric && interliningsFabric.draperyOrderLiningsFabric && interliningsFabric.name === "Interlining") {
                    interliningYardageNeeded = interliningsFabric.draperyOrderLiningsFabric.yardsNeeded || 0;
                }
            }
            const interliningUnitPrice = parseFloat(
                this.state.prices.interliningsFabricUnitPrice
            );
            const interliningSHPrice = parseFloat(
                this.state.prices.interliningsFabricShippingPrice
            );

            totalFabricInterlining =
                (isNaN(interliningYardageNeeded) || isNaN(interliningUnitPrice) || isNaN(interliningSHPrice) || !quantity)
                    ? 0
                    : (interliningYardageNeeded * interliningUnitPrice + interliningSHPrice) / quantity;

            sumLiningInterlining = totalFabricLining + totalFabricInterlining;

            const fullTotal = total + sumLiningInterlining;
            return fullTotal.toFixed(2);
        };

        let liningMaterialYardage: number | undefined;
        let interliningMaterialYardage: number | undefined;

        let totalAmount: string | undefined;
        if (quantity !== undefined) {
            const totalPriceQuantity = parseFloat(calculateTotal()) * quantity;
            totalAmount = totalPriceQuantity.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        } else {
            totalAmount = undefined;
        }

        let totalPerUnit: string | undefined;
        const totalPriceQuantity = parseFloat(calculateTotal());
        totalPerUnit = totalPriceQuantity.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        //WORK ORDER DESCRIPTION

        const draperyItemDetail = this.props.data.draperyItemDetail;
        let salesDescriptionSettingsMapped: any[] = [];

        if (draperyItemDetail && draperyItemDetail.length > 0) {
            const firstDraperyItem = draperyItemDetail[0];

            if (firstDraperyItem.salesDescriptionSettings) {
                salesDescriptionSettingsMapped =
                    firstDraperyItem.salesDescriptionSettings;
            }
        }

        // BASE PRICE TOTAL
        let calculatedBasePrice: string = this.state.prices.basePrice;

        const formattedPrice = calculatedBasePrice;

        const basePriceElement = document.querySelector(".basePrice");
        if (basePriceElement) {
            basePriceElement.textContent = `${formattedPrice} Base`;
        }
        // LINING AND INTERLINING PRICE
        const liningActive = this.props.data.liningsList;

        let calculatedLiningPrice: string = this.state.prices.liningPrice;
        let calculatedInterliningPrice: string =
            this.state.prices.interliningPrice;

        const formattedLiningPrice = calculatedLiningPrice;
        const formattedInterliningPrice = calculatedInterliningPrice;

        const liningPriceElement = document.querySelector(".liningPrice");
        const interliningPriceElement = document.querySelector(".interliningPrice");

        const liningMaterialPriceElement = document.querySelector(".liningMaterialPrice");
        const interliningMaterialPriceElement = document.querySelector(".interliningMaterialPrice");
        const formattedTotalFabricLining = totalFabricLining.toFixed(2);
        const formattedTotalFabricInterlining = totalFabricInterlining.toFixed(2);



        if (Array.isArray(liningActive)) {
            const lining = liningActive.find(item => item.name === "Lining" && item.active === true);

            if (lining) {
                if (liningPriceElement) {
                    liningPriceElement.textContent = `${formattedLiningPrice} Lining Fabrication`;
                }
                if (liningMaterialPriceElement && parseFloat(formattedTotalFabricLining) >= 0) {
                    liningMaterialPriceElement.textContent = `${formattedTotalFabricLining} Lining Material`
                }
            } else {
                if (liningPriceElement) {
                    liningPriceElement.textContent = "";
                }
            }
        }

        if (Array.isArray(liningActive)) {
            const interlining = liningActive.find(item => item.name === "Interlining" && item.active === true);

            if (interlining) {
                if (interliningPriceElement) {
                    interliningPriceElement.textContent = `${formattedInterliningPrice} Interlining Fabrication`;
                }
                if (interliningMaterialPriceElement && parseFloat(formattedTotalFabricInterlining) >= 0) {
                    interliningMaterialPriceElement.textContent = `${formattedTotalFabricInterlining} Interlining Material`
                }
            } else {
                if (interliningPriceElement) {
                    interliningPriceElement.textContent = "";
                }
            }
        }

        // EMBELLISHMENT PRICE
        const activeEmbellishmet = this.props.data.embellishmentsList;
        // BANDINGS PRICE
        let calculatedBandingPrice: string = this.state.prices.bandingPrice;
        const bandingPriceElement = document.querySelector(".bandingPrice");

        if (Array.isArray(activeEmbellishmet)) {
            const activeBanding = activeEmbellishmet.find(item => item.title === "Banding" && item.checked === true);

            if (activeBanding) {
                if (bandingPriceElement) {
                    bandingPriceElement.textContent =
                        calculatedBandingPrice + " Banding";
                }
            }
        }

        // CORDING PRE MADE PRICE
        let calculatedCordingPrePrice: string = this.state.prices.cordingPrePrice;
        const cordingPriceElement = document.querySelector(".cordingPrice");

        if (Array.isArray(activeEmbellishmet)) {
            const activeCording = activeEmbellishmet.find(item => item.title === "Cording" && item.checked === true);
            if (activeCording) {
                const activeCordingPreMade = this.props.data.cordings.find((cording: { subType: number; }) => cording.subType === 0);
                if (activeCordingPreMade) {
                    if (cordingPriceElement) {
                        cordingPriceElement.textContent =
                            calculatedCordingPrePrice + " Cording pre-made";
                    }
                }
            }
        }


        // CORDING FROM FABRIC
        let calculatedCordingFabricPrice: string =
            this.state.prices.cordingFabricPrice;

        const cordingFabricPriceElement = document.querySelector(
            ".cordingFabricPrice"
        );
        if (Array.isArray(activeEmbellishmet)) {
            const activeCording = activeEmbellishmet.find(item => item.title === "Cording" && item.checked === true);
            if (activeCording) {
                const activeCordingFabric = this.props.data.cordings.find((cording: { subType: number; }) => cording.subType === 1);
                if (activeCordingFabric) {
                    if (cordingFabricPriceElement) {
                        cordingFabricPriceElement.textContent =
                            calculatedCordingFabricPrice + " Cording from fabric";
                    }
                }
            }
        }

        // FLANGE PRE MADE PRICE
        let calculatedFlangePrePrice: string = this.state.prices.flangePrePrice;

        const flangePriceElement = document.querySelector(".flangePrice");

        if (Array.isArray(activeEmbellishmet)) {
            const activeFlange = activeEmbellishmet.find(item => item.title === "Flange" && item.checked === true);
            if (activeFlange) {
                const activeFlangePreMade = this.props.data.flanges.find((flange: { subType: number; }) => flange.subType === 0);
                if (activeFlangePreMade) {
                    if (flangePriceElement) {
                        flangePriceElement.textContent =
                            calculatedFlangePrePrice + " Flange pre-made";
                    }
                }
            }
        }

        // FLANGE FABRIC PRICE
        let calculatedFlangeFabricPrice: string =
            this.state.prices.flangeFabricPrice;

        const flangeFabricPriceElement =
            document.querySelector(".flangeFabricPrice");

        if (Array.isArray(activeEmbellishmet)) {
            const activeFlange = activeEmbellishmet.find(item => item.title === "Flange" && item.checked === true);
            if (activeFlange) {
                const activeFlangeFabric = this.props.data.flanges.find((flange: { subType: number; }) => flange.subType === 1);
                if (activeFlangeFabric) {
                    if (flangeFabricPriceElement) {
                        flangeFabricPriceElement.textContent =
                            calculatedFlangeFabricPrice + " Flange from fabric";
                    }
                }
            }
        }

        // TRIMS PRE MADE PRICE
        let calculatedTrimPrePrice: string = this.state.prices.trimPrePrice;

        const trimPriceElement = document.querySelector(".trimPrice");

        if (Array.isArray(activeEmbellishmet)) {
            const activeTrim = activeEmbellishmet.find(item => item.title === "Trim" && item.checked === true);
            if (activeTrim) {
                const activeTrimPreMade = this.props.data.trims.find((trim: { subType: number; }) => trim.subType === 0);
                if (activeTrimPreMade) {
                    if (trimPriceElement) {
                        trimPriceElement.textContent =
                            calculatedTrimPrePrice + " Trim pre-made";
                    }
                }
            }
        }

        // TRIMS FABRIC PRICE
        let calculatedTrimFabricPrice: string =
            this.state.prices.trimFabricPrice;

        const trimFabricPriceElement =
            document.querySelector(".trimFabricPrice");

        if (Array.isArray(activeEmbellishmet)) {
            const activeTrim = activeEmbellishmet.find(item => item.title === "Trim" && item.checked === true);
            if (activeTrim) {
                const activeTrimFabric = this.props.data.trims.find((trim: { subType: number; }) => trim.subType === 1);
                if (activeTrimFabric) {
                    if (trimFabricPriceElement) {
                        trimFabricPriceElement.textContent =
                            calculatedTrimFabricPrice + " Trim from fabric";
                    }
                }

            }
        }

        //OTHER EMBELLISHMENTS
        let calculatedOtherPrice: string = this.state.prices.otherPrice;

        const formatedOtherPrice = calculatedOtherPrice;

        const othersEmbelishmentsPrice = document.querySelector(
            ".othersEmbelishmentsPrice"
        );

        if (Array.isArray(activeEmbellishmet)) {
            const activeOther = activeEmbellishmet.find(item => item.stateName === "othersEmbellishments" && item.checked === true);
            if (activeOther) {
                if (othersEmbelishmentsPrice) {
                    othersEmbelishmentsPrice.textContent = `${formatedOtherPrice} Other embellishments`;
                }
            }
        }

        //DRAPERY PINING

        let draperyPininngTotal: string = this.state.prices.draperyPinningPrice;
        const draperyActive = this.props.data && this.props.data.draperyOrderDesign && this.props.data.draperyOrderDesign.isPinSet;

        const pinningPriceElement = document.querySelector(".pinningPrice");

        if (draperyActive) {
            if (pinningPriceElement) {
                pinningPriceElement.textContent =
                    draperyPininngTotal + " Drapery pinning";
            }
        }

        //WEIGHT 

        let weightTotal: string = this.state.prices.weightPrice;
        const weightActive = this.props.data && this.props.data.draperyOrderDesign && this.props.data.draperyOrderDesign.isWeight;

        const weightPriceElement = document.querySelector(".weightPrice");

        if (weightActive) {
            let weightDescription = "";
            if (this.props.data.draperyOrderDesign.weightRadio === "standard-corner") {
                weightDescription = "Weight: Standard Corner";
            } else if (this.props.data.draperyOrderDesign.weightRadio === "beaded-chain") {
                weightDescription = "Weight: Beaded Chain";
            }
        
            if (weightPriceElement) {
                weightPriceElement.textContent = `${weightTotal} ${weightDescription}`;
            }
        }

        // EXTRA LONG PANELS
        let extraLongDeduction: string = this.state.prices.extraLongPrice;

        const extraLongElement = document.querySelector(".extraLongPrice");
        if (parseFloat(extraLongDeduction) > 0) {
            if (extraLongElement) {
                extraLongElement.textContent =
                    extraLongDeduction + " Extra Long Panels";
            }
        } else {
            if (extraLongElement) {
                extraLongElement.textContent = "";
            }
        }

        // EXTRA WIDE PANELS
        let extraWideTotal: string = this.state.prices.extraWidePrice;

        let extraWideElement = document.querySelector(".extraWidePrice");

        if (parseFloat(extraWideTotal) > 0) {
            if (extraWideElement) {
                extraWideElement.textContent =
                    extraWideTotal + " Extra Wide Panels";
            }
        } else {
            if (extraWideElement) {
                extraWideElement.textContent = "";
            }
        }

        // CAFE CURTAIN DISCOUNT
        let cafeCurtainDeduction: string = this.state.prices.cafeCurtainPrice;

        const cafePriceElement = document.querySelector(".cafePrice");
        if (parseFloat(cafeCurtainDeduction) < 0) {
            if (cafePriceElement) {
                cafePriceElement.textContent =
                    cafeCurtainDeduction + " Café Curtain Discount";
            }
        } else {
            if (cafePriceElement) {
                cafePriceElement.textContent = "";
            }
        }

        // SPECIALTY BASE PRICE

        let calculatedSpeciality: any =
            this.state.prices.calculatedSpecialityPrice;

        if (
            typeof calculatedSpeciality === "number" &&
            !isNaN(calculatedSpeciality)
        ) {
            calculatedSpeciality = calculatedSpeciality.toFixed(2);
        }

        const specialityBasePriceElement = document.querySelector(".specialityPrice");

        if (specialityBasePriceElement) {
            specialityBasePriceElement.innerHTML =
                parseFloat(calculatedSpeciality) > 0
                    ? calculatedSpeciality + " Specialty Fabrics Surcharge"
                    : "";
        }

        // SPECIALTY LINING PRICE
        let calculatedSpecialityLining: string =
            this.state.prices.calculatedSpecialityLiningPrice;

        const specialityLiningPriceElement = document.querySelector(
            ".specialityPriceLining"
        );

        if (specialityLiningPriceElement) {
            if (parseFloat(calculatedSpecialityLining) !== 0) {
                specialityLiningPriceElement.textContent =
                    calculatedSpecialityLining + " Specialty Lining";
            } else {
                specialityLiningPriceElement.textContent = "";
            }
        }

        // SPECIALTY INTERLINING PRICE
        let calculatedSpecialityInterlining: string =
            this.state.prices.calculatedSpecialityInterliningPrice;

        const specialityInterliningPriceElement = document.querySelector(
            ".specialityPriceInterlining"
        );

        if (specialityInterliningPriceElement) {
            if (parseFloat(calculatedSpecialityInterlining) !== 0) {
                specialityInterliningPriceElement.textContent =
                    calculatedSpecialityInterlining + " Specialty Interlining";
            } else {
                specialityInterliningPriceElement.textContent = "";
            }
        }

        // SPECIALTY BANDINGS PRICE
        let calculatedSpecialityBanding: string =
            this.state.prices.calculatedSpecialityBandingPrice;

        const specialityBandingPriceElement = document.querySelector(
            ".specialityPriceBanding"
        );

        if (specialityBandingPriceElement) {
            if (
                calculatedSpecialityBanding &&
                parseFloat(calculatedSpecialityBanding) !== 0
            ) {
                specialityBandingPriceElement.textContent =
                    calculatedSpecialityBanding + " Specialty Banding";
            } else {
                specialityBandingPriceElement.textContent = "";
            }
        }
        // SPECIALTY CORDING PRE MADE
        let calculatedSpecialityCordingPreMade: string =
            this.state.prices.calculatedSpecialityCordingPreMadePrice;

        const specialityCordingPriceElement = document.querySelector(
            ".specialityPriceCordingPreMade"
        );

        if (specialityCordingPriceElement) {
            if (
                calculatedSpecialityCordingPreMade &&
                parseFloat(calculatedSpecialityCordingPreMade) !== 0
            ) {
                specialityCordingPriceElement.textContent =
                    calculatedSpecialityCordingPreMade +
                    " Specialty Cording pre-made";
            } else {
                specialityCordingPriceElement.textContent = "";
            }
        }
        // SPECIALTY CORDING FROM FABRIC
        let calculatedSpecialityCordingFabric: string =
            this.state.prices.calculatedSpecialityCordingFabricPrice;

        const specialityCordingFabricPriceElement = document.querySelector(
            ".specialityPriceCordingFabric"
        );

        if (specialityCordingFabricPriceElement) {
            if (
                calculatedSpecialityCordingFabric &&
                parseFloat(calculatedSpecialityCordingFabric) !== 0
            ) {
                specialityCordingFabricPriceElement.textContent =
                    calculatedSpecialityCordingFabric +
                    " Specialty Cording from fabric";
            } else {
                specialityCordingFabricPriceElement.textContent = "";
            }
        }

        // SPECIALTY FLANGE PRE MADE
        let calculatedSpecialityFlangePreMade: string =
            this.state.prices.calculatedSpecialityFlangePreMadePrice;

        const specialityFlangePriceElement = document.querySelector(
            ".specialityPriceFlangePreMade"
        );

        if (specialityFlangePriceElement) {
            if (
                calculatedSpecialityFlangePreMade &&
                parseFloat(calculatedSpecialityFlangePreMade) !== 0
            ) {
                specialityFlangePriceElement.textContent =
                    calculatedSpecialityFlangePreMade +
                    " Specialty Flange pre-made";
            } else {
                specialityFlangePriceElement.textContent = "";
            }
        }

        // SPECIALTY FLANGE FROM FABRIC
        let calculatedSpecialityFlangeFabric: string =
            this.state.prices.calculatedSpecialityFlangeFabricPrice;

        const specialityFlangeFabricPriceElement = document.querySelector(
            ".specialityPriceFlangeFabric"
        );

        if (specialityFlangeFabricPriceElement) {
            if (
                calculatedSpecialityFlangeFabric &&
                parseFloat(calculatedSpecialityFlangeFabric) !== 0
            ) {
                specialityFlangeFabricPriceElement.textContent =
                    calculatedSpecialityFlangeFabric +
                    " Specialty Flange from fabric";
            } else {
                specialityFlangeFabricPriceElement.textContent = "";
            }
        }

        // SPECIALTY TRIM PRE MADE
        let calculatedSpecialityTrimPreMade: string =
            this.state.prices.calculatedSpecialityTrimPreMadePrice;

        const specialityTrimPriceElement = document.querySelector(
            ".specialityPriceTrimPreMade"
        );

        if (specialityTrimPriceElement) {
            if (
                calculatedSpecialityTrimPreMade &&
                parseFloat(calculatedSpecialityTrimPreMade) !== 0
            ) {
                specialityTrimPriceElement.textContent =
                    calculatedSpecialityTrimPreMade +
                    " Specialty Trim pre-made";
            } else {
                specialityTrimPriceElement.textContent = "";
            }
        }

        // SPECIALTY TRIM FROM FABRIC
        let calculatedSpecialityTrimFabric: string =
            this.state.prices.calculatedSpecialityTrimFabricPrice;

        const specialityTrimFabricPriceElement = document.querySelector(
            ".specialityPriceTrimFabric"
        );

        if (specialityTrimFabricPriceElement) {
            if (
                calculatedSpecialityTrimFabric &&
                parseFloat(calculatedSpecialityTrimFabric) !== 0
            ) {
                specialityTrimFabricPriceElement.textContent =
                    calculatedSpecialityTrimFabric +
                    " Specialty Trim from fabric";
            } else {
                specialityTrimFabricPriceElement.textContent = "";
            }
        }

        // SPECIALTY OTHER EMBELLISHMENTS
        let calculatedSpecialityOther: string =
            this.state.prices.calculatedSpecialityOtherPrice;

        const specialityOtherPriceElement = document.querySelector(
            ".specialityPriceOther"
        );
        if (specialityOtherPriceElement) {
            if (
                calculatedSpecialityOther &&
                parseFloat(calculatedSpecialityOther) !== 0
            ) {
                specialityOtherPriceElement.textContent =
                    calculatedSpecialityOther +
                    " Specialty other embellishments";
            } else {
                specialityOtherPriceElement.textContent = "";
            }
        }

        const formatPleatType = (pleatType: any) => {
            let formattedPleatType = pleatType.replace(/\d{4}$/, '');

            formattedPleatType = formattedPleatType.replace(/_/g, ' ');

            formattedPleatType = formattedPleatType.replace(/\b\w/g, (char: string) => char.toUpperCase());
            return formattedPleatType;
        };

        const changeStatus = (e: ChangeEvent<HTMLSelectElement>): void => {
            this.props.updateStateByName("draperyOrder.status", e.currentTarget.value);
        };

        return (
            <>
                <DisableAutocompleteInput name="firstName" />
                <SummaryContainer>
                    <SummaryTimeline />
                    <SummaryContent>
                        <SummaryInfoBlock>
                            <DraperyHeader
                                noMarginTop
                                uiTimeStatus

                            >
                            </DraperyHeader>

                            <HContainer uiBaseLine uiGap>
                                <CalculatorFormField uiInline uiNoMargin>
                                    <TitleNotes>Status</TitleNotes>
                                    <Select name="draperyOrder.status" value={this.props.draperyOrder.status} onChange={changeStatus} uiDisabled={this.props.draperyOrder.archived}>
                                        <option value={"New"}>New</option>
                                        <option value={"Planning"}>Planning</option>
                                        <option value={"Measured"}>Measured</option>
                                        <option value={"Need Measure"}>Need Measure</option>
                                        <option value={"Pending"}>Pending</option>
                                        <option value={"Approved"}>Approved</option>
                                        <option value={"Production"}>
                                            Production
                                        </option>
                                        <option value={"Ready"}>Ready</option>
                                        <option value={"Closed"}>Closed</option>
                                        <option value={"Rejected"}>Rejected</option>
                                    </Select>
                                </CalculatorFormField>

                                <CalculatorFormField uiInline uiNoMargin style={{ width: "50%" }}>
                                    <TitleNotes>Project Overview Notes</TitleNotes>
                                    <TextArea
                                        style={{
                                            border: "1px solid #B3B3B3",
                                            height: "131px",
                                        }}
                                        name="draperyOrderSummary.notes"
                                        onChange={this.props.handleInputChangeForString}
                                        placeholder="Click to enter notes"
                                        value={draperyOrderSummary.notes || ''}
                                        uiDisabled={this.props.draperyOrder.archived}
                                    />
                                </CalculatorFormField>
                            </HContainer>
                        </SummaryInfoBlock>
                        <SummaryInfoBlock>
                            <DraperyHeader
                                noMarginTop
                                uiTimeStatus
                                uiTimeStatusChecked={!!workOrder.id}
                                className='firstCheck'
                            >
                                ITEM SUMMARY

                                <div style={{ gap: '20px' }} className="flex items-center">
                                    <div>
                                    {this.props.data.workOrder.id > 0 && (
                                    <DraftCheckBox>
                                        <input
                                            type="checkbox"
                                            id="isDraft"
                                            name="draperyOrder.isDraft"
                                            checked={
                                                this.props.data.draperyOrder
                                                    .isDraft
                                            }
                                            onChange={
                                                this.props
                                                    .handleInputChangeForBoolean
                                            }
                                            value={
                                                this.props.data.draperyOrder
                                                    .isDraft
                                            }
                                            disabled={this.props.draperyOrder.archived}
                                            style={this.props.draperyOrder.archived ? { border: "2px solid #EEEEEE" } : {}}

                                        />
                                        <span>Mark as Draft</span>
                                    </DraftCheckBox>
                                )}
                                    </div>

                                    <div className="flex items-center">
                                        <DuplicateBtn
                                            uiDisabled={this.props.workOrderEditMode ? true : this.props.draperyOrder.archived}
                                            uiColor="primary"
                                            type="button"
                                            onClick={this.props.handleDuplicate}

                                        >
                                            Duplicate
                                        </DuplicateBtn>

                                        {!!this.props.draperyOrderEditable && cancelBtn}
                                        {this.props.data.workOrder.id > 0 && (
                                            <EditButton
                                                uiDisabled={loadingUpdate}
                                                uiColor='primary'
                                                type='button'
                                                onClick={() => this.props.workOrderEditMode ? this.props.updateWorkOrder('delete_messag') : this.props.editNavigate(0)}
                                            >
                                                {!this.props.workOrderEditMode && (
                                                    <EditIcon className="inline-flex mr-2" />
                                                )}
                                                <span>{this.props.workOrderEditMode ? "Update" : "Edit"}</span>
                                            </EditButton>
                                        )}

                                    </div>
                                </div>
                            </DraperyHeader>

                            {this.props.data &&
                                this.props.data.workOrderPdfContentSummery &&
                                this.props.data.workOrder.id > 0 ? (
                                <HContainer
                                    text="fabrics"
                                    style={{ marginBottom: "30px" }}
                                    uiColumnDirection
                                >
                                    <SummaryTable>
                                        <SummaryTableCol
                                            text="not-fabrics"
                                            color="header"
                                        >
                                            <SummaryTableCell text="title">
                                                Work Order
                                            </SummaryTableCell>
                                            {workOrderEditMode ? (null) : (
                                                salesDescriptionSettingsMapped.some(
                                                    (item) =>
                                                        item.id ===
                                                        "saleslinktoworkorder" &&
                                                        item.isEnabled
                                                ) ? (
                                                    <SummaryTableCell
                                                        style={{
                                                            textAlign: "left",
                                                        }}
                                                        onClick={() => this.props.handleDownloadWorkOrderPdf(true)}
                                                    >
                                                        <WorkOrderLink style={{ flexWrap: "wrap" }}>
                                                            <WorkOrderNumber className="woNumber"
                                                                style={{
                                                                    textAlign: "left",
                                                                    fontWeight: "600",
                                                                    minHeight: "40px"
                                                                }}
                                                            >
                                                                {"WO - " +
                                                                    workOrder.num}
                                                            </WorkOrderNumber>
                                                            {this.props.data
                                                                .draperyOrder
                                                                .isDraft && (
                                                                    <Draft>DRAFT</Draft>
                                                                )}
                                                        </WorkOrderLink>
                                                    </SummaryTableCell>
                                                ) : (
                                                    <WOContainer>
                                                        <WorkOrderNumberNoLink>
                                                            WO {workOrder.num}
                                                        </WorkOrderNumberNoLink>
                                                        {this.props.data
                                                            .draperyOrder
                                                            .isDraft && (
                                                                <Draft>DRAFT</Draft>
                                                            )}
                                                    </WOContainer>
                                                )
                                            )}
                                        </SummaryTableCol>
                                        <SummaryTableCol
                                            text="fabrics-center"
                                            color="header"
                                        >
                                            <SummaryTableCell text="title">
                                                Description <span>&nbsp;</span><span style={{ fontStyle: 'italic' }}>(per unit)</span>
                                            </SummaryTableCell>
                                            {workOrderEditMode ? (null) : (
                                                <>
                                                    {salesDescriptionSettingsMapped.some(
                                                        (item) =>
                                                            item.id ===
                                                            "saleswindowid" &&
                                                            item.isEnabled
                                                    ) ? (
                                                        <SummaryTableCell
                                                            text="content"
                                                            style={{
                                                                textAlign: "left",
                                                                textTransform:
                                                                    "uppercase",
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    fontWeight: "600",
                                                                    paddingBottom:
                                                                        "20px",
                                                                }}
                                                            >
                                                                {this.props.data
                                                                    .workOrderPdfContent &&
                                                                    this.props.data
                                                                        .workOrderPdfContent
                                                                        .room}{" "}
                                                                /{" "}
                                                                {this.props.data
                                                                    .draperyOrder &&
                                                                    this.props.data
                                                                        .draperyOrder
                                                                        .window}
                                                            </span>
                                                        </SummaryTableCell>
                                                    ) : (
                                                        <span></span>
                                                    )}

                                                    <SummaryTableCell
                                                        style={{
                                                            flex: 1,
                                                            borderRight:
                                                                "1px solid #dadada",
                                                            borderLeft:
                                                                "1px solid #dadada",
                                                            whiteSpace: "pre-wrap",
                                                        }}
                                                    >
                                                        {salesDescriptionSettingsMapped.some(
                                                            (item) =>
                                                                item.id ===
                                                                "salesfunctionalityandstyle" &&
                                                                item.isEnabled
                                                        ) && (
                                                                <>
                                                                    {this.props.data
                                                                        .draperyOrderDesign
                                                                        .category === 0 && (
                                                                            <span className="boldText">
                                                                                Functional
                                                                            </span>
                                                                        )}
                                                                    {this.props.data
                                                                        .draperyOrderDesign
                                                                        .category === 1 && (
                                                                            <span className="boldText">
                                                                                Stationary
                                                                            </span>
                                                                        )}

                                                                    {(this.props.data
                                                                        .draperyOrderDesign
                                                                        .draperyType ===
                                                                        "full-length" ||
                                                                        this.props.data
                                                                            .draperyOrderDesign
                                                                            .draperyType ===
                                                                        "cafe-curtain") && (
                                                                            <>
                                                                                <span className="boldText">
                                                                                {this.props.data.draperyOrderDesign.headingType !== "ripplefold" &&
                                                                                    (this.props.data.draperyOrderDesign.draperyType === "full-length"
                                                                                        ? "/ Full Length"
                                                                                        : "/ Cafe Curtain")
                                                                                    }
                                                                                </span>
                                                                            </>
                                                                        )}
                                                                </>
                                                            )}
                                                        {salesDescriptionSettingsMapped.some(
                                                            (item) =>
                                                                item.id ===
                                                                "salesfinishedlength" &&
                                                                item.isEnabled
                                                        ) && (
                                                                <>
                                                                    {salesDescriptionSettingsMapped.some(
                                                                        (item) =>
                                                                            item.id ===
                                                                            "salesfunctionalityandstyle" &&
                                                                            item.isEnabled
                                                                    ) && (
                                                                            <span className="boldText">
                                                                                {" "}
                                                                                /{" "}
                                                                            </span>
                                                                        )}
                                                                    <span className="nowrap">
                                                                        <span className="boldText">Finished Length:{" "}</span>
                                                                        <span className="nonBold">
                                                                            {this.props.data.draperyOrderDesign.finishedLengthOfPanels}
                                                                            {""}
                                                                            ''
                                                                        </span>
                                                                    </span>
                                                                </>
                                                            )}
                                                        <br></br>
                                                        {salesDescriptionSettingsMapped.some(
                                                            (item) =>
                                                                item.id ===
                                                                "salesheadingandpleattype" &&
                                                                item.isEnabled
                                                        ) ? (
                                                            <>
                                                                <span className="boldText">
                                                                    {this.props.data.draperyOrderDesign.headingType === "rodandrings"
                                                                        ? "Rod and Rings / "
                                                                        : this.props.data.draperyOrderDesign.headingType === "ripplefold"
                                                                        ? "Ripplefold / "
                                                                        : ""}
                                                                </span>
                                                                <span className="boldText" style={{ textTransform: "capitalize" }}>
                                                                {
                                                                    (() => {
                                                                        if (this.props.data.draperyOrderDesign.headingType === "ripplefold") {
                                                                            const { masterCarrier } = this.props.data && this.props.data.draperyOrderDesign &&
                                                                            this.props.data.draperyOrderDesign.ripplefoldOptions || "";
                                                                            if (masterCarrier === "butt") {
                                                                                return "Butt";
                                                                            } else if (masterCarrier === "overlap") {
                                                                                return "Overlap";
                                                                            }
                                                                            return ''; 
                                                                        } 
                                                                        
                                                                        if (this.props.draperyItemDetail && this.props.draperyItemDetail[0].pleatTypesWithPricing) {
                                                                            const pleat = this.props.draperyItemDetail[0].pleatTypesWithPricing.find(
                                                                                (pleat: any) => pleat.id === this.props.data.workOrderPdfContentSummery.pleatType
                                                                            );
                                                                            if (pleat) {
                                                                                return pleat.name.toLowerCase();
                                                                            }
                                                                            return formatPleatType(this.props.data.draperyOrderDesign.pleatType.toLowerCase());
                                                                        }
                                                                        return ''; 
                                                                    })()
                                                                }
                                                            </span>

                                                            </>
                                                        ) : (
                                                            <span></span>
                                                        )}
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        {salesDescriptionSettingsMapped.some(
                                                            (item) =>
                                                                item.id ===
                                                                "salespanelandwidthdetails" &&
                                                                item.isEnabled
                                                        ) ? (
                                                            <>
                                                                <span className="boldText">
                                                                    Panels:
                                                                </span>
                                                                <span
                                                                    style={{
                                                                        paddingLeft:
                                                                            "8px",
                                                                        fontWeight:
                                                                            "400",
                                                                    }}
                                                                >
                                                                    {Object.keys(
                                                                        this.props.data
                                                                            .draperyOrderDesign
                                                                            .panelJson
                                                                    )
                                                                        .map(
                                                                            (key) =>
                                                                                this
                                                                                    .props
                                                                                    .data
                                                                                    .draperyOrderDesign
                                                                                    .panelJson[
                                                                                key
                                                                                ]
                                                                        )
                                                                        .filter(
                                                                            (value) =>
                                                                                value !==
                                                                                undefined
                                                                        )
                                                                        .map(
                                                                            (value) =>
                                                                                value +
                                                                                "w"
                                                                        )
                                                                        .join(" - ")}
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <span></span>
                                                        )}
                                                        </div>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        {salesDescriptionSettingsMapped.some(
                                                            (item) =>
                                                                item.id ===
                                                                "salestotalnumberofpanelsandwidths" &&
                                                                item.isEnabled
                                                        ) ? (
                                                            <>
                                                                <span className="boldText">
                                                                    Total Panels:
                                                                </span>
                                                                <span
                                                                    style={{
                                                                        paddingLeft:
                                                                            "8px",
                                                                        fontWeight:
                                                                            "400",
                                                                    }}
                                                                >
                                                                    {
                                                                        Object.keys(
                                                                            this.props
                                                                                .data
                                                                                .draperyOrderDesign
                                                                                .panelJson
                                                                        ).filter(
                                                                            (key) =>
                                                                                typeof this
                                                                                    .props
                                                                                    .data
                                                                                    .draperyOrderDesign
                                                                                    .panelJson[
                                                                                key
                                                                                ] !==
                                                                                "undefined"
                                                                        ).length
                                                                    }{" "}
                                                                    /
                                                                </span>
                                                                <span
                                                                    className="boldText"
                                                                    style={{
                                                                        paddingLeft:
                                                                            "2px",
                                                                    }}
                                                                >
                                                                    Total Widths:
                                                                </span>
                                                                <span
                                                                    style={{
                                                                        paddingLeft:
                                                                            "8px",
                                                                        fontWeight:
                                                                            "400",
                                                                    }}
                                                                >
                                                                    {Object.values(
                                                                        this.props.data
                                                                            .draperyOrderDesign
                                                                            .panelJson
                                                                    )
                                                                        .map(
                                                                            (value) => {
                                                                                const numericValue =
                                                                                    parseFloat(
                                                                                        value as string
                                                                                    );
                                                                                return isNaN(
                                                                                    numericValue
                                                                                )
                                                                                    ? 0
                                                                                    : numericValue;
                                                                            }
                                                                        )
                                                                        .reduce(
                                                                            (
                                                                                acc,
                                                                                currentValue
                                                                            ) =>
                                                                                acc +
                                                                                currentValue,
                                                                            0
                                                                        )
                                                                        .toLocaleString(
                                                                            undefined,
                                                                            {
                                                                                minimumFractionDigits: 0,
                                                                                maximumFractionDigits: 2,
                                                                            }
                                                                        )}
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <span></span>
                                                        )}
                                                        </div>
                                                        {salesDescriptionSettingsMapped.some(
                                                            (item) =>
                                                                item.id ===
                                                                "salesifliningandinterliningselected" &&
                                                                item.isEnabled
                                                        ) ? (
                                                            <>
                                                                <span className="boldText">
                                                                    Lining:
                                                                </span>
                                                                <span
                                                                    style={{
                                                                        paddingLeft:
                                                                            "8px",
                                                                        fontWeight:
                                                                            "400",
                                                                    }}
                                                                >
                                                                    {this.props.data.liningsList.some(
                                                                        (lining: any) =>
                                                                            lining.name ===
                                                                            "Lining" &&
                                                                            lining.active
                                                                    )
                                                                        ? "Yes"
                                                                        : "No"}
                                                                </span>
                                                                <br></br>
                                                                <span className="boldText">
                                                                    Interlining:
                                                                </span>
                                                                <span
                                                                    style={{
                                                                        paddingLeft:
                                                                            "8px",
                                                                        fontWeight:
                                                                            "400",
                                                                    }}
                                                                >
                                                                    {this.props.data.liningsList.some(
                                                                        (lining: any) =>
                                                                            lining.name ===
                                                                            "Interlining" &&
                                                                            lining.active
                                                                    )
                                                                        ? "Yes"
                                                                        : "No"}
                                                                </span>
                                                                <br></br>
                                                                {this.props.data.liningsList.some(
                                                                    (lining: any) =>
                                                                        lining.name ===
                                                                        "Other interlining" &&
                                                                        lining.active
                                                                ) && (
                                                                        <div>
                                                                            <span
                                                                                className="boldText"
                                                                                style={{
                                                                                    paddingLeft:
                                                                                        "2px",
                                                                                }}
                                                                            >
                                                                                Additional
                                                                                Interlining:
                                                                            </span>
                                                                            <span
                                                                                style={{
                                                                                    paddingLeft:
                                                                                        "8px",
                                                                                    fontWeight:
                                                                                        "400",
                                                                                }}
                                                                            >
                                                                                Yes
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                            </>
                                                        ) : (
                                                            <span></span>
                                                        )}
                                                        {salesDescriptionSettingsMapped.some(
                                                            (item) =>
                                                                item.id ===
                                                                "salesliningandinterliningnames" &&
                                                                item.isEnabled
                                                        ) ? (
                                                            <>
                                                                {this.props.data.liningsList
                                                                    .filter(
                                                                        (lining: any) =>
                                                                            lining.active
                                                                    )
                                                                    .map(
                                                                        (
                                                                            lining: any,
                                                                            index: number
                                                                        ) => (
                                                                            <div
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                {lining.name ===
                                                                                    "Lining" &&
                                                                                    lining.draperyOrderLiningsFabric && (
                                                                                        <span>
                                                                                            <span className="boldText">
                                                                                                Lining:
                                                                                            </span>{" "}
                                                                                            <span className="nonBold">
                                                                                                {lining
                                                                                                    .draperyOrderLiningsFabric
                                                                                                    .pattern
                                                                                                    ? lining
                                                                                                        .draperyOrderLiningsFabric
                                                                                                        .pattern
                                                                                                    : null}
                                                                                            </span>
                                                                                        </span>
                                                                                    )}
                                                                                {lining.name ===
                                                                                    "Interlining" &&
                                                                                    lining.active && (
                                                                                        <span>
                                                                                            <span className="boldText">
                                                                                                Interlining:
                                                                                            </span>{" "}
                                                                                            <span className="nonBold">
                                                                                                {lining.draperyOrderLiningsFabric &&
                                                                                                    lining
                                                                                                        .draperyOrderLiningsFabric
                                                                                                        .pattern
                                                                                                    ? lining
                                                                                                        .draperyOrderLiningsFabric
                                                                                                        .pattern
                                                                                                    : null}
                                                                                            </span>
                                                                                        </span>
                                                                                    )}
                                                                                {lining.name ===
                                                                                    "Other interlining" &&
                                                                                    lining.active && (
                                                                                        <span>
                                                                                            <span className="boldText">
                                                                                                Additional
                                                                                                Interlining:
                                                                                            </span>{" "}
                                                                                            <span className="nonBold">
                                                                                                {lining.draperyOrderLiningsFabric &&
                                                                                                    lining
                                                                                                        .draperyOrderLiningsFabric
                                                                                                        .pattern
                                                                                                    ? lining
                                                                                                        .draperyOrderLiningsFabric
                                                                                                        .pattern
                                                                                                    : null}
                                                                                            </span>
                                                                                        </span>
                                                                                    )}
                                                                            </div>
                                                                        )
                                                                    )}
                                                            </>
                                                        ) : (
                                                            <span></span>
                                                        )}
                                                        {salesDescriptionSettingsMapped.some(
                                                            (item) =>
                                                                item.id ===
                                                                "salesembellishmentsselected" &&
                                                                item.isEnabled
                                                        ) ? (
                                                            <>
                                                                <span className="boldText">
                                                                    Embellishments:
                                                                </span>
                                                                {this.props.data.embellishmentsList.some(
                                                                    (
                                                                        embellishment: any
                                                                    ) =>
                                                                        embellishment.checked &&
                                                                        embellishment.title ===
                                                                        "Banding"
                                                                ) && (
                                                                        <span>
                                                                            {this.props.data.bandings.map(
                                                                                (
                                                                                    banding: any,
                                                                                    index: number
                                                                                ) => (
                                                                                    <span
                                                                                        key={
                                                                                            index
                                                                                        }
                                                                                        className="nonBold"
                                                                                    >
                                                                                        {" "}
                                                                                        Banding
                                                                                        {banding
                                                                                            .topEdge
                                                                                            .active
                                                                                            ? " on Top Edge" +
                                                                                            (banding
                                                                                                .bottomEdge
                                                                                                .active >
                                                                                                0
                                                                                                ? ","
                                                                                                : "")
                                                                                            : ""}
                                                                                        {banding
                                                                                            .bottomEdge
                                                                                            .active
                                                                                            ? banding
                                                                                                .topEdge
                                                                                                .active
                                                                                                ? " Bottom Edge"
                                                                                                : " on Bottom Edge"
                                                                                            : ""}
                                                                                        {banding
                                                                                            .leadReturnEdge
                                                                                            .active &&
                                                                                            banding.totalStrips >
                                                                                            0 &&
                                                                                            banding
                                                                                                .topEdge
                                                                                                .active &&
                                                                                            banding
                                                                                                .bottomEdge
                                                                                                .active
                                                                                            ? ","
                                                                                            : ""}
                                                                                        {banding
                                                                                            .leadReturnEdge
                                                                                            .active &&
                                                                                            banding.totalStrips >
                                                                                            0 &&
                                                                                            !banding
                                                                                                .topEdge
                                                                                                .active &&
                                                                                            !banding
                                                                                                .bottomEdge
                                                                                                .active
                                                                                            ? " on"
                                                                                            : banding
                                                                                                .leadReturnEdge
                                                                                                .active &&
                                                                                                banding.totalStrips >
                                                                                                0 &&
                                                                                                (banding
                                                                                                    .topEdge
                                                                                                    .active ||
                                                                                                    banding
                                                                                                        .bottomEdge
                                                                                                        .active)
                                                                                                ? " and"
                                                                                                : ""}
                                                                                        {banding
                                                                                            .leadReturnEdge
                                                                                            .active &&
                                                                                            banding.totalStrips >
                                                                                            0
                                                                                            ? ` ${banding.totalStrips
                                                                                            } Vertical Edge${banding.totalStrips >
                                                                                                1
                                                                                                ? "s"
                                                                                                : ""
                                                                                            }`
                                                                                            : ""}
                                                                                        .
                                                                                    </span>
                                                                                )
                                                                            )}
                                                                        </span>
                                                                    )}
                                                                {this.props.data.embellishmentsList.some(
                                                                    (
                                                                        embellishment: any
                                                                    ) =>
                                                                        embellishment.checked &&
                                                                        embellishment.title ===
                                                                        "Cording"
                                                                ) && (
                                                                        <span>
                                                                            {this.props.data.cordings.map(
                                                                                (
                                                                                    cording: any,
                                                                                    index: number
                                                                                ) => (
                                                                                    <span
                                                                                        key={
                                                                                            index
                                                                                        }
                                                                                        className="nonBold"
                                                                                    >
                                                                                        {cording &&
                                                                                            cording.draperyOrderFabric &&
                                                                                            (cording.draperyOrderFabric.name.includes(
                                                                                                "WELT CORDING FABRIC"
                                                                                            )
                                                                                                ? " Welt Cording"
                                                                                                : cording.draperyOrderFabric.name.includes(
                                                                                                    "CORDING (PRE-MADE)"
                                                                                                )
                                                                                                    ? " Cording (Pre-Made)"
                                                                                                    : " Cording")}
                                                                                        {cording
                                                                                            .topEdge
                                                                                            .active
                                                                                            ? " on Top Edge" +
                                                                                            (cording
                                                                                                .bottomEdge
                                                                                                .active >
                                                                                                0
                                                                                                ? ","
                                                                                                : "")
                                                                                            : ""}
                                                                                        {cording
                                                                                            .bottomEdge
                                                                                            .active
                                                                                            ? cording
                                                                                                .topEdge
                                                                                                .active
                                                                                                ? " Bottom Edge"
                                                                                                : " on Bottom Edge"
                                                                                            : ""}
                                                                                        {cording
                                                                                            .leadReturnEdge
                                                                                            .active &&
                                                                                            cording.totalStrips >
                                                                                            0 &&
                                                                                            cording
                                                                                                .topEdge
                                                                                                .active &&
                                                                                            cording
                                                                                                .bottomEdge
                                                                                                .active
                                                                                            ? ","
                                                                                            : ""}
                                                                                        {cording
                                                                                            .leadReturnEdge
                                                                                            .active &&
                                                                                            cording.totalStrips >
                                                                                            0 &&
                                                                                            !cording
                                                                                                .topEdge
                                                                                                .active &&
                                                                                            !cording
                                                                                                .bottomEdge
                                                                                                .active
                                                                                            ? " on"
                                                                                            : cording
                                                                                                .leadReturnEdge
                                                                                                .active &&
                                                                                                cording.totalStrips >
                                                                                                0 &&
                                                                                                (cording
                                                                                                    .topEdge
                                                                                                    .active ||
                                                                                                    cording
                                                                                                        .bottomEdge
                                                                                                        .active)
                                                                                                ? " and"
                                                                                                : ""}
                                                                                        {cording
                                                                                            .leadReturnEdge
                                                                                            .active &&
                                                                                            cording.totalStrips >
                                                                                            0
                                                                                            ? ` ${cording.totalStrips
                                                                                            } Vertical Edge${cording.totalStrips >
                                                                                                1
                                                                                                ? "s"
                                                                                                : ""
                                                                                            }`
                                                                                            : ""}
                                                                                        .
                                                                                    </span>
                                                                                )
                                                                            )}
                                                                        </span>
                                                                    )}
                                                                {this.props.data.embellishmentsList.some(
                                                                    (
                                                                        embellishment: any
                                                                    ) =>
                                                                        embellishment.checked &&
                                                                        embellishment.title ===
                                                                        "Flange"
                                                                ) && (
                                                                        <span>
                                                                            {this.props.data.flanges.map(
                                                                                (
                                                                                    flange: any,
                                                                                    index: number
                                                                                ) => (
                                                                                    <span
                                                                                        key={
                                                                                            index
                                                                                        }
                                                                                        className="nonBold"
                                                                                    >
                                                                                        {flange &&
                                                                                            flange.draperyOrderFabric &&
                                                                                            (flange.draperyOrderFabric.name.includes(
                                                                                                "FLANGE FABRIC"
                                                                                            )
                                                                                                ? " Flange from Fabric"
                                                                                                : flange.draperyOrderFabric.name.includes(
                                                                                                    "FLANGE (PRE-MADE)"
                                                                                                )
                                                                                                    ? " Flange (Pre-Made)"
                                                                                                    : " Flange")}
                                                                                        {flange
                                                                                            .topEdge
                                                                                            .active
                                                                                            ? " on Top Edge" +
                                                                                            (flange
                                                                                                .bottomEdge
                                                                                                .active >
                                                                                                0
                                                                                                ? ","
                                                                                                : "")
                                                                                            : ""}
                                                                                        {flange
                                                                                            .bottomEdge
                                                                                            .active
                                                                                            ? flange
                                                                                                .topEdge
                                                                                                .active
                                                                                                ? " Bottom Edge"
                                                                                                : " on Bottom Edge"
                                                                                            : ""}
                                                                                        {flange
                                                                                            .leadReturnEdge
                                                                                            .active &&
                                                                                            flange.totalStrips >
                                                                                            0 &&
                                                                                            flange
                                                                                                .topEdge
                                                                                                .active &&
                                                                                            flange
                                                                                                .bottomEdge
                                                                                                .active
                                                                                            ? ","
                                                                                            : ""}
                                                                                        {flange
                                                                                            .leadReturnEdge
                                                                                            .active &&
                                                                                            flange.totalStrips >
                                                                                            0 &&
                                                                                            !flange
                                                                                                .topEdge
                                                                                                .active &&
                                                                                            !flange
                                                                                                .bottomEdge
                                                                                                .active
                                                                                            ? " on"
                                                                                            : flange
                                                                                                .leadReturnEdge
                                                                                                .active &&
                                                                                                flange.totalStrips >
                                                                                                0 &&
                                                                                                (flange
                                                                                                    .topEdge
                                                                                                    .active ||
                                                                                                    flange
                                                                                                        .bottomEdge
                                                                                                        .active)
                                                                                                ? " and"
                                                                                                : ""}
                                                                                        {flange
                                                                                            .leadReturnEdge
                                                                                            .active &&
                                                                                            flange.totalStrips >
                                                                                            0
                                                                                            ? ` ${flange.totalStrips
                                                                                            } Vertical Edge${flange.totalStrips >
                                                                                                1
                                                                                                ? "s"
                                                                                                : ""
                                                                                            }`
                                                                                            : ""}
                                                                                        .
                                                                                    </span>
                                                                                )
                                                                            )}
                                                                        </span>
                                                                    )}
                                                                {this.props.data.embellishmentsList.some(
                                                                    (
                                                                        embellishment: any
                                                                    ) =>
                                                                        embellishment.checked &&
                                                                        embellishment.title ===
                                                                        "Trim"
                                                                ) && (
                                                                        <span>
                                                                            {this.props.data.trims.map(
                                                                                (
                                                                                    trim: any,
                                                                                    index: number
                                                                                ) => (
                                                                                    <span
                                                                                        key={
                                                                                            index
                                                                                        }
                                                                                    >
                                                                                        {trim &&
                                                                                            trim.draperyOrderFabric &&
                                                                                            (trim.draperyOrderFabric.name.includes(
                                                                                                "TRIM FABRIC"
                                                                                            )
                                                                                                ? " Trim from Fabric"
                                                                                                : trim.draperyOrderFabric.name.includes(
                                                                                                    "TRIM (PRE-MADE)"
                                                                                                )
                                                                                                    ? " Trim (Pre-Made)"
                                                                                                    : " Trim")}
                                                                                        {trim
                                                                                            .topEdge
                                                                                            .active
                                                                                            ? " on Top Edge" +
                                                                                            (trim
                                                                                                .bottomEdge
                                                                                                .active >
                                                                                                0
                                                                                                ? ","
                                                                                                : "")
                                                                                            : ""}
                                                                                        {trim
                                                                                            .bottomEdge
                                                                                            .active
                                                                                            ? trim
                                                                                                .topEdge
                                                                                                .active
                                                                                                ? " Bottom Edge"
                                                                                                : " on Bottom Edge"
                                                                                            : ""}
                                                                                        {trim
                                                                                            .leadReturnEdge
                                                                                            .active &&
                                                                                            trim.totalStrips >
                                                                                            0 &&
                                                                                            trim
                                                                                                .topEdge
                                                                                                .active &&
                                                                                            trim
                                                                                                .bottomEdge
                                                                                                .active
                                                                                            ? ","
                                                                                            : ""}
                                                                                        {trim
                                                                                            .leadReturnEdge
                                                                                            .active &&
                                                                                            trim.totalStrips >
                                                                                            0 &&
                                                                                            !trim
                                                                                                .topEdge
                                                                                                .active &&
                                                                                            !trim
                                                                                                .bottomEdge
                                                                                                .active
                                                                                            ? " on"
                                                                                            : trim
                                                                                                .leadReturnEdge
                                                                                                .active &&
                                                                                                trim.totalStrips >
                                                                                                0 &&
                                                                                                (trim
                                                                                                    .topEdge
                                                                                                    .active ||
                                                                                                    trim
                                                                                                        .bottomEdge
                                                                                                        .active)
                                                                                                ? " and"
                                                                                                : ""}
                                                                                        {trim
                                                                                            .leadReturnEdge
                                                                                            .active &&
                                                                                            trim.totalStrips >
                                                                                            0
                                                                                            ? ` ${trim.totalStrips
                                                                                            } Vertical Edge${trim.totalStrips >
                                                                                                1
                                                                                                ? "s"
                                                                                                : ""
                                                                                            }`
                                                                                            : ""}
                                                                                        .
                                                                                    </span>
                                                                                )
                                                                            )}
                                                                        </span>
                                                                    )}
                                                                {this.props.data.embellishmentsList.some(
                                                                    (
                                                                        embellishment: any
                                                                    ) =>
                                                                        embellishment.checked &&
                                                                        embellishment.stateName ===
                                                                        "othersEmbellishments"
                                                                ) && (
                                                                        <span>
                                                                            {" "}
                                                                            {this.props.data.othersEmbellishments
                                                                                .map(
                                                                                    (
                                                                                        other: any
                                                                                    ) =>
                                                                                        other.name
                                                                                )
                                                                                .join(", ")}
                                                                            {"."}
                                                                        </span>
                                                                    )}
                                                                {!this.props.data.embellishmentsList.some(
                                                                    (
                                                                        embellishment: any
                                                                    ) =>
                                                                        embellishment.checked
                                                                ) && <span> None</span>}
                                                            </>
                                                        ) : (
                                                            <span></span>
                                                        )}
                                                    </SummaryTableCell>
                                                </>
                                            )}
                                        </SummaryTableCol>
                                        <SummaryTableCol
                                            text="fabrics-center"
                                            color="header"
                                            style={{ minWidth: '5%', maxWidth: '10%' }}
                                        >
                                            <SummaryTableCell text="title">
                                                Quantity
                                            </SummaryTableCell>

                                            {workOrderEditMode ? (null) : (
                                                <SummaryTableCell
                                                    style={{
                                                        textAlign: "left",
                                                        textTransform:
                                                            "uppercase",
                                                        whiteSpace: "pre-wrap",
                                                    }}
                                                >
                                                    <span className="boldText">{quantity}</span>
                                                </SummaryTableCell>
                                            )}
                                        </SummaryTableCol>
                                        <SummaryTableCol
                                            text="fabrics"
                                            color="header"
                                            style={{ minWidth: '20%', maxWidth: '25%' }}
                                        >
                                            <SummaryTableCell
                                                text="title"
                                                style={{ whiteSpace: "nowrap" }}
                                            >
                                                Fabrication price <span>&nbsp;</span><span style={{ fontStyle: 'italic' }}>(per unit)</span>
                                            </SummaryTableCell>

                                            {workOrderEditMode ? (null) : (
                                                <SummaryTableCell text="total-per-unit">
                                                    <span
                                                        style={{
                                                            fontWeight: "600",
                                                            paddingBottom: "20px",
                                                        }}
                                                    >
                                                        $ {totalPerUnit} TOTAL PER UNIT
                                                    </span>
                                                    <br></br>
                                                    <br></br>
                                                    <div className="nonBold">
                                                        <span className="basePrice"></span>
                                                    </div>
                                                    <div className="price-item nonBold" style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <span className="liningPrice" style={{ marginLeft: '5px' }}></span>
                                                        <span className="liningMaterialPrice" style={{ marginLeft: '5px' }}></span>
                                                    </div>
                                                    <div className="price-item nonBold" style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <span className="interliningPrice" style={{ marginLeft: '5px' }}></span>
                                                        <span className="interliningMaterialPrice" style={{ marginLeft: '5px' }}></span>
                                                    </div>
                                                    <div className="price-item nonBold" style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <span className="bandingPrice"></span>
                                                        <span className="cordingPrice"></span>
                                                        <span className="cordingFabricPrice"></span>
                                                        <span className="flangePrice"></span>
                                                        <span className="flangeFabricPrice"></span>
                                                        <span className="trimPrice"></span>
                                                        <span className="trimFabricPrice"></span>
                                                        <span className="othersEmbelishmentsPrice"></span>
                                                    </div>
                                                    <div className="price-item" style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <span className="pinningPrice"></span>
                                                        <span className="weightPrice"></span>
                                                        <span className="cafePrice"></span>
                                                        <span className="extraLongPrice"></span>
                                                        <span className="extraWidePrice"></span>
                                                    </div>
                                                    <div className="price-item" style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <span className="specialityPrice"></span>
                                                        <span className="specialityPriceLining"></span>
                                                        <span className="specialityPriceInterlining"></span>
                                                        <span className="specialityPriceBanding"></span>
                                                        <span className="specialityPriceCordingPreMade"></span>
                                                        <span className="specialityPriceCordingFabric"></span>
                                                        <span className="specialityPriceFlangePreMade"></span>
                                                        <span className="specialityPriceFlangeFabric"></span>
                                                        <span className="specialityPriceTrimPreMade"></span>
                                                        <span className="specialityPriceTrimFabric"></span>
                                                        <span className="specialityPriceOther"></span>
                                                    </div>
                                                    <br></br>
                                                    <br></br>
                                                </SummaryTableCell>
                                            )}
                                        </SummaryTableCol>
                                        <SummaryTableCol
                                            text="fabrics"
                                            color="header"
                                            style={{ minWidth: '13%', maxWidth: '18%' }}
                                        >
                                            <SummaryTableCell text="title">
                                                Fabrication Price
                                            </SummaryTableCell>

                                            {workOrderEditMode ? (null) : (
                                                <SummaryTableCell>
                                                    <span
                                                        style={{
                                                            fontWeight: "600",
                                                            paddingBottom: "20px",
                                                        }}
                                                    >
                                                        $ {totalAmount} TOTAL
                                                    </span>
                                                </SummaryTableCell>
                                            )}
                                        </SummaryTableCol>
                                    </SummaryTable>

                                    {workOrderEditMode && (
                                        loadingUpdate ? (
                                            <div className="work_order_edit_mode_alert_container">
                                                <Loader uiSize="2em" />
                                            </div>
                                        ) : (
                                            <div className="work_order_edit_mode_alert_container">
                                                <RedWarning />
                                                <p className="work_order_edit_mode_alert_message">This field will be re-calculated after update</p>
                                            </div>
                                        )
                                    )}
                                </HContainer>
                            ) : (
                                <HContainer text="fabrics">
                                    <SummaryTable
                                    >
                                        <SummaryTableCol>
                                            <SummaryTableCell
                                                text="title"
                                            >
                                                Work Order
                                            </SummaryTableCell>
                                            <SummaryTableCell>
                                            </SummaryTableCell>
                                            <SummaryTableCell>
                                            </SummaryTableCell>
                                            <SummaryTableCell>
                                            </SummaryTableCell>
                                        </SummaryTableCol>
                                        <SummaryTableCol>
                                            <SummaryTableCell
                                                text="title"
                                            >
                                                Description <span>&nbsp;</span><span style={{ fontStyle: 'italic' }}>(per unit)</span>
                                            </SummaryTableCell>
                                        </SummaryTableCol>
                                        <SummaryTableCol>
                                            <SummaryTableCell
                                                text="title"
                                            >
                                                Quantity
                                            </SummaryTableCell>
                                        </SummaryTableCol>

                                        <SummaryTableCol>
                                            <SummaryTableCell
                                                text="title"
                                                style={{ whiteSpace: 'nowrap' }}
                                            >
                                                Fabrication price <span>&nbsp;</span><span style={{ fontStyle: 'italic' }}>(per unit)</span>
                                            </SummaryTableCell>
                                        </SummaryTableCol>
                                        <SummaryTableCol>
                                            <SummaryTableCell
                                                text="title"
                                            >
                                                Fabrication price
                                            </SummaryTableCell>
                                        </SummaryTableCol>
                                    </SummaryTable>
                                </HContainer>
                            )}

                            {this.props.data &&
                                this.props.data.workOrderPdfContentSummery &&
                                this.props.data.workOrder.id > 0 ? (
                                <HContainer
                                    text="fabrics"
                                    uiColumnDirection
                                >
                                    <SummaryTable>
                                        <SummaryTableCol
                                            text="fabrics"
                                            color="header"
                                        >
                                            <SummaryTableCell
                                                className="fabricHeader"
                                                text="title"
                                                style={{
                                                    fontSize:
                                                        window.innerWidth <
                                                            992
                                                            ? "10px"
                                                            : "inherit",
                                                }}
                                            >
                                                Fabrics
                                            </SummaryTableCell>

                                            {workOrderEditMode ? (null) : (
                                                <SummaryTableCell text="fabrics">
                                                    <WorkOrderNumber className="left">
                                                        <div>
                                                            <p>
                                                                {this.props.data
                                                                    .mainFabric &&
                                                                    this.props
                                                                        .data
                                                                        .mainFabric
                                                                        .name}
                                                            </p>
                                                            <p>
                                                                {this.props.data.mainFabric && (
                                                                    this.props.data.mainFabric.supplierName
                                                                        ? this.props.data.mainFabric.supplierName
                                                                        : this.props.data.mainFabric.customSupplier
                                                                            ? this.props.data.mainFabric.customSupplier
                                                                            : this.props.data.mainFabric.orderOrigin === 1
                                                                                ? "Unspecified"
                                                                                : this.props.data.mainFabric.orderOrigin === 2
                                                                                    ? "COM"
                                                                                    : ""
                                                                )}
                                                            </p>
                                                            <p>
                                                                {(this.props
                                                                    .data
                                                                    .mainFabric &&
                                                                    this.props
                                                                        .data
                                                                        .mainFabric
                                                                        .pattern &&
                                                                    this.props
                                                                        .data
                                                                        .mainFabric
                                                                        .pattern) ||
                                                                    "Unspecified"}
                                                            </p>

                                                            <div>
                                                                {this.props
                                                                    .data &&
                                                                    this.props
                                                                        .data
                                                                        .fabricsPatternItems &&
                                                                    this.props.data.fabricsPatternItems
                                                                        .filter(
                                                                            (
                                                                                item: any
                                                                            ) =>
                                                                                item.name ===
                                                                                this
                                                                                    .props
                                                                                    .data
                                                                                    .mainFabric
                                                                                    .pattern
                                                                        )
                                                                        .map(
                                                                            (
                                                                                item: any
                                                                            ) => {
                                                                                return (
                                                                                    <div
                                                                                        style={{
                                                                                            color: "#545454",
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            item.itemNumber
                                                                                        }
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        )}
                                                            </div>
                                                            <WorkorderMeasureWrapper>
                                                                {this.props
                                                                    .data &&
                                                                    this.props
                                                                        .data
                                                                        .fabricsPatternItems &&
                                                                    Object.values(
                                                                        this
                                                                            .props
                                                                            .data
                                                                            .mainFabric
                                                                            .values
                                                                    ).map(
                                                                        (
                                                                            value,
                                                                            index
                                                                        ) => {
                                                                            return (
                                                                                <WorkorderMeasure
                                                                                    className="last-separator"
                                                                                    style={{
                                                                                        color: "#545454",
                                                                                    }}
                                                                                >
                                                                                    <span>
                                                                                        {" "}
                                                                                        {
                                                                                            property[
                                                                                            index
                                                                                            ]
                                                                                        }
                                                                                    </span>
                                                                                    <p>
                                                                                        {
                                                                                            value
                                                                                        }

                                                                                        "
                                                                                        <span>
                                                                                            /
                                                                                        </span>
                                                                                    </p>
                                                                                </WorkorderMeasure>
                                                                            );
                                                                        }
                                                                    )}
                                                            </WorkorderMeasureWrapper>
                                                        </div>
                                                    </WorkOrderNumber>

                                                    {this.props.data.workOrderPdfContentSummery.bandingFabricContents.map(
                                                        (
                                                            item: any,
                                                            index: number
                                                        ) => {
                                                            const newItem =
                                                                item && item.pattern;
                                                            return (
                                                                <WorkOrderNumber className="left">
                                                                    <div>
                                                                        <p>
                                                                            {
                                                                                item.fabric
                                                                            }
                                                                        </p>
                                                                        <p>
                                                                            {item.supplier
                                                                                ? item.supplier
                                                                                : item.orderOrigin === 1
                                                                                    ? "Unspecified"
                                                                                    : item.orderOrigin === 2
                                                                                        ? "COM"
                                                                                        : item.name.split("/")[0] || ""}
                                                                        </p>
                                                                        <p>
                                                                            {item.pattern ||
                                                                                "Unspecified"}
                                                                        </p>

                                                                        <div>
                                                                            {this
                                                                                .props
                                                                                .data &&
                                                                                this
                                                                                    .props
                                                                                    .data
                                                                                    .fabricsPatternItems &&
                                                                                this.props.data.fabricsPatternItems
                                                                                    .filter(
                                                                                        (
                                                                                            item: any,
                                                                                            index: number
                                                                                        ) => {
                                                                                            return (
                                                                                                item.name ===
                                                                                                newItem
                                                                                            );
                                                                                        }
                                                                                    )
                                                                                    .map(
                                                                                        (
                                                                                            item: any
                                                                                        ) => {
                                                                                            return (
                                                                                                <div
                                                                                                    style={{
                                                                                                        color: "#545454",
                                                                                                    }}
                                                                                                >
                                                                                                    {
                                                                                                        item.itemNumber
                                                                                                    }
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                        </div>
                                                                        <WorkorderMeasureWrapper>
                                                                            {this
                                                                                .props
                                                                                .data &&
                                                                                this
                                                                                    .props
                                                                                    .data
                                                                                    .fabricsPatternItems &&
                                                                                Object.entries(
                                                                                    this
                                                                                        .props
                                                                                        .data
                                                                                        .workOrderPdfContentSummery
                                                                                        .bandingFabricContents[
                                                                                        index
                                                                                    ]
                                                                                        .trimWidth
                                                                                )
                                                                                    .filter(([key, value]) => key !== 'trimWidth')
                                                                                    .map(
                                                                                        (
                                                                                            [key, value],
                                                                                            index
                                                                                        ) => {
                                                                                            return (
                                                                                                <WorkorderMeasure
                                                                                                    className="last-separator"
                                                                                                    style={{
                                                                                                        color: "#545454",
                                                                                                    }}
                                                                                                >
                                                                                                    <span>
                                                                                                        {" "}
                                                                                                        {
                                                                                                            property[
                                                                                                            index
                                                                                                            ]
                                                                                                        }
                                                                                                    </span>
                                                                                                    <p>
                                                                                                        {
                                                                                                            value
                                                                                                        }

                                                                                                        "
                                                                                                        <span>
                                                                                                            /
                                                                                                        </span>
                                                                                                    </p>
                                                                                                </WorkorderMeasure>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                        </WorkorderMeasureWrapper>
                                                                    </div>
                                                                </WorkOrderNumber>
                                                            );
                                                        }
                                                    )}
                                                    {this.props.data.workOrderPdfContentSummery.premadeCordingFabricContents.map(
                                                        (item: any) => {
                                                            const newItem =
                                                                item && item.pattern;
                                                            return (
                                                                <WorkOrderNumber className="left">
                                                                    <div>
                                                                        <p>
                                                                            {
                                                                                item.fabric
                                                                            }
                                                                        </p>
                                                                        <p>
                                                                            {item.supplier
                                                                                ? item.supplier
                                                                                : item.name.split("/")[0]
                                                                                    ? item.name.split("/")[0]
                                                                                    : item.fabric.orderOrigin === 1
                                                                                        ? "Unspecified"
                                                                                        : item.fabric.orderOrigin === 2
                                                                                            ? "COM"
                                                                                            : ""}
                                                                        </p>
                                                                        <p>
                                                                            {item.pattern ||
                                                                                "Unspecified"}
                                                                        </p>

                                                                        <div>
                                                                            {this
                                                                                .props
                                                                                .data &&
                                                                                this
                                                                                    .props
                                                                                    .data
                                                                                    .fabricsPatternItems &&
                                                                                this.props.data.fabricsPatternItems
                                                                                    .filter(
                                                                                        (
                                                                                            item: any,
                                                                                            index: number
                                                                                        ) => {
                                                                                            return (
                                                                                                item.name ===
                                                                                                newItem
                                                                                            );
                                                                                        }
                                                                                    )
                                                                                    .map(
                                                                                        (
                                                                                            item: any
                                                                                        ) => {
                                                                                            return (
                                                                                                <div
                                                                                                    style={{
                                                                                                        color: "#545454",
                                                                                                    }}
                                                                                                >
                                                                                                    {
                                                                                                        item.itemNumber
                                                                                                    }
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                        </div>
                                                                    </div>
                                                                </WorkOrderNumber>
                                                            );
                                                        }
                                                    )}
                                                    {this.props.data.workOrderPdfContentSummery.premadeFlangeFabricContents.map(
                                                        (item: any) => {
                                                            const newItem =
                                                                item && item.pattern;
                                                            return (
                                                                <WorkOrderNumber className="left">
                                                                    <div>
                                                                        <p>
                                                                            {
                                                                                item.fabric
                                                                            }
                                                                        </p>
                                                                        <p>
                                                                            {item.supplier
                                                                                ? item.supplier
                                                                                : item.name.split("/")[0]
                                                                                    ? item.name.split("/")[0]
                                                                                    : item.fabric.orderOrigin === 1
                                                                                        ? "Unspecified"
                                                                                        : item.fabric.orderOrigin === 2
                                                                                            ? "COM"
                                                                                            : ""}
                                                                        </p>
                                                                        <p>
                                                                            {item.pattern ||
                                                                                "Unspecified"}
                                                                        </p>

                                                                        <div>
                                                                            {this
                                                                                .props
                                                                                .data &&
                                                                                this
                                                                                    .props
                                                                                    .data
                                                                                    .fabricsPatternItems &&
                                                                                this.props.data.fabricsPatternItems
                                                                                    .filter(
                                                                                        (
                                                                                            item: any,
                                                                                            index: number
                                                                                        ) => {
                                                                                            return (
                                                                                                item.name ===
                                                                                                newItem
                                                                                            );
                                                                                        }
                                                                                    )
                                                                                    .map(
                                                                                        (
                                                                                            item: any
                                                                                        ) => {
                                                                                            return (
                                                                                                <div
                                                                                                    style={{
                                                                                                        color: "#545454",
                                                                                                    }}
                                                                                                >
                                                                                                    {
                                                                                                        item.itemNumber
                                                                                                    }
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                        </div>
                                                                    </div>
                                                                </WorkOrderNumber>
                                                            );
                                                        }
                                                    )}
                                                    {this.props.data.workOrderPdfContentSummery.premadeTrimFabricContents.map(
                                                        (item: any) => {
                                                            const newItem =
                                                                item && item.pattern;
                                                            return (
                                                                <WorkOrderNumber className="left">
                                                                    <div>
                                                                        <p>
                                                                            {
                                                                                item.fabric
                                                                            }
                                                                        </p>
                                                                        <p>
                                                                            {item.supplier
                                                                                ? item.supplier
                                                                                : item.name.split("/")[0]
                                                                                    ? item.name.split("/")[0]
                                                                                    : item.fabric.orderOrigin === 1
                                                                                        ? "Unspecified"
                                                                                        : item.fabric.orderOrigin === 2
                                                                                            ? "COM"
                                                                                            : ""}
                                                                        </p>
                                                                        <p>
                                                                            {item.pattern ||
                                                                                "Unspecified"}
                                                                        </p>

                                                                        <div>
                                                                            {this
                                                                                .props
                                                                                .data &&
                                                                                this
                                                                                    .props
                                                                                    .data
                                                                                    .fabricsPatternItems &&
                                                                                this.props.data.fabricsPatternItems
                                                                                    .filter(
                                                                                        (
                                                                                            item: any,
                                                                                            index: number
                                                                                        ) => {
                                                                                            return (
                                                                                                item.name ===
                                                                                                newItem
                                                                                            );
                                                                                        }
                                                                                    )
                                                                                    .map(
                                                                                        (
                                                                                            item: any
                                                                                        ) => {
                                                                                            return (
                                                                                                <div
                                                                                                    style={{
                                                                                                        color: "#545454",
                                                                                                    }}
                                                                                                >
                                                                                                    {
                                                                                                        item.itemNumber
                                                                                                    }
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                        </div>
                                                                        {item.trimWidth && (
                                                                            <div>
                                                                                <span>
                                                                                    W:
                                                                                </span>
                                                                                {
                                                                                    item.trimWidth
                                                                                }
                                                                                "
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </WorkOrderNumber>
                                                            );
                                                        }
                                                    )}
                                                    {this.props.data.workOrderPdfContentSummery.weltCordingFabricContents.map(
                                                        (item: any) => {
                                                            const newItem =
                                                                item && item.pattern;
                                                            return (
                                                                <WorkOrderNumber className="left">
                                                                    <div>
                                                                        <p>
                                                                            {
                                                                                item.fabric
                                                                            }
                                                                        </p>
                                                                        <p>
                                                                            {item.supplier
                                                                                ? item.supplier
                                                                                : item.name.split("/")[0]
                                                                                    ? item.name.split("/")[0]
                                                                                    : item.fabric.orderOrigin === 1
                                                                                        ? "Unspecified"
                                                                                        : item.fabric.orderOrigin === 2
                                                                                            ? "COM"
                                                                                            : ""}
                                                                        </p>
                                                                        <p>
                                                                            {item.pattern ||
                                                                                "Unspecified"}
                                                                        </p>

                                                                        <div>
                                                                            {this
                                                                                .props
                                                                                .data &&
                                                                                this
                                                                                    .props
                                                                                    .data
                                                                                    .fabricsPatternItems &&
                                                                                this.props.data.fabricsPatternItems
                                                                                    .filter(
                                                                                        (
                                                                                            item: any,
                                                                                            index: number
                                                                                        ) => {
                                                                                            return (
                                                                                                item.name ===
                                                                                                newItem
                                                                                            );
                                                                                        }
                                                                                    )
                                                                                    .map(
                                                                                        (
                                                                                            item: any
                                                                                        ) => {
                                                                                            return (
                                                                                                <div
                                                                                                    style={{
                                                                                                        color: "#545454",
                                                                                                    }}
                                                                                                >
                                                                                                    {
                                                                                                        item.itemNumber
                                                                                                    }
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                        </div>
                                                                        <div>
                                                                            <span>
                                                                                W:
                                                                            </span>
                                                                            {
                                                                                item.trimWidth
                                                                            }
                                                                            "
                                                                        </div>
                                                                    </div>
                                                                </WorkOrderNumber>
                                                            );
                                                        }
                                                    )}
                                                    {this.props.data.workOrderPdfContentSummery.fabricFlangeFabricContents.map(
                                                        (item: any) => {
                                                            const newItem =
                                                                item && item.pattern;

                                                            return (
                                                                <WorkOrderNumber className="left">
                                                                    <div>
                                                                        <p>
                                                                            {
                                                                                item.fabric
                                                                            }
                                                                        </p>
                                                                        <p>
                                                                            {item.supplier
                                                                                ? item.supplier
                                                                                : item.name.split("/")[0]
                                                                                    ? item.name.split("/")[0]
                                                                                    : item.fabric.orderOrigin === 1
                                                                                        ? "Unspecified"
                                                                                        : item.fabric.orderOrigin === 2
                                                                                            ? "COM"
                                                                                            : ""}
                                                                        </p>
                                                                        <p>
                                                                            {item.pattern ||
                                                                                "Unspecified"}
                                                                        </p>

                                                                        <div>
                                                                            {this
                                                                                .props
                                                                                .data &&
                                                                                this
                                                                                    .props
                                                                                    .data
                                                                                    .fabricsPatternItems &&
                                                                                this.props.data.fabricsPatternItems
                                                                                    .filter(
                                                                                        (
                                                                                            item: any,
                                                                                            index: number
                                                                                        ) => {
                                                                                            return (
                                                                                                item.name ===
                                                                                                newItem
                                                                                            );
                                                                                        }
                                                                                    )
                                                                                    .map(
                                                                                        (
                                                                                            item: any
                                                                                        ) => {
                                                                                            return (
                                                                                                <div
                                                                                                    style={{
                                                                                                        color: "#545454",
                                                                                                    }}
                                                                                                >
                                                                                                    {
                                                                                                        item.itemNumber
                                                                                                    }
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                        </div>
                                                                        <div>
                                                                            <span>
                                                                                W:
                                                                            </span>
                                                                            {
                                                                                item.trimWidth
                                                                            }
                                                                            "
                                                                        </div>
                                                                    </div>
                                                                </WorkOrderNumber>
                                                            );
                                                        }
                                                    )}
                                                    {this.props.data.workOrderPdfContentSummery.fabricTrimFabricContents.map(
                                                        (
                                                            item: any,
                                                            index: number
                                                        ) => {
                                                            const newItem =
                                                                item && item.pattern;
                                                            return (
                                                                <WorkOrderNumber className="left">
                                                                    <div>
                                                                        <p>
                                                                            {
                                                                                item.fabric
                                                                            }
                                                                        </p>
                                                                        <p>
                                                                            {item.supplier
                                                                                ? item.supplier
                                                                                : item.name.split("/")[0]
                                                                                    ? item.name.split("/")[0]
                                                                                    : item.fabric.orderOrigin === 1
                                                                                        ? "Unspecified"
                                                                                        : item.fabric.orderOrigin === 2
                                                                                            ? "COM"
                                                                                            : ""}
                                                                        </p>
                                                                        <p>
                                                                            {item.pattern ||
                                                                                "Unspecified"}
                                                                        </p>

                                                                        <div>
                                                                            {this
                                                                                .props
                                                                                .data &&
                                                                                this
                                                                                    .props
                                                                                    .data
                                                                                    .fabricsPatternItems &&
                                                                                this.props.data.fabricsPatternItems
                                                                                    .filter(
                                                                                        (
                                                                                            item: any,
                                                                                            index: number
                                                                                        ) => {
                                                                                            return (
                                                                                                item.name ===
                                                                                                newItem
                                                                                            );
                                                                                        }
                                                                                    )
                                                                                    .map(
                                                                                        (
                                                                                            item: any
                                                                                        ) => {
                                                                                            return (
                                                                                                <div
                                                                                                    style={{
                                                                                                        color: "#545454",
                                                                                                    }}
                                                                                                >
                                                                                                    {
                                                                                                        item.itemNumber
                                                                                                    }
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                        </div>
                                                                        <WorkorderMeasureWrapper>
                                                                            {this
                                                                                .props
                                                                                .data &&
                                                                                this
                                                                                    .props
                                                                                    .data
                                                                                    .fabricsPatternItems &&
                                                                                Object.entries(
                                                                                    this
                                                                                        .props
                                                                                        .data
                                                                                        .workOrderPdfContentSummery
                                                                                        .fabricTrimFabricContents[
                                                                                        index
                                                                                    ]
                                                                                        .trimWidth
                                                                                )
                                                                                    .filter(([key, value]) => key !== 'trimWidth')
                                                                                    .map(
                                                                                        (
                                                                                            [key, value],
                                                                                            index
                                                                                        ) => {
                                                                                            return (
                                                                                                <WorkorderMeasure
                                                                                                    className="last-separator"
                                                                                                    style={{
                                                                                                        color: "#545454",
                                                                                                    }}
                                                                                                >
                                                                                                    <span>
                                                                                                        {" "}
                                                                                                        {
                                                                                                            property[
                                                                                                            index
                                                                                                            ]
                                                                                                        }
                                                                                                    </span>
                                                                                                    <p>
                                                                                                        {
                                                                                                            value
                                                                                                        }

                                                                                                        "
                                                                                                        <span>
                                                                                                            /
                                                                                                        </span>
                                                                                                    </p>
                                                                                                </WorkorderMeasure>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                        </WorkorderMeasureWrapper>
                                                                    </div>
                                                                </WorkOrderNumber>
                                                            );
                                                        }
                                                    )}
                                                    {this.props.data &&
                                                        this.props.data
                                                            .liningsList &&
                                                        this.props.data.liningsList.map(
                                                            (item: any) => {
                                                                const newItem =
                                                                    item &&
                                                                        item.draperyOrderLiningsFabric &&
                                                                        item
                                                                            .draperyOrderLiningsFabric
                                                                            .pattern &&
                                                                        item
                                                                            .draperyOrderLiningsFabric
                                                                            .orderOrigin ===
                                                                        1
                                                                        ? item
                                                                            .draperyOrderLiningsFabric
                                                                            .pattern
                                                                        : item.draperyOrderLiningsFabric;
                                                                if (newItem) {
                                                                    return (
                                                                        <WorkOrderNumber className="left">
                                                                            <div>
                                                                                <p>
                                                                                    {
                                                                                        item
                                                                                            .draperyOrderLiningsFabric
                                                                                            .name
                                                                                    }
                                                                                </p>
                                                                                <p>
                                                                                    {item.draperyOrderLiningsFabric.supplierName
                                                                                        ? item.draperyOrderLiningsFabric.supplierName
                                                                                        : item.draperyOrderLiningsFabric.customSupplier
                                                                                            ? item.draperyOrderLiningsFabric.customSupplier
                                                                                            : item.draperyOrderLiningsFabric.orderOrigin === 1
                                                                                                ? "Unspecified"
                                                                                                : item.draperyOrderLiningsFabric.orderOrigin === 2
                                                                                                    ? "COM"
                                                                                                    : ""}
                                                                                </p>
                                                                                <p>
                                                                                    {item
                                                                                        .draperyOrderLiningsFabric
                                                                                        .pattern ||
                                                                                        "Unspecified"}
                                                                                </p>

                                                                                <div>
                                                                                    {this
                                                                                        .props
                                                                                        .data &&
                                                                                        this
                                                                                            .props
                                                                                            .data
                                                                                            .liningsPatternItems &&
                                                                                        this.props.data.liningsPatternItems
                                                                                            .filter(
                                                                                                (
                                                                                                    item: any,
                                                                                                    index: number
                                                                                                ) => {
                                                                                                    return (
                                                                                                        item.name ===
                                                                                                        newItem
                                                                                                    );
                                                                                                }
                                                                                            )
                                                                                            .map(
                                                                                                (
                                                                                                    item: any
                                                                                                ) => {
                                                                                                    return (
                                                                                                        <div
                                                                                                            style={{
                                                                                                                color: "#545454",
                                                                                                            }}
                                                                                                        >
                                                                                                            {
                                                                                                                item.itemNumber
                                                                                                            }
                                                                                                        </div>
                                                                                                    );
                                                                                                }
                                                                                            )}
                                                                                </div>
                                                                                <div>
                                                                                    <span className="mr-2">
                                                                                        W:
                                                                                    </span>
                                                                                    {item &&
                                                                                        item.draperyOrderLiningsFabric &&
                                                                                        item
                                                                                            .draperyOrderLiningsFabric
                                                                                            .values &&
                                                                                        item
                                                                                            .draperyOrderLiningsFabric
                                                                                            .values
                                                                                            .fabricWidth}

                                                                                    "
                                                                                </div>
                                                                            </div>
                                                                        </WorkOrderNumber>
                                                                    );
                                                                }
                                                            }
                                                        )}
                                                    {this.props.data
                                                        .othersEmbellishments[0]
                                                        .name &&
                                                        this.props.data.othersEmbellishments.map(
                                                            (
                                                                item: any,
                                                                index: number
                                                            ) => {
                                                                const newItem = item && item.draperyOrderFabric &&
                                                                item.draperyOrderFabric.pattern;
                                                                return (
                                                                    <WorkOrderNumber className="left">
                                                                        <div>
                                                                            <p>
                                                                                {item &&
                                                                                    item.name &&
                                                                                    `${item.name.toUpperCase()} FABRIC`}
                                                                            </p>
                                                                            <p>
                                                                                {item &&
                                                                                    item.draperyOrderFabric &&
                                                                                    item.draperyOrderFabric.supplierName
                                                                                    ? item.draperyOrderFabric.supplierName
                                                                                    : item && item.draperyOrderFabric && item.draperyOrderFabric.customSupplier
                                                                                        ? item && item.draperyOrderFabric && item.draperyOrderFabric.customSupplier
                                                                                        : item && item.draperyOrderFabric && item.draperyOrderFabric.orderOrigin === 1
                                                                                            ? "Unspecified"
                                                                                            : item && item.draperyOrderFabric && item.draperyOrderFabric.orderOrigin === 2
                                                                                                ? "COM"
                                                                                                : ""}
                                                                            </p>
                                                                            <p>
                                                                                {item &&
                                                                                    item.draperyOrderFabric &&
                                                                                    item
                                                                                        .draperyOrderFabric
                                                                                        .pattern
                                                                                    ? item
                                                                                        .draperyOrderFabric
                                                                                        .pattern
                                                                                    : "Unspecified"}
                                                                            </p>

                                                                            <div>
                                                                                {this
                                                                                    .props
                                                                                    .data &&
                                                                                    this
                                                                                        .props
                                                                                        .data
                                                                                        .fabricsPatternItems &&
                                                                                    this.props.data.fabricsPatternItems
                                                                                        .filter(
                                                                                            (
                                                                                                item: any,
                                                                                                index: number
                                                                                            ) => {
                                                                                                return (
                                                                                                    item.name ===
                                                                                                    newItem
                                                                                                );
                                                                                            }
                                                                                        )
                                                                                        .map(
                                                                                            (
                                                                                                item: any
                                                                                            ) => {
                                                                                                return (
                                                                                                    <div
                                                                                                        style={{
                                                                                                            color: "#545454",
                                                                                                        }}
                                                                                                    >
                                                                                                        {
                                                                                                            item.itemNumber
                                                                                                        }
                                                                                                    </div>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                            </div>
                                                                            <WorkorderMeasureWrapper>
                                                                            {this.props &&
this.props.data &&
this.props.data.fabricsPatternItems &&
this.props.data.othersEmbellishments &&
this.props.data.othersEmbellishments[index] &&
this.props.data.othersEmbellishments[index].draperyOrderFabric &&
this.props.data.othersEmbellishments[index].draperyOrderFabric.values &&
Object.entries(this.props.data.othersEmbellishments[index].draperyOrderFabric.values)
  .filter(([key, value]) => key !== 'trimWidth')
  .map(([key, value], index) => {
                                                                                                return (
                                                                                                    <WorkorderMeasure
                                                                                                        className="last-separator"
                                                                                                        style={{
                                                                                                            color: "#545454",
                                                                                                        }}
                                                                                                    >
                                                                                                        <span>
                                                                                                            {" "}
                                                                                                            {
                                                                                                                property[
                                                                                                                index
                                                                                                                ]
                                                                                                            }
                                                                                                        </span>
                                                                                                        <p>
                                                                                                            {
                                                                                                                value
                                                                                                            }

                                                                                                            "
                                                                                                            <span>
                                                                                                                /
                                                                                                            </span>
                                                                                                        </p>
                                                                                                    </WorkorderMeasure>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                            </WorkorderMeasureWrapper>
                                                                        </div>
                                                                    </WorkOrderNumber>
                                                                );
                                                            }
                                                        )}
                                                </SummaryTableCell>
                                            )}
                                        </SummaryTableCol>
                                        <SummaryTableCol text="not-fabrics">
                                            <SummaryTableCell
                                                className="fabricHeader"
                                                position="center"
                                                style={{
                                                    fontSize:
                                                        window.innerWidth <
                                                            992
                                                            ? "10px"
                                                            : "inherit",
                                                }}
                                            >
                                                Yardage
                                            </SummaryTableCell>
                                            {workOrderEditMode ? (null) : (
                                                <SummaryTableCell text="fabrics">
                                                    <WorkOrderNumber>
                                                        {
                                                            this.props.data
                                                                .workOrderPdfContentSummery
                                                                .mainFabricContent
                                                                .yardsNeeded
                                                        }
                                                    </WorkOrderNumber>
                                                    {this.props.data.workOrderPdfContentSummery.bandingFabricContents.map(
                                                        (content: any) => {
                                                            return (
                                                                <WorkOrderNumber>
                                                                    {
                                                                        content.yardsNeeded
                                                                    }
                                                                </WorkOrderNumber>
                                                            );
                                                        }
                                                    )}
                                                    {this.props.data.workOrderPdfContentSummery.premadeCordingFabricContents.map(
                                                        (content: any) => {
                                                            return (
                                                                <WorkOrderNumber>
                                                                    {
                                                                        content.yardsNeeded
                                                                    }
                                                                </WorkOrderNumber>
                                                            );
                                                        }
                                                    )}
                                                    {this.props.data.workOrderPdfContentSummery.premadeFlangeFabricContents.map(
                                                        (content: any) => {
                                                            return (
                                                                <WorkOrderNumber>
                                                                    {
                                                                        content.yardsNeeded
                                                                    }
                                                                </WorkOrderNumber>
                                                            );
                                                        }
                                                    )}
                                                    {this.props.data.workOrderPdfContentSummery.premadeTrimFabricContents.map(
                                                        (content: any) => {
                                                            return (
                                                                <WorkOrderNumber>
                                                                    {
                                                                        content.yardsNeeded
                                                                    }
                                                                </WorkOrderNumber>
                                                            );
                                                        }
                                                    )}
                                                    {this.props.data.workOrderPdfContentSummery.weltCordingFabricContents.map(
                                                        (content: any) => {
                                                            return (
                                                                <WorkOrderNumber>
                                                                    {
                                                                        content.yardsNeeded
                                                                    }
                                                                </WorkOrderNumber>
                                                            );
                                                        }
                                                    )}
                                                    {this.props.data.workOrderPdfContentSummery.fabricFlangeFabricContents.map(
                                                        (content: any) => {
                                                            return (
                                                                <WorkOrderNumber>
                                                                    {
                                                                        content.yardsNeeded
                                                                    }
                                                                </WorkOrderNumber>
                                                            );
                                                        }
                                                    )}
                                                    {this.props.data.workOrderPdfContentSummery.fabricTrimFabricContents.map(
                                                        (content: any) => {
                                                            return (
                                                                <WorkOrderNumber>
                                                                    {
                                                                        content.yardsNeeded
                                                                    }
                                                                </WorkOrderNumber>
                                                            );
                                                        }
                                                    )}
                                                    {this.props.data && this.props.data.liningsList && this.props.data.liningsList.length > 0
                                                        && this.props.data.liningsList[0] && this.props.data.liningsList[0].active && (
                                                            <WorkOrderNumber>
                                                                {this.props.data && this.props.data.liningsList && (
                                                                    <>
                                                                        {this.props.data.liningsList.map((item: any) => {
                                                                            if (
                                                                                item.draperyOrderLiningsFabric &&
                                                                                item.draperyOrderLiningsFabric.name === "LINING FABRIC"
                                                                            ) {
                                                                                liningMaterialYardage = item.draperyOrderLiningsFabric.yardsNeeded;
                                                                                return liningMaterialYardage;
                                                                            }
                                                                        })}
                                                                    </>
                                                                )}
                                                            </WorkOrderNumber>
                                                        )}
                                                    {this.props.data && this.props.data.liningsList && this.props.data.liningsList.length > 0
                                                        && this.props.data.liningsList[1] && this.props.data.liningsList[1].active && (
                                                            <WorkOrderNumber>
                                                                <>
                                                                    {this.props.data.liningsList.map((item: any) => {
                                                                        if (
                                                                            item.draperyOrderLiningsFabric &&
                                                                            item.draperyOrderLiningsFabric.name === "INTERLINING FABRIC"
                                                                        ) {
                                                                            interliningMaterialYardage = item.draperyOrderLiningsFabric.yardsNeeded;
                                                                            return interliningMaterialYardage;
                                                                        }
                                                                    })}
                                                                </>

                                                            </WorkOrderNumber>
                                                        )}
                                                    {this.props.data
                                                        .othersEmbellishments[0]
                                                        .name &&
                                                        this.props.data.othersEmbellishments.map(
                                                            (content: any) => {
                                                                return (
                                                                    <WorkOrderNumber>
                                                                        {
                                                                            content && content.draperyOrderFabric
                                                                            && content.draperyOrderFabric.yardsNeeded
                                                                        }
                                                                    </WorkOrderNumber>
                                                                );
                                                            }
                                                        )}
                                                </SummaryTableCell>
                                            )}
                                        </SummaryTableCol>
                                        <SummaryTableCol text="not-fabrics">
                                            <SummaryTableCell
                                                className="fabricHeader"
                                                position="center"
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    fontSize:
                                                        window.innerWidth <
                                                            992
                                                            ? "10px"
                                                            : "inherit",
                                                }}
                                            >
                                                Unit Price ($)
                                            </SummaryTableCell>

                                            {workOrderEditMode ? (null) : (
                                                <SummaryTableCell text="fabrics">
                                                    <WorkOrderNumber>
                                                        {this.props.data &&
                                                            this.props.data
                                                                .mainFabric
                                                                .orderOrigin ===
                                                            2 ? (
                                                            <WorkOrderNumber>
                                                                COM
                                                            </WorkOrderNumber>
                                                        ) : (
                                                            <WorkOrderNumber>
                                                                {
                                                                    this.state
                                                                        .prices
                                                                        .mainFabricUnitPrice
                                                                }
                                                            </WorkOrderNumber>
                                                        )}
                                                    </WorkOrderNumber>
                                                    {this.props.data.workOrderPdfContentSummery.bandingFabricContents.map(
                                                        (
                                                            item: any,
                                                            index: any
                                                        ) => {
                                                            const bandingUnitPrice =
                                                                [
                                                                    ...this
                                                                        .state
                                                                        .prices
                                                                        .bandingUnitPrice,
                                                                ];
                                                            for (
                                                                let i = 0;
                                                                i <
                                                                this.props.data
                                                                    .workOrderPdfContentSummery
                                                                    .bandingFabricContents
                                                                    .length;
                                                                i++
                                                            ) {
                                                                if (
                                                                    this.props
                                                                        .data
                                                                        .workOrderPdfContentSummery
                                                                        .bandingFabricContents[
                                                                        i
                                                                    ]
                                                                        .orderOrigin ===
                                                                    2
                                                                ) {
                                                                    bandingUnitPrice.splice(
                                                                        i,
                                                                        0,
                                                                        undefined
                                                                    );
                                                                }
                                                            }
                                                            return (
                                                                <>
                                                                    {bandingUnitPrice[
                                                                        index
                                                                    ] ? (
                                                                        <WorkOrderNumber>
                                                                            {
                                                                                bandingUnitPrice[
                                                                                    index
                                                                                ]
                                                                                    .price
                                                                            }
                                                                        </WorkOrderNumber>
                                                                    ) : (
                                                                        <WorkOrderNumber>
                                                                            COM
                                                                        </WorkOrderNumber>
                                                                    )}
                                                                </>
                                                            );
                                                        }
                                                    )}
                                                    {this.props.data.workOrderPdfContentSummery.premadeCordingFabricContents.map(
                                                        (
                                                            item: any,
                                                            index: number
                                                        ) => {
                                                            const cordingUnitPrice =
                                                                [
                                                                    ...this
                                                                        .state
                                                                        .prices
                                                                        .cordingUnitPrice,
                                                                ].filter(
                                                                    (item) =>
                                                                        item.subType ===
                                                                        0
                                                                );
                                                            for (
                                                                let i = 0;
                                                                i <
                                                                this.props.data
                                                                    .workOrderPdfContentSummery
                                                                    .premadeCordingFabricContents
                                                                    .length;
                                                                i++
                                                            ) {
                                                                if (
                                                                    this.props
                                                                        .data
                                                                        .workOrderPdfContentSummery
                                                                        .premadeCordingFabricContents[
                                                                        i
                                                                    ]
                                                                        .orderOrigin ===
                                                                    2
                                                                ) {
                                                                    cordingUnitPrice.splice(
                                                                        i,
                                                                        0,
                                                                        undefined
                                                                    );
                                                                }
                                                            }
                                                            return (
                                                                <>
                                                                    {cordingUnitPrice[
                                                                        index
                                                                    ] ? (
                                                                        <WorkOrderNumber>
                                                                            {
                                                                                cordingUnitPrice[
                                                                                    index
                                                                                ]
                                                                                    .price
                                                                            }{" "}
                                                                        </WorkOrderNumber>
                                                                    ) : (
                                                                        <WorkOrderNumber>
                                                                            COM
                                                                        </WorkOrderNumber>
                                                                    )}
                                                                </>
                                                            );
                                                        }
                                                    )}
                                                    {this.props.data.workOrderPdfContentSummery.premadeFlangeFabricContents.map(
                                                        (
                                                            item: any,
                                                            index: number
                                                        ) => {
                                                            const flangeUnitPrice =
                                                                [
                                                                    ...this
                                                                        .state
                                                                        .prices
                                                                        .flangeUnitPrice,
                                                                ].filter(
                                                                    (item) =>
                                                                        item.subType ===
                                                                        0
                                                                );
                                                            for (
                                                                let i = 0;
                                                                i <
                                                                this.props.data
                                                                    .workOrderPdfContentSummery
                                                                    .premadeFlangeFabricContents
                                                                    .length;
                                                                i++
                                                            ) {
                                                                if (
                                                                    this.props
                                                                        .data
                                                                        .workOrderPdfContentSummery
                                                                        .premadeFlangeFabricContents[
                                                                        i
                                                                    ]
                                                                        .orderOrigin ===
                                                                    2
                                                                ) {
                                                                    flangeUnitPrice.splice(
                                                                        i,
                                                                        0,
                                                                        undefined
                                                                    );
                                                                }
                                                            }
                                                            return (
                                                                <>
                                                                    {flangeUnitPrice[
                                                                        index
                                                                    ] ? (
                                                                        <WorkOrderNumber>
                                                                            {
                                                                                flangeUnitPrice[
                                                                                    index
                                                                                ]
                                                                                    .price
                                                                            }
                                                                        </WorkOrderNumber>
                                                                    ) : (
                                                                        <WorkOrderNumber>
                                                                            COM
                                                                        </WorkOrderNumber>
                                                                    )}
                                                                </>
                                                            );
                                                        }
                                                    )}
                                                    {this.props.data.workOrderPdfContentSummery.premadeTrimFabricContents.map(
                                                        (
                                                            item: any,
                                                            index: number
                                                        ) => {
                                                            const trimUnitPrice =
                                                                [
                                                                    ...this
                                                                        .state
                                                                        .prices
                                                                        .trimUnitPrice,
                                                                ].filter(
                                                                    (item) =>
                                                                        item.subType ===
                                                                        0
                                                                );
                                                            for (
                                                                let i = 0;
                                                                i <
                                                                this.props.data
                                                                    .workOrderPdfContentSummery
                                                                    .premadeTrimFabricContents
                                                                    .length;
                                                                i++
                                                            ) {
                                                                if (
                                                                    this.props
                                                                        .data
                                                                        .workOrderPdfContentSummery
                                                                        .premadeTrimFabricContents[
                                                                        i
                                                                    ]
                                                                        .orderOrigin ===
                                                                    2
                                                                ) {
                                                                    trimUnitPrice.splice(
                                                                        i,
                                                                        0,
                                                                        undefined
                                                                    );
                                                                }
                                                            }
                                                            return (
                                                                <>
                                                                    {trimUnitPrice[
                                                                        index
                                                                    ] ? (
                                                                        <WorkOrderNumber>
                                                                            {
                                                                                trimUnitPrice[
                                                                                    index
                                                                                ]
                                                                                    .price
                                                                            }
                                                                        </WorkOrderNumber>
                                                                    ) : (
                                                                        <WorkOrderNumber>
                                                                            COM
                                                                        </WorkOrderNumber>
                                                                    )}
                                                                </>
                                                            );
                                                        }
                                                    )}
                                                    {this.props.data.workOrderPdfContentSummery.weltCordingFabricContents.map(
                                                        (
                                                            item: any,
                                                            index: number
                                                        ) => {
                                                            const cordingUnitPrice =
                                                                [
                                                                    ...this
                                                                        .state
                                                                        .prices
                                                                        .cordingUnitPrice,
                                                                ].filter(
                                                                    (item) =>
                                                                        item.subType ===
                                                                        1
                                                                );
                                                            for (
                                                                let i = 0;
                                                                i <
                                                                this.props.data
                                                                    .workOrderPdfContentSummery
                                                                    .weltCordingFabricContents
                                                                    .length;
                                                                i++
                                                            ) {
                                                                if (
                                                                    this.props
                                                                        .data
                                                                        .workOrderPdfContentSummery
                                                                        .weltCordingFabricContents[
                                                                        i
                                                                    ]
                                                                        .orderOrigin ===
                                                                    2
                                                                ) {
                                                                    cordingUnitPrice.splice(
                                                                        i,
                                                                        0,
                                                                        undefined
                                                                    );
                                                                }
                                                            }

                                                            return (
                                                                <>
                                                                    {cordingUnitPrice[
                                                                        index
                                                                    ] ? (
                                                                        <WorkOrderNumber>
                                                                            {
                                                                                cordingUnitPrice[
                                                                                    index
                                                                                ]
                                                                                    .price
                                                                            }
                                                                        </WorkOrderNumber>
                                                                    ) : (
                                                                        <WorkOrderNumber>
                                                                            COM
                                                                        </WorkOrderNumber>
                                                                    )}
                                                                </>
                                                            );
                                                        }
                                                    )}
                                                    {this.props.data.workOrderPdfContentSummery.fabricFlangeFabricContents.map(
                                                        (
                                                            item: any,
                                                            index: number
                                                        ) => {
                                                            const flangeUnitPrice =
                                                                [
                                                                    ...this
                                                                        .state
                                                                        .prices
                                                                        .flangeUnitPrice,
                                                                ].filter(
                                                                    (item) =>
                                                                        item.subType ===
                                                                        1
                                                                );
                                                            for (
                                                                let i = 0;
                                                                i <
                                                                this.props.data
                                                                    .workOrderPdfContentSummery
                                                                    .fabricFlangeFabricContents
                                                                    .length;
                                                                i++
                                                            ) {
                                                                if (
                                                                    this.props
                                                                        .data
                                                                        .workOrderPdfContentSummery
                                                                        .fabricFlangeFabricContents[
                                                                        i
                                                                    ]
                                                                        .orderOrigin ===
                                                                    2
                                                                ) {
                                                                    flangeUnitPrice.splice(
                                                                        i,
                                                                        0,
                                                                        undefined
                                                                    );
                                                                }
                                                            }
                                                            return (
                                                                <>
                                                                    {flangeUnitPrice[
                                                                        index
                                                                    ] ? (
                                                                        <WorkOrderNumber>
                                                                            {
                                                                                flangeUnitPrice[
                                                                                    index
                                                                                ]
                                                                                    .price
                                                                            }
                                                                        </WorkOrderNumber>
                                                                    ) : (
                                                                        <WorkOrderNumber>
                                                                            COM
                                                                        </WorkOrderNumber>
                                                                    )}
                                                                </>
                                                            );
                                                        }
                                                    )}
                                                    {this.props.data.workOrderPdfContentSummery.fabricTrimFabricContents.map(
                                                        (
                                                            item: any,
                                                            index: number
                                                        ) => {
                                                            const trimUnitPrice =
                                                                [
                                                                    ...this
                                                                        .state
                                                                        .prices
                                                                        .trimUnitPrice,
                                                                ].filter(
                                                                    (item) =>
                                                                        item.subType ===
                                                                        1
                                                                );
                                                            for (
                                                                let i = 0;
                                                                i <
                                                                this.props.data
                                                                    .workOrderPdfContentSummery
                                                                    .fabricTrimFabricContents
                                                                    .length;
                                                                i++
                                                            ) {
                                                                if (
                                                                    this.props
                                                                        .data
                                                                        .workOrderPdfContentSummery
                                                                        .fabricTrimFabricContents[
                                                                        i
                                                                    ]
                                                                        .orderOrigin ===
                                                                    2
                                                                ) {
                                                                    trimUnitPrice.splice(
                                                                        i,
                                                                        0,
                                                                        undefined
                                                                    );
                                                                }
                                                            }
                                                            return (
                                                                <>
                                                                    {trimUnitPrice[
                                                                        index
                                                                    ] ? (
                                                                        <WorkOrderNumber>
                                                                            {
                                                                                trimUnitPrice[
                                                                                    index
                                                                                ]
                                                                                    .price
                                                                            }
                                                                        </WorkOrderNumber>
                                                                    ) : (
                                                                        <WorkOrderNumber>
                                                                            COM
                                                                        </WorkOrderNumber>
                                                                    )}
                                                                </>
                                                            );
                                                        }
                                                    )}
                                                    {this.props.data &&
                                                        this.props.data
                                                            .liningsList &&
                                                        this.props.data.liningsList.map(
                                                            (item: any) => {
                                                                const newItem =
                                                                    item &&
                                                                    item.draperyOrderLiningsFabric &&
                                                                    item.draperyOrderLiningsFabric;

                                                                if (newItem) {
                                                                    return (
                                                                        <>
                                                                            {this
                                                                                .props
                                                                                .data &&
                                                                                this
                                                                                    .props
                                                                                    .data
                                                                                    .liningsPatternItems &&
                                                                                item
                                                                                    .draperyOrderLiningsFabric
                                                                                    .orderOrigin ===
                                                                                2 ? (
                                                                                <WorkOrderNumber>
                                                                                    COM
                                                                                </WorkOrderNumber>
                                                                            ) : (
                                                                                <WorkOrderNumber>
                                                                                    {item.name.toLowerCase() ===
                                                                                        "lining"
                                                                                        ? this
                                                                                            .state
                                                                                            .prices
                                                                                            .liningsFabricUnitPrice
                                                                                        : this
                                                                                            .state
                                                                                            .prices
                                                                                            .interliningsFabricUnitPrice}
                                                                                </WorkOrderNumber>
                                                                            )}
                                                                        </>
                                                                    );
                                                                }
                                                            }
                                                        )}
                                                    {this.props.data
                                                        .othersEmbellishments[0]
                                                        .name &&
                                                        this.props.data.othersEmbellishments.map(
                                                            (
                                                                item: any,
                                                                index: number
                                                            ) => {
                                                                const othersEmbellishmentsUnitPrice =
                                                                    [
                                                                        ...this
                                                                            .state
                                                                            .prices
                                                                            .othersEmbellishmentsUnitPrice,
                                                                    ];
                                                                for (
                                                                    let i = 0;
                                                                    i <
                                                                    this.props
                                                                        .data
                                                                        .othersEmbellishments
                                                                        .length;
                                                                    i++
                                                                ) {
                                                                    if (
                                                                        this
                                                                            .props
                                                                            .data
                                                                            .othersEmbellishments[
                                                                            i
                                                                        ]
                                                                            .orderOrigin ===
                                                                        2
                                                                    ) {
                                                                        othersEmbellishmentsUnitPrice.splice(
                                                                            i,
                                                                            0,
                                                                            undefined
                                                                        );
                                                                    }
                                                                }
                                                                return (
                                                                    <>
                                                                        {othersEmbellishmentsUnitPrice[
                                                                            index
                                                                        ] ? (
                                                                            <WorkOrderNumber>
                                                                                {
                                                                                    othersEmbellishmentsUnitPrice[
                                                                                        index
                                                                                    ]
                                                                                        .price
                                                                                }
                                                                            </WorkOrderNumber>
                                                                        ) : (
                                                                            <WorkOrderNumber>
                                                                                COM
                                                                            </WorkOrderNumber>
                                                                        )}
                                                                    </>
                                                                );
                                                            }
                                                        )}
                                                </SummaryTableCell>
                                            )}
                                        </SummaryTableCol>{" "}
                                        <SummaryTableCol text="not-fabrics">
                                            <SummaryTableCell
                                                className="fabricHeader"
                                                position="center"
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    fontSize:
                                                        window.innerWidth <
                                                            992
                                                            ? "10px"
                                                            : "inherit",
                                                }}
                                            >
                                                S+H ($)
                                            </SummaryTableCell>

                                            {workOrderEditMode ? (null) : (
                                                <SummaryTableCell text="fabrics">
                                                    {this.props.data &&
                                                        this.props.data
                                                            .fabricsPatternItems &&
                                                        this.props.data.mainFabric
                                                            .orderOrigin === 2 ? (
                                                        <WorkOrderNumber>
                                                            COM
                                                        </WorkOrderNumber>
                                                    ) : (
                                                        <WorkOrderNumber>
                                                            {
                                                                this.state
                                                                    .prices
                                                                    .mainFabricShippingPrice
                                                            }
                                                        </WorkOrderNumber>
                                                    )}
                                                    {this.props.data.workOrderPdfContentSummery.bandingFabricContents.map(
                                                        (
                                                            item: any,
                                                            index: number
                                                        ) => {
                                                            const bandingShippingPrice =
                                                                [
                                                                    ...this
                                                                        .state
                                                                        .prices
                                                                        .bandingShippingPrice,
                                                                ];
                                                            for (
                                                                let i = 0;
                                                                i <
                                                                this.props.data
                                                                    .workOrderPdfContentSummery
                                                                    .bandingFabricContents
                                                                    .length;
                                                                i++
                                                            ) {
                                                                if (
                                                                    this.props
                                                                        .data
                                                                        .workOrderPdfContentSummery
                                                                        .bandingFabricContents[
                                                                        i
                                                                    ]
                                                                        .orderOrigin ===
                                                                    2
                                                                ) {
                                                                    bandingShippingPrice.splice(
                                                                        i,
                                                                        0,
                                                                        undefined
                                                                    );
                                                                }
                                                            }

                                                            return (
                                                                <div>
                                                                    {bandingShippingPrice[
                                                                        index
                                                                    ] ? (
                                                                        <WorkOrderNumber>
                                                                            {
                                                                                bandingShippingPrice[
                                                                                    index
                                                                                ]
                                                                                    .price
                                                                            }
                                                                        </WorkOrderNumber>
                                                                    ) : (
                                                                        <WorkOrderNumber>
                                                                            COM
                                                                        </WorkOrderNumber>
                                                                    )}
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                    {this.props.data.workOrderPdfContentSummery.premadeCordingFabricContents.map(
                                                        (
                                                            item: any,
                                                            index: number
                                                        ) => {
                                                            const cordingShippingPrice =
                                                                [
                                                                    ...this
                                                                        .state
                                                                        .prices
                                                                        .cordingShippingPrice,
                                                                ].filter(
                                                                    (item) =>
                                                                        item.subType ===
                                                                        0
                                                                );
                                                            for (
                                                                let i = 0;
                                                                i <
                                                                this.props.data
                                                                    .workOrderPdfContentSummery
                                                                    .premadeCordingFabricContents
                                                                    .length;
                                                                i++
                                                            ) {
                                                                if (
                                                                    this.props
                                                                        .data
                                                                        .workOrderPdfContentSummery
                                                                        .premadeCordingFabricContents[
                                                                        i
                                                                    ]
                                                                        .orderOrigin ===
                                                                    2
                                                                ) {
                                                                    cordingShippingPrice.splice(
                                                                        i,
                                                                        0,
                                                                        undefined
                                                                    );
                                                                }
                                                            }
                                                            return (
                                                                <div>
                                                                    {cordingShippingPrice[
                                                                        index
                                                                    ] ? (
                                                                        <WorkOrderNumber>
                                                                            {
                                                                                cordingShippingPrice[
                                                                                    index
                                                                                ]
                                                                                    .price
                                                                            }
                                                                        </WorkOrderNumber>
                                                                    ) : (
                                                                        <WorkOrderNumber>
                                                                            COM
                                                                        </WorkOrderNumber>
                                                                    )}
                                                                </div>
                                                            );
                                                        }
                                                    )}

                                                    {this.props.data.workOrderPdfContentSummery.premadeFlangeFabricContents.map(
                                                        (
                                                            item: any,
                                                            index: number
                                                        ) => {
                                                            const flangeShippingPrice =
                                                                [
                                                                    ...this
                                                                        .state
                                                                        .prices
                                                                        .flangeShippingPrice,
                                                                ].filter(
                                                                    (item) =>
                                                                        item.subType ===
                                                                        0
                                                                );
                                                            for (
                                                                let i = 0;
                                                                i <
                                                                this.props.data
                                                                    .workOrderPdfContentSummery
                                                                    .premadeFlangeFabricContents
                                                                    .length;
                                                                i++
                                                            ) {
                                                                if (
                                                                    this.props
                                                                        .data
                                                                        .workOrderPdfContentSummery
                                                                        .premadeFlangeFabricContents[
                                                                        i
                                                                    ]
                                                                        .orderOrigin ===
                                                                    2
                                                                ) {
                                                                    flangeShippingPrice.splice(
                                                                        i,
                                                                        0,
                                                                        undefined
                                                                    );
                                                                }
                                                            }
                                                            return (
                                                                <div>
                                                                    {flangeShippingPrice[
                                                                        index
                                                                    ] ? (
                                                                        <WorkOrderNumber>
                                                                            {
                                                                                flangeShippingPrice[
                                                                                    index
                                                                                ]
                                                                                    .price
                                                                            }
                                                                        </WorkOrderNumber>
                                                                    ) : (
                                                                        <WorkOrderNumber>
                                                                            COM
                                                                        </WorkOrderNumber>
                                                                    )}
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                    {this.props.data.workOrderPdfContentSummery.premadeTrimFabricContents.map(
                                                        (
                                                            item: any,
                                                            index: number
                                                        ) => {
                                                            const trimShippingPrice =
                                                                [
                                                                    ...this
                                                                        .state
                                                                        .prices
                                                                        .trimShippingPrice,
                                                                ].filter(
                                                                    (item) =>
                                                                        item.subType ===
                                                                        0
                                                                );
                                                            for (
                                                                let i = 0;
                                                                i <
                                                                this.props.data
                                                                    .workOrderPdfContentSummery
                                                                    .premadeTrimFabricContents
                                                                    .length;
                                                                i++
                                                            ) {
                                                                if (
                                                                    this.props
                                                                        .data
                                                                        .workOrderPdfContentSummery
                                                                        .premadeTrimFabricContents[
                                                                        i
                                                                    ]
                                                                        .orderOrigin ===
                                                                    2
                                                                ) {
                                                                    trimShippingPrice.splice(
                                                                        i,
                                                                        0,
                                                                        undefined
                                                                    );
                                                                }
                                                            }
                                                            return (
                                                                <div>
                                                                    {trimShippingPrice[
                                                                        index
                                                                    ] ? (
                                                                        <WorkOrderNumber>
                                                                            {
                                                                                trimShippingPrice[
                                                                                    index
                                                                                ]
                                                                                    .price
                                                                            }
                                                                        </WorkOrderNumber>
                                                                    ) : (
                                                                        <WorkOrderNumber>
                                                                            COM
                                                                        </WorkOrderNumber>
                                                                    )}
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                    {this.props.data.workOrderPdfContentSummery.weltCordingFabricContents.map(
                                                        (
                                                            item: any,
                                                            index: number
                                                        ) => {
                                                            const cordingShippingPrice =
                                                                [
                                                                    ...this
                                                                        .state
                                                                        .prices
                                                                        .cordingShippingPrice,
                                                                ].filter(
                                                                    (item) =>
                                                                        item.subType ===
                                                                        1
                                                                );
                                                            for (
                                                                let i = 0;
                                                                i <
                                                                this.props.data
                                                                    .workOrderPdfContentSummery
                                                                    .weltCordingFabricContents
                                                                    .length;
                                                                i++
                                                            ) {
                                                                if (
                                                                    this.props
                                                                        .data
                                                                        .workOrderPdfContentSummery
                                                                        .weltCordingFabricContents[
                                                                        i
                                                                    ]
                                                                        .orderOrigin ===
                                                                    2
                                                                ) {
                                                                    cordingShippingPrice.splice(
                                                                        i,
                                                                        0,
                                                                        undefined
                                                                    );
                                                                }
                                                            }
                                                            return (
                                                                <div>
                                                                    {cordingShippingPrice[
                                                                        index
                                                                    ] ? (
                                                                        <WorkOrderNumber>
                                                                            {
                                                                                cordingShippingPrice[
                                                                                    index
                                                                                ]
                                                                                    .price
                                                                            }
                                                                        </WorkOrderNumber>
                                                                    ) : (
                                                                        <WorkOrderNumber>
                                                                            COM
                                                                        </WorkOrderNumber>
                                                                    )}
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                    {this.props.data.workOrderPdfContentSummery.fabricFlangeFabricContents.map(
                                                        (
                                                            item: any,
                                                            index: number
                                                        ) => {
                                                            const flangeShippingPrice =
                                                                [
                                                                    ...this
                                                                        .state
                                                                        .prices
                                                                        .flangeShippingPrice,
                                                                ].filter(
                                                                    (item) =>
                                                                        item.subType ===
                                                                        1
                                                                );
                                                            for (
                                                                let i = 0;
                                                                i <
                                                                this.props.data
                                                                    .workOrderPdfContentSummery
                                                                    .fabricFlangeFabricContents
                                                                    .length;
                                                                i++
                                                            ) {
                                                                if (
                                                                    this.props
                                                                        .data
                                                                        .workOrderPdfContentSummery
                                                                        .fabricFlangeFabricContents[
                                                                        i
                                                                    ]
                                                                        .orderOrigin ===
                                                                    2
                                                                ) {
                                                                    flangeShippingPrice.splice(
                                                                        i,
                                                                        0,
                                                                        undefined
                                                                    );
                                                                }
                                                            }
                                                            return (
                                                                <div>
                                                                    {flangeShippingPrice[
                                                                        index
                                                                    ] ? (
                                                                        <WorkOrderNumber>
                                                                            {
                                                                                flangeShippingPrice[
                                                                                    index
                                                                                ]
                                                                                    .price
                                                                            }
                                                                        </WorkOrderNumber>
                                                                    ) : (
                                                                        <WorkOrderNumber>
                                                                            COM
                                                                        </WorkOrderNumber>
                                                                    )}
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                    {this.props.data.workOrderPdfContentSummery.fabricTrimFabricContents.map(
                                                        (
                                                            item: any,
                                                            index: number
                                                        ) => {
                                                            const trimShippingPrice =
                                                                [
                                                                    ...this
                                                                        .state
                                                                        .prices
                                                                        .trimShippingPrice,
                                                                ].filter(
                                                                    (item) =>
                                                                        item.subType ===
                                                                        1
                                                                );
                                                            for (
                                                                let i = 0;
                                                                i <
                                                                this.props.data
                                                                    .workOrderPdfContentSummery
                                                                    .fabricTrimFabricContents
                                                                    .length;
                                                                i++
                                                            ) {
                                                                if (
                                                                    this.props
                                                                        .data
                                                                        .workOrderPdfContentSummery
                                                                        .fabricTrimFabricContents[
                                                                        i
                                                                    ]
                                                                        .orderOrigin ===
                                                                    2
                                                                ) {
                                                                    trimShippingPrice.splice(
                                                                        i,
                                                                        0,
                                                                        undefined
                                                                    );
                                                                }
                                                            }
                                                            return (
                                                                <div>
                                                                    {trimShippingPrice[
                                                                        index
                                                                    ] ? (
                                                                        <WorkOrderNumber>
                                                                            {
                                                                                trimShippingPrice[
                                                                                    index
                                                                                ]
                                                                                    .price
                                                                            }
                                                                        </WorkOrderNumber>
                                                                    ) : (
                                                                        <WorkOrderNumber>
                                                                            COM
                                                                        </WorkOrderNumber>
                                                                    )}
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                    {this.props.data.liningsList.map(
                                                        (item: any) => {
                                                            const newItem =
                                                                item &&
                                                                item.draperyOrderLiningsFabric &&
                                                                item.draperyOrderLiningsFabric;
                                                            if (newItem) {
                                                                return (
                                                                    <div>
                                                                        {this
                                                                            .props
                                                                            .data &&
                                                                            this
                                                                                .props
                                                                                .data
                                                                                .liningsPatternItems &&
                                                                            item
                                                                                .draperyOrderLiningsFabric
                                                                                .orderOrigin ===
                                                                            2 ? (
                                                                            <WorkOrderNumber>
                                                                                COM
                                                                            </WorkOrderNumber>
                                                                        ) : (
                                                                            <WorkOrderNumber>
                                                                                {item.name
                                                                                    .toLowerCase()
                                                                                    .startsWith(
                                                                                        "lining"
                                                                                    )
                                                                                    ? this
                                                                                        .state
                                                                                        .prices
                                                                                        .liningsFabricShippingPrice
                                                                                    : this
                                                                                        .state
                                                                                        .prices
                                                                                        .interliningsFabricShippingPrice}
                                                                            </WorkOrderNumber>
                                                                        )}
                                                                    </div>
                                                                );
                                                            }
                                                        }
                                                    )}
                                                    {this.props.data
                                                        .othersEmbellishments[0]
                                                        .name &&
                                                        this.props.data.othersEmbellishments.map(
                                                            (
                                                                item: any,
                                                                index: number
                                                            ) => {
                                                                const othersEmbellishmentsShippingPrice =
                                                                    [
                                                                        ...this
                                                                            .state
                                                                            .prices
                                                                            .othersEmbellishmentsShippingPrice,
                                                                    ];
                                                                for (
                                                                    let i = 0;
                                                                    i <
                                                                    this.props
                                                                        .data
                                                                        .othersEmbellishments
                                                                        .length;
                                                                    i++
                                                                ) {
                                                                    if (
                                                                        this
                                                                            .props
                                                                            .data
                                                                            .othersEmbellishments[
                                                                            i
                                                                        ]
                                                                            .orderOrigin ===
                                                                        2
                                                                    ) {
                                                                        othersEmbellishmentsShippingPrice.splice(
                                                                            i,
                                                                            0,
                                                                            undefined
                                                                        );
                                                                    }
                                                                }
                                                                return (
                                                                    <div>
                                                                        {othersEmbellishmentsShippingPrice[
                                                                            index
                                                                        ] ? (
                                                                            <WorkOrderNumber>
                                                                                {
                                                                                    othersEmbellishmentsShippingPrice[
                                                                                        index
                                                                                    ]
                                                                                        .price
                                                                                }
                                                                            </WorkOrderNumber>
                                                                        ) : (
                                                                            <WorkOrderNumber>
                                                                                COM
                                                                            </WorkOrderNumber>
                                                                        )}
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                </SummaryTableCell>
                                            )}
                                        </SummaryTableCol>{" "}
                                        <SummaryTableCol text="not-fabrics">
                                            <SummaryTableCell
                                                position="center"
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    fontSize:
                                                        window.innerWidth <
                                                            992
                                                            ? "10px"
                                                            : "inherit",
                                                }}
                                            >
                                                Total Price ($)
                                            </SummaryTableCell>

                                            {workOrderEditMode ? (null) : (
                                                <SummaryTableCell
                                                    text="fabrics"
                                                    last="last"
                                                >
                                                    <WorkOrderNumber>
                                                        {this.props.data &&
                                                            this.props.data
                                                                .fabricsPatternItems &&
                                                            this.props.data
                                                                .mainFabric
                                                                .orderOrigin ===
                                                            1 ? (
                                                            (
                                                                parseFloat(
                                                                    this.state
                                                                        .prices
                                                                        .mainFabricUnitPrice
                                                                ) *
                                                                (this.props
                                                                    .data
                                                                    .workOrderPdfContentSummery
                                                                    .mainFabricContent
                                                                    .yardsNeeded ||
                                                                    1) +
                                                                parseFloat(
                                                                    this.state
                                                                        .prices
                                                                        .mainFabricShippingPrice
                                                                )
                                                            ).toLocaleString('en-US', {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            })
                                                        ) : (
                                                            <WorkOrderNumber>
                                                                COM
                                                            </WorkOrderNumber>
                                                        )}
                                                    </WorkOrderNumber>
                                                    {this.props.data &&
                                                        this.props.data
                                                            .workOrderPdfContent &&
                                                        this.props.data
                                                            .workOrderPdfContent
                                                            .bandingFabricContents &&
                                                        this.props.data.workOrderPdfContent.bandingFabricContents.map(
                                                            (
                                                                item: any,
                                                                index: number
                                                            ) => {
                                                                const bandingShippingPrice =
                                                                    [
                                                                        ...this
                                                                            .state
                                                                            .prices
                                                                            .bandingShippingPrice,
                                                                    ];
                                                                const bandingUnitPrice =
                                                                    [
                                                                        ...this
                                                                            .state
                                                                            .prices
                                                                            .bandingUnitPrice,
                                                                    ];
                                                                for (
                                                                    let i = 0;
                                                                    i <
                                                                    this.props
                                                                        .data
                                                                        .workOrderPdfContentSummery
                                                                        .bandingFabricContents
                                                                        .length;
                                                                    i++
                                                                ) {
                                                                    if (
                                                                        this
                                                                            .props
                                                                            .data
                                                                            .workOrderPdfContentSummery
                                                                            .bandingFabricContents[
                                                                            i
                                                                        ]
                                                                            .orderOrigin ===
                                                                        2
                                                                    ) {
                                                                        bandingShippingPrice.splice(
                                                                            i,
                                                                            0,
                                                                            undefined
                                                                        );
                                                                        bandingUnitPrice.splice(
                                                                            i,
                                                                            0,
                                                                            undefined
                                                                        );
                                                                    }
                                                                }

                                                                return (
                                                                    <div>
                                                                        <WorkOrderNumber>
                                                                            {this
                                                                                .props
                                                                                .data &&
                                                                                this
                                                                                    .props
                                                                                    .data
                                                                                    .fabricsPatternItems ? (
                                                                                bandingUnitPrice[
                                                                                    index
                                                                                ] &&
                                                                                    bandingShippingPrice[
                                                                                    index
                                                                                    ] ? (
                                                                                    (
                                                                                        parseFloat(
                                                                                            bandingUnitPrice[
                                                                                                index
                                                                                            ]
                                                                                                .price
                                                                                        ) *
                                                                                        (item.yardsNeeded ||
                                                                                            1) +
                                                                                        parseFloat(
                                                                                            bandingShippingPrice[
                                                                                                index
                                                                                            ]
                                                                                                .price
                                                                                        )
                                                                                    ).toLocaleString('en-US', {
                                                                                        minimumFractionDigits: 2,
                                                                                        maximumFractionDigits: 2
                                                                                    })
                                                                                ) : (
                                                                                    "COM"
                                                                                )
                                                                            ) : (
                                                                                <WorkOrderNumber>
                                                                                    0.00
                                                                                </WorkOrderNumber>
                                                                            )}
                                                                        </WorkOrderNumber>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    {this.props.data &&
                                                        this.props.data
                                                            .workOrderPdfContent &&
                                                        this.props.data
                                                            .workOrderPdfContent
                                                            .premadeCordingFabricContents &&
                                                        this.props.data.workOrderPdfContent.premadeCordingFabricContents.map(
                                                            (
                                                                item: any,
                                                                index: number
                                                            ) => {
                                                                const cordingUnitPrice =
                                                                    [
                                                                        ...this
                                                                            .state
                                                                            .prices
                                                                            .cordingUnitPrice,
                                                                    ].filter(
                                                                        (
                                                                            item
                                                                        ) =>
                                                                            item.subType ===
                                                                            0
                                                                    );
                                                                const cordingShippingPrice =
                                                                    [
                                                                        ...this
                                                                            .state
                                                                            .prices
                                                                            .cordingShippingPrice,
                                                                    ].filter(
                                                                        (
                                                                            item
                                                                        ) =>
                                                                            item.subType ===
                                                                            0
                                                                    );
                                                                for (
                                                                    let i = 0;
                                                                    i <
                                                                    this.props
                                                                        .data
                                                                        .workOrderPdfContentSummery
                                                                        .premadeCordingFabricContents
                                                                        .length;
                                                                    i++
                                                                ) {
                                                                    if (
                                                                        this
                                                                            .props
                                                                            .data
                                                                            .workOrderPdfContentSummery
                                                                            .premadeCordingFabricContents[
                                                                            i
                                                                        ]
                                                                            .orderOrigin ===
                                                                        2
                                                                    ) {
                                                                        cordingUnitPrice.splice(
                                                                            i,
                                                                            0,
                                                                            undefined
                                                                        );
                                                                        cordingShippingPrice.splice(
                                                                            i,
                                                                            0,
                                                                            undefined
                                                                        );
                                                                    }
                                                                }
                                                                return (
                                                                    <div>
                                                                        <WorkOrderNumber>
                                                                            {this
                                                                                .props
                                                                                .data &&
                                                                                this
                                                                                    .props
                                                                                    .data
                                                                                    .fabricsPatternItems ? (
                                                                                cordingUnitPrice[
                                                                                    index
                                                                                ] &&
                                                                                    cordingShippingPrice[
                                                                                    index
                                                                                    ] ? (
                                                                                    (
                                                                                        parseFloat(
                                                                                            cordingUnitPrice[
                                                                                                index
                                                                                            ]
                                                                                                .price
                                                                                        ) *
                                                                                        (item.yardsNeeded ||
                                                                                            1) +
                                                                                        parseFloat(
                                                                                            cordingShippingPrice[
                                                                                                index
                                                                                            ]
                                                                                                .price
                                                                                        )
                                                                                    ).toLocaleString('en-US', {
                                                                                        minimumFractionDigits: 2,
                                                                                        maximumFractionDigits: 2
                                                                                    })
                                                                                ) : (
                                                                                    "COM"
                                                                                )
                                                                            ) : (
                                                                                <WorkOrderNumber>
                                                                                    0.00
                                                                                </WorkOrderNumber>
                                                                            )}
                                                                        </WorkOrderNumber>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    {this.props.data &&
                                                        this.props.data
                                                            .workOrderPdfContent &&
                                                        this.props.data
                                                            .workOrderPdfContent
                                                            .premadeFlangeFabricContents &&
                                                        this.props.data.workOrderPdfContent.premadeFlangeFabricContents.map(
                                                            (
                                                                item: any,
                                                                index: number
                                                            ) => {
                                                                const flangeUnitPrice =
                                                                    [
                                                                        ...this
                                                                            .state
                                                                            .prices
                                                                            .flangeUnitPrice,
                                                                    ].filter(
                                                                        (
                                                                            item
                                                                        ) =>
                                                                            item.subType ===
                                                                            0
                                                                    );
                                                                const flangeShippingPrice =
                                                                    [
                                                                        ...this
                                                                            .state
                                                                            .prices
                                                                            .flangeShippingPrice,
                                                                    ].filter(
                                                                        (
                                                                            item
                                                                        ) =>
                                                                            item.subType ===
                                                                            0
                                                                    );
                                                                for (
                                                                    let i = 0;
                                                                    i <
                                                                    this.props
                                                                        .data
                                                                        .workOrderPdfContentSummery
                                                                        .premadeFlangeFabricContents
                                                                        .length;
                                                                    i++
                                                                ) {
                                                                    if (
                                                                        this
                                                                            .props
                                                                            .data
                                                                            .workOrderPdfContentSummery
                                                                            .premadeFlangeFabricContents[
                                                                            i
                                                                        ]
                                                                            .orderOrigin ===
                                                                        2
                                                                    ) {
                                                                        flangeUnitPrice.splice(
                                                                            i,
                                                                            0,
                                                                            undefined
                                                                        );
                                                                        flangeShippingPrice.splice(
                                                                            i,
                                                                            0,
                                                                            undefined
                                                                        );
                                                                    }
                                                                }

                                                                return (
                                                                    <div>
                                                                        <WorkOrderNumber>
                                                                            {this
                                                                                .props
                                                                                .data &&
                                                                                this
                                                                                    .props
                                                                                    .data
                                                                                    .fabricsPatternItems ? (
                                                                                flangeUnitPrice[
                                                                                    index
                                                                                ] &&
                                                                                    flangeShippingPrice[
                                                                                    index
                                                                                    ] ? (
                                                                                    (
                                                                                        (item.yardsNeeded ||
                                                                                            1) *
                                                                                        parseFloat(
                                                                                            flangeUnitPrice[
                                                                                                index
                                                                                            ]
                                                                                                .price
                                                                                        ) +
                                                                                        parseFloat(
                                                                                            flangeShippingPrice[
                                                                                                index
                                                                                            ]
                                                                                                .price
                                                                                        )
                                                                                    ).toLocaleString('en-US', {
                                                                                        minimumFractionDigits: 2,
                                                                                        maximumFractionDigits: 2
                                                                                    })
                                                                                ) : (
                                                                                    "COM"
                                                                                )
                                                                            ) : (
                                                                                <WorkOrderNumber>
                                                                                    0.00
                                                                                </WorkOrderNumber>
                                                                            )}
                                                                        </WorkOrderNumber>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    {this.props.data &&
                                                        this.props.data
                                                            .workOrderPdfContent &&
                                                        this.props.data
                                                            .workOrderPdfContent
                                                            .premadeTrimFabricContents &&
                                                        this.props.data.workOrderPdfContent.premadeTrimFabricContents.map(
                                                            (
                                                                item: any,
                                                                index: number
                                                            ) => {
                                                                const trimUnitPrice =
                                                                    [
                                                                        ...this
                                                                            .state
                                                                            .prices
                                                                            .trimUnitPrice,
                                                                    ].filter(
                                                                        (
                                                                            item
                                                                        ) =>
                                                                            item.subType ===
                                                                            0
                                                                    );
                                                                const trimShippingPrice =
                                                                    [
                                                                        ...this
                                                                            .state
                                                                            .prices
                                                                            .trimShippingPrice,
                                                                    ].filter(
                                                                        (
                                                                            item
                                                                        ) =>
                                                                            item.subType ===
                                                                            0
                                                                    );
                                                                for (
                                                                    let i = 0;
                                                                    i <
                                                                    this.props
                                                                        .data
                                                                        .workOrderPdfContentSummery
                                                                        .premadeTrimFabricContents
                                                                        .length;
                                                                    i++
                                                                ) {
                                                                    if (
                                                                        this
                                                                            .props
                                                                            .data
                                                                            .workOrderPdfContentSummery
                                                                            .premadeTrimFabricContents[
                                                                            i
                                                                        ]
                                                                            .orderOrigin ===
                                                                        2
                                                                    ) {
                                                                        trimUnitPrice.splice(
                                                                            i,
                                                                            0,
                                                                            undefined
                                                                        );
                                                                        trimShippingPrice.splice(
                                                                            i,
                                                                            0,
                                                                            undefined
                                                                        );
                                                                    }
                                                                }
                                                                return (
                                                                    <div>
                                                                        <WorkOrderNumber>
                                                                            {this
                                                                                .props
                                                                                .data &&
                                                                                this
                                                                                    .props
                                                                                    .data
                                                                                    .fabricsPatternItems ? (
                                                                                trimUnitPrice[
                                                                                    index
                                                                                ] &&
                                                                                    trimShippingPrice[
                                                                                    index
                                                                                    ] ? (
                                                                                    (
                                                                                        (item.yardsNeeded ||
                                                                                            1) *
                                                                                        parseFloat(
                                                                                            trimUnitPrice[
                                                                                                index
                                                                                            ]
                                                                                                .price
                                                                                        ) +
                                                                                        parseFloat(
                                                                                            trimShippingPrice[
                                                                                                index
                                                                                            ]
                                                                                                .price
                                                                                        )
                                                                                    ).toLocaleString('en-US', {
                                                                                        minimumFractionDigits: 2,
                                                                                        maximumFractionDigits: 2
                                                                                    })
                                                                                ) : (
                                                                                    "COM"
                                                                                )
                                                                            ) : (
                                                                                <WorkOrderNumber>
                                                                                    0.00
                                                                                </WorkOrderNumber>
                                                                            )}
                                                                        </WorkOrderNumber>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    {this.props.data &&
                                                        this.props.data
                                                            .workOrderPdfContent &&
                                                        this.props.data
                                                            .workOrderPdfContent
                                                            .weltCordingFabricContents &&
                                                        this.props.data.workOrderPdfContent.weltCordingFabricContents.map(
                                                            (
                                                                item: any,
                                                                index: number
                                                            ) => {
                                                                const cordingUnitPrice =
                                                                    [
                                                                        ...this
                                                                            .state
                                                                            .prices
                                                                            .cordingUnitPrice,
                                                                    ].filter(
                                                                        (
                                                                            item
                                                                        ) =>
                                                                            item.subType ===
                                                                            1
                                                                    );
                                                                const cordingShippingPrice =
                                                                    [
                                                                        ...this
                                                                            .state
                                                                            .prices
                                                                            .cordingShippingPrice,
                                                                    ].filter(
                                                                        (
                                                                            item
                                                                        ) =>
                                                                            item.subType ===
                                                                            1
                                                                    );
                                                                for (
                                                                    let i = 0;
                                                                    i <
                                                                    this.props
                                                                        .data
                                                                        .workOrderPdfContentSummery
                                                                        .weltCordingFabricContents
                                                                        .length;
                                                                    i++
                                                                ) {
                                                                    if (
                                                                        this
                                                                            .props
                                                                            .data
                                                                            .workOrderPdfContentSummery
                                                                            .weltCordingFabricContents[
                                                                            i
                                                                        ]
                                                                            .orderOrigin ===
                                                                        2
                                                                    ) {
                                                                        cordingUnitPrice.splice(
                                                                            i,
                                                                            0,
                                                                            undefined
                                                                        );
                                                                        cordingShippingPrice.splice(
                                                                            i,
                                                                            0,
                                                                            undefined
                                                                        );
                                                                    }
                                                                }
                                                                return (
                                                                    <div>
                                                                        <WorkOrderNumber>
                                                                            {this
                                                                                .props
                                                                                .data &&
                                                                                this
                                                                                    .props
                                                                                    .data
                                                                                    .fabricsPatternItems ? (
                                                                                cordingUnitPrice[
                                                                                    index
                                                                                ] &&
                                                                                    cordingShippingPrice[
                                                                                    index
                                                                                    ] ? (
                                                                                    (
                                                                                        (item.yardsNeeded ||
                                                                                            1) *
                                                                                        parseFloat(
                                                                                            cordingUnitPrice[
                                                                                                index
                                                                                            ]
                                                                                                .price
                                                                                        ) +
                                                                                        parseFloat(
                                                                                            cordingShippingPrice[
                                                                                                index
                                                                                            ]
                                                                                                .price
                                                                                        )
                                                                                    ).toLocaleString('en-US', {
                                                                                        minimumFractionDigits: 2,
                                                                                        maximumFractionDigits: 2
                                                                                    })
                                                                                ) : (
                                                                                    "COM"
                                                                                )
                                                                            ) : (
                                                                                <WorkOrderNumber>
                                                                                    0.00
                                                                                </WorkOrderNumber>
                                                                            )}
                                                                        </WorkOrderNumber>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    {this.props.data &&
                                                        this.props.data
                                                            .workOrderPdfContent &&
                                                        this.props.data
                                                            .workOrderPdfContent
                                                            .fabricFlangeFabricContents &&
                                                        this.props.data.workOrderPdfContent.fabricFlangeFabricContents.map(
                                                            (
                                                                item: any,
                                                                index: number
                                                            ) => {
                                                                const flangeUnitPrice =
                                                                    [
                                                                        ...this
                                                                            .state
                                                                            .prices
                                                                            .flangeUnitPrice,
                                                                    ].filter(
                                                                        (
                                                                            item
                                                                        ) =>
                                                                            item.subType ===
                                                                            1
                                                                    );
                                                                const flangeShippingPrice =
                                                                    [
                                                                        ...this
                                                                            .state
                                                                            .prices
                                                                            .flangeShippingPrice,
                                                                    ].filter(
                                                                        (
                                                                            item
                                                                        ) =>
                                                                            item.subType ===
                                                                            1
                                                                    );
                                                                for (
                                                                    let i = 0;
                                                                    i <
                                                                    this.props
                                                                        .data
                                                                        .workOrderPdfContentSummery
                                                                        .fabricFlangeFabricContents
                                                                        .length;
                                                                    i++
                                                                ) {
                                                                    if (
                                                                        this
                                                                            .props
                                                                            .data
                                                                            .workOrderPdfContentSummery
                                                                            .fabricFlangeFabricContents[
                                                                            i
                                                                        ]
                                                                            .orderOrigin ===
                                                                        2
                                                                    ) {
                                                                        flangeUnitPrice.splice(
                                                                            i,
                                                                            0,
                                                                            undefined
                                                                        );
                                                                        flangeShippingPrice.splice(
                                                                            i,
                                                                            0,
                                                                            undefined
                                                                        );
                                                                    }
                                                                }
                                                                return (
                                                                    <div>
                                                                        <WorkOrderNumber>
                                                                            {this
                                                                                .props
                                                                                .data &&
                                                                                this
                                                                                    .props
                                                                                    .data
                                                                                    .fabricsPatternItems ? (
                                                                                flangeUnitPrice[
                                                                                    index
                                                                                ] &&
                                                                                    flangeShippingPrice[
                                                                                    index
                                                                                    ] ? (
                                                                                    (
                                                                                        (item.yardsNeeded ||
                                                                                            1) *
                                                                                        parseFloat(
                                                                                            flangeUnitPrice[
                                                                                                index
                                                                                            ]
                                                                                                .price
                                                                                        ) +
                                                                                        parseFloat(
                                                                                            flangeShippingPrice[
                                                                                                index
                                                                                            ]
                                                                                                .price
                                                                                        )
                                                                                    ).toLocaleString('en-US', {
                                                                                        minimumFractionDigits: 2,
                                                                                        maximumFractionDigits: 2
                                                                                    })
                                                                                ) : (
                                                                                    "COM"
                                                                                )
                                                                            ) : (
                                                                                <WorkOrderNumber>
                                                                                    0.00
                                                                                </WorkOrderNumber>
                                                                            )}
                                                                        </WorkOrderNumber>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    {this.props.data &&
                                                        this.props.data
                                                            .workOrderPdfContent &&
                                                        this.props.data
                                                            .workOrderPdfContent
                                                            .fabricTrimFabricContents &&
                                                        this.props.data.workOrderPdfContent.fabricTrimFabricContents.map(
                                                            (
                                                                item: any,
                                                                index: number
                                                            ) => {
                                                                const trimUnitPrice =
                                                                    [
                                                                        ...this
                                                                            .state
                                                                            .prices
                                                                            .trimUnitPrice,
                                                                    ].filter(
                                                                        (
                                                                            item
                                                                        ) =>
                                                                            item.subType ===
                                                                            1
                                                                    );
                                                                const trimShippingPrice =
                                                                    [
                                                                        ...this
                                                                            .state
                                                                            .prices
                                                                            .trimShippingPrice,
                                                                    ].filter(
                                                                        (
                                                                            item
                                                                        ) =>
                                                                            item.subType ===
                                                                            1
                                                                    );
                                                                for (
                                                                    let i = 0;
                                                                    i <
                                                                    this.props
                                                                        .data
                                                                        .workOrderPdfContentSummery
                                                                        .fabricTrimFabricContents
                                                                        .length;
                                                                    i++
                                                                ) {
                                                                    if (
                                                                        this
                                                                            .props
                                                                            .data
                                                                            .workOrderPdfContentSummery
                                                                            .fabricTrimFabricContents[
                                                                            i
                                                                        ]
                                                                            .orderOrigin ===
                                                                        2
                                                                    ) {
                                                                        trimUnitPrice.splice(
                                                                            i,
                                                                            0,
                                                                            undefined
                                                                        );
                                                                        trimShippingPrice.splice(
                                                                            i,
                                                                            0,
                                                                            undefined
                                                                        );
                                                                    }
                                                                }
                                                                return (
                                                                    <div>
                                                                        <WorkOrderNumber>
                                                                            {this
                                                                                .props
                                                                                .data &&
                                                                                this
                                                                                    .props
                                                                                    .data
                                                                                    .fabricsPatternItems ? (
                                                                                trimUnitPrice[
                                                                                    index
                                                                                ] &&
                                                                                    trimShippingPrice[
                                                                                    index
                                                                                    ] ? (
                                                                                    (
                                                                                        (item.yardsNeeded ||
                                                                                            1) *
                                                                                        parseFloat(
                                                                                            trimUnitPrice[
                                                                                                index
                                                                                            ]
                                                                                                .price
                                                                                        ) +
                                                                                        parseFloat(
                                                                                            trimShippingPrice[
                                                                                                index
                                                                                            ]
                                                                                                .price
                                                                                        )
                                                                                    ).toLocaleString('en-US', {
                                                                                        minimumFractionDigits: 2,
                                                                                        maximumFractionDigits: 2
                                                                                    })
                                                                                ) : (
                                                                                    "COM"
                                                                                )
                                                                            ) : (
                                                                                <WorkOrderNumber>
                                                                                    0.00
                                                                                </WorkOrderNumber>
                                                                            )}
                                                                        </WorkOrderNumber>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    {this.props.data &&
                                                        this.props.data.liningsList.map(
                                                            (item: any) => {
                                                                return (
                                                                    <div>
                                                                        {this
                                                                            .props
                                                                            .data &&
                                                                            this
                                                                                .props
                                                                                .data
                                                                                .fabricsPatternItems &&
                                                                            item.draperyOrderLiningsFabric &&
                                                                            item
                                                                                .draperyOrderLiningsFabric
                                                                                .orderOrigin ===
                                                                            1 ? (
                                                                            item.name ===
                                                                                "Lining" ? (
                                                                                <WorkOrderNumber>
                                                                                    {(
                                                                                        ((liningMaterialYardage || 1) * parseFloat(this.state.prices.liningsFabricUnitPrice) +
                                                                                            parseFloat(this.state.prices.liningsFabricShippingPrice)) / quantity
                                                                                    ).toLocaleString('en-US', {
                                                                                        minimumFractionDigits: 2,
                                                                                        maximumFractionDigits: 2
                                                                                    })}
                                                                                    <p className="text-sm text-slate-400">
                                                                                        Included
                                                                                        with
                                                                                        fabrication
                                                                                        price
                                                                                    </p>
                                                                                </WorkOrderNumber>
                                                                            ) : (
                                                                                <WorkOrderNumber>
                                                                                    {(
                                                                                        ((interliningMaterialYardage || 1) * parseFloat(this.state.prices.interliningsFabricUnitPrice) +
                                                                                            parseFloat(this.state.prices.interliningsFabricShippingPrice)) / quantity
                                                                                    ).toLocaleString('en-US', {
                                                                                        minimumFractionDigits: 2,
                                                                                        maximumFractionDigits: 2
                                                                                    })}
                                                                                    <p className="text-sm text-slate-400">
                                                                                        Included
                                                                                        with
                                                                                        fabrication
                                                                                        price
                                                                                    </p>
                                                                                </WorkOrderNumber>
                                                                            )
                                                                        ) : this
                                                                            .props
                                                                            .data &&
                                                                            this
                                                                                .props
                                                                                .data
                                                                                .fabricsPatternItems &&
                                                                            item.draperyOrderLiningsFabric &&
                                                                            item
                                                                                .draperyOrderLiningsFabric
                                                                                .orderOrigin ===
                                                                            2 ? (
                                                                            <WorkOrderNumber>
                                                                                COM
                                                                            </WorkOrderNumber>
                                                                        ) : null}
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    {this.props.data &&
                                                        this.props.data
                                                            .othersEmbellishments &&
                                                        this.props.data
                                                            .othersEmbellishments[0]
                                                            .name &&
                                                        this.props.data.othersEmbellishments.map(
                                                            (
                                                                item: any,
                                                                index: number
                                                            ) => {
                                                                const othersEmbellishmentsUnitPrice =
                                                                    [
                                                                        ...this
                                                                            .state
                                                                            .prices
                                                                            .othersEmbellishmentsUnitPrice,
                                                                    ];
                                                                const othersEmbellishmentsShippingPrice =
                                                                    [
                                                                        ...this
                                                                            .state
                                                                            .prices
                                                                            .othersEmbellishmentsShippingPrice,
                                                                    ];
                                                                for (
                                                                    let i = 0;
                                                                    i <
                                                                    this.props
                                                                        .data
                                                                        .othersEmbellishments
                                                                        .length;
                                                                    i++
                                                                ) {
                                                                    if (
                                                                        this
                                                                            .props
                                                                            .data
                                                                            .othersEmbellishments[
                                                                            i
                                                                        ]
                                                                            .orderOrigin ===
                                                                        2
                                                                    ) {
                                                                        othersEmbellishmentsUnitPrice.splice(
                                                                            i,
                                                                            0,
                                                                            undefined
                                                                        );
                                                                        othersEmbellishmentsShippingPrice.splice(
                                                                            i,
                                                                            0,
                                                                            undefined
                                                                        );
                                                                    }
                                                                }
                                                                return (
                                                                    <div>
                                                                        <WorkOrderNumber>
                                                                            {this
                                                                                .props
                                                                                .data &&
                                                                                this
                                                                                    .props
                                                                                    .data
                                                                                    .fabricsPatternItems ? (
                                                                                othersEmbellishmentsUnitPrice[
                                                                                    index
                                                                                ] &&
                                                                                    othersEmbellishmentsShippingPrice[
                                                                                    index
                                                                                    ] ? (
                                                                                    (
                                                                                        (item && item.draperyOrderFabric
                                                                                          && item.draperyOrderFabric
                                                                                            .yardsNeeded ===
                                                                                            0
                                                                                            ? 0
                                                                                            : item && item.draperyOrderFabric 
                                                                                            && item.draperyOrderFabric
                                                                                                .yardsNeeded ||
                                                                                            1) *
                                                                                        parseFloat(
                                                                                            othersEmbellishmentsUnitPrice[
                                                                                                index
                                                                                            ]
                                                                                                .price
                                                                                        ) +
                                                                                        parseFloat(
                                                                                            othersEmbellishmentsShippingPrice[
                                                                                                index
                                                                                            ]
                                                                                                .price
                                                                                        )
                                                                                    ).toLocaleString('en-US', {
                                                                                        minimumFractionDigits: 2,
                                                                                        maximumFractionDigits: 2
                                                                                    })
                                                                                ) : (
                                                                                    "COM"
                                                                                )
                                                                            ) : (
                                                                                <WorkOrderNumber>
                                                                                    0.00
                                                                                </WorkOrderNumber>
                                                                            )}
                                                                        </WorkOrderNumber>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                </SummaryTableCell>
                                            )}
                                        </SummaryTableCol>
                                    </SummaryTable>

                                    {workOrderEditMode && (
                                        loadingUpdate ? (
                                            <div className="work_order_edit_mode_alert_container">
                                                <Loader uiSize="2em" />
                                            </div>
                                        ) : (
                                            <div className="work_order_edit_mode_alert_container">
                                                <RedWarning />
                                                <p className="work_order_edit_mode_alert_message">This field will be re-calculated after update</p>
                                            </div>
                                        )
                                    )}
                                </HContainer>
                            ) : (
                                <HContainer text="fabrics">
                                    <SummaryTable>
                                        <SummaryTableCol
                                            text="fabrics"
                                            color="header"
                                        >
                                            <SummaryTableCell
                                                className="fabricHeader"
                                                text="title"
                                                style={{
                                                    fontSize:
                                                        window.innerWidth <
                                                            992
                                                            ? "10px"
                                                            : "inherit",
                                                }}
                                            >
                                                Fabrics
                                            </SummaryTableCell>
                                            <SummaryTableCell>
                                            </SummaryTableCell>
                                            <SummaryTableCell>
                                            </SummaryTableCell>
                                            <SummaryTableCell>
                                            </SummaryTableCell>
                                        </SummaryTableCol>
                                        <SummaryTableCol text="not-fabrics">
                                            <SummaryTableCell
                                                className="fabricHeader"
                                                position="center"
                                                style={{
                                                    fontSize:
                                                        window.innerWidth <
                                                            992
                                                            ? "10px"
                                                            : "inherit",
                                                }}
                                            >
                                                Yardage
                                            </SummaryTableCell>
                                        </SummaryTableCol>
                                        <SummaryTableCol text="not-fabrics">
                                            <SummaryTableCell
                                                className="fabricHeader"
                                                position="center"
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    fontSize:
                                                        window.innerWidth <
                                                            992
                                                            ? "10px"
                                                            : "inherit",
                                                }}
                                            >
                                                Unit Price ($)
                                            </SummaryTableCell>
                                        </SummaryTableCol>{" "}
                                        <SummaryTableCol text="not-fabrics">
                                            <SummaryTableCell
                                                className="fabricHeader"
                                                position="center"
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    fontSize:
                                                        window.innerWidth <
                                                            992
                                                            ? "10px"
                                                            : "inherit",
                                                }}
                                            >
                                                S+H ($)
                                            </SummaryTableCell>
                                        </SummaryTableCol>{" "}
                                        <SummaryTableCol text="not-fabrics">
                                            <SummaryTableCell
                                                position="center"
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    fontSize:
                                                        window.innerWidth <
                                                            992
                                                            ? "10px"
                                                            : "inherit",
                                                }}
                                            >
                                                Total Price ($)
                                            </SummaryTableCell>
                                        </SummaryTableCol>
                                    </SummaryTable>
                                </HContainer>
                            )}
                        </SummaryInfoBlock>
                        <SummaryInfoBlock>
                            <DraperyHeader
                                noMarginTop
                                uiTimeStatus
                                uiTimeStatusChecked={
                                    !!draperyOrderSummary.estimateJson.num
                                }
                            >
                                ESTIMATE
                            </DraperyHeader>
                            <SummaryTable>
                                <SummaryTableCol>
                                    <SummaryTableCell>Number</SummaryTableCell>
                                    <SummaryTableCell>
                                        <CalculatorFieldInput uiWidth={100}>
                                            <CalculatorInput
                                                id="draperyOrderSummary.estimateJson.num"
                                                name="draperyOrderSummary.estimateJson.num"
                                                type="text"
                                                value={
                                                    draperyOrderSummary
                                                        .estimateJson.num || ""
                                                }
                                                onChange={
                                                    this.props
                                                        .handleInputChangeForString
                                                }
                                                uiDisabled={this.props.draperyOrder.archived}
                                            />
                                        </CalculatorFieldInput>
                                    </SummaryTableCell>
                                </SummaryTableCol>
                                <SummaryTableCol>
                                    <SummaryTableCell>
                                        Description
                                    </SummaryTableCell>
                                    <SummaryTableCell>
                                        <CalculatorFieldInput uiWidth={200}>
                                            <CalculatorInput
                                                id="draperyOrderSummary.estimateJson.description"
                                                name="draperyOrderSummary.estimateJson.description"
                                                type="text"
                                                value={
                                                    draperyOrderSummary
                                                        .estimateJson
                                                        .description || ""
                                                }
                                                onChange={
                                                    this.props
                                                        .handleInputChangeForString
                                                }
                                                uiDisabled={this.props.draperyOrder.archived}
                                            />
                                        </CalculatorFieldInput>
                                    </SummaryTableCell>
                                </SummaryTableCol>
                                <SummaryTableCol>
                                    <SummaryTableCell>
                                        Date Sent
                                    </SummaryTableCell>
                                    <SummaryTableCell>
                                        <DatePicker
                                            selected={datesForPicker.dateSent}
                                            onChange={(v: Date | null) => this.props.handleChangeDates(v, 'draperyOrderSummary.estimateJson.dateSent')}
                                            locale={'en'}
                                            isClearable={!this.props.draperyOrder.archived}
                                            placeholderText="MM/DD/YYYY"
                                            className="custom_date_picker"
                                            disabled={this.props.draperyOrder.archived}
                                        />
                                    </SummaryTableCell>
                                </SummaryTableCol>
                            </SummaryTable>
                        </SummaryInfoBlock>
                        <SummaryInfoBlock>
                            <DraperyHeader
                                noMarginTop
                                uiTimeStatus
                                uiTimeStatusChecked={
                                    !!draperyOrderSummary.invoiceJson.num
                                }
                            >
                                INVOICE
                            </DraperyHeader>
                            <SummaryTable>
                                <SummaryTableCol>
                                    <SummaryTableCell>Number</SummaryTableCell>
                                    <SummaryTableCell>
                                        <CalculatorFieldInput uiWidth={100}>
                                            <CalculatorInput
                                                id="draperyOrderSummary.invoiceJson.num"
                                                name="draperyOrderSummary.invoiceJson.num"
                                                type="text"
                                                value={
                                                    draperyOrderSummary
                                                        .invoiceJson.num || ""
                                                }
                                                onChange={
                                                    this.props
                                                        .handleInputChangeForString
                                                }
                                                uiDisabled={this.props.draperyOrder.archived}
                                            />
                                        </CalculatorFieldInput>
                                    </SummaryTableCell>
                                </SummaryTableCol>
                                <SummaryTableCol>
                                    <SummaryTableCell>
                                        Description
                                    </SummaryTableCell>
                                    <SummaryTableCell>
                                        <CalculatorFieldInput uiWidth={200}>
                                            <CalculatorInput
                                                id="draperyOrderSummary.invoiceJson.description"
                                                name="draperyOrderSummary.invoiceJson.description"
                                                type="text"
                                                value={
                                                    draperyOrderSummary
                                                        .invoiceJson
                                                        .description || ""
                                                }
                                                onChange={
                                                    this.props
                                                        .handleInputChangeForString
                                                }
                                                uiDisabled={this.props.draperyOrder.archived}
                                            />
                                        </CalculatorFieldInput>
                                    </SummaryTableCell>
                                </SummaryTableCol>
                                <SummaryTableCol>
                                    <SummaryTableCell>Paid</SummaryTableCell>
                                    <SummaryTableCell>
                                        <CalculatorFieldInput uiWidth={100}>
                                            <SelectList
                                                name="draperyOrderSummary.invoiceJson.paid"
                                                selectList={paidList}
                                                handleClickSelect={
                                                    this.props
                                                        .handleSelectListChange
                                                }
                                                valueSelect={
                                                    draperyOrderSummary
                                                        .invoiceJson.paid
                                                }
                                                flexDirection="row"
                                                smallPadding
                                                noClearBox
                                                uiDropArrow
                                                disabled={this.props.draperyOrder.archived}
                                            />
                                        </CalculatorFieldInput>
                                    </SummaryTableCell>
                                </SummaryTableCol>
                            </SummaryTable>
                        </SummaryInfoBlock>
                        <SummaryInfoBlock>
                            <DraperyHeader
                                noMarginTop
                                uiTimeStatus
                                uiTimeStatusChecked={componentChecked}
                            >
                                COMPONENTS NEEDED
                            </DraperyHeader>

                            <SummaryComponentTable>
                                <SummaryComponentTableRow>
                                    <SummaryComponentTableCell uiFlex={1}>
                                        Item
                                    </SummaryComponentTableCell>
                                    <SummaryComponentTableCell uiFlex={1}>
                                        PO #
                                    </SummaryComponentTableCell>
                                    <SummaryComponentTableCell uiFlex={1.5}>
                                        Ordered
                                    </SummaryComponentTableCell>
                                    <SummaryComponentTableCell uiFlex={1.5}>
                                        ETA
                                    </SummaryComponentTableCell>
                                    <SummaryComponentTableCell uiFlex={1.5}>
                                        Received
                                    </SummaryComponentTableCell>
                                    <SummaryComponentTableCell uiFlex={1}>
                                        Notes
                                    </SummaryComponentTableCell>
                                </SummaryComponentTableRow>

                                {this.renderComponentRow(
                                    mainFabric.draperyOrderSummaryComponent,
                                    `mainFabric`,
                                    `mainFabric.draperyOrderSummaryComponent`
                                )}
                                {embellishmentsList[0].checked &&
                                    bandings.length !== 0 &&
                                    bandings.map((el, idx) => {
                                        if (!el.draperyOrderFabric) {
                                            return "";
                                        }
                                        return this.renderComponentRow(
                                            el.draperyOrderFabric
                                                .draperyOrderSummaryComponent,
                                            `bandings.${idx}`,
                                            `bandings.${idx}.draperyOrderFabric.draperyOrderSummaryComponent`
                                        );
                                    })}
                                {embellishmentsList[1].checked &&
                                    cordings.length !== 0 &&
                                    cordings.map((el, idx) => {
                                        if (!el.draperyOrderFabric) {
                                            return "";
                                        }

                                        return this.renderComponentRow(
                                            el.draperyOrderFabric
                                                .draperyOrderSummaryComponent,
                                            `cordings.${idx}`,
                                            `cordings.${idx}.draperyOrderFabric.draperyOrderSummaryComponent`
                                        );
                                    })}
                                {embellishmentsList[2].checked &&
                                    flanges.length !== 0 &&
                                    flanges.map((el, idx) => {
                                        if (!el.draperyOrderFabric) {
                                            return "";
                                        }
                                        return this.renderComponentRow(
                                            el.draperyOrderFabric
                                                .draperyOrderSummaryComponent,
                                            `flanges.${idx}`,
                                            `flanges.${idx}.draperyOrderFabric.draperyOrderSummaryComponent`
                                        );
                                    })}
                                {embellishmentsList[3].checked &&
                                    trims.length !== 0 &&
                                    trims.map((el, idx) => {
                                        if (!el.draperyOrderFabric) {
                                            return "";
                                        }
                                        return this.renderComponentRow(
                                            el.draperyOrderFabric
                                                .draperyOrderSummaryComponent,
                                            `trims.${idx}`,
                                            `trims.${idx}.draperyOrderFabric.draperyOrderSummaryComponent`
                                        );
                                    })}
                                {embellishmentsList[4].checked &&
                                    othersEmbellishments.length !== 0 &&
                                    othersEmbellishments.map((el, idx) => {
                                        if (!el.draperyOrderFabric) {
                                            return "";
                                        }
                                        return this.renderComponentRow(
                                            el.draperyOrderFabric
                                                .draperyOrderSummaryComponent,
                                            `othersEmbellishments.${idx}`,
                                            `othersEmbellishments.${idx}.draperyOrderFabric.draperyOrderSummaryComponent`
                                        );
                                    })}
                                {draperyOrderSummaryComponents.map(
                                    (component, id) =>
                                        this.renderComponentRow(component, id)
                                )}
                            </SummaryComponentTable>
                            <AddSummaryComponentBtn
                                uiColor="primary"
                                type="button"
                                onClick={this.props.handleAddSummaryComponent}
                                uiDisabled={this.props.draperyOrder.archived}
                            >
                                Add more
                            </AddSummaryComponentBtn>
                        </SummaryInfoBlock>
                        <SummaryInfoBlock>
                            <DraperyHeader
                                noMarginTop
                                uiTimeStatus
                                uiTimeStatusChecked={
                                    !!draperyOrderSummary.productionJson
                                        .finishedDate
                                }
                            >
                                PRODUCTION
                            </DraperyHeader>
                            <SummaryTable>
                                <SummaryTableCol>
                                    <SummaryTableCell>
                                        Start Date
                                    </SummaryTableCell>
                                    <SummaryTableCell>
                                        <DatePicker
                                            selected={datesForPicker.startDate}
                                            onChange={(v: Date | null) => this.props.handleChangeDates(v, 'draperyOrderSummary.productionJson.startDate')}
                                            locale={'en'}
                                            isClearable={!this.props.draperyOrder.archived}
                                            placeholderText="MM/DD/YYYY"
                                            className="custom_date_picker"
                                            disabled={this.props.draperyOrder.archived}
                                        />
                                    </SummaryTableCell>
                                </SummaryTableCol>
                                <SummaryTableCol>
                                    <SummaryTableCell>
                                        Due Date
                                    </SummaryTableCell>
                                    <SummaryTableCell>
                                        <DatePicker
                                            selected={datesForPicker.dueDate}
                                            onChange={(v: Date | null) => this.props.handleChangeDates(v, 'draperyOrderSummary.productionJson.dueDate')}
                                            locale={'en'}
                                            isClearable={!this.props.draperyOrder.archived}
                                            placeholderText="MM/DD/YYYY"
                                            className="custom_date_picker"
                                            disabled={this.props.draperyOrder.archived}
                                        />
                                    </SummaryTableCell>
                                </SummaryTableCol>
                                <SummaryTableCol>
                                    <SummaryTableCell>
                                        Finished Date
                                    </SummaryTableCell>
                                    <SummaryTableCell>
                                        <DatePicker
                                            selected={datesForPicker.finishedDate}
                                            onChange={(v: Date | null) => this.props.handleChangeDates(v, 'draperyOrderSummary.productionJson.finishedDate')}
                                            locale={'en'}
                                            isClearable={!this.props.draperyOrder.archived}
                                            placeholderText="MM/DD/YYYY"
                                            className="custom_date_picker"
                                            disabled={this.props.draperyOrder.archived}
                                        />
                                    </SummaryTableCell>
                                </SummaryTableCol>
                            </SummaryTable>
                        </SummaryInfoBlock>
                        <SummaryInfoBlock>
                            <DraperyHeader
                                noMarginTop
                                uiTimeStatus
                                uiTimeStatusChecked={
                                    !!draperyOrderSummary.installationJson
                                        .dateOfInstall
                                }
                            >
                                INSTALLATION
                            </DraperyHeader>
                            <SummaryTable>
                                <SummaryTableCol>
                                    <SummaryTableCell>Order #</SummaryTableCell>
                                    <SummaryTableCell>
                                        <CalculatorFieldInput uiWidth={100}>
                                            <CalculatorInput
                                                type="text"
                                                id="draperyOrderSummary.installationJson.orderNum"
                                                name="draperyOrderSummary.installationJson.orderNum"
                                                value={
                                                    draperyOrderSummary
                                                        .installationJson
                                                        .orderNum || ""
                                                }
                                                onChange={
                                                    this.props
                                                        .handleInputChangeForString
                                                }
                                                uiDisabled={this.props.draperyOrder.archived}
                                            />
                                        </CalculatorFieldInput>
                                    </SummaryTableCell>
                                </SummaryTableCol>
                                <SummaryTableCol>
                                    <SummaryTableCell>
                                        Date of Install
                                    </SummaryTableCell>
                                    <SummaryTableCell>
                                        <DatePicker
                                            selected={datesForPicker.installDate}
                                            onChange={(v: Date | null) => this.props.handleChangeDates(v, 'draperyOrderSummary.installationJson.dateOfInstall')}
                                            locale={'en'}
                                            isClearable={!this.props.draperyOrder.archived}
                                            placeholderText="MM/DD/YYYY"
                                            className="custom_date_picker"
                                            disabled={this.props.draperyOrder.archived}
                                        />
                                    </SummaryTableCell>
                                </SummaryTableCol>
                            </SummaryTable>
                        </SummaryInfoBlock>
                    </SummaryContent>
                </SummaryContainer>
            </>
        );
    }

    public renderComponentRow(
        component: DraperyModule.DraperyOrderSummaryComponent,
        id: number | string,
        targetName?: string
    ) {
        const componentOrderedDate = !component.orderedDate ? null : moment(component.orderedDate).isValid() ? moment(component.orderedDate).toDate() : null

        const componentETADate = !component.eta ? null : moment(component.eta).isValid() ? moment(component.eta).toDate() : null

        const componentReceivedDate = !component.receivedDate ? null : moment(component.receivedDate).isValid() ? moment(component.receivedDate).toDate() : null
        return (
            <SummaryComponentTableRow key={id}>
                <SummaryComponentTableCell uiFlex={1}>
                    {targetName ? (
                        component.item || ""
                    ) : (
                        <CalculatorFieldInput uiWidth={100}>
                            <CalculatorInput
                                type="text"
                                id={`draperyOrderSummaryComponents.${id}.item`}
                                name={`draperyOrderSummaryComponents.${id}.item`}
                                value={component.item || ""}
                                onChange={this.props.handleInputChangeForString}
                                uiDisabled={this.props.draperyOrder.archived}
                            />
                        </CalculatorFieldInput>
                    )}
                </SummaryComponentTableCell>
                <SummaryComponentTableCell uiFlex={1}>
                    <CalculatorFieldInput uiWidth={100}>
                        <CalculatorInput
                            type="text"
                            id={
                                targetName
                                    ? `${targetName}.po`
                                    : `draperyOrderSummaryComponents.${id}.po`
                            }
                            name={
                                targetName
                                    ? `${targetName}.po`
                                    : `draperyOrderSummaryComponents.${id}.po`
                            }
                            value={component.po || ""}
                            onChange={this.props.handleInputChangeForString}
                            uiDisabled={this.props.draperyOrder.archived}
                        />
                    </CalculatorFieldInput>
                </SummaryComponentTableCell>
                <SummaryComponentTableCell uiFlex={1.5}>
                    <DatePicker
                        className="w-full custom_date_picker"
                        selected={componentOrderedDate}
                        isClearable={!this.props.draperyOrder.archived}
                        placeholderText="MM/DD/YYYY"
                        onChange={(v: Date | null) => this.props.handleChangeDates(v, (targetName ? `${targetName}.orderedDate` : `draperyOrderSummaryComponents.${id}.orderedDate`))}
                        disabled={this.props.draperyOrder.archived}
                    />
                </SummaryComponentTableCell>
                <SummaryComponentTableCell uiFlex={1.5}>
                    <DatePicker
                        className="w-full custom_date_picker"
                        selected={componentETADate}
                        isClearable={!this.props.draperyOrder.archived}
                        placeholderText="MM/DD/YYYY"
                        onChange={(v: Date | null) => this.props.handleChangeDates(v, (targetName ? `${targetName}.eta` : `draperyOrderSummaryComponents.${id}.eta`))}
                        disabled={this.props.draperyOrder.archived}
                    />
                </SummaryComponentTableCell>
                <SummaryComponentTableCell uiFlex={1.5}>
                    <DatePicker
                        className="w-full custom_date_picker"
                        selected={componentReceivedDate}
                        isClearable={!this.props.draperyOrder.archived}
                        placeholderText="MM/DD/YYYY"
                        onChange={(v: Date | null) => this.props.handleChangeDates(v, (targetName ? `${targetName}.receivedDate` : `draperyOrderSummaryComponents.${id}.receivedDate`))}
                        disabled={this.props.draperyOrder.archived}
                    />
                </SummaryComponentTableCell>
                <SummaryComponentTableCell uiFlex={1}>
                    <CalculatorFieldInput uiWidth={100}>
                        <CalculatorInput
                            type="text"
                            id={
                                targetName
                                    ? `${targetName}.notes`
                                    : `draperyOrderSummaryComponents.${id}.notes`
                            }
                            name={
                                targetName
                                    ? `${targetName}.notes`
                                    : `draperyOrderSummaryComponents.${id}.notes`
                            }
                            value={component.notes || ""}
                            onChange={this.props.handleInputChangeForString}
                            uiDisabled={this.props.draperyOrder.archived}
                        />
                    </CalculatorFieldInput>
                    {typeof id === "number" && (
                        <DeleteBtn
                            disabled={this.props.draperyOrder.archived}
                            uiDisabled={this.props.draperyOrder.archived}
                            uiColor="primary"
                            type="button"
                            onClick={() =>
                                this.props.handleDeleteSummaryComponent(id)
                            }
                        >
                            &times;
                        </DeleteBtn>
                    )}
                </SummaryComponentTableCell>
            </SummaryComponentTableRow>
        );
    }
}

export default SummaryTab;
